import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { setUser } from 'store/reducers/auth/authSlice'
import { switchApplicationLogin } from 'store/reducers/auth/thunk'
import styles from './tokenLogin.module.scss'

const TokenLogin = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { token } = useParams()

  useEffect(() => {
    const _fetchUserFromToken = async customToken => {
      await dispatch(setUser({}))
      const { payload = {} } = await dispatch(switchApplicationLogin(customToken))
      if (payload?.user?.role !== 'user' && payload.token && payload.refreshToken) {
        history.push('/home')
      } else {
        history.push('/register')
      }
    }
    if (token) _fetchUserFromToken(token)
  }, [])

  return (
    <div className={styles.wrapper}>
      <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{ color: 'black' }}></i>
    </div>
  )
}
export default TokenLogin
