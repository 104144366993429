import { request } from './http.service'

class PaymentService {
  async payment(data) {
    return request('post', '/api/payment', {
      mode: data.mode,
      data: data,
      type: 'stripe'
    })
  }

  async getListOfAllCards(data) {
    return request('post', '/api/payment', {
      mode: 'list-payment-methods',
      data: data,
      type: 'stripe'
    })
  }

  async removeUserCreditCard(data) {
    return request('post', '/api/payment', {
      mode: 'delete-payment-method',
      data: data,
      type: 'stripe'
    })
  }
}

export default new PaymentService()
