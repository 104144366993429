import React from 'react'
import styles from './attributeIcon.module.scss'

const AttributeIcon = ({ plus = true }) => {
  return (
    <div className={styles.container}>
      <div className={styles.horizontalLine}></div>
      {plus && <div className={styles.verticalLine}></div>}
    </div>
  )
}

export default AttributeIcon
