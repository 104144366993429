import { Buffer } from 'buffer'
import Button from 'components/button'
import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardWrapper from 'components/card/components/wrapper'
import ShareOptions from 'components/shareOptions'
import SimpleSearch from 'components/simpleSearch'
import SwitchButton, { SWITCH_SIZE } from 'components/switch'
import User from 'components/user'
import { awsUrlResize, url } from 'environment'
import cloneDeep from 'lodash/cloneDeep'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dataService from 'services/data.service'
import fileService from 'services/file.service'
// import { toastService } from 'services/toastService'
import { CARD_BACK_TYPES, CARD_SIZE, CARD_TYPES } from 'staticConstants'
// import { cardActions } from 'store/actions/card.actions'
// import { dataActions } from 'store/actions/data.actions'
import { converFileToBase64 } from 'utils/getBaseFromFile'
import { isObjectEmpty } from 'utils/helpers'
import styles from './cardShare.module.scss'

const tabs = [
  { value: 'private', title: 'Private' },
  { value: 'public', title: 'Public' }
]
export const uploadCardImages = async data => {
  const tempData = cloneDeep(data)
  // FRONT IMAGE
  if (tempData.frontImage instanceof File) tempData.frontImage = await uploadMedia(tempData.frontImage)
  if (tempData.frontImage instanceof Element)
    tempData.frontImage = await uploadMedia(tempData.frontImage, null, null, null, true)
  // BACK FIELDS OR FULL CARD IMAGE
  if (!tempData.fullCardImage || !tempData.fullCardImage.active) {
    tempData.fullCardImage = { active: false, image: '' }
    if (tempData.backFields.length > 0)
      await Promise.all(
        tempData.backFields.map(async field => {
          if (field.type === CARD_BACK_TYPES.image && field.content instanceof File) {
            field.content = await uploadMedia(field.content)
          }
          if (field.type === CARD_BACK_TYPES.image && field.content instanceof Element) {
            field.content = await uploadMedia(field.content, null, null, null, true)
          }
        })
      )
  } else {
    tempData.backFields = []
    tempData.fullCardImage.image = await uploadMedia(tempData.fullCardImage.image)
  }
  // DOUBLE CARD IMAGE
  if (tempData.doubleCardImage instanceof File) tempData.doubleCardImage = await uploadMedia(tempData.doubleCardImage)
  // SLIDER
  await Promise.all(
    tempData.slider.map(async slide => {
      if (slide.image instanceof File) slide.image = await uploadMedia(slide.image)
      else if (slide.image instanceof Element) slide.image = await uploadMedia(slide.image, null, null, null, true)
    })
  )
  return tempData
}
export const uploadPackImages = async data => {
  const tempData = cloneDeep(data)
  // LOGO IMAGE
  if (tempData.logoImage instanceof File) tempData.logoImage = await uploadMedia(tempData.logoImage)
  return tempData
}
export const uploadMedia = (file, showProgress = true, cancelToken, setLoader, uploadCanvas = false) => {
  if (!uploadCanvas) {
    return new Promise((resolve, reject) => {
      converFileToBase64(file).then(base64 => {
        const options = {}
        if (setLoader)
          options.onUploadProgress = event => {
            const { loaded, total } = event
            const percent = Math.floor((loaded * 100) / total)
            setLoader(percent === 0 ? 1 : percent)
          }
        if (cancelToken) options.cancelToken = cancelToken
        fileService
          .run('s3', 'upload', { data: base64, name: file.name }, showProgress ? options : {})
          .then(res => {
            if (res) resolve(res.fileId)
            else reject(new Error('Empty response!'))
          })
          .catch(err => {
            reject(new Error(err))
          })
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      const base64result = file.toDataURL().split(',')[1]
      const binaryBlob = Buffer.from(base64result, 'base64')
      fileService
        .run('s3', 'upload', { data: binaryBlob, name: 'filename.jpg' })
        .then(res => {
          if (res) resolve(res.fileId)
          else reject(new Error('Empty response!'))
        })
        .catch(err => {
          reject(new Error(err))
        })
    })
  }
}

export const CardShare = ({
  isPublished = false,
  isPublic = 'private',
  className,
  // users,
  cardClass,
  onPublicChange,
  onPublish,
  showBtns = true,
  publishDisabled = false,
  ShareIdFnc,
  card
}) => {
  // const dispatch = useDispatch()
  const [active, setActive] = useState(isPublic ? 'public' : 'private')
  const [loadUpdate, setLoadUpdate] = useState(false)
  const [followUser, setFollowUser] = useState('')
  const [dataFollowing, setDataFollowing] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const store = useSelector(state => state)
  const { user } = useSelector(state => state.auth)

  const setActiveHandle = value => {
    setActive(value)
    onPublicChange && onPublicChange(value)
  }

  const backFollowing = async () => {
    try {
      const res = await dataService.run('data', 'followUser', 'getFollowingUsers', {
        userId: store.auth.user._id
      })
      if (!res.isAxiosError) {
        setDataFollowing(res)
      }
    } catch (err) {
      console.warn('_e', err)
    }
  }

  useEffect(() => {
    backFollowing()
  }, [])

  const shareHandler = async () => {
    if (isObjectEmpty(selectedUser)) return
    // const data = { cardId: card._id, userId: store.auth.user?._id, cardType: card.cardType, interactionType: 'share' }
    // dispatch(cardActions.saveCardInteraction({ data, card }))
    ShareIdFnc(url + '/home/' + card._id)

    const message = {
      type: 'message',
      subType: card.type,
      data: { _id: card?._id, userId: card?.userId, username: card?.username },
      to: selectedUser?.username,
      toId: selectedUser?._id,
      from: user?.username,
      fromId: user?._id
    }

    console.log(message)
    if (card?._id) {
      // dispatch(dataActions.run('chats', 'message', 'new', message, { storeKey: 'user' })).then(() => {
      //   toastService('success', `${card.type} sent!`)
      //   setSelectedUser({})
      // })
    }
  }

  const onPublishHandler = async () => {
    setLoadUpdate(true)
    if (onPublish) await onPublish()
    setLoadUpdate(false)
  }

  const onSearchEnd = value => {
    setFollowUser(value)
  }

  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card className={cardClass} size={CARD_SIZE.normal} type={CARD_TYPES.Share}>
        <CardBody type={CARD_TYPES.Share}>
          <div className={styles.content}>
            <div className={styles.header}>
              {showBtns ? (
                <>
                  <SwitchButton
                    activeTab={active}
                    setActiveTab={setActiveHandle}
                    tabs={tabs}
                    size={SWITCH_SIZE.Large}
                  />
                  <div className={styles.info}>
                    {active === 'private' && <p>Your card will be private and you can only send it to your contacts</p>}
                    {active === 'public' && (
                      <p>Your card will show up in the main deck and be accessible to other users. </p>
                    )}
                  </div>
                </>
              ) : (
                <p className={styles.backShareCard}>Share {card.type}</p>
              )}
            </div>
            <div className={styles.icons}>
              <ShareOptions ShareIdFnc={ShareIdFnc} card={card} />
            </div>
            <h6>Share with your contacts</h6>
            <SimpleSearch
              onSearch={onSearchEnd}
              selected={!isObjectEmpty(selectedUser) ? selectedUser?.username : ''}
              onSelectionClose={() => setSelectedUser({})}
            />
            <div className={styles.users}>
              {dataFollowing
                .filter(usr => usr.username.toLowerCase().includes(followUser))
                .map(({ _id, username }, index) => {
                  return (
                    <User
                      key={index}
                      logo={awsUrlResize + '/' + _id + '.jpg'}
                      subtitle={username}
                      className={styles.usersColumn}
                      onClick={() => setSelectedUser(dataFollowing[index])}
                    />
                  )
                })}
            </div>
          </div>
          {showBtns ? (
            <>
              <Button
                variation="primary"
                label={isPublished ? 'Published' : 'Publish'}
                disabled={isPublished || publishDisabled}
                size="xs"
                className={styles.buttonPosition}
                onClick={onPublishHandler}
                spinner={loadUpdate}
              />
            </>
          ) : (
            <Button
              variation="primary"
              label={'Share'}
              size="xs"
              className={styles.buttonPosition}
              onClick={shareHandler}
              disabled={isObjectEmpty(selectedUser)}
            />
          )}
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CardShare
