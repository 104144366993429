// import { createSlice, current } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { localStorageService } from 'services/localStorage.service'
import { sessionStorageService } from 'services/sessionStorage.service'
import { clearUserStorage, logoutSpecificUser } from 'utils/logout'
import {
  activateOrDeactivateProfile,
  changePassword,
  changePersonalDetails,
  checkRegister,
  checkTimestamp,
  confirmChangeAccountType,
  confirmEmail,
  confirmForgotPasswordChange,
  login,
  register,
  sendNewsletter,
  sendRespondEmail,
  updateUser,
  switchApplicationLogin,
  confirmCancelSub,
  addAccount
} from './thunk'

export const getLocalUser = () => {
  const username = sessionStorageService.get('username')
  const lastUser = localStorageService.get('lastUser')
  const user = localStorageService.get(`user_${username ?? lastUser}`) || {}
  return user
}

export const getSessionUser = () => {
  const username = sessionStorageService.get('username')
  const user = localStorageService.get(`user_${username}`) || {}
  return user
}

const initialUser = getLocalUser()
const initialState = {
  user: initialUser,
  error: null,
  path: '',
  sendRespondEmailStatus: 0,
  checkRegister: false,
  registeredUser: {},
  confirmEmail: false,
  confirmForgotPasswordChange: null,
  confirmForgotPasswordChangeTimestamp: 0,
  errorMessage: [],
  changePassword: false,
  passwordChangeAt: 0,
  sendedNewsletter: false,
  newsletterTimestamp: 0,
  accountTypeFlag: false,
  accountTypeTimestamp: 0,
  perosnalDetailsTimestamp: 0,
  pollAnswers: []
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // add your non- async reducers here
    setRoute(state, action) {
      state.path = action.payload
    },

    setUserForRegistartion(state, action) {
      state.userForRegistartion = action.payload
    },

    setRegisteredUser(state, action) {
      state.registeredUser = action.payload
    },

    setErrorMessage(state, action) {
      state.errorMessage = action.payload
    },

    logOut(state) {
      state.user = {}
      logoutSpecificUser(getLocalUser())
    },

    logOutSessionUser(state) {
      state.user = {}
      logoutSpecificUser(getSessionUser())
    },

    setUser(state, action) {
      state.user = action.payload
    },
    setPollAnswers(state, action) {
      state.pollAnswers = action.payload
    }
  },
  extraReducers: builder => {
    // add your async reducers here

    // Login
    builder.addCase(login.fulfilled, (state, action) => {
      // Immer return state in Proxies form to read curent state you need use current form( @reduxjs/toolkit)
      // console.log('auth login extraReducers success', current(state), action.payload)
      const { user = {}, token = '', refreshToken = '' } = action.payload
      storeUserLocalStorage(user, token, refreshToken, user.rememberMe)
      state.user = user
      state.error = null
    })

    builder.addCase(login.rejected, (state, action) => {
      state.error = action.error
    })

    builder.addCase(addAccount.fulfilled, (state, action) => {
      const { user = {}, token = '', refreshToken = '' } = action.payload
      addAccountLocalStorage(user, token, refreshToken)
    })

    builder.addCase(addAccount.rejected, (state, action) => {
      state.error = action.error
    })

    builder.addCase(checkRegister.fulfilled, state => {
      state.checkRegister = true
      state.checkRegisterTimestamp = moment().valueOf()
    })

    builder.addCase(checkRegister.rejected, state => {
      state.checkRegister = false
      state.checkRegisterTimestamp = moment().valueOf()
    })

    // Register
    builder.addCase(register.fulfilled, (state, action) => {
      state.registeredUser = action.payload
      state.error = null
    })

    builder.addCase(register.rejected, (state, action) => {
      state.registeredUser = {}
      state.error = action.error
    })

    builder.addCase(updateUser.fulfilled, (state, action) => {
      const storageUser = getLocalUser()
      if (action.payload.email === storageUser.email) {
        state.user = action.payload
        localStorageService.set(`user_${storageUser.username}`, action.payload)
      }
      state.error = null
    })

    builder.addCase(updateUser.rejected, (state, action) => {
      state.error = action.error
    })

    builder.addCase(activateOrDeactivateProfile.fulfilled, (state, action) => {
      localStorageService.set(`user_${action.payload.username}`, action.payload)
      state.user = action.payload
      state.error = null
    })

    builder.addCase(activateOrDeactivateProfile.rejected, (state, action) => {
      state.error = action.error
    })

    builder.addCase(sendRespondEmail.fulfilled, state => {
      state.sendRespondEmailStatus = new Date().getTime()
    })

    builder.addCase(sendRespondEmail.rejected, (state, action) => {
      state.sendRespondEmailStatus = 0
      state.error = action.error
    })

    builder.addCase(confirmEmail.fulfilled, (state, action) => {
      const { user = {}, token = '', refreshToken = '' } = action.payload
      if (user && user._id) {
        storeUserLocalStorage(user, token, refreshToken, true)
        state.user = user
        state.error = null
        state.confirmEmail = true
      }
    })

    builder.addCase(confirmEmail.rejected, state => {
      state.confirmEmail = false
    })

    builder.addCase(confirmForgotPasswordChange.fulfilled, state => {
      state.error = null
      state.confirmForgotPasswordChange = true
      state.confirmForgotPasswordChangeTimestamp = moment().valueOf()
    })

    builder.addCase(confirmForgotPasswordChange.rejected, (state, action) => {
      state.confirmForgotPasswordChange = 'false'
      state.error = action.error
      state.confirmForgotPasswordChangeTimestamp = moment().valueOf()
    })

    builder.addCase(changePassword.fulfilled, state => {
      state.error = null
      state.changePassword = true
      state.passwordChangeAt = new Date().getTime()
    })

    builder.addCase(changePassword.rejected, (state, action) => {
      state.changePassword = false
      state.error = action.error

      state.passwordChangeAt = new Date().getTime()
    })

    builder.addCase(sendNewsletter.fulfilled, state => {
      state.sendedNewsletter = true
      state.newsletterTimestamp = new Date().getTime()
    })

    builder.addCase(sendNewsletter.rejected, (state, action) => {
      state.sendedNewsletter = false
      state.error = action.error
      state.newsletterTimestamp = new Date().getTime()
    })

    builder.addCase(confirmChangeAccountType.fulfilled, (state, action) => {
      const { user = {} } = action.payload
      localStorageService.set(`user_${user.username}`, user)
      state.user = user
      state.accountTypeFlag = true
      state.accountTypeTimestamp = moment().valueOf()
    })

    builder.addCase(switchApplicationLogin.fulfilled, (state, action) => {
      const { user = {}, token, refreshToken } = action.payload
      if (user?.role !== 'user' && token && refreshToken) {
        storeUserLocalStorage(user, token, refreshToken, true)
        state.user = user
      }
      state.error = null
    })

    builder.addCase(switchApplicationLogin.rejected, (state, action) => {
      state.user = {}
      state.error = action.error
      clearUserStorage()
    })

    builder.addCase(confirmChangeAccountType.rejected, (state, action) => {
      state.accountTypeFlag = false
      state.error = action.error
      state.accountTypeTimestamp = moment().valueOf()
    })

    builder.addCase(checkTimestamp.fulfilled, (state, action) => {
      state.type = action.payload
    })

    builder.addCase(checkTimestamp.rejected, (state, action) => {
      state.type = action.payload
      state.error = action.error
    })

    builder.addCase(changePersonalDetails.fulfilled, (state, action) => {
      localStorageService.set(`user_${action.payload.username}`, action.payload)
      state.user = action.payload
    })

    builder.addCase(changePersonalDetails.rejected, (state, action) => {
      state.error = action.error
    })

    builder.addCase(confirmCancelSub.fulfilled, (state, action) => {
      localStorageService.set(`user_${action.payload[1].username}`, action.payload[1])
      state.user = action.payload[1]
    })
  }
})

export const {
  logOut,
  logOutSessionUser,
  setRoute,
  setUserForRegistartion,
  setRegisteredUser,
  setErrorMessage,
  setUser,
  setPollAnswers
} = auth.actions
export default auth.reducer

export const storeUserLocalStorage = (user, token, refreshToken, rememberMe) => {
  sessionStorageService.set('username', user.username)
  localStorageService.set(`user_${user.username}`, user)
  localStorageService.set(`token_${user.username}`, token)
  localStorageService.set(`refreshToken_${user.username}`, refreshToken)
  localStorageService.set('REMEMBER_ME', rememberMe)
  localStorageService.set('lastUser', user.username)
}

export const addAccountLocalStorage = (user, token, refreshToken) => {
  localStorageService.set(`user_${user.username}`, user)
  localStorageService.set(`token_${user.username}`, token)
  localStorageService.set(`refreshToken_${user.username}`, refreshToken)
  localStorageService.set('lastUser', user.username)
}
