import Button from 'components/button'
import { uploadMedia } from 'components/cardCreation/preview'
import Pack from 'components/pack'
import Toggle from 'components/toggle'
import cloneDeep from 'lodash/cloneDeep'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedPack } from 'store/reducers/pack/packSlice'
import { onPackPublish } from '.'
import styles from './packCreation.module.scss'

const PackPreview = ({ pack, active, onUpdate, buttonSpinnerForUpdate }) => {
  const dispatch = useDispatch()

  const [loadUpdate, setLoadUpdate] = useState(false)

  const { auth } = useSelector(state => state)

  const onPublicChange = () => {
    dispatch(setSelectedPack({ ...pack, public: !pack.public }))
  }

  return (
    <div className={`${styles.packPreview} ${active === 3 ? styles.test : ''}`}>
      <Pack pack={pack} />
      <div className={styles.wrapFooter}>
        <div className={styles.footer}>
          <Toggle checked={!pack.public} onChange={onPublicChange} className={styles.fixMargins} />{' '}
          <span>
            {' '}
            <strong>Make Private? </strong> The pack will only be visible to you. You can still share a private pack{' '}
            <br /> if you'd like, but a private pack cannot be forwarded by anyone who recives it.
          </span>{' '}
        </div>
        <div>
          {active === 3 && !pack._id ? (
            <Button
              label={pack.published ? 'Published' : 'Publish'}
              disabled={pack.published}
              size="xs"
              className={styles.publishBtn}
              spinner={loadUpdate}
              onClick={() => pack.public && onPackPublish(dispatch, pack, auth?.user, setLoadUpdate)}
            />
          ) : (
            <div>
              <Button
                label={`${pack._id ? 'Update pack' : 'Save Draft'}`}
                size="xs"
                className={styles.publishBtn}
                onClick={onUpdate}
                spinner={buttonSpinnerForUpdate}
                style={{ padding: '0.5rem 5.648rem ' }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default PackPreview

export const uploadPackImages = async data => {
  const tempData = cloneDeep(data)
  // LOGO IMAGE
  if (tempData.logoImage instanceof File) tempData.logoImage = await uploadMedia(tempData.logoImage)
  if (tempData.logoImage instanceof Element)
    tempData.logoImage = await uploadMedia(tempData.logoImage, null, null, null, true)
  return tempData
}
