import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styles from './calendar.module.scss'
import Button from 'components/button'
import { useSelector } from 'react-redux'
import { toastService } from 'services/toastService'
import dataService from 'services/data.service'
// import { cardActions } from 'store/actions/card.actions'

const WidgetCalendar = ({ data, type = 'preview', card = {}, className }) => {
  const isEditOpen = document.getElementById('card-creation')

  // const dispatch = useDispatch()
  const store = useSelector(state => state)
  const user = store.auth.user
  const [addedToCalendar, setAddedToCalendar] = useState(null)

  useEffect(() => {
    if (store?.card?.savedDate) {
      setAddedToCalendar(card._id ? store.card.savedDate[card._id] || null : null)
    }
  }, [store?.card?.savedDate])

  const addToCalendar = async () => {
    if (!card._id) return toastService('warning', 'You need to save card.')
    if (!user) return toastService('warning', 'You need to have account to add this card to calendar.')
    const temp = { type: 'calendar', userId: user._id, cardId: card._id, date: data }
    const res = await dataService.run('data', 'calendar', 'new', temp)
    if (res) toastService('success', 'Card added to your calendar.')
    const dateSaved = await dataService.run('data', 'calendar', 'all', { filter: [{ userId: user._id }] })
    const tempDate = {}
    if (dateSaved)
      dateSaved.forEach(e => {
        tempDate[e.cardId] = e
      })
    // dispatch(cardActions.setSavedDates(tempDate))
  }

  const renderButtons = () => {
    return (
      <>
        {addedToCalendar ? (
          <Button label="Added to your calendar" variation="primary" size="xs" disabled />
        ) : isEditOpen ? (
          <Button label="Add to your calendar" variation="primary" size="xs" disabled />
        ) : (
          <Button label="Add to your calendar" variation="primary" size="xs" onClick={addToCalendar} />
        )}
      </>
    )
  }
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={`${type === 'edit' ? styles.edit : styles.preview}`}>
        <div className={styles.details}>
          <div className={styles.icon}>{moment(data).format('DD')}</div>
          <div className={styles.info}>
            <h4 className={styles.event}>{card && card.heading ? card.heading : ''}</h4>

            {/* <p>{moment(data).format('dddd, MMMM Do, h:mm a')}</p> */}
            <p>{moment(data).format('dddd, MMMM Do YYYY, h:mm a')}</p>
          </div>
        </div>
        {type === 'preview' && renderButtons()}
      </div>
    </div>
  )
}

export default WidgetCalendar
