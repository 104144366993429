import Heading from 'components/Heading'
import Button from 'components/button'
import CampaignCreation from 'components/campaignCreation'
// import CardCreation from 'components/cardCreation'
import CardFront from 'components/cardFront'
import { isTypeAvailableForLoggedUser } from 'components/cardTypeSelection'
import DynamicTablePagination from 'components/dynamicTable/pagination'
import Input from 'components/input'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import dataService from 'services/data.service'
import { CARD_TYPES } from 'staticConstants'
import { setCreatedCard } from 'store/reducers/card/cardSlice'
// import { openModal } from 'store/reducers/modal/modalSlice'
import { DEFAULT_PAGE_SIZE } from 'utils'
import styles from './campaignCardSelection.module.scss'
import { toastService } from 'services/toastService'

const CAMPAIGN_CARD_TYPES = {
  general: { $nin: [CARD_TYPES.Marketplace, CARD_TYPES.Instacard, CARD_TYPES.Sponsored] },
  marketplace: CARD_TYPES.Marketplace,
  partnership: CARD_TYPES.Sponsored,
  media: CARD_TYPES.Instacard
}

const CampaignCardSelection = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { campaignType } = useParams()

  const user = useSelector(state => state.auth.user)
  const availableTypesForLoggedUser = useSelector(state => state?.config?.cardCreation?.[user?.subType])
  const [cards, setCards] = useState({ rows: [], total: 0 })
  const [selectedCard, setSelectedCard] = useState({})
  const [searchNameValue, setSearchNameValue] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [packsDisplay, setPacksDisplay] = useState(false)
  const [filter, setFilter] = useState({
    filter: {
      userId: user.role === 'admin' || (user.role === 'premiumUser' && user.subType === 7) ? undefined : user._id,
      disabled: { $ne: true },
      cardType: CAMPAIGN_CARD_TYPES[campaignType]
    },
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber
  })
  const { createdCard } = useSelector(store => store?.card)

  useEffect(() => {
    if (pageNumber) {
      setFilter({ ...filter, pageNumber })
    }
  }, [pageNumber])

  useEffect(() => {
    const fetchCards = async () => {
      let response
      if (packsDisplay) {
        response = await dataService.run('data', 'pack', 'page', {
          // filter: { userId: (user.role === 'admin' || (user.role === 'premiumUser' && user.subType === 7)) ? undefined : user._id, disabled: { $ne: true } },
          // pageSize: DEFAULT_PAGE_SIZE,
          // pageNumber
          ...filter
        })
      } else {
        response = await dataService.run('data', 'card', 'page', {
          ...filter
        })
      }
      setCards(response)
    }
    if (filter?.filter) fetchCards()
  }, [user?._id, filter, packsDisplay])

  useEffect(() => {
    if (createdCard?._id) {
      setFilter({ ...filter })
      dispatch(setCreatedCard({}))
    }
  }, [createdCard?._id])

  const handleBack = () => {
    history.push('/campaign')
  }

  const onSearchNameSubmit = event => {
    if (event.key === 'Enter') {
      // setFilter({ ...filter, filter: { ...filter.filter, heading: { $regex: searchNameValue, $options: 'i' } } })
      setFilter({
        ...filter,
        filter: {
          ...filter.filter,
          $or: [
            { heading: { $regex: searchNameValue, $options: 'i' } },
            { subheading: { $regex: searchNameValue, $options: 'i' } },
            { description: { $regex: searchNameValue, $options: 'i' } }
          ]
        }
      })
    }
  }

  // const onCloseTrigger = () => {
  //   dispatch(setDefaultCard({}))
  // }

  // const onCreateCardClick = () => {
  //   dispatch(
  //     openModal({
  //       content: <CardCreation />,
  //       show: true,
  //       size: 'max1100',
  //       name: 'Card Creation Modal',
  //       onclose: onCloseTrigger()
  //     })
  //   )
  // }

  const onCardClick = card => {
    if (card?.published && card?.public) {
      setSelectedCard(card)
    } else {
      toastService('warning', 'Only published and public cards allowed.')
    }
  }

  const onInstacardButtonClick = () => {
    history.push('/instacard')
  }

  const onSearchNameChange = event => {
    if (event.target.value === '') {
      setFilter({
        filter: {
          userId: user.role === 'admin' || (user.role === 'premiumUser' && user.subType === 7) ? undefined : user._id,
          disabled: { $ne: true },
          cardType: CAMPAIGN_CARD_TYPES[campaignType]
        },
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber
      })
      setSearchNameValue('')
    } else {
      setSearchNameValue(event?.target?.value)
    }
  }

  useEffect(() => {
    if (
      user?.subType &&
      campaignType === 'media' &&
      !isTypeAvailableForLoggedUser(CARD_TYPES.Instacard, availableTypesForLoggedUser)
    ) {
      history.push('/home')
    }
  }, [user?._id])
  return (
    <div className={styles.wrapper}>
      {selectedCard?._id ? (
        <CampaignCreation type={campaignType} selectedCard={selectedCard} onBackClick={() => setSelectedCard({})} />
      ) : (
        <div className={styles.createCardAndList}>
          <Heading
            title="Choose Card"
            leftMiddleElement={
              <div className={styles.backButton} onClick={handleBack}>
                &#x27A4;
              </div>
            }
            rightMiddleElement={
              campaignType !== 'media' ? (
                <div className={styles.packToggle}>
                  {/* <Button
                    label="Create New Card"
                    variation="secondary"
                    onClick={onCreateCardClick}
                    disabled={packsDisplay}
                  /> */}
                  {campaignType === 'general' &&
                    (packsDisplay ? (
                      <Button label="Choose A Card" variation="secondary" onClick={() => setPacksDisplay(false)} />
                    ) : (
                      <Button
                        label="Choose A Pack"
                        variation="secondary"
                        onClick={() => setPacksDisplay(true)}
                        disabled={user?.subType === 2}
                      />
                    ))}
                </div>
              ) : (
                <Button label="Instacard!" variation="secondary" onClick={onInstacardButtonClick} />
              )
            }
            bottomElement={
              <div className={styles.inputFilter}>
                <Input
                  variation="cardInput"
                  type={'text'}
                  value={searchNameValue}
                  onChange={onSearchNameChange}
                  onKeyPress={onSearchNameSubmit}
                  placeholder="Enter card name"
                />
              </div>
            }
          />
          <div className={styles.cards}>
            {cards?.total > 0 &&
              cards?.rows?.map((one, i) => {
                return (
                  <div key={i} className={styles.singleCard} onClick={() => onCardClick(one)}>
                    <CardFront card={one} />
                  </div>
                )
              })}
            {cards?.total === 0 && (
              <Typography
                text="There are no cards"
                variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
                className="marginY10"
              />
            )}
          </div>
          {cards?.total > 0 && (
            <DynamicTablePagination total={cards?.total} onPageChange={value => setPageNumber(value)} />
          )}
        </div>
      )}
    </div>
  )
}

export default CampaignCardSelection
