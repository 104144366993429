import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setRoute } from 'store/reducers/auth/authSlice'

const PublicWrapper = props => {
  const dispatch = useDispatch()
  const { location } = props
  useEffect(() => {
    dispatch(setRoute(location.pathname))
  }, [location.pathname])

  return <div>{props.children}</div>
}

export default PublicWrapper
