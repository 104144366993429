import { ReactComponent as IconComment } from 'assets/icons/iconComment.svg'
import UserAvatar from 'components/userAvatar'
import { getAvatarImage } from 'utils/getAvatarImage'
import { getRecentTime } from 'utils/getRecentTime'
import { checkTimestampValidity } from 'utils/helpers'
import styles from './comment.module.scss'

const Comment = ({ comment, threadOpened, setThreadOpened, type }) => {
  const onThreadOpenHandler = () => {
    if (threadOpened === comment?._id) {
      return setThreadOpened(null)
    }
    setThreadOpened(comment)
  }
  return (
    <>
      <div key={comment?._id} className={styles.commentWrapper}>
        <UserAvatar className={styles.authorAvatar} imageUrl={getAvatarImage(comment?.userId)} />
        <div className={styles.column}>
          <span className={styles.nameAndComment}>
            <strong>{comment?.fullname}</strong> {comment?.content}
          </span>
          {checkTimestampValidity(comment?.createdAt) && (
            <span className={styles.date}>{getRecentTime(comment?.createdAt, true)}</span>
          )}
        </div>
        {type !== 'thread' && <IconComment className={styles.threadComment} onClick={onThreadOpenHandler} />}
      </div>
      {!threadOpened && comment?.thread?.length > 0 && type !== 'thread' && (
        <div className={styles.threadBox} onClick={() => setThreadOpened(comment)}>
          {comment?.thread?.length} repl{comment?.thread?.length === 1 ? 'y' : 'ies'}
        </div>
      )}
    </>
  )
}

export default Comment
