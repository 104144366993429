import Button from 'components/button'
import Heading, { TABS } from 'components/Heading'
import SingleCampain from 'components/singleCampain'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import dataService from 'services/data.service'
import styles from './manageCampaign.module.scss'

const ManageCampaign = () => {
  const user = useSelector(state => state.auth.user)
  const campaign = useSelector(state => state.campaign)
  const history = useHistory()
  const [active, setActive] = useState('')
  const [camapigns, setCamapigns] = useState([])

  useEffect(() => {
    const fetchCampaigns = async () => {
      const userId = user && user._id
      if (userId) {
        let filter = { userId: userId, endDate: { $gt: moment().valueOf() } }
        if (active !== '') {
          filter = { ...filter, typeOfCampaign: active }
        }
        const res = await dataService.run('data', 'campaign', 'all', { filter })
        setCamapigns(res)
      }
    }
    fetchCampaigns()
  }, [campaign, active])

  const setActiveTab = type => {
    setActive(type)
  }

  const onNewCampaignClick = () => {
    if (active && active !== '') {
      history.push('/campaign/new/' + active)
    } else {
      history.push('/campaign')
    }
  }

  return (
    <div className={styles.wrapper}>
      <Heading
        title="Manage Campaigns"
        onTabChange={setActiveTab}
        tabs={user?.role === 'admin' ? TABS.ManageCampaignWithPartnership : TABS.ManageCampaign}
        rightMiddleElement={<Button label="New Campaign" variation="secondary" onClick={onNewCampaignClick} />}
      />
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <span>Name</span>
          <span>Type</span>
          <span>Duration</span>
        </div>
        <div className={styles.innerContent}>
          {camapigns && camapigns.length > 0 ? (
            camapigns.map((one, i) => {
              return <SingleCampain props={one} key={i} />
            })
          ) : (
            <Typography
              text="There are no campaigns"
              variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
              className={styles.emptyMessageMargin}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ManageCampaign
