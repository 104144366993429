import React from 'react'
import checkIcon from '../../assets/icons/check.svg'
import styles from './checkbox.module.scss'

const Checkbox = ({ name, value, checked, onChange, indeterminate, disabled = false, className }) => {
  return (
    <>
      <label className={`${styles.checkbox} ${className} `}>
        <input
          name={name}
          className={styles.inputCheck}
          value={value}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className={styles[indeterminate ? 'checkmarkDisabled' : 'checkmark']}>
          <img src={checkIcon} alt="checkbox" />
        </span>
      </label>
    </>
  )
}

export default Checkbox
