import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal } from 'store/reducers/modal/modalSlice'
import styles from './adminSendPack.module.scss'
import iconClose from 'assets/icons/iconCloseBlue.svg'
import Button from 'components/button'
import AdminPackSelection from 'components/packSelection'
import dataService from 'services/data.service'
import { TIER_NAME_BY_LEVEL } from 'utils'
import { toastService } from 'services/toastService'

const AdminSendPack = ({ filter }) => {
  const dispatch = useDispatch()
  const [selectedPack, setSelectedPack] = useState({})

  const onClose = () => {
    dispatch(closeModal())
  }
  const onSendClick = async () => {
    const res = await dataService.run('chats', 'message', 'sendPackToFilteredUsers', {
      filter: filter,
      pack: selectedPack
    })
    if (res) {
      toastService('success', 'Sent pack successfully.')
      dispatch(closeModal())
    }
  }

  const onPackSelectionChangeHandle = pack => {
    if (pack?._id) setSelectedPack(pack)
  }
  const renderFilterKey = (key, index) => {
    if (key) {
      switch (key) {
        case '$or':
          if (filter.$or?.[0]?.name?.$regex)
            return (
              <div key={key + index} className={styles.filterValue}>
                {filter.$or?.[0]?.name?.$regex}
              </div>
            )
          return <></>
        case 'subType':
          if (filter?.subType)
            return (
              <div key={key + index} className={styles.filterValue}>
                {filter?.subType}
              </div>
            )
          return <></>
        case 'active':
          if (filter?.active)
            return (
              <div key={key + index} className={styles.filterValue}>
                {filter?.active ? 'Active' : 'Inactive'}
              </div>
            )
          return <></>
        case 'ageRange':
          if (filter?.ageRange)
            return (
              <div key={key + index} className={styles.filterValue}>
                {filter?.ageRange}
              </div>
            )
          return <></>
        case 'email':
          if (filter?.email?.$regex)
            return (
              <div key={key + index} className={styles.filterValue}>
                {filter?.email?.$regex}
              </div>
            )
          return <></>
        case 'tier.level':
          if (filter?.['tier.level'] && TIER_NAME_BY_LEVEL[filter?.['tier.level']])
            return (
              <div key={key + index} className={styles.filterValue}>
                {TIER_NAME_BY_LEVEL[filter?.['tier.level']]}
              </div>
            )
          return <></>
        default:
          return <></>
      }
    }
    return <></>
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerRow}>
        <div onClick={onClose} className={styles.leftSide}>
          <img src={iconClose} alt="Close icon" />
        </div>
        <h2 className={styles.middle}>Send pack to users</h2>
        <Button disabled={!selectedPack?._id} label="Send Pack" onClick={onSendClick} className={styles.sendPack} />
      </div>
      <div className={styles.filterContainer}>
        {Object.keys(filter || {}).length > 0 &&
          Object.keys(filter || {}).map((key, index) => renderFilterKey(key, index))}
      </div>
      <AdminPackSelection onSelectionChange={onPackSelectionChangeHandle} />
    </div>
  )
}
export default AdminSendPack
