import React from 'react'
import { Route } from 'react-router-dom'
import PublicWrapper from './publicWrapper'

export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <PublicWrapper {...props} {...rest}>
            <Component {...props} {...rest} />
          </PublicWrapper>
        )
      }}
    />
  )
}
