import React, { useState } from 'react'
import Button from 'components/button'
import Input from 'components/input'
import styles from './forgot.module.scss'
import { useDispatch } from 'react-redux'
import { sendForgotPasswordLink } from 'store/reducers/auth/thunk'
import validateEmail from 'utils/validateEmail'
import { toastService } from 'services/toastService'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import Login from '../login'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')

  const submit = async () => {
    if (validateEmail(email)) {
      dispatch(sendForgotPasswordLink({ email, premium: true }))
      toastService('success', 'Forgot password email successfully sent.')
      dispatch(closeModal())
    } else {
      toastService('error', 'Email is not valid.')
    }
  }

  const openLogin = () => {
    dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
  }

  return (
    <div className={styles.wrapper}>
      <h2>Forgot password</h2>
      <p>Enter your email and we will send you a guidelines to reset your password</p>
      <Input
        variation="formInput"
        inputClass="lgInput"
        placeholder="enter email"
        onChange={e => setEmail(e.target.value)}
        className={styles.loginEmail}
      />
      <Button label="Submit" variation="secondary" size="md" onClick={submit} />
      <h3 onClick={openLogin}>Back to login</h3>
    </div>
  )
}

export default ForgotPassword
