import { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import styles from './imageViewer.module.scss'

const ImageViewer = ({ fullImage, slides = [], currentSlideIndex = 0 }) => {
  const carouselRef = useRef(null)
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  useEffect(() => {
    carouselRef.current.slickGoTo(currentSlideIndex, true)
  }, [currentSlideIndex])

  return (
    <div className={styles.wrapper}>
      {fullImage && <img src={fullImage} alt="" />}
      {slides && (
        <Slider {...settings} className={styles.my_slider} ref={carouselRef}>
          {slides.map((slide, index) => {
            const image = getMediaFromFileOrString(slide.image, false)
            return (
              <>
                <img key={index} src={image} alt="Img" className={styles.swipe} />
              </>
            )
          })}
        </Slider>
      )}
    </div>
  )
}

export default ImageViewer
