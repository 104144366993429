import React from 'react'
import styles from '../questionSlider.module.scss'
const SliderDots = ({ slides, onChange, currentSlideIndex, validateQuestion = false, isDotValid = () => true }) => {
  const getDotStyle = index => {
    return isDotValid(index) ? '' : styles.disableDot
  }
  return (
    <div className={styles.containerDots}>
      {slides.map((slide, index) => {
        if (!validateQuestion || (slide.question !== '' && slide.answers.length > 0))
          return (
            <div
              key={'dot' + index}
              onClick={() => isDotValid(index) && onChange(index)}
              className={`${styles.dot} ${currentSlideIndex === index ? styles.active : ''} ${getDotStyle(index)}`}
            ></div>
          )
      })}
    </div>
  )
}
export default SliderDots
