import React, { Suspense, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes } from './routes/routes'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import { useHistory } from 'react-router-dom'
import ModalWrapper from 'components/modal'
import Stripe from 'components/stripe'
import Navbar from 'components/navbar'
import SideBar from 'components/sidebar'
import '../node_modules/font-awesome/css/font-awesome.min.css'
import './App.scss'
import Socket from 'sockets'
import { toastService } from 'services/toastService'
import { localStorageService } from 'services/localStorage.service'
import { isObjectEmpty } from 'utils'
import { clearUserStorage } from 'utils/logout'
import moment from 'moment'
import { getLocalUser, logOut, getSessionUser, logOutSessionUser } from 'store/reducers/auth/authSlice'
import Login from 'pages/auth/login'
import Listeners from 'components/listeners'
import ChatModal from 'components/chatModal'
import PackModal from 'components/packModal'
import YesNoModal from 'components/yesNoModal'
import dataService from 'services/data.service'
import { setCardCreation, setUserSubTypeLabels } from 'store/reducers/config/configSlice'
import { USER_TYPE_VALUE } from 'staticConstants'
import useTabVisibility from 'hooks/useVisibility'

const queryString = require('query-string')

const TAB_COUNT_STORAGE = 'TAB_COUNT_STORAGE'

const addTabsCounting = () => {
  const loggedUser = getLocalUser() || {}
  const rememberMe = localStorageService.get('REMEMBER_ME') || false

  let opennedWindows = Number(localStorageService.get(TAB_COUNT_STORAGE) || 0)
  opennedWindows++
  localStorageService.set(TAB_COUNT_STORAGE, opennedWindows)

  if (
    !rememberMe &&
    opennedWindows === 1 &&
    !isObjectEmpty(loggedUser) &&
    window.sessionStorage.getItem('SESSION_CHECK') !== 'true'
  ) {
    clearUserStorage()
    window.location.assign('/register')
  }
  window.sessionStorage.setItem('SESSION_CHECK', true)

  window.addEventListener('beforeunload', ev => {
    console.log(ev)
    let opennedWindows = Number(localStorageService.get(TAB_COUNT_STORAGE) || 1)
    opennedWindows--
    if (opennedWindows < 0) opennedWindows = 0
    localStorageService.set(TAB_COUNT_STORAGE, opennedWindows)
  })
}

addTabsCounting()

const App = () => {
  const passwordChangeTimestamp = useSelector(state => state.auth.passwordChangeAt)
  const passwordChangeFlag = useSelector(state => state.auth.changePassword)
  const accountTypeFlag = useSelector(state => state.auth.accountTypeFlag)
  const accountTypeTimestamp = useSelector(state => state.auth.accountTypeTimestamp)
  const { modal, chatModal, packModal, yesNoModal } = useSelector(state => state)
  const user = useSelector(state => state.auth.user)
  const [isLog, setIsLog] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const parsedUrl = queryString.parse(history.location.search)

  const handleTabActivation = useCallback(() => {
    // the current session user logged out in another tab / window
    const user = getSessionUser()
    if (!user.username) {
      dispatch(logOutSessionUser())
      history.push('/register')
    }
  }, [history, dispatch])

  useTabVisibility(handleTabActivation)

  const closeStripe = () => {
    dispatch(logOut())
    history.push('/register')
    dispatch(openModal({ content: <Login />, show: true, name: 'Login Modal' }))
  }

  const openStripe = () => {
    dispatch(openModal({ show: true, content: <Stripe />, name: 'Stripe modal', onclose: closeStripe, undo: true }))
  }

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setIsLog(true)
      if (modal && modal.name === 'Login Modal') {
        dispatch(closeModal())
      }
      const path = window.location.pathname
      if (path === '/register' || path === '/' || !path) {
        history.push('/home')
      }

      if (
        (user.customer &&
          !user.membership &&
          user.role !== 'admin' &&
          user.subType !== USER_TYPE_VALUE.editor &&
          (!user.subscriptionEnds || Date.now() > user.subscriptionEnds)) ||
        (user.membership === null &&
          user.role !== 'admin' &&
          user.subType !== USER_TYPE_VALUE.editor &&
          (!user.subscriptionEnds || Date.now() > user.subscriptionEnds))
      ) {
        openStripe()
      }
    } else {
      setIsLog(false)
    }
  }, [user && user._id])

  useEffect(() => {
    if (Object.keys(parsedUrl).length > 0 && parsedUrl.email) {
      const userLoc = getLocalUser()
      if (userLoc && userLoc.email) {
        const email = parsedUrl.email.replace(' ', '+')
        if (userLoc.email !== email) {
          dispatch(logOut())
          history.push('/register')
          dispatch(openModal({ content: <Login />, show: true, name: 'Login Modal' }))
        }
      }
    }
    const fetchUserTypeLabels = async () => {
      const { labels = [] } = await dataService.run('config', 'userSubTypes', 'get', { type: 'userSubTypes' })
      dispatch(setUserSubTypeLabels(labels))
    }
    const fetchCardCreationConfig = async () => {
      const res = await dataService.run('config', 'cardCreation', 'get', { type: 'cardCreation' })
      await dispatch(setCardCreation(res))
    }
    fetchUserTypeLabels()
    fetchCardCreationConfig()
    setTimeout(() => {
      if (
        user &&
        user.customer &&
        !user.membership &&
        user.role !== 'admin' &&
        (!user.subscriptionEnds || Date.now() > user.subscriptionEnds)
      ) {
        openStripe()
      }
    }, 10)
  }, [])

  useEffect(() => {
    if (passwordChangeFlag) {
      toastService('success', 'Successfully changed password.')
    }
  }, [passwordChangeTimestamp])

  useEffect(() => {
    if (accountTypeFlag && accountTypeTimestamp + 10000 > moment().valueOf()) {
      toastService('success', 'Successfully changed account type.')
      history.push('/home')
    }
  }, [accountTypeTimestamp])

  return (
    <div className="App">
      <Suspense fallback="add loader here">
        {!isLog && (
          <>
            <Listeners />
            {modal.show && (
              <ModalWrapper
                show={modal.show}
                content={modal.content}
                size={modal.size}
                onclose={modal.onclose}
                xButton={modal.xButton}
                overlayClassName={modal.overlayClassName}
                confirmModalText={modal.confirmModalText}
              />
            )}
            {chatModal?.show && <ChatModal user={chatModal?.user} defaultChat={chatModal?.defaultChat} />}
            {packModal?.show && <PackModal packId={packModal?.packId} />}
          </>
        )}
        {isLog && (
          <>
            <Socket />
            <Listeners />
          </>
        )}
        <main className={isLog ? 'main-wrapper' : ''}>
          {isLog && <SideBar />}
          <div className="content">
            {isLog && <Navbar />}
            <div className={isLog ? 'scrolableContent' : ''}>
              <Routes />
            </div>
            {isLog && modal.show && (
              <ModalWrapper
                show={modal.show}
                content={modal.content}
                size={modal.size}
                onclose={modal.onclose}
                xButton={modal.xButton}
                overlayClassName={modal.overlayClassName}
                confirmModalText={modal.confirmModalText}
              />
            )}
            {isLog && chatModal?.show && <ChatModal user={chatModal?.user} defaultChat={chatModal?.defaultChat} />}
            {isLog && packModal?.show && <PackModal packId={packModal?.packId} />}

            {isLog && yesNoModal?.show && (
              <YesNoModal
                title={yesNoModal?.title}
                content={yesNoModal?.content}
                onsubmit={yesNoModal?.onsubmit}
                className={yesNoModal?.modalClass}
                additionalInputPlaceholder={yesNoModal?.additionalInputPlaceholder}
                yesTitle={yesNoModal?.yesTitle}
                noTitle={yesNoModal?.noTitle}
              />
            )}
          </div>
        </main>
      </Suspense>
    </div>
  )
}

export default App
