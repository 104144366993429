import React from 'react'
import styles from './cardSlideBack.module.scss'
import backPreviewImg from 'assets/images/defaultUser.png'
import CardWrapper from 'components/card/components/wrapper'
import Card from 'components/card'
import CardBody from 'components/card/components/body'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import CardHeader, { HeaderPosition } from 'components/card/components/header'
import CardAction from 'components/card/components/actionOption'
import CardSliderComponent from 'components/card/components/slider'
import { awsUrlResize } from 'environment'
export const CardSlideBack = ({ card, className, openComments, disableActions = false }) => {
  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card size={CARD_SIZE.Normal} type={CARD_TYPES.Slide} userType={card?.userRole}>
        <CardBody size={CARD_SIZE.Normal} userType={card?.userRole}>
          <CardHeader
            heading={card.fullname}
            subheading={card.username}
            type={CARD_TYPES.Slide}
            logo={card?.userId ? awsUrlResize + '/' + card.userId + '.jpg' : backPreviewImg}
            headingClass={styles.headerFont}
            logoClass={styles.avatarSize}
            position={HeaderPosition.Floating}
            userType={card?.userRole}
            showElipsisForUsername
          />

          {card.slider.length > 0 && (
            <CardSliderComponent
              className="previewSlider"
              slides={card.slider}
              caption={card.slider[card.activeSlide]?.caption}
            />
          )}
          <CardAction
            type={CARD_TYPES.Slide}
            size="small"
            openComments={() => openComments && openComments()}
            card={card}
            disabled={disableActions}
          />
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CardSlideBack
