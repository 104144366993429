import Button from 'components/button'
import styles from './registerCard.module.scss'
// import video from 'assets/video/video.mp4'
import thumbnail from 'assets/images/thumbnail.png'
import chart from 'assets/images/chart.png'

export const RegisterCard = ({
  cardContent = '',
  cardTitle = '',
  cardPrice = 0,
  isHighlited = false,
  onClickNext = null,
  isVideo = false,
  isImage = false,
  buttonLabel = 'Apply now',
  isChangeAccount = false,
  onClick
}) => {
  const onCLickHandler = event => {
    onClick && onClick(event)
  }

  const video = 'https://www.youtube.com/embed/1c9r8hxoWM4'
  return isVideo ? (
    <div className={isHighlited ? styles.scaledWrapper : styles.wrapper} onClick={event => onCLickHandler(event)}>
      {isHighlited ? (
        <iframe
          title=" "
          style={{ pointerEvents: 'none' }}
          onClick={event => onCLickHandler(event)}
          src={video + '?autoplay=1'}
        />
      ) : (
        <img src={thumbnail} alt="alt" />
      )}
    </div>
  ) : isImage ? (
    <div
      className={isHighlited ? styles.scaledWrapper : styles.wrapper}
      style={{ backgroundImage: `url(${chart})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}
      onClick={event => onCLickHandler(event)}
    ></div>
  ) : (
    <div className={isHighlited ? styles.scaledWrapper : styles.wrapper} onClick={event => onCLickHandler(event)}>
      <div className={styles.cardTitle}>{cardTitle}</div>
      <div className={styles.cardContent} style={{ overflowY: isHighlited || isChangeAccount ? 'scroll' : 'hidden' }}>
        {cardContent}
      </div>
      <div className={styles.cardPrice}>
        <p>{'$' + cardPrice + '/month*'} </p>
        <Button
          variation="secondary"
          label={buttonLabel}
          className={styles.btnCard}
          onClick={onClickNext}
          disabled={isChangeAccount ? false : !isHighlited}
          size="md"
        />
      </div>
    </div>
  )
}

export default RegisterCard
