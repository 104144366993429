import { createSlice } from '@reduxjs/toolkit'
import { localStorageService } from 'services/localStorage.service'
import { login, register } from './thunk'

const initialUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
const initialState = {
  user: initialUser,
  error: null,
  value: 0
}
// in component method non- async actions are called
// import { logout,increment,decrement } from 'store/reducers/auth/authSlice'
// dispatch(logout())

// in component method async actions are called
// import { login } from 'store/reducers/auth/thunk'
// dispatch(login(userData))

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // add your non- async reducers here
    increment(state) {
      state.value++
    },
    decrement(state) {
      state.value--
    },
    incrementByAmount(state, action) {
      state.value += action.payload
    },
    logout(state) {
      state.user = null
      localStorageService.destroy('user')
    }
  },
  extraReducers: builder => {
    // add your async reducers here

    // Login
    builder.addCase(login.fulfilled, (state, action) => {
      // Immer return state in Proxies form to read curent state you need use current form( @reduxjs/toolkit)
      localStorageService.set('user', action.payload)
      state.user = action.payload
      state.error = null
    })
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.error
    })

    // Register
    builder.addCase(register.fulfilled, (state, action) => {
      state.user = action.payload
      state.error = null
    })
  }
})

export const { logout, increment, decrement, incrementByAmount } = auth.actions
export default auth.reducer
