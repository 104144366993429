import Button from 'components/button'
import RegisterCard from 'components/registerCard/registerCard'
import MediaText from 'components/registerText/media'
import PrimetimeAdvertiserText from 'components/registerText/primetimeAdvertiser'
import WonderPlusText from 'components/registerText/wonderPlus'
import Slider from 'components/slider'
import SwitchButton from 'components/switch'
import ThanksApply from 'components/thanksApply'
import Apply from 'pages/apply'
import PledgeModal from 'pages/pledge/pledgeModal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { localStorageService } from 'services/localStorage.service'
import { USER_TYPE_VALUE } from 'staticConstants'
import { setErrorMessage, setRegisteredUser } from 'store/reducers/auth/authSlice'
import { checkRegister, register } from 'store/reducers/auth/thunk'
import { openModal } from 'store/reducers/modal/modalSlice'
import { setClosePreview } from 'store/reducers/previewModal/previewModalSlice'
import { trimObjectData } from 'utils/trimObjectData'
import logo from '../../../assets/images/logo/logo.svg'
import Login from '../login'
import styles from './register.module.scss'
import { clearUserStorage } from 'utils/logout'
import { adminUrl } from 'environment'

const queryString = require('query-string')

const Register = () => {
  const dispatch = useDispatch()
  const { username, email } = useParams()
  const defaultUsername = username || ''
  const defaultEmail = email || ''
  const { userTypeLabels } = useSelector(store => store?.config)
  const userForRegistartion = useSelector(state => state.auth.userForRegistartion)
  const regiseteredUser = useSelector(state => state.auth.registeredUser)
  const checkRegistrationTimestamp = useSelector(state => state.auth.checkRegisterTimestamp)
  const checkRegistration = useSelector(state => state.auth.checkRegister)
  const [currentStep, setCurrentStep] = useState(0)
  const [active, setActive] = useState(1)
  const [currentSlide, setCurrentSlide] = useState(2)
  // eslint-disable-next-line no-unused-vars
  const [listItems, setListItems] = useState([])

  const history = useHistory()
  const parsedUrl = queryString.parse(history.location.search)

  useEffect(() => {
    if (
      parsedUrl?.clearLocal &&
      parsedUrl?.clearLocal === 'true' &&
      defaultEmail &&
      defaultEmail !== '' &&
      defaultUsername &&
      defaultUsername !== ''
    ) {
      clearUserStorage()
      window.location.href = `${adminUrl}/register/${defaultEmail}/${defaultUsername}`
    }
  }, [])

  useEffect(() => {
    if (Object.keys(parsedUrl).length > 0 && parsedUrl.type) {
      const userType = parsedUrl.type
      setTimeout(() => {
        dispatch(setErrorMessage([]))
        setCurrentStep(1)
        if (userType === 'primetime') {
          setActive(1)
        } else if (userType === 'wonderPlus') {
          setActive(2)
        } else if (parsedUrl.type === 'media') {
          setActive(3)
        }
      }, 100)
    }
  }, [])

  const cardsFilling = [
    {
      subtype: USER_TYPE_VALUE.businessSubscriber,
      title: userTypeLabels?.[USER_TYPE_VALUE.businessSubscriber],
      price: 20,
      content: <WonderPlusText />,
      cardType: 'registrationCard',
      itemIndex: 2
    },
    {
      subtype: USER_TYPE_VALUE.media,
      title: userTypeLabels?.[USER_TYPE_VALUE.media],
      price: 100,
      content: <MediaText />,
      cardType: 'registrationCard',
      itemIndex: 3
    },
    {
      subtype: USER_TYPE_VALUE.premiumAdvertiser,
      title: userTypeLabels?.[USER_TYPE_VALUE.premiumAdvertiser],
      price: 1000,
      content: <PrimetimeAdvertiserText />,
      cardType: 'registrationCard',
      itemIndex: 1
    },
    {
      subtype: 'video',
      title: 'video',
      cardType: 'registrationCard'
    },
    {
      subtype: 'image',
      cardType: 'registrationCard',
      title: 'image'
    }
  ]

  const nextStep = index => {
    dispatch(setErrorMessage([]))
    dispatch(setClosePreview())
    setCurrentStep(currentStep + 1)
    setCurrentSlide(index)
    setActive(index)
  }

  const nextStepRegister = () => {
    dispatch(checkRegister(userForRegistartion))
    history.push('/')
  }

  const previousStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const previousStepFromPledge = () => {
    setCurrentStep(currentStep - 1)
    setActive(1)
  }

  let listItems1 = cardsFilling.map((one, i) => (
    <div key={one.price}>
      <RegisterCard
        cardTitle={one.title}
        cardContent={one.content}
        cardPrice={one.price}
        isHighlited={i === 1}
        onClickNext={() => nextStep(i)}
        isVideo={one.title === 'video'}
        isImage={one.title === 'image'}
      />
    </div>
  ))

  const setListItem = index => {
    listItems1 = cardsFilling.map((one, i) => (
      <div key={one.price}>
        <RegisterCard
          cardTitle={one.title}
          cardContent={one.content}
          cardPrice={one.price}
          isHighlited={index === i}
          onClickNext={() => nextStep(i)}
          isVideo={one.title === 'video'}
          isImage={one.title === 'image'}
        />
      </div>
    ))
  }

  const applyClickHandler = () => {
    setCurrentStep(1)
    setActive(2)
  }

  const openLogin = () => {
    dispatch(
      openModal({
        content: <Login setActive={applyClickHandler} />,
        name: 'Login Modal',
        size: 'max600',
        show: true
      })
    )
  }

  const registerUser = () => {
    const user = trimObjectData(userForRegistartion)
    dispatch(register(user))
  }

  const forms = [
    {
      title: 'Wonder Shuffle Plus',
      value: 2,
      content: (
        <Apply
          onclickNext={nextStepRegister}
          onClickBack={previousStep}
          subtype={cardsFilling[0].subtype}
          title={cardsFilling[0].title}
          defaultEmail={defaultEmail}
          defaultUsername={defaultUsername}
        />
      )
    },
    {
      title: 'Primetime Advertiser',
      value: 1,
      content: (
        <Apply
          onclickNext={nextStepRegister}
          onClickBack={previousStep}
          subtype={cardsFilling[2].subtype}
          title={cardsFilling[2].title}
          defaultEmail={defaultEmail}
          defaultUsername={defaultUsername}
        />
      )
    },
    {
      title: 'Media',
      value: 3,
      content: (
        <Apply
          onclickNext={nextStepRegister}
          onClickBack={previousStep}
          subtype={cardsFilling[1].subtype}
          title={cardsFilling[1].title}
          defaultEmail={defaultEmail}
          defaultUsername={defaultUsername}
        />
      )
    }
  ]

  const setActiveTab = prop => {
    setActive(prop)
    dispatch(setErrorMessage([]))
  }
  const currentStepHTML =
    currentStep === 0 ? (
      <div className={styles.registerWrapper}>
        <div className={styles.cards}>
          <div>
            <Slider
              items={cardsFilling}
              firstCard={false}
              moveByCardClick
              showWSButton={false}
              returnIndex={returnIndex => nextStep(returnIndex)}
              activeSlide={currentSlide}
              showBackButton
              firstLoadForActiveSlide
            />
          </div>
        </div>
      </div>
    ) : currentStep === 1 ? (
      <>
        <SwitchButton
          tabs={forms}
          className="authTab-medium"
          setActiveTab={setActiveTab}
          activeTab={active}
          size={'xlarge'}
          hasBackgroundColor={false}
          style={{ margin: 'auto' }}
        />
        {active === 2 && forms[0].content}
        {active === 1 && forms[1].content}
        {active === 3 && forms[2].content}
      </>
    ) : currentStep === 2 ? (
      <PledgeModal onClickBack={previousStepFromPledge} onclickNext={registerUser} />
    ) : (
      ''
    )

  useEffect(() => {
    setListItem(currentSlide)
    setListItems(listItems1)
  }, [currentSlide])

  useEffect(() => {
    setCurrentStep(0)
    if (Object.keys(regiseteredUser).length > 0) {
      dispatch(
        openModal({ content: <ThanksApply />, name: 'Thanks modal', size: 'max500', show: true, xButton: false })
      )
      dispatch(setRegisteredUser({}))
    }
  }, [regiseteredUser])

  useEffect(() => {
    if (checkRegistration) {
      dispatch(setErrorMessage([]))
      setCurrentStep(2)
    } else {
      const errorMsg = localStorageService.get('errorMsg') ? localStorageService.get('errorMsg') : []
      dispatch(setErrorMessage(errorMsg))
    }
  }, [checkRegistrationTimestamp])

  return (
    <>
      <div className={currentStep === 0 ? styles.topRegister : styles.topRegisterCenter}>
        {currentStep === 0 && <div style={{ width: '15.625rem' }}></div>}
        <div className={styles.centeredDiv}>
          <img src={logo} alt="logo" />
          <h3>Premium</h3>
        </div>
        {currentStep === 0 && <Button label="Sign In" variation="secondary" size="md" onClick={openLogin}></Button>}
      </div>
      {currentStepHTML}
    </>
  )
}

export default Register
