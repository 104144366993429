import React from 'react'

const RibbonComponent = ({ className, color = 'black' }) => {
  return (
    <svg
      width="100%"
      height="7.40rem"
      viewBox="0 0 318 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9 0C4.47226 3.37453 -1.86658 11.4989 9 17V0Z" fill="#6F0432" />
      <path d="M309 0C315.51 4.47059 320.79 12.8517 309 19V0Z" fill="#3A0019" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M309.82 0.620286C315.854 5.17986 318 6.80099 318 16V109.01C318 114.533 313.523 119 308 119H159L10 119C4.47715 119 0 114.533 0 109.01V16C-1.1794e-05 8.77178 2.33865 5.0518 9 8.58307e-06C2.33865 5.0518 2 16 12 16L159 16H306C316 16 315.661 5.05179 309 0C309.281 0.213058 309.554 0.41952 309.82 0.620286Z"
        fill={color !== '' ? color : 'black'}
      />

      <path
        d="M318 16C318 6.39595 315.661 5.05179 309 0C315.661 5.05179 316 16 306 16H249.5V119H308C313.523 119 318 114.533 318 109.01V16Z"
        fill="url(#paint0_linear_3783_11082)"
      />
      <path
        d="M0 16C-1.20949e-05 8.77177 2.33865 5.05179 9 0C2.33865 5.05179 2 16 12 16H68.5V119H10C4.47715 119 0 114.533 0 109.01V16Z"
        fill="url(#paint1_linear_3783_11082)"
      />

      <defs>
        <linearGradient id="paint0_linear_3783_11082" x1="318" y1="60" x2="249" y2="60" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.4" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3783_11082"
          x1="4.38969e-07"
          y1="60"
          x2="68"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.51" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default RibbonComponent
