import React from 'react'
import styles from './thread.module.scss'

import Comment from '../comment'

const Thread = ({ open, comment, setThreadOpened }) => {
  return (
    <div>
      <Comment comment={comment[0]} type="thread" />
      {comment?.[0]?.thread?.length > 0 && open && (
        <div className={styles.thread}>
          {comment[0].thread.map(single => (
            <Comment type="thread" comment={single} setThreadOpened={setThreadOpened} />
          ))}
        </div>
      )}
    </div>
  )
}

export default Thread
