import iconCloseBlue from 'assets/icons/iconCloseBlue.svg'
import Button from 'components/button'
import CardShare from 'components/card/types/share'
// import Rays from 'components/rays'
import SwitchButton, { SWITCH_SIZE } from 'components/switch'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import {
  packDefaultState,
  setCreatedPack,
  setRequiredFields,
  setSelectedPack,
  setUpdatedPack,
  setUpdatedPackAt
} from 'store/reducers/pack/packSlice'
import useWindowDimensions from 'utils/screenSize'
import InlinePack from '../card/components/inlinePack'
import PackCreate from './create'
import styles from './packCreation.module.scss'
import PackPreview, { uploadPackImages } from './preview'
import PackSelection from './selection'
import PackSelectionPreview from './selectionPreview'
import moment from 'moment'
import { sortDataByIds } from '../../utils/getSortedCards'

const PackCreation = () => {
  const dispatch = useDispatch()

  const [active, setActive] = useState(1)
  const [loadUpdate, setLoadUpdate] = useState(false)
  // const [triggerAnimation, setTriggerAnimation] = useState(false)
  const [showNewCard, setShowNewCard] = useState(false)

  const pack = useSelector(state => state.pack.selectedPack)
  const isPackSelected = useSelector(state => state.pack.isPackSelected)
  const auth = useSelector(state => state.auth)
  const { selectedPack } = useSelector(state => state.pack)

  const { isMobile } = useWindowDimensions()

  const setActiveHandler = event => {
    if (arePackFieldsValid(pack, dispatch)) setActive(event)
  }

  const onUpdateOrSave = async () => {
    setLoadUpdate(true)
    try {
      const packWithUploadedImages = await uploadPackImages(pack)
      if (packWithUploadedImages.cards?.length > 0 && packWithUploadedImages.cards[0]?._id) {
        packWithUploadedImages.cards = packWithUploadedImages.cards.map(card => card?._id)
      }
      const res = await dataService.run('data', 'pack', pack._id ? 'update' : 'new', packWithUploadedImages)
      if (res) {
        dispatch(setUpdatedPack(res))
        dispatch(setUpdatedPackAt(moment().valueOf()))
        dispatch(closeModal())
        dispatch(setSelectedPack({ ...packDefaultState }))
      }
      setLoadUpdate(false)
    } catch (e) {}
  }

  const selectedCard = card => {
    if (pack && pack.cards) dispatch(setSelectedPack({ ...pack, cards: [...pack.cards, card] }))
    setShowNewCard(false)
  }

  useEffect(() => {
    const fetchCardsByIds = async () => {
      if (selectedPack?.cards?.length > 0) {
        const cards = await dataService.run('data', 'card', 'all', { filter: { _id: { $in: selectedPack?.cards } } })
        if (cards.length > 0) {
          const sortedArray = sortDataByIds(cards, selectedPack?.cards)
          dispatch(setSelectedPack({ ...selectedPack, cards: sortedArray }))
        }
      }
    }
    const addInitData = () => {
      if (auth.user) {
        dispatch(
          setSelectedPack({
            ...pack,
            userId: auth.user._id,
            username: auth.user.username,
            fullname: auth.user.name,
            avatar: auth.user.avatar || '',
            userRole: auth.user.role
          })
        )
      }
    }
    if (selectedPack?._id) {
      fetchCardsByIds()
    } else {
      addInitData()
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      {isMobile ? null : (
        <>
          {isPackSelected && (
            <div className={styles.header}>
              <SwitchButton
                activeTab={active}
                setActiveTab={setActiveHandler}
                tabs={defaultTabs}
                size={SWITCH_SIZE.Large}
                className={'cardCreation'}
                background={true}
              />
              <>
                {active === 2 && (
                  <Button
                    label={'Add new card'}
                    size="xs"
                    className={styles.btnAddNewCard}
                    onClick={() => setShowNewCard(true)}
                  />
                )}{' '}
              </>
            </div>
          )}
        </>
      )}

      {!isPackSelected && <PackSelection pack={pack} />}
      {isPackSelected && (
        <div className={styles.switchContent}>
          {active === 1 && <PackCreate pack={pack} />}
          {active === 2 && <PackSelectionPreview pack={pack} />}
          {active === 3 && (
            <PackPreview pack={pack} active={active} onUpdate={onUpdateOrSave} buttonSpinnerForUpdate={loadUpdate} />
          )}
          {showNewCard && (
            <div className={styles.addNewCard}>
              <img
                className={styles.closeAddNewCard}
                // src={iconClose}
                src={iconCloseBlue}
                alt="Close icon"
                onClick={() => setShowNewCard(false)}
              />
              <InlinePack
                onAdd={card => selectedCard(card)}
                ignoreCardIds={selectedPack?.cards?.map(card => (card instanceof Object ? card?._id : null))}
                contentModeretionCardFilter
              />
            </div>
          )}
        </div>
      )}
      {isMobile ? (
        <>
          {isPackSelected && (
            <div className={styles.mobileUpdate}>
              {!pack._id ? (
                <Button
                  label={pack.published ? 'Published' : 'Publish'}
                  disabled={pack.published}
                  size="xs"
                  className={styles.saveBtnMobile}
                  style={{ padding: '0.5rem 5.648rem ' }}
                  spinner={loadUpdate}
                  // onClick={() =>
                  //   pack.public
                  //     ? onPackPublish(dispatch, pack, auth?.user, setLoadUpdate, true, setTriggerAnimation)
                  //     : onPackPublish(dispatch, pack, auth?.user, setLoadUpdate)
                  // }
                  onClick={() => onPackPublish(dispatch, pack, auth?.user, setLoadUpdate)}
                />
              ) : (
                <div>
                  <Button
                    label={`${pack._id ? 'Update pack' : 'Save Draft'}`}
                    size="xs"
                    className={styles.saveBtnMobile}
                    onClick={onUpdateOrSave}
                    spinner={loadUpdate}
                    style={{ padding: '0.5rem 5.648rem ' }}
                  />
                </div>
              )}
            </div>
          )}
        </>
      ) : null}
      {/* {triggerAnimation && <Rays points={'50'} type="pink" showText={false} />} */}
    </div>
  )
}
export default PackCreation

export const onPackPublish = (dispatch, pack, user, setLoading, showPointsAnimation = false, setTriggerAnimation) => {
  const modalClose = () => {
    dispatch(closeModal())
  }
  const createOrUpdatePack = async () => {
    setLoading(true)
    const packWithUploadedImages = await uploadPackImages(pack)
    packWithUploadedImages.published = true
    if (packWithUploadedImages.cards?.length > 0 && packWithUploadedImages.cards[0]?._id) {
      packWithUploadedImages.cards = packWithUploadedImages.cards.map(card => card?._id)
    }
    const createdPack = await dataService.run(
      'data',
      'pack',
      pack._id ? 'update' : 'new',
      packWithUploadedImages,
      dispatch
    )
    if (createdPack) {
      dispatch(setCreatedPack(createdPack))
      setLoading(false)

      const ShareIdFnc = async (text = '') => {
        await navigator.clipboard.writeText(text)
      }
      dispatch(
        openModal(
          <CardShare
            card={createdPack}
            showBtns={false}
            className={styles.cardShare}
            ShareIdFnc={ShareIdFnc}
            onShareClick={modalClose}
          />,
          'Card share modal',
          '20rem',
          'cardPreviewModalWithExit',
          null,
          null,
          null
        )
      )
    }
  }
  if (showPointsAnimation) {
    setTriggerAnimation && setTriggerAnimation(true)
    setTimeout(() => {
      createOrUpdatePack()
      setTriggerAnimation && setTriggerAnimation(false)
    }, 2400)
  } else {
    createOrUpdatePack()
  }
}

const defaultTabs = [
  { value: 1, title: 'Create' },
  { value: 2, title: 'Cards' },
  { value: 3, title: 'Preview' }
]

export const arePackFieldsValid = (pack, dispatch) => {
  const tempErrorMessages = {}
  let valid = true
  if (pack.heading === '') {
    tempErrorMessages.heading = 'Name required'
    valid = false
  } else {
    tempErrorMessages.heading = ''
  }
  if (pack.category === '') {
    tempErrorMessages.category = 'Category required'
    valid = false
  } else {
    tempErrorMessages.category = ''
  }
  dispatch(setRequiredFields({ error: !valid, errorMessages: tempErrorMessages }))
  return valid
}
