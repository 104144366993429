import { Document, Font, Image, Page, PDFViewer, StyleSheet, View, Text } from '@react-pdf/renderer'
import Roboto from '../../assets/fonts/roboto/Roboto-Bold.ttf'

export const ReportsDoc = ({ imgSrc = '', name = '' }) => {
  // Font styles
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: Roboto
      }
    ]
  })

  // Create styles
  const styles = StyleSheet.create({
    section: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    heading: {
      textTransform: 'capitalize',
      width: '100%',
      display: 'flex',
      textAlign: 'center',
      paddingBottom: '30'
    }
  })

  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading}>{name}</Text>
          <View style={styles.img} fixed>
            <Image style={{ width: '100%', height: 'auto' }} src={imgSrc} alt="img" />
          </View>
        </View>
      </Page>
    </Document>
  )

  return (
    <PDFViewer
      style={{
        width: '100%',
        height: '80vh'
      }}
    >
      <MyDocument />
    </PDFViewer>
  )
}

export default ReportsDoc
