import Button from 'components/button'
import DynamicFilter, { FILTER_ALIGNMENT, FILTER_TYPES } from 'components/dynamicFilter'
import DynamicTable from 'components/dynamicTable'
import EditUser from 'components/editUserProfile'
import InviteUser from 'components/inviteUserModal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import {
  AGE_RANGE_OPTIONS,
  DEFAULT_PAGE_SIZE,
  SORT_OPTIONS,
  STATUS_OPTIONS,
  USERS_TABLE_COLUMNS,
  USER_LEVEL_OPTIONS
} from 'utils'
import styles from './users.module.scss'
import edit from '../../../assets/images/edit.svg'
import moment from 'moment'
import AdminSendPack from '../sendPack'
import Heading from 'components/Heading'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import { USER_TYPE_VALUE } from 'staticConstants'
import RegisterCoAdminForm from 'components/registerCoAdminForm'
import { setErrorMessage } from 'store/reducers/auth/authSlice'
import { activateOrDeactivateProfileFromAdmin } from 'store/reducers/auth/thunk'

const _generateRegexSearch = searchString => {
  // Escape special characters in the search string
  const regexString = searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  return regexString
}

const ActiveSwitchButton = ({ user, filter, onClick }) => {
  return (
    <div
      className={`${user?.active ? styles.active : styles.inActive} ${
        filter.collection === 'archive' ? styles.disabled : ''
      }`}
      onClick={() => onClick(user)}
    >
      <div className={styles.inner}></div>
    </div>
  )
}
const EditButton = ({ user, onClick, available = false }) => {
  const onClickHandler = () => {
    available && onClick()
  }
  return (
    <div className={`${styles.editButton} ${!available ? styles.disabled : ''}`} onClick={() => onClickHandler(user)}>
      <img src={edit} alt="" />
    </div>
  )
}

const AdminUsers = () => {
  const dispatch = useDispatch()
  const { userTypeLabels } = useSelector(store => store?.config)
  const [users, setUsers] = useState({ rows: [], total: 0 })
  const [filter, setFilter] = useState({
    filter: {},
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: 1,
    collection: 'profiles'
  })
  const [sort, setSort] = useState({ name: 1, createdAt: 1 })
  const [triggerDataReload, setTriggerDataReload] = useState(new Date().getTime())
  const [filterReset, setFilterReset] = useState(true)
  const loggedUser = useSelector(store => store?.auth?.user)
  const { errorMessage } = useSelector(state => state.auth)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await dataService.run(filter.collection, 'user', 'page', {
          sort,
          ...filter,
          collation: { locale: 'en', strength: 2 }
        })
        if (res?.rows?.length > 0) {
          setUsers({
            total: res?.total || 0,
            rows: res?.rows.map(user => ({
              ...user,
              loggedAt: user?.loggedAt ? moment(user?.loggedAt).fromNow() : 'Never',
              activeEl: user?.role !== 'admin' && (
                <ActiveSwitchButton user={user} filter={filter} onClick={handleClickActive} />
              ),
              edit: user?.role !== 'admin' && (
                <EditButton
                  user={user}
                  filter={filter}
                  onClick={() => openModalEdit(user)}
                  available={
                    loggedUser?.role === 'admin' ||
                    (loggedUser?.subType === USER_TYPE_VALUE.editor &&
                      user?.role !== 'admin' &&
                      user?.subType !== USER_TYPE_VALUE.contributor &&
                      user?.subType !== USER_TYPE_VALUE.editor)
                  }
                />
              ),
              formatedSubType: userTypeLabels?.[user?.subType]
            }))
          })
        } else {
          setUsers({ rows: [], total: 0 })
        }
      } catch (err) {
        console.warn('_e', err)
      }
    }
    fetchUser()
  }, [filter, sort, triggerDataReload])

  useEffect(() => {
    if (!filterReset) setFilterReset(true)
  }, [filterReset])

  useEffect(() => {
    if (errorMessage?.length) dispatch(setErrorMessage([]))
  }, [])

  useEffect(() => {
    if (filter?.pageNumber !== 1) setFilter({ ...filter, pageNumber: 1 })
  }, [filter?.filter])

  const handlePageChange = number => {
    if (number) setFilter({ ...filter, pageNumber: number })
  }

  const openModalInvite = () => {
    dispatch(
      openModal({
        content: <InviteUser />,
        name: 'Invite modal',
        size: 'max700',
        show: true,
        xButton: false
      })
    )
  }

  const closeModalHandler = () => {
    dispatch(closeModal())
  }

  const openRegisterCoAdminFormModal = () => {
    dispatch(
      openModal({
        content: <RegisterCoAdminForm onClickBack={closeModalHandler} />,
        name: 'Register co admin form modal',
        size: 'max1100',
        show: true,
        xButton: false
      })
    )
  }

  const openSendPack = () => {
    dispatch(
      openModal({
        content: <AdminSendPack filter={filter?.filter} />,
        name: 'Invite modal',
        size: 'max900',
        show: true,
        xButton: false
      })
    )
  }

  const handleClickActive = async user => {
    if (user?._id && filter.collection !== 'archive') {
      user.active = !user.active
      const res = await dispatch(activateOrDeactivateProfileFromAdmin({ email: user.email }))
      if (res) {
        setTriggerDataReload(new Date().getTime())
      }
    }
  }

  const openModalEdit = user => {
    dispatch(
      openModal({
        content: (
          <EditUser
            user={user}
            triggerUsersReload={() => setTriggerDataReload(new Date().getTime())}
            disabledBtn={filter.collection === 'archive'}
          />
        ),
        name: 'Edit profile modal',
        size: 'max900',
        show: true,
        xButton: false
      })
    )
  }

  const onInputChange = (name, value) => {
    if (name) {
      switch (name) {
        case 'nameOrUsername':
          setFilter(oldFilter => ({
            ...oldFilter,
            filter: {
              ...oldFilter.filter,
              $or: [{ name: { $regex: value, $options: 'i' } }, { username: { $regex: value, $options: 'i' } }]
            }
          }))
          break
        case 'email':
          setFilter(oldFilter => ({
            ...oldFilter,
            filter: {
              ...oldFilter.filter,
              email: { $regex: _generateRegexSearch(value), $options: 'i' }
            }
          }))
          break
        default:
          break
      }
    }
  }

  const onSelectChange = (name, value) => {
    if (name) {
      let tempFilter = { ...filter.filter }
      let collection = 'profiles'
      switch (name) {
        case 'subType':
        case 'ageRange':
          if (!value) {
            delete tempFilter[name]
          } else {
            tempFilter[name] = value
          }
          break
        case 'tier':
          if (!value) {
            delete tempFilter?.['tier.level']
          } else {
            tempFilter['tier.level'] = value
          }
          break
        case 'status':
          delete tempFilter?.disabled
          delete tempFilter?.deleted
          delete tempFilter?.active
          if (value) {
            if (value === 4 && collection !== 'profiles') collection = 'profiles'
            let query = {}
            switch (value) {
              case 1:
                query = { active: true }
                break
              case 2:
                query = { active: { $ne: true } }
                break
              case 3:
                query = { disabled: true }
                break
              case 4:
                collection = 'archive'
                break
              default:
                break
            }
            tempFilter = { ...tempFilter, ...query }
          }
          break
        default:
          break
      }
      setFilter({
        ...filter,
        filter: tempFilter,
        collection
      })
    }
  }

  const onSortChange = value => {
    if (value) {
      switch (value) {
        case 'Name (A-Z)':
          setSort({ name: 1, createdAt: 1 })
          break
        case 'Name (Z-A)':
          setSort({ name: -1, createdAt: 1 })
          break
        case 'Tier (Lowest First)':
          setSort({ 'tier.level': 1, createdAt: 1 })
          break
        case 'Tier (Highest First)':
          setSort({ 'tier.level': -1, createdAt: 1 })
          break
        case 'Followers (Lowest First)':
          setSort({ followers: 1, createdAt: 1 })
          break
        case 'Followers (Highest First)':
          setSort({ followers: -1, createdAt: 1 })
          break
        case 'Date Created (Newest First)':
          setSort({ createdAt: -1, updatedAt: 1 })
          break
        case 'Date Created (Oldest First)':
          setSort({ createdAt: 1, updatedAt: 1 })
          break
        default:
          break
      }
    }
  }
  const SUB_TYPE_OPTIONS = [
    { value: '', label: 'View All' },
    { value: USER_TYPE_VALUE.individual, label: userTypeLabels?.[USER_TYPE_VALUE.individual] },
    { value: USER_TYPE_VALUE.businessSubscriber, label: userTypeLabels?.[USER_TYPE_VALUE.businessSubscriber] },
    { value: USER_TYPE_VALUE.media, label: userTypeLabels?.[USER_TYPE_VALUE.media] },
    { value: USER_TYPE_VALUE.premiumAdvertiser, label: userTypeLabels?.[USER_TYPE_VALUE.premiumAdvertiser] },
    { value: USER_TYPE_VALUE.contributor, label: userTypeLabels?.[USER_TYPE_VALUE.contributor] },
    { value: USER_TYPE_VALUE.editor, label: userTypeLabels?.[USER_TYPE_VALUE.editor] },
    { value: USER_TYPE_VALUE.admin, label: userTypeLabels?.[USER_TYPE_VALUE.admin] }
  ]

  const upperFilter = [
    {
      label: 'Name or username',
      name: 'nameOrUsername',
      type: FILTER_TYPES.Input,
      styles: { width: '20rem' },
      onChange: (value, name) => onInputChange(name, value)
    },
    {
      label: 'Email',
      name: 'email',
      type: FILTER_TYPES.Input,
      styles: { width: '20rem' },
      onChange: (value, name) => onInputChange(name, value)
    },
    {
      label: 'Age Range',
      name: 'ageRange',
      type: FILTER_TYPES.Select,
      selectClass: 'normalFont',
      options: AGE_RANGE_OPTIONS,
      onChange: (value, name) => onSelectChange(name, value)
    },
    {
      label: 'Tier',
      name: 'tier',
      type: FILTER_TYPES.Select,
      selectClass: 'normalFont',
      options: USER_LEVEL_OPTIONS,
      onChange: (value, name) => onSelectChange(name, value)
    },
    {
      label: 'Role',
      name: 'subType',
      type: FILTER_TYPES.Select,
      selectClass: 'filterSizeMd',
      options: SUB_TYPE_OPTIONS,
      onChange: (value, name) => onSelectChange(name, value)
    }
  ]
  const lowerFilter = [
    {
      label: 'Status',
      name: 'status',
      type: FILTER_TYPES.Select,
      selectClass: 'normalFont',
      options: STATUS_OPTIONS,
      onChange: (value, name) => onSelectChange(name, value)
    },
    {
      label: 'Sort',
      name: 'sort',
      type: FILTER_TYPES.Select,
      selectClass: 'filterSizeMd',
      options: SORT_OPTIONS,
      onChange: value => onSortChange(value)
    }
  ]

  return (
    <div className={styles.applicantsWrapper}>
      <Heading
        title="Users"
        leftMiddleElement={
          <>
            <Button size="fitContent" style={{ height: '2.5rem' }} label="Invite User" onClick={openModalInvite} />
            {loggedUser?.role === 'admin' && (
              <Button
                size="fitContent"
                style={{ height: '2.5rem', marginLeft: '1rem' }}
                label="Add Co-Admin"
                onClick={openRegisterCoAdminFormModal}
              />
            )}
          </>
        }
        rightMiddleElement={
          <Button
            label="Send Pack"
            onClick={openSendPack}
            size="fitContent"
            style={{ height: '2.5rem' }}
            disabled={filter.collection === 'archive' || users.total === 0}
          />
        }
      />
      {filterReset ? (
        <DynamicFilter
          filters={upperFilter}
          className={styles.filterWrapper}
          alignContent={FILTER_ALIGNMENT.SpaceBetween}
        />
      ) : (
        <div className={styles.filterReplace}></div>
      )}

      <div className={styles.bottomFilterContainer}>
        <div className={styles.clearAndTotalContainer}>
          <Button
            label="Clear"
            onClick={() => {
              setFilter({ filter: {}, pageSize: DEFAULT_PAGE_SIZE, pageNumber: 1, collection: 'profiles' })
              setFilterReset(false)
            }}
            className={styles.clearButton}
          />
          <Typography text={`Total: ${users?.total || 0}`} variation={TYPOGRAPHY_VARIATION.LargerParagraph} />
        </div>

        {filterReset ? (
          <DynamicFilter filters={lowerFilter} alignContent={FILTER_ALIGNMENT.Right} />
        ) : (
          <div className={styles.filterReplace}></div>
        )}
      </div>

      <DynamicTable
        currentPageData={users.rows}
        columns={USERS_TABLE_COLUMNS}
        onPageChange={number => handlePageChange(number)}
        total={users.total}
      />
    </div>
  )
}

export default AdminUsers
