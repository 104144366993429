import React from 'react'
import { FacebookShareButton } from 'react-share'
import styles from './socialShare.module.scss'
import FacebookIcon from 'assets/icons/social/36/Facebook.png'

const FacebookShare = ({ url, quote, hastags, onClose, className }) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <FacebookShareButton onShareWindowClose={() => onClose(true)} url={url} quote={quote} hashtag={hastags}>
        <img src={FacebookIcon} alt="Facebook" />
      </FacebookShareButton>
    </div>
  )
}

export default FacebookShare
