import backImg from 'assets/icons/UnionBack.svg'
import backImgBlue from 'assets/icons/UnionBackBlue.svg'
import Button from 'components/button'
import CardTypeSelection from 'components/cardTypeSelection'
import SwitchButton, { SWITCH_SIZE } from 'components/switch'
import usePrevious from 'hooks/usePrevious'
import useUploadQue from 'hooks/useUploadQue'
import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { CARD_TYPES, FRONT_BACK_PREVIEW, FRONT_PREVIEW, SCRAPE_PREVIEW } from 'staticConstants'
import { setDefaultCard, setRequiredFields, setSelectedCard, setUpdatedCardAt } from 'store/reducers/card/cardSlice'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import { getTypeFields, validateCardFields } from 'utils/getCardFields'
import useWindowDimensions from 'utils/screenSize'
import CardBackLayout from './back'
import styles from './cardCreation.module.scss'
import CardFrontLayout from './front'
import CardPreviewLayout, { filterPollOptions, uploadCardImages } from './preview'

const CardCreation = ({ className, id = null, withoutTypeSelection = false, rememberCardType }) => {
  const dispatch = useDispatch()
  const { auth, card } = useSelector(state => state)
  const [availableTabs, setAvailableTabs] = useState(FRONT_BACK_PREVIEW)
  const [saveDraft, setSaveDraft] = useState(false)
  const [loadUpdate, setLoadUpdate] = useState(false)
  const [active, setActive] = useState(1)
  const [backFieldsUploadQue] = useUploadQue('backFields')
  const { isMobile } = useWindowDimensions()
  // const { isBussines } = useSelector(store => store?.navbar)
  const [isBussines] = useState(true)
  const prevCard = usePrevious(card?.selectedCard)

  useEffect(() => {
    const fetch = async id => {
      const res = await dataService.run('data', 'card', 'get', { filter: { _id: id } })
      if (res) {
        if (
          res.cardType === CARD_TYPES.Event ||
          res.cardType === CARD_TYPES.Anchor ||
          res.cardType === CARD_TYPES.Poll ||
          res.cardType === CARD_TYPES.OneSidedTrivia
        )
          setAvailableTabs(FRONT_PREVIEW)
        else if (res.cardType === CARD_TYPES.Instacard) {
          setAvailableTabs(SCRAPE_PREVIEW)
        }
      }
      dispatch(setSelectedCard(res))
    }
    if (id) fetch(id)
  }, [id])

  const onSwitchHandle = event => {
    const tempFields = validateCardFields(card.selectedCard, event)
    let change = true
    if (card?.selectedCard?.cardType === 'instacard') {
      change = card?.selectedCard?.isScraped
    }
    if (Object.entries(tempFields.front).length > 0 && change) {
      setActive(1)
    } else if (Object.entries(tempFields.back).length > 0 && change) {
      setActive(2)
    }
    if (Object.entries(tempFields.front).length > 0 || Object.entries(tempFields.back).length > 0) {
      dispatch(
        setRequiredFields({
          error: true,
          fields: {
            ...tempFields.front,
            ...tempFields.back
          }
        })
      )
      if (tempFields?.errorIndex >= 0) {
        dispatch(setSelectedCard({ ...card?.selectedCard, triggerSlideSwitch: tempFields?.errorIndex }))
      }
    } else {
      dispatch(
        setRequiredFields({
          error: false,
          tempFields: []
        })
      )
      if (change) {
        setActive(event)
      } else {
        toastService('info', 'Creating Instacard is in progress, please wait.')
      }
    }
  }

  const handleSave = () => {
    setSaveDraft(true)
    onUpdateOrSave()
  }

  const onUpdateOrSave = async () => {
    const tempFields = validateCardFields(card.selectedCard, 1)
    if (Object.entries(tempFields.front).length > 0) {
      toastService('error', 'Missing required card data.')
      return
    }
    setLoadUpdate(true)
    try {
      const cardWithUploadedImages = await uploadCardImages(card.selectedCard)
      if (cardWithUploadedImages) {
        const data = getTypeFields(cloneDeep(cardWithUploadedImages))
        data.userRole = auth?.user?.subType
        const cardId = id || card.selectedCard._id
        if (!data.cardType) return toastService('error', 'Card type is required.')
        if (data.cardType === 'poll') {
          const filteredArray = filterPollOptions(data.pollOptions)
          data.pollOptions = filteredArray
        }
        data.isDeleted = false
        const res = await dataService.run('data', 'card', cardId ? 'update' : 'new', data)
        if (res) {
          if (!res.published) console.log('res')
          // dispatch(setSaveDraftCard(res))
          else {
            dispatch(setUpdatedCardAt(res))
            // const calendar = res?.backFields.find(el => el.type === 'calendar')
            // dispatch(addRemoveSaveDate({ card: res, addDate: calendar && Object.keys(calendar).length > 0 }))
          }
          dispatch(closeModal())
        }
      } else {
        setLoadUpdate(false)
        setSaveDraft(false)
      }
    } finally {
      setLoadUpdate(false)
    }
  }

  const onHandleBack = () => {
    const rememberCardType = card.selectedCard.cardType
    dispatch(setDefaultCard({}))
    setAvailableTabs(FRONT_BACK_PREVIEW)
    dispatch(
      setRequiredFields({
        error: false
      })
    )
    dispatch(
      openModal({
        content: <CardCreation rememberCardType={rememberCardType} />,
        show: true,
        size: 'max1100',
        name: 'Card Creation Modal'
      })
    )
  }

  useEffect(() => {
    if (!prevCard || prevCard.cardType !== card.selectedCard?.cardType) {
      if (auth.user) {
        dispatch(
          setSelectedCard({
            ...card.selectedCard,
            userId: auth.user._id,
            username: auth.user.username,
            fullname: auth.user.name,
            avatar: auth.user.avatar || '',
            userRole: auth.user.role
          })
        )
      }
      return () => {
        dispatch(setSelectedCard(null))
      }
    }
  }, [card?.selectedCard?.cardType])

  return (
    <div className={`${styles.container} ${className}`} id="card-creation">
      {card?.selectedCard?.cardType !== '' && (
        <div className={styles.contentHeader}>
          {!isMobile && active === 1 && !id && (
            <div onClick={onHandleBack} className={styles.positionBack}>
              <img src={isBussines ? backImgBlue : backImg} alt="back" />
            </div>
          )}
          <SwitchButton
            activeTab={active}
            setActiveTab={event => onSwitchHandle(event)}
            style={{ height: '2.5rem' }}
            tabs={availableTabs}
            size={SWITCH_SIZE.Large}
            background={true}
            className={'cardCreation'}
          />
          {!isMobile && (active === 2 || active === 1) && !card?.published && (
            <Button
              label={`${id ? 'Update card' : 'Save Draft'}`}
              size="xs"
              className={styles.positionUpdateBtn}
              onClick={() => handleSave()}
              spinner={loadUpdate}
              disabled={Object.keys(backFieldsUploadQue).length > 0}
              // Save draft hidden because we does not show draft cards
              style={{ visibility: 'hidden' }}
            />
          )}
        </div>
      )}
      {!withoutTypeSelection && card.selectedCard.cardType === '' && (
        <CardTypeSelection rememberCardType={rememberCardType} setAvailableTabs={setAvailableTabs} />
      )}
      {<CardFrontLayout className={card.selectedCard.cardType !== '' && active === 1 ? styles.active : ''} />}
      {
        <CardBackLayout
          className={`${card.selectedCard.cardType !== '' && active === 2 ? styles.active : ''} ${
            card.selectedCard.cardType === 'double' && isMobile ? styles.doubleMobileCard : ''
          }`}
        />
      }
      <CardPreviewLayout
        className={card.selectedCard.cardType !== '' && active === 3 ? styles.active : ''}
        onUpdateOrSave={onUpdateOrSave}
        active={active}
        buttonSpinnerForUpdate={loadUpdate}
        saveDraftActive={saveDraft}
        updateCardLoading={loadUpdate}
      />
      {isMobile && active !== '' && card.selectedCard.cardType !== '' && active < 3 && (
        <div className={styles.mobileBtns}>
          <Button
            label={`${id ? 'Update card' : 'Save Draft'}`}
            size="xs"
            className={styles.saveBtn}
            onClick={onUpdateOrSave}
            spinner={loadUpdate}
            disabled={Object.keys(backFieldsUploadQue).length > 0}
          />
        </div>
      )}
    </div>
  )
}

CardCreation.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string
}
export default CardCreation
