import Avatar from 'components/avatar'
import Button from 'components/button'
import ConfirmationModal from 'components/confirmModal'
import Heading from 'components/Heading'
// import { awsUrlResize } from 'environment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  sendConfirmDeactivationOrRestore,
  sendConfirmPassLink,
  sendConfirmPersonalDetailsLink
} from 'store/reducers/auth/thunk'
import { openModal } from 'store/reducers/modal/modalSlice'
import { getAvatarImage } from 'utils/getAvatarImage'
import styles from './general.module.scss'
import { USER_TYPE_VALUE } from 'staticConstants'

const General = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const store = useSelector(state => state)
  const [activeAccount, setActiveAccount] = useState(store.auth.user.active)
  const user = useSelector(state => state.auth.user)
  // const src = user && awsUrlResize + '/' + user._id + '.jpg'

  const handleClick = (type = '') => {
    switch (type) {
      case 'Deactivate Account':
        dispatch(sendConfirmDeactivationOrRestore({ activeAccount: true, premium: true }))
        dispatch(openModal({ content: <ConfirmationModal type={type} />, show: true }))
        break
      case 'Restore Account':
        dispatch(sendConfirmDeactivationOrRestore({ activeAccount: false, premium: true }))
        dispatch(openModal({ content: <ConfirmationModal type={type} />, show: true }))
        break
      case 'Change Password':
        dispatch(sendConfirmPassLink({ premium: true }))
        dispatch(openModal({ content: <ConfirmationModal type={type} />, show: true }))
        break
      case 'Change Personal Details':
        dispatch(sendConfirmPersonalDetailsLink({ premium: true }))
        dispatch(openModal({ content: <ConfirmationModal type={type} />, show: true }))
        break
      default:
        break
    }
  }

  useEffect(() => {
    setActiveAccount(user.active)
  }, [user && user.active])

  return (
    <div className={styles.wrapper}>
      <Heading title="Settings" />
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <div className={styles.singleRow}>
            <span>Name</span>
            <h3>{user && user.name}</h3>
          </div>
          <div className={styles.singleRow}>
            <span>Username</span>
            <h3>{user && user.username}</h3>
          </div>
          <div className={styles.singleRow}>
            <span>Email</span>
            <h3>{user && user.email}</h3>
          </div>
          <div className={styles.singleRow}>
            <span>Contact number</span>
            <h3>{user && user.phoneNumber}</h3>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.avatar}>
            <Avatar
              className={styles.myAvatar}
              source={getAvatarImage(store.auth.user._id, store.auth.user.avatarUpdatedAt)}
              frameSize={'small'}
              editable="true"
            />
          </div>
          <div className={styles.buttons}>
            <Button
              label="Change Personal Details"
              size="xl"
              onClick={() => handleClick('Change Personal Details')}
              variation="secondary"
            />
            <Button
              label="Change Password"
              size="xl"
              onClick={() => handleClick('Change Password')}
              variation="secondary"
            />
            {user?.role !== 'admin' && (
              <Button
                label={activeAccount ? 'Deactivate Account' : 'Restore Account'}
                size="xl"
                onClick={() => handleClick(activeAccount ? 'Deactivate Account' : 'Restore Account')}
                variation="secondary"
              />
            )}
            {user && user?.role !== 'admin' && user?.subType !== USER_TYPE_VALUE.editor && (
              <Button
                label="Change Account"
                size="xl"
                onClick={() => history.push('/settings/changeAccount')}
                variation="secondary"
              />
            )}
            {/* <Button label="Cancel Subsription" size="xl" onClick={() => handleClick('Cancel Subsription')} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default General
