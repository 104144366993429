/* eslint-disable indent */
import backPreviewImg from 'assets/images/defaultUser.png'
import Card from 'components/card'
import CardAction from 'components/card/components/actionOption'
import CardBody from 'components/card/components/body'
import DndPreview from 'components/card/components/dndPreview'
import CardHeader from 'components/card/components/header'
import CardScrollableContent from 'components/card/components/scrollableContent'
import CardWrapper from 'components/card/components/wrapper'
import { awsUrlResize } from 'environment'
import React from 'react'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import { getMediaWithFallbackFromFileOrString } from 'utils/getMediaWithFallbackFromFileOrString'
import styles from './cardStandardBack.module.scss'
export const CardStandardBack = ({
  card,
  className,
  byline = '',
  size = CARD_SIZE.Normal,
  setIsInline,
  openComments,
  disableActions = false
}) => {
  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card
        size={size}
        type={card.cardType && card.cardType !== '' ? card.cardType : CARD_TYPES.Standard}
        userType={card?.userRole}
      >
        <CardBody
          size={size}
          style={
            (card.cardType === CARD_TYPES.Collectible || card.cardType === CARD_TYPES.Mystery) &&
            card.fullCardImage &&
            card.fullCardImage.active
              ? {
                  backgroundImage: getMediaWithFallbackFromFileOrString(card.fullCardImage.image)
                }
              : {}
          }
          type={card?.cardType}
          userType={card?.userRole}
        >
          <CardHeader
            heading={card.fullname}
            subheading={byline !== '' ? 'Sponsored by ' + byline : card.username}
            type={card.cardType && card.cardType !== '' ? card.cardType : CARD_TYPES.Standard}
            logo={card?.userId ? awsUrlResize + '/' + card?.userId + '.jpg' : backPreviewImg}
            headingClass={styles.headerFont}
            logoClass={styles.avatarSize}
            size={size}
            userType={card?.userRole}
            showElipsisForUsername
          />
          {(!(card.cardType === CARD_TYPES.Collectible || card.cardType === CARD_TYPES.Mystery) ||
            (card.fullCardImage && !card.fullCardImage.active)) && (
            <CardScrollableContent type={card.cardType} className={styles.backFields}>
              <div
                className={`${styles.activeContent} ${
                  (card?.cardType === 'marketplace' || card?.cardType === 'sponsored') && styles.fontColorMarket
                }`}
              >
                {card.backFields && card.backFields.length > 0 && (
                  <DndPreview data={card.backFields} setIsInline={setIsInline} card={card} />
                )}
              </div>
            </CardScrollableContent>
          )}
          <CardAction
            type={card.cardType}
            size="small"
            openComments={() => openComments && openComments()}
            card={card}
            disabled={disableActions}
          />
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CardStandardBack
