import Button from 'components/button'
import Heading from 'components/Heading'
import Pack from 'components/pack'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { ADMIN_EMAIL } from 'staticConstants'
import { updateUser } from 'store/reducers/auth/thunk'
import { openModal as openPackModal } from 'store/reducers/packModal/packModalSlice'
import styles from './welcomePack.module.scss'

export const WelcomePack = ({}) => {
  const [adminPacks, setAdminPacks] = useState([])
  const [filteredAdminPacks, setFilteredAdminPacks] = useState([])
  const [selectActive, setSelectActive] = useState(false)
  const user = useSelector(state => state?.auth?.user)
  const [adminUserForMessage, setAdminUserForMessage] = useState({})
  const [succes, setSucces] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchAdminUser = async () => {
      try {
        const res = await dataService.run('profiles', 'user', 'all', { filter: { email: ADMIN_EMAIL } })
        if (res) {
          setAdminUserForMessage(res)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const fetchPacks = async () => {
      try {
        const res = await dataService.run('data', 'pack', 'all', { filter: { userId: user?._id } })
        if (res) {
          setAdminPacks(res)
          setFilteredAdminPacks(res)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchPacks()
    fetchAdminUser()
  }, [succes])

  const handleSelectedPack = async selectedPack => {
    if (selectActive && selectedPack) {
      if (adminUserForMessage[0].welcomePack?._id !== selectedPack._id) {
        dispatch(updateUser({ ...adminUserForMessage[0], welcomePack: selectedPack }))
        toastService(
          'success',
          `Successfully ${
            adminUserForMessage && adminUserForMessage[0].welcomePack ? 'updated' : 'added'
          } welcome pack.`
        )
        setSucces(true)
      } else {
        toastService('success', 'The selected pack is already selected.')
      }

      setSelectActive(false)
      setTimeout(() => {
        setSucces(false)
      }, 2000)
    }
  }
  const handleChange = e => {
    const { value } = e.target
    if (value !== '') {
      setSearchValue(e.target.value)
    } else {
      setSearchValue(value)
      fetchFilterPacks()
    }
  }

  const handleFilterOnEnter = e => {
    if (e.key === 'Enter') {
      fetchFilterPacks()
    }
  }

  const fetchFilterPacks = () => {
    const inputValue = searchValue.toLowerCase().trim()
    const newPacks = []
    if (adminPacks && adminPacks.length > 0) {
      adminPacks.map(one => {
        const packName = one.heading?.toLowerCase()
        if (packName?.includes(inputValue)) {
          newPacks.push(one)
        }
      })
    }
    setFilteredAdminPacks(newPacks)
  }

  return (
    <div className={styles.wrapper}>
      <Heading
        title="Welcome Pack"
        leftMiddleElement={
          <div className={styles.filter}>
            <input
              type="text"
              onChange={handleChange}
              onKeyUp={handleFilterOnEnter}
              value={searchValue}
              placeholder="Enter pack name"
              name={'heading'}
            />
          </div>
        }
        rightMiddleElement={
          <Button
            label={selectActive ? 'Deselect' : 'Select'}
            size="fitContent"
            className={styles.marginButton}
            onClick={() => setSelectActive(!selectActive)}
            disabled={!adminPacks && !adminPacks.length > 0}
          />
        }
      />
      <Typography text="Selected Pack" variation={TYPOGRAPHY_VARIATION.Subtitle} />
      <div className={styles.selectedContent}>
        {adminUserForMessage && adminUserForMessage[0]?.welcomePack ? (
          <div className={styles.wrapPack}>
            <Pack
              onClick={() =>
                !selectActive && dispatch(openPackModal({ packId: adminUserForMessage[0]?.welcomePack?._id }))
              }
              ignoreUpdate
              key={adminUserForMessage[0]?.welcomePack?._id}
              packId={adminUserForMessage[0]?.welcomePack?._id}
            />
          </div>
        ) : (
          <div className={styles.emptySelection}>
            <Typography text="Pack not selected." variation={TYPOGRAPHY_VARIATION.EmptyMessageText} />
          </div>
        )}
      </div>
      <Typography text="Available Welcome Packs" variation={TYPOGRAPHY_VARIATION.Subtitle} />
      <div className={styles.selectedContent}>
        {filteredAdminPacks && filteredAdminPacks.length > 0 ? (
          filteredAdminPacks.map((pack, index) => {
            return (
              <>
                <div
                  className={`${styles.wrapPack} ${selectActive && styles.backgroundSelection}`}
                  onClick={() => handleSelectedPack(pack)}
                  key={index}
                >
                  <Pack
                    onClick={() => !selectActive && dispatch(openPackModal({ packId: pack?._id }))}
                    key={pack?._id}
                    packId={pack?._id}
                    ignoreUpdate
                  />
                </div>
              </>
            )
          })
        ) : (
          <Typography
            text="There are no packs"
            variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
            className="marginY10"
          />
        )}
      </div>
    </div>
  )
}

export default WelcomePack
