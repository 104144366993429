import OkModal from 'components/okModal'
import useUploadQue from 'hooks/useUploadQue'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { CARD_TYPES } from 'staticConstants'
import styles from './dndFields.module.scss'
import Item from './item'
// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const DndFields = ({ fields = [], onDelete, onChange, type = CARD_TYPES.Standard }) => {
  const [items, setItems] = useState(fields)
  const [backFieldsUploadQue] = useUploadQue('backFields')
  const [showInfoModal, setShowInfoModal] = useState(false)
  const onDragEnd = result => {
    if (Object.keys(backFieldsUploadQue).length > 0) {
      setShowInfoModal(true)
      return
    }
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const temp = reorder(items, result.source.index, result.destination.index)
    setItems(temp)
    onChange(temp)
  }
  const onDeleteHandler = index => {
    if (Object.keys(backFieldsUploadQue).length > 0) {
      setShowInfoModal(true)
      return
    }
    onDelete && onDelete(index)
  }
  useEffect(() => {
    if (fields) setItems(fields)
  }, [fields])
  return (
    <>
      <div className={styles.wrapper}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" className={styles.droppable}>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable key={item.id + index} draggableId={`${item.type}_${index}`} index={index}>
                    {provided => (
                      <div className={styles.list} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <div className={`${styles.iconBlock} ${styles['icon-' + type]}`}>
                          <i className="fa fa-trash" aria-hidden="true" onClick={() => onDeleteHandler(index)}></i>
                          <i {...provided.draggableProps} className="fa fa-arrows" aria-hidden="true"></i>
                        </div>
                        <Item key={`${item.type}_${index}`} item={item} index={index} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {showInfoModal && <OkModal onClose={() => setShowInfoModal(false)} message="Wait for upload to finish." />}
    </>
  )
}

export default DndFields
