import React, { useState } from 'react'
import styles from './tab.module.scss'

const SwitchTab = ({
  value,
  title,
  onClick,
  className,
  tabClass,
  parentClass,
  size,
  notifications = false,
  onMouseEnter,
  onMouseLeave,
  onTouchStart
}) => {
  // const { isBussines } = useSelector(store => store?.navbar)
  const [isBussines] = useState(true)

  const clickHandler = () => {
    onClick(value)
    onTouchStart && onTouchStart()
  }

  return (
    <div
      onMouseEnter={() => onMouseEnter && onMouseEnter()}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      className={`${styles.container}  ${styles['size-' + size]} ${styles[className]} ${styles[tabClass]} ${
        styles[parentClass]
      } ${isBussines && styles.isBussines}`}
      onClick={clickHandler}
    >
      <div className={`${styles.left} ${styles.cornerWrapper}`}>
        <div className={styles.corner}></div>
      </div>
      <div className={`${styles.right} ${styles.cornerWrapper}`}>
        <div className={styles.corner}></div>
      </div>
      <span>{title}</span>
      {notifications && <div className={`${styles.notificationCircle} ${isBussines && styles.isBussines}`}></div>}
    </div>
  )
}

export default SwitchTab
