import React from 'react'

const IconComponentSave = ({ width = '19', height = '26', fillIcon = false, color = 'black' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1H18L18 24.4447L10.33 20.9461C9.8028 20.7057 9.1972 20.7057 8.66999 20.9461L1 24.4447V1Z"
        stroke={color}
        fill={fillIcon ? color : 'transparent'}
        strokeWidth="2"
      />
    </svg>
  )
}
export default IconComponentSave
