import Avatar from 'components/avatar'
import Card from 'components/card'
import CardBody from 'components/card/components/body'
import UserCardAction from 'components/card/components/userActionOption'
import CardWrapper from 'components/card/components/wrapper'
import Counter from 'components/counter'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import dataService from 'services/data.service'
import { closeModal } from 'store/reducers/modal/modalSlice'
import { getAvatarImage } from 'utils/getAvatarImage'
import styles from './userCard.module.scss'

const UserCard = ({
  userClassName,
  className,
  showOption = true,
  userId,
  user,
  isFollowed = false,
  userType,
  trackUser
}) => {
  const cardStyles = ['blue', 'orange', 'green']
  const cardColor = cardStyles[Math.floor(Math.random() * (3 - 0) + 0)]
  const history = useHistory()
  const [currentUser, setCurrentUser] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    const getUserById = async () => {
      const res = await dataService.run('profiles', 'user', 'get', { filter: { _id: userId } })
      if (res?.type === 'user') setCurrentUser(res)
    }
    if (userId) {
      getUserById()
    } else {
      setCurrentUser(user)
    }
  }, [])

  const onClickHandler = () => {
    if (trackUser) {
      history.replace('/public/' + currentUser.username)
      dispatch(closeModal())
    }
  }

  return (
    <div className={styles.wrapper} onClick={() => onClickHandler()}>
      <CardWrapper className={`${styles.wrapper} ${className}`}>
        <Card type="user" className={`${styles['background_' + cardColor]} ${userClassName}`}>
          <CardBody className={styles.cardBody}>
            <div className={`${styles.container} ${styles['background_' + cardColor]}`}>
              <Avatar
                className={styles.avatar}
                source={getAvatarImage(currentUser?._id)}
                status={currentUser?.tier?.name}
              />
              <Counter value={currentUser?.points ?? 0} className={styles.counter} />
              <h1 className={styles.fullname}>{currentUser?.name ?? ''}</h1>
              <h2 className={styles.username}>{currentUser?.username ?? ''}</h2>
            </div>
            <div className={styles.info}>
              {currentUser?.intro?.length ? <p>"{currentUser?.intro ?? ''}"</p> : null}
              <a href="#">{currentUser?.website ?? ''}</a>
              <div>
                <a href="#">{currentUser?.numberOfCards ? currentUser?.numberOfCards : 0} Cards</a>
                <a href="#">{currentUser?.followers ? currentUser?.followers : 0} Followers</a>
              </div>
            </div>
            <div>
              {showOption && <UserCardAction account={currentUser} isFollowed={isFollowed} userType={userType} />}
            </div>
          </CardBody>
        </Card>
      </CardWrapper>
    </div>
  )
}

export default UserCard
