import DynamicTablePagination from './pagination'
import styles from './dynamicTable.module.scss'
import React, { useMemo, useState } from 'react'
import moment from 'moment'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'

const DynamicTable = ({
  columns = [],
  currentPageData,
  total,
  onPageChange,
  draggableColumns = false,
  className,
  showPagination = true,
  emptyMessage = ''
}) => {
  const [columnsData, setColumnsData] = useState(columns)
  let currentElementKey

  const columnsDataMemo = useMemo(() => columnsData, [columnsData])

  const dragStart = key => {
    if (draggableColumns) currentElementKey = key
  }

  const drop = dropElementKey => {
    if (draggableColumns && currentElementKey && dropElementKey) {
      const tempArray = columnsDataMemo
      const temp = tempArray.find(col => col.key === currentElementKey)
      tempArray.splice(tempArray.indexOf(temp), 1)
      tempArray.splice(
        tempArray.findIndex(col => col.key === dropElementKey),
        0,
        temp
      )
      setColumnsData([...tempArray])
    }
  }
  const getFieldValue = (item, colKey, format) => {
    let result = item
    if (colKey) {
      if (Array.isArray(colKey)) {
        colKey.forEach(key => {
          result = result?.[key]
        })
      } else if (typeof colKey === 'string') {
        result = result?.[colKey]
      }
    }
    if (typeof result === 'string' || typeof result === 'number' || React.isValidElement(result)) {
      // format output
      if (format) {
        switch (format) {
          case 'date':
            return moment(result).format('DD/MM/YYYY-HH:mm')
          default:
            return result
        }
      }
      return result
    }
    return ''
  }
  return (
    <div className={`${styles.primaryTable} ${className}`}>
      <div className={styles.tableScrollable}>
        <table onDragOver={event => event.preventDefault()}>
          <thead>
            <tr>
              {columnsDataMemo.map(col => (
                <th
                  draggable={draggableColumns}
                  onDragStart={() => dragStart(col.key)}
                  key={col.key}
                  onDrop={() => drop(col.key)}
                  className={draggableColumns ? styles.draggable : ''}
                >
                  {col.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentPageData?.length > 0 &&
              currentPageData.map((item, index) => (
                <tr key={index}>
                  {columnsDataMemo.map(col => (
                    <td key={col.key}>{getFieldValue(item, col?.key, col?.format)}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        {currentPageData?.length === 0 && (
          <Typography text={emptyMessage} variation={TYPOGRAPHY_VARIATION.EmptyMessageText} className="marginY10" />
        )}
      </div>
      {total > 0 && showPagination && <DynamicTablePagination total={total} onPageChange={onPageChange} />}
    </div>
  )
}
export default DynamicTable
