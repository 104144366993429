import { createSlice } from '@reduxjs/toolkit'

export const packDefaultState = {
  _id: null,
  published: false,
  public: true,
  type: 'pack',
  // USER
  userId: '',
  username: '',
  fullname: '',
  userRole: '',
  // FRONT
  heading: '',
  description: '',
  category: '',
  tags: [],
  logoImage: '',
  color: '#d20000',
  cards: [],
  selectionMode: false
}

export const packRequiredFieldsDefaultState = {
  error: false,
  errorMessages: {}
}

const initialState = {
  selectedPack: packDefaultState,
  selectionMode: false,
  isPackSelected: false,
  requiredFields: packRequiredFieldsDefaultState,
  createdPack: {},
  updatedPack: {},
  deletedPack: {},
  restoredPack: {},
  packUpdatedAt: 0
}

export const pack = createSlice({
  name: 'pack',
  initialState,
  reducers: {
    setSelectedPack: (state, action) => {
      state.selectedPack = action.payload
    },
    setSelectionMode: (state, action) => {
      state.selectionMode = action.payload
    },
    setIsPackSelected: (state, action) => {
      state.isPackSelected = action.payload
    },
    setRequiredFields: (state, action) => {
      if (Object.keys(action.payload).length === 0) {
        state.requiredFields = {
          ...packRequiredFieldsDefaultState
        }
      } else {
        state.requiredFields = action.payload
      }
    },
    setUpdatedPack: (state, action) => {
      state.updatedPack = action.payload
    },
    setUpdatedPackAt: (state, action) => {
      state.packUpdatedAt = action.payload
    },
    setCreatedPack: (state, action) => {
      state.createdPack = action.payload
    }
  }
})

export const {
  setSelectedPack,
  setSelectionMode,
  setRequiredFields,
  setIsPackSelected,
  setUpdatedPack,
  setUpdatedPackAt,
  setCreatedPack
} = pack.actions

export default pack.reducer
