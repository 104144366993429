import CardFlip from 'components/cardFlip'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styles from './item.module.scss'
const Item = ({ item, index, itemSize }) => {
  return (
    <Draggable draggableId={`${item.type}_${index}`} index={index}>
      {provided => (
        <div className={styles.item} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <CardFlip data={item} flippable={false} size={itemSize} />
        </div>
      )}
    </Draggable>
  )
}

export default Item
