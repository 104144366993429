import React, { useEffect, useState } from 'react'
// import SliderArrowButton from './arrowButton'
import styles from './cardSliderComponent.module.scss'
import MediaPicker from '../../../mediaPicker'
import usePrevious from 'hooks/usePrevious'
import IconClose from 'assets/icons/states/failed.png'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import { useDispatch, useSelector } from 'react-redux'
import ImageViewer from 'components/imageViewer'
import { getMediaWithFallbackFromFileOrString } from 'utils/getMediaWithFallbackFromFileOrString'
import { openModal } from 'store/reducers/modal/modalSlice'
import { setSelectedCard } from 'store/reducers/card/cardSlice'
import { setClosePreview } from 'store/reducers/previewModal/previewModalSlice'
import Button from 'components/button'

const CardSliderComponent = ({ className, slides = [], onImageChange, onDelete, editable = false }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const prevSlides = usePrevious({ slides })
  const dispatch = useDispatch()
  const card = useSelector(state => state.card.selectedCard)

  // const slideLeft = () => {
  //   if (currentSlideIndex !== 1) {
  //     setCurrentSlideIndex(currentSlideIndex - 1)
  //   } else if (currentSlideIndex === 1) {
  //     setCurrentSlideIndex(slides.length)
  //   }
  // }

  // const slideRight = () => {
  //   if (currentSlideIndex !== slides.length) {
  //     setCurrentSlideIndex(currentSlideIndex + 1)
  //   } else if (currentSlideIndex === slides.length) {
  //     setCurrentSlideIndex(1)
  //   }
  // }

  const renderImage = (imageClass, image, slides, currentSlideIndex) => {
    const onClickHandler = () => {
      dispatch(
        openModal({
          content: <ImageViewer slides={slides} currentSlideIndex={currentSlideIndex} />,
          name: 'Image viewer modal',
          show: true,
          size: 'imgPreviewModal',
          overlayClassName: 'overlayImgPreview',
          onclose: closePreviewModal
        })
      )
    }

    const closePreviewModal = () => {
      dispatch(setClosePreview())
    }

    if (image)
      return (
        <img
          className={imageClass}
          onClick={onClickHandler}
          style={{
            backgroundImage: getMediaWithFallbackFromFileOrString(image)
          }}
          alt=""
        ></img>
      )
  }

  const moveDot = index => {
    setCurrentSlideIndex(index)
    editable && dispatch(setSelectedCard({ ...card, activeSlide: index }))
  }

  const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/

  const openLink = link => {
    if (urlPattern.test(link?.trim())) {
      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        // Check the protocol of the target website
        fetch(link, { method: 'HEAD' })
          .then(response => {
            const targetProtocol = response.url.startsWith('https://') ? 'https://' : 'http://'
            window.open(`${targetProtocol}${link}`)
          })
          .catch(error => {
            console.error('Error checking protocol:', error)
          })
      } else {
        window.open(link)
      }
    } else {
      // Handle invalid URL (e.g., show an error message)
      console.error('Invalid URL:', link)
    }
  }

  useEffect(() => {
    if (prevSlides) {
      if (currentSlideIndex === 0) setCurrentSlideIndex(0)
      else if (prevSlides.slides.length < slides.length) {
        setCurrentSlideIndex(slides.length - 1)
      } else if (prevSlides.slides.length > slides.length) {
        setCurrentSlideIndex(currentSlideIndex > 0 ? currentSlideIndex - 1 : 1)
      }
    }
  }, [slides, prevSlides])

  return (
    <div className={`${styles.container} ${className} ${styles[className]}`}>
      {/* Close icon  */}
      <div className={styles.closeContainer}>
        {slides.length > 0 &&
          onDelete &&
          editable &&
          slides.map((slide, index) => {
            return (
              <img
                key={'close' + slide.id}
                className={`${styles.closeIcon} ${styles.slide} ${currentSlideIndex === index ? styles.active : ''}`}
                alt="CloseIcon"
                src={IconClose}
                onClick={() => onDelete(currentSlideIndex)}
              />
            )
          })}
      </div>
      {/* Slides */}
      <div className={styles.slidesContainer}>
        {slides?.length > 0 &&
          slides.map((slide, index) => {
            if (slide?.image || editable)
              return (
                <div
                  key={slide?.id}
                  className={`${styles.slide} ${currentSlideIndex === index ? styles.activeAnim : ''}`}
                >
                  {editable && (
                    <MediaPicker
                      className="cardCover"
                      onImageChange={image => onImageChange(image, currentSlideIndex)}
                      stateMedia={getMediaFromFileOrString(slide?.image)}
                      removable={false}
                      onImageCrop={image => onImageChange(image, currentSlideIndex)}
                      showCrop
                    />
                  )}
                  {!editable &&
                    renderImage(styles.slideImage, slides[currentSlideIndex]?.image, slides, currentSlideIndex)}
                </div>
              )
          })}
        {/* Caption  */}
        {slides.length > 0 && (slides[currentSlideIndex]?.caption || slides[currentSlideIndex]?.link) && (
          <div className={`${styles.captionContainer} ${editable ? styles.bottomBorder : ''}`}>
            <span>{slides[currentSlideIndex]?.caption}</span>
            {slides[currentSlideIndex].link && (
              <Button
                size="xs"
                className={styles.openLinkButton}
                onClick={() => openLink(slides[currentSlideIndex]?.link)}
              >
                Open Link
              </Button>
            )}
          </div>
        )}
      </div>
      {/* Arrows 
      {slides.length > 1 && (
        <>
          <SliderArrowButton direction="left" onClick={slideLeft} />
          <SliderArrowButton direction="right" onClick={slideRight} />
        </>
      )} */}
      {/* Dots  */}
      {slides.length > 0 && (
        <div className={styles.containerDots}>
          {slides.map((slide, index) => {
            return (
              <div
                key={'dot' + slide.id}
                onClick={() => moveDot(index)}
                className={`${styles.dot} ${currentSlideIndex === index ? styles.active : ''}`}
              ></div>
            )
          })}
        </div>
      )}
    </div>
  )
}
export default CardSliderComponent
