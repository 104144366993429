import deleteIcon from 'assets/icons/iconCloseWhite.svg'
import Button from 'components/button'
import Heading from 'components/Heading'
import Input from 'components/input'
import MediaPicker from 'components/mediaPicker'
import Selector from 'components/select'
import TagInput from 'components/tagInput'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import { useEffect, useState } from 'react'
import dataService from 'services/data.service'
import fileService from 'services/file.service'
import { toastService } from 'services/toastService'
import { textToLowerSeparatedWithHyphen } from 'utils'
import { converFileToBase64 } from 'utils/getBaseFromFile'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import styles from './categoryForm.module.scss'
import cloneDeep from 'lodash/cloneDeep'

const maindeckParentOptions = [
  { value: 'news', label: 'News' },
  { value: 'culture', label: 'Culture' },
  { value: 'travel', label: 'Travel' },
  { value: 'style', label: 'Style' },
  { value: 'play', label: 'Play' }
]
const cardsParentOptions = [{ value: 'cards', label: 'By Cards' }]
const marketParentOptions = [{ value: 'categories', label: 'Categories' }]
const tailorOptions = [
  { value: 0, label: 'None' },
  { value: 1, label: 'News' },
  { value: 2, label: 'Interests' },
  { value: 3, label: 'Locations' }
]

const INIT_ROW = { title: '', tags: [] }

const errorMsg = type => {
  let message
  switch (type) {
    case 'name':
      message = 'Name required'
      break
    case 'nameDuplicate':
      message = 'Name already exists'
      break
    case 'parent':
      message = 'Parent required'
      break
    case 'tagline':
      message = 'Tagline required'
      break
    case 'tempImage':
      message = 'Picture required'
      break
    case 'rowTitle':
      message = 'Title required'
      break
    default:
      message = 'Error'
      break
  }
  return message
}

const DEFAULT_CATEGORY = {
  type: 'category',
  parent: '',
  name: '',
  value: '',
  tagline: '',
  showOnReg: 0,
  rows: [{ ...INIT_ROW }],
  showOnMarket: false,
  showOnMainDeck: false,
  timesUsed: 0,
  timesSearched: 0,
  active: true
}

const CategoryForm = ({ edit = false, category, onBackClick, maindeckCategory = true }) => {
  const [error, setError] = useState(false)
  const [nameDuplicated, setNameDuplicated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newCategory, setNewCategory] = useState({
    ...DEFAULT_CATEGORY,
    showOnMarket: !maindeckCategory,
    showOnMainDeck: Boolean(maindeckCategory),
    parent: maindeckCategory ? '' : 'categories'
  })
  const parentOptions = maindeckCategory
    ? category?.parent === 'cards'
      ? cardsParentOptions
      : maindeckParentOptions
    : marketParentOptions
  const [tempImage, setTempImage] = useState(
    category?.value && category?.value !== ''
      ? `${maindeckCategory ? 'main' : 'market'}_${category?.parent || 'null'}_${category?.value}`
      : null
  )

  const deleteRow = index => {
    if (newCategory?.rows?.length >= 2) {
      const tempRows = newCategory?.rows || []
      tempRows.splice(index, 1)
      setNewCategory({ ...newCategory, rows: tempRows })
    } else {
      toastService('info', 'At least one row required.')
    }
  }

  const onInputChangeHandler = (key, value) => {
    if (key) {
      setNewCategory({ ...newCategory, [key]: value })
    }
  }

  const addRow = () => {
    if (newCategory?.rows?.length < 5) {
      setNewCategory({ ...newCategory, rows: [...(newCategory?.rows || []), { ...INIT_ROW }] })
    } else {
      toastService('info', 'The maximum number of rows allowed is 5.')
    }
  }

  const handleChangeRowTags = (newTags = [], rowIndex) => {
    const newRows = [...newCategory.rows]
    if (Number.isInteger(rowIndex) && Array.isArray(newTags)) {
      const updatedRow = newRows[rowIndex] || { title: '', filter: { tags: [] } }
      if (Array.isArray(updatedRow?.filter?.tags)) {
        updatedRow.filter.tags = newTags
      } else {
        updatedRow.filter = { ...(updatedRow?.filter || {}), tags: newTags }
      }
      newRows[rowIndex] = updatedRow
      setNewCategory({ ...newCategory, rows: newRows })
    }
  }

  const handleChangeRowTitle = event => {
    event.preventDefault()
    const rowIndex = event.target.id
    const updatedTitle = event?.target?.value || ''

    const newRows = [...newCategory.rows]
    const updatedRow = newRows[rowIndex] || { title: '', filter: { tags: [] } }
    updatedRow.title = updatedTitle

    newRows[rowIndex] = updatedRow

    setNewCategory({ ...newCategory, rows: newRows })
  }

  const onBackClickHandler = () => {
    setNewCategory({ ...DEFAULT_CATEGORY, rows: [cloneDeep({ ...INIT_ROW })] })
    onBackClick && onBackClick(false)
  }

  const validateCategory = () => {
    let rowsValid = true
    newCategory?.rows?.forEach(item => {
      if (!item?.title || item.title === '') {
        rowsValid = false
      }
    })
    if (
      rowsValid &&
      newCategory.name !== '' &&
      (newCategory.parent || (category?.value && !category?.parent)) !== '' &&
      newCategory.tagline &&
      tempImage
    ) {
      setError(false)
      return true
    } else {
      setError(true)
      return false
    }
  }

  const updateFileToAmazon = async (file, fileName) => {
    const base64 = await converFileToBase64(file)
    const res = await fileService
      .run('s3', 'upload', { data: base64, name: fileName + '.jpg' }, null, fileName)
      .catch(() => {
        toastService('error', 'Error.')
      })
    return res
  }

  const onSaveCategoryClick = async () => {
    if (validateCategory()) {
      setLoading(true)
      const tempCat = { ...newCategory }
      // get value from name (Category Name #^12-3  =>  category-name-12-3)
      tempCat.value = tempCat?.value || textToLowerSeparatedWithHyphen(tempCat?.name)

      try {
        const res = await dataService.run('data', 'category', category?._id ? 'update' : 'new', tempCat)
        // update image if its changed
        if (typeof tempImage !== 'string') {
          const imageName = `${maindeckCategory ? 'main' : 'market'}_${
            tempCat?.parent && tempCat?.parent !== '' ? tempCat.parent : 'null'
          }_${tempCat.value}`

          await updateFileToAmazon(tempImage, imageName.toString())
        }
        setLoading(false)
        if (res?._id) {
          toastService('success', `Successfully ${edit ? 'edited' : 'created'} category.`)
        } else {
          toastService('error', `Failed to ${edit ? 'edit' : 'create'} category.`)
        }
        setNewCategory({ ...DEFAULT_CATEGORY, rows: [cloneDeep({ ...INIT_ROW })] })
        onBackClick && onBackClick(true)
      } catch (error) {
        if (error?.response?.status === 406) {
          setNameDuplicated(true)
          setError(true)
        }
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (category?._id) {
      setNewCategory({ ...category, rows: category?.rows || [] })
    }
  }, [category?._id])

  return (
    <div className={styles.wrapper}>
      <Heading
        title={edit ? 'Edit Category' : 'New Category'}
        className={styles.headingClass}
        leftMiddleElement={
          <div className={styles.back} onClick={onBackClickHandler}>
            &#x27A4;
          </div>
        }
        rightMiddleElement={
          <div className={styles.buttonDiv}>
            <Button label={edit ? 'Save' : 'Create'} onClick={onSaveCategoryClick} spinner={loading} />
          </div>
        }
      />
      <div className={styles.contentWrapper}>
        <div className={styles.inputs}>
          <Input
            maxLength={30}
            placeholder={'Enter name'}
            className={'cardInput'}
            value={newCategory?.name}
            onChange={e => onInputChangeHandler('name', e.target.value)}
            error={(error && !newCategory.name) || nameDuplicated}
            errorMsg={nameDuplicated ? errorMsg('nameDuplicate') : errorMsg('name')}
            disabled={category?.value && !category?.parent}
          />

          <div className={styles.selector}>
            <Selector
              selectClass={'selectGrayHeight100'}
              placeholder={'Show in Tailor'}
              selectedOption={tailorOptions.find(
                option => option.value === newCategory.showOnReg || (option.value === 0 && category?.parent === 'cards')
              )}
              options={tailorOptions}
              name={'showOnReg'}
              type={'text'}
              handleChangeSelect={e => onInputChangeHandler('showOnReg', e.value)}
              disabled={(category?.value && !category?.parent) || category?.parent === 'cards'}
            />
          </div>
          <div className={styles.selector}>
            <Selector
              selectClass={'selectGrayHeight100'}
              placeholder={'Select parent'}
              selectedOption={parentOptions.find(option => option.value === newCategory.parent)}
              error={error && !newCategory.parent}
              errorMsg={errorMsg('parent')}
              options={parentOptions}
              name={'topics'}
              type={'text'}
              disabled={!maindeckCategory || (category?.value && !category?.parent) || category?.parent === 'cards'}
              handleChangeSelect={e => onInputChangeHandler('parent', e.value)}
            />
          </div>
        </div>
        <div className={styles.uploadImage}>
          <div className={styles.mediaPickerContainer}>
            <MediaPicker
              className="categoryMediaPicker"
              stateMedia={tempImage && getMediaFromFileOrString(tempImage)}
              showCrop
              onImageCrop={image => setTempImage(image)}
              onImageChange={image => setTempImage(image)}
              removable={false}
            />
          </div>
          {error && !tempImage && (
            <Typography variation={TYPOGRAPHY_VARIATION.ErrorMessageSmall} text={errorMsg('tempImage')} />
          )}
        </div>
        <div className={styles.rows}>
          <div className={`${styles.inputContainer} ${styles.taglineContainer}`}>
            <div></div>
            <Input
              maxLength={50}
              placeholder={'Enter tagline'}
              className={'cardInput'}
              value={newCategory?.tagline}
              onChange={e => setNewCategory({ ...newCategory, tagline: e.target.value })}
              error={error && !newCategory.tagline}
              errorMsg={errorMsg('tagline')}
            />
            <div className={styles.toogleButton}>
              <div
                className={newCategory?.active ? styles.active : styles.inActive}
                onClick={() => onInputChangeHandler('active', !newCategory?.active)}
              >
                <div className={styles.inner}></div>
              </div>
              <span>Active</span>
            </div>
          </div>
          <div className={styles.tagInput}>
            <h1>Rows</h1>
            {newCategory?.rows?.length > 0 &&
              newCategory.rows.map((item, index) => {
                return (
                  <div className={styles.singleInput} key={index}>
                    <div className={styles.input}>
                      <Input
                        maxLength={70}
                        placeholder={'Row title'}
                        className={'cardInput'}
                        value={item?.title}
                        name={index}
                        onChange={handleChangeRowTitle}
                        placeholderOrentation="placeholderCenter"
                        error={error && !item?.title}
                        errorMsg={errorMsg('rowTitle')}
                      />
                    </div>
                    <div className={styles.singleRow}>
                      <TagInput
                        id={index}
                        placeholder={'Row tags'}
                        tagsLimit={50}
                        tags={[...new Set(item?.filter?.tags)]}
                        className="full-width-fixed"
                        onTagChange={tags => handleChangeRowTags(tags, index)}
                      />
                      <div className={styles.deleteRowIcon} onClick={() => deleteRow(index)}>
                        <img src={deleteIcon} alt="Delete icon" />
                      </div>{' '}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div className={styles.buttonDiv}>
          <Button label="Add row" size={'fitContent'} onClick={addRow} />
        </div>
      </div>
    </div>
  )
}

export default CategoryForm
