import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import AttributeButton from 'components/attributeButton'
import Input from 'components/input'
import { CARD_BACK_TYPES } from 'staticConstants'
// import { cardActions } from 'store/actions/card.actions'
import styles from '../cardCreationForm.module.scss'
import DatePickerComponent from 'components/datePicker'
import GoogleMapSearch from 'components/googleMapSearch'
import iconCloseBlue from 'assets/icons/iconCloseBlue.svg'
import TextArea from 'components/textarea'
import InlinePack from 'components/card/components/inlinePack'
import axios from 'axios'
import OkModal from 'components/okModal'
import useWindowDimensions from 'utils/screenSize'
import { setRequiredFields, setSelectedCard } from 'store/reducers/card/cardSlice'
import LinkedImageForm from 'components/linkedImageForm'
import isAllowedForCurrentUser from 'utils/isFieldAllowedForTheUser'
import cloneDeep from 'lodash/cloneDeep'
import { MAX_SIZE_FOR_IMAGE, MAX_SIZE_FOR_VIDEO } from 'utils'

const RenderBackFields = (
  card = {},
  cardFields = {},
  clickedOnAddMap = {},
  clickedOnAddInline = {},
  clickedOnLinkedImage = null
) => {
  const dispatch = useDispatch()
  const refMap = useRef(null)
  const refInline = useRef(null)
  const requiredFields = useSelector(state => state.card.requiredFields)
  const [calendar, setCalendar] = useState(Date.now())
  const [openPicker, setOpenPicker] = useState(false)
  const [openMap, setOpenMap] = useState(false)
  const [openInlinePack, setOpenInlinePack] = useState(false)
  const [openLinkedImageModal, setOpenLinkedImageModal] = useState(false)
  const maxVideoSizeMsg = `Maximum size is ${MAX_SIZE_FOR_VIDEO}mb`
  const maxImageSizeMsg = `Maximum size is ${MAX_SIZE_FOR_IMAGE}mb`
  const result = useRef(null)
  const [showInfoModal, setShowInfoModal] = useState({ show: false, message: '' })
  const { isMobile } = useWindowDimensions()
  const [isBussines] = useState(true)
  const { subType = null } = useSelector(state => state?.auth?.user)

  useEffect(() => {
    const closeResault = e => {
      const datePickerRef = document.getElementById('root-portal')
      if (openPicker && !datePickerRef?.contains(e.target) && !result?.current?.contains(e.target)) {
        setOpenPicker(false)
      }
    }
    document.body.addEventListener('click', closeResault)
    return () => {
      document.body.removeEventListener('click', closeResault)
    }
  })

  const onChange = (key, value) => {
    dispatch(setSelectedCard({ ...card, [key]: value }))
  }
  const onBackFieldsChange = type => {
    const cancelToken = axios.CancelToken.source()
    dispatch(
      setSelectedCard({
        ...card,
        backFields: [
          ...card.backFields,
          { id: new Date().getTime(), type: type, content: '', cancelToken: cancelToken }
        ]
      })
    )
  }
  const onFullCardMediaChange = () => {
    dispatch(
      setSelectedCard({
        ...card,
        fullCardImage: { active: !card.fullCardImage.active, image: '' }
      })
    )
  }
  const onAddNewSlide = (clearError = false) => {
    if (card.slider.length < 10) {
      const slides = card.slider
      const slideId = new Date().getTime()
      dispatch(
        setSelectedCard({
          ...card,
          activeSlide: card.slider.length,
          slider: [...slides, { id: slideId, image: '', caption: '' }]
        })
      )
    }
    if (clearError) {
      const tempFields = { ...requiredFields.fields }
      tempFields.slider = ''
      dispatch(setRequiredFields({ ...requiredFields, fields: tempFields }))
    }
  }
  const onAddNewQuestion = () => {
    if (!card.hasAnswers) {
      if (card.questions.length < 10) {
        const questions = [...card.questions]
        dispatch(
          setSelectedCard({
            ...card,
            activeSlide: card.questions.length,
            questions: [
              ...questions,
              {
                question: '',
                answers: [],
                explanation: ''
              }
            ]
          })
        )
      }
    } else {
      /* eslint-disable */
      setShowInfoModal({ show: true, message: "This card already has answers. Options can't be changed." })
      /* eslint-enable */
    }
  }
  const onAddQuestionOption = (questionIndex, correctValue) => {
    if (!card.hasAnswers) {
      const questions = cloneDeep(card.questions)
      if (questions[questionIndex].answers.length < 4) {
        questions[questionIndex].answers.push({ id: new Date().getTime(), answer: '', correct: correctValue })
        dispatch(
          setSelectedCard({
            ...card,
            questions: [...questions]
          })
        )
      }
    } else {
      /* eslint-disable */
      setShowInfoModal({ show: true, message: "This card already has answers. Options can't be changed." })
      /* eslint-enable */
    }
  }
  const onChangeQuestionExplanation = (questionIndex = 0, explanationValue) => {
    const questions = cloneDeep(card.questions)
    questions[questionIndex].explanation = explanationValue
    dispatch(
      setSelectedCard({
        ...card,
        questions: [...questions]
      })
    )
  }
  const onChangeQuestion = (questionIndex = 0, questionValue) => {
    const questions = cloneDeep(card.questions)
    questions[questionIndex].question = questionValue
    dispatch(
      setSelectedCard({
        ...card,
        questions: [...questions]
      })
    )
  }
  const onChangeSliderCaption = (sliderIndex = 0, captionValue) => {
    const updatedSlider = card.slider.map((sliderItem, index) =>
      index === sliderIndex ? { ...sliderItem, caption: captionValue } : sliderItem
    )
    dispatch(
      setSelectedCard({
        ...card,
        slider: updatedSlider
      })
    )
  }
  const onChangeSliderLink = (sliderIndex = 0, linkValue) => {
    const updatedSlider = card.slider.map((sliderItem, index) =>
      index === sliderIndex ? { ...sliderItem, link: linkValue } : sliderItem
    )
    dispatch(
      setSelectedCard({
        ...card,
        slider: updatedSlider
      })
    )
  }
  const onDateChange = (date, e) => {
    setCalendar(moment(date).valueOf())
    if (!e) {
      setOpenPicker(!openPicker)
      dispatch(
        setSelectedCard({
          ...card,
          backFields: [
            ...card.backFields,
            { id: new Date().getTime(), type: CARD_BACK_TYPES.calendar, content: moment(date).valueOf() }
          ]
        })
      )
    }
  }
  const handleClickOutside = event => {
    if (refMap.current && !refMap.current.contains(event.target)) {
      setOpenMap(false)
    }
    if (refInline.current && !refInline.current.contains(event.target)) {
      setOpenInlinePack(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const addLocationOrInline = (type, value) => {
    setOpenMap(false)
    setOpenInlinePack(false)
    dispatch(
      setSelectedCard({
        ...card,
        backFields: [
          ...card.backFields,
          {
            id: new Date().getTime(),
            type: type,
            content: value
          }
        ]
      })
    )
  }
  const checkIfMapExists = () => card.backFields && card.backFields.find(e => e.type === 'map')
  const checkIfCalendarExists = () => card.backFields && card.backFields.find(e => e.type === 'calendar')

  return (
    <>
      {showInfoModal.show && (
        <OkModal onClose={() => setShowInfoModal({ show: false, message: '' })} message={showInfoModal.message} />
      )}
      {openMap && (
        <div className={`${styles.googleMapBack} ${isBussines && styles.isBussines}`} id="positionMap" ref={refMap}>
          <img
            className={`${styles.googleClose} ${isBussines && styles.isBussines}`}
            src={iconCloseBlue}
            alt="Close icon"
            onClick={() => setOpenMap(false)}
          />
          <GoogleMapSearch mapClass="search-input" onAdd={event => addLocationOrInline(CARD_BACK_TYPES.map, event)} />
        </div>
      )}
      {openLinkedImageModal && (
        <div className={`${styles.googleMapBack} ${isBussines && styles.isBussines}`} id="positionMap" ref={refMap}>
          <img
            className={`${styles.googleClose} ${isBussines && styles.isBussines}`}
            src={iconCloseBlue}
            alt="Close icon"
            onClick={() => setOpenLinkedImageModal(false)}
          />
          <LinkedImageForm card={card} closeModal={() => setOpenLinkedImageModal(false)} />
        </div>
      )}
      {openInlinePack && (
        <div className={`${styles.inlineBack} ${isBussines && styles.isBussines}`} id="positionInline" ref={refInline}>
          <img
            className={`${styles.googleClose} ${isBussines && styles.isBussines}`}
            src={iconCloseBlue}
            alt="Close icon"
            onClick={() => setOpenInlinePack(false)}
          />
          <InlinePack card={card} onAdd={event => addLocationOrInline(CARD_BACK_TYPES.inlinePack, event)} />
        </div>
      )}
      {/* Textbox */}
      {/* Type: Double */}
      {cardFields.back && cardFields.back.textbox && (
        <AttributeButton
          label="Textbox"
          className="full-width"
          onClick={() => onBackFieldsChange(CARD_BACK_TYPES.text)}
        />
      )}
      {/* Image */}
      {/* Type: Double */}
      {cardFields.back && cardFields.back.image && (
        <AttributeButton
          info={maxImageSizeMsg}
          label="Image"
          className="full-width"
          onClick={() => onBackFieldsChange(CARD_BACK_TYPES.image)}
        />
      )}
      {/* Media */}
      {/* Type: Double */}
      {cardFields.back && cardFields.back.video && (
        <AttributeButton
          info={maxVideoSizeMsg}
          label="Video"
          className="full-width"
          onClick={() => onBackFieldsChange(CARD_BACK_TYPES.video)}
        />
      )}
      {/* Map */}
      {/* Type: Double */}
      {cardFields.back && cardFields.back.map && (
        <AttributeButton
          label="Map"
          className="full-width"
          containerClass={checkIfMapExists() && 'disabledButton'}
          onClick={() => {
            if (checkIfMapExists()) return
            setOpenMap(!openMap)
            setOpenPicker(false)
            if (isMobile) clickedOnAddMap()
          }}
        />
      )}
      {/* Calendar */}
      {/* Type: Double */}
      {cardFields.back && cardFields.back.calendar && (
        <div ref={result} style={{ width: '100%' }}>
          <AttributeButton
            label="Calendar"
            className="full-width"
            onClick={() => {
              if (checkIfCalendarExists()) return
              setOpenPicker(!openPicker)
            }}
            containerClass={checkIfCalendarExists() && 'disabledButton'}
          />
          {openPicker && (
            <div style={{ visibility: 'hidden' }}>
              <DatePickerComponent
                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                selected={calendar ? new Date(calendar) : Date.now()}
                name="date"
                label="date"
                onDatePickerChange={(date, e) => onDateChange(date, e)}
                showTimeSelect={true}
                timeIntervals={5}
                open={openPicker}
                datePickerNoInput={true}
              />
            </div>
          )}
        </div>
      )}
      {/* Inline Pack/Card */}
      {/* Type: Double */}
      {cardFields.back && cardFields.back.inlinePack && (
        <AttributeButton
          label="Inline Card"
          className="full-width"
          onClick={() => {
            setOpenInlinePack(true)
            setOpenPicker(false)
            setOpenMap(false)
            if (isMobile) clickedOnAddInline()
          }}
        />
      )}
      {/* linkedImage */}
      {/* Type: Double */}
      {cardFields.back && cardFields.back.linkedImage && isAllowedForCurrentUser('linkedImage', subType) && (
        <AttributeButton
          info={maxImageSizeMsg}
          label="Image with link"
          className="full-width"
          onClick={() =>
            isMobile && clickedOnLinkedImage ? clickedOnLinkedImage() : setOpenLinkedImageModal(prevState => !prevState)
          }
        />
      )}

      {/* Group: heading, map */}
      {/* Type: Standard */}
      {cardFields.back && cardFields.back.groupTextboxMap && (
        <div className={styles.group}>
          <AttributeButton
            label="Textbox"
            className="full-width"
            onClick={() => onBackFieldsChange(CARD_BACK_TYPES.text)}
          />
          <AttributeButton
            label="Map"
            className="full-width"
            containerClass={checkIfMapExists() && 'disabledButton'}
            onClick={() => {
              if (checkIfMapExists()) return
              setOpenMap(!openMap)
              setOpenPicker(false)
              if (isMobile) clickedOnAddMap()
            }}
          />
        </div>
      )}
      {/* Group: textbox, callendar */}
      {/* Type: Standard */}
      {cardFields.back && cardFields.back.groupImageCalendar && (
        <div className={styles.group}>
          <AttributeButton
            info={maxImageSizeMsg}
            label="Image"
            className="full-width"
            onClick={() => onBackFieldsChange(CARD_BACK_TYPES.image)}
          />
          <div ref={result} style={{ height: '3.125rem' }}>
            <AttributeButton
              label="Calendar"
              className="full-width"
              onClick={() => {
                if (checkIfCalendarExists()) return
                setOpenPicker(true)
              }}
              containerClass={checkIfCalendarExists() && 'disabledButton'}
            />
            {openPicker && (
              <div style={{ visibility: 'hidden' }}>
                (
                <DatePickerComponent
                  maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                  selected={calendar ? new Date(calendar) : Date.now()}
                  name="date"
                  label="date"
                  onDatePickerChange={(date, e) => onDateChange(date, e)}
                  showTimeSelect={true}
                  timeIntervals={5}
                  open={openPicker}
                  datePickerNoInput={true}
                />
                )
              </div>
            )}
          </div>
        </div>
      )}
      {/* Group: media, inline pack/card */}
      {/* Type: Standard */}
      {cardFields.back && cardFields.back.groupVideoInlinePack && (
        <div className={styles.group}>
          <AttributeButton
            info={maxVideoSizeMsg}
            label="Video"
            className="full-width"
            onClick={() => onBackFieldsChange(CARD_BACK_TYPES.video)}
          />
          <AttributeButton
            label="Inline Card"
            className="full-width"
            onClick={() => {
              setOpenInlinePack(true)
              setOpenPicker(false)
              setOpenMap(false)
              if (isMobile) clickedOnAddInline()
            }}
          />
        </div>
      )}
      {/* Group: textbox, full card media */}
      {/* Type: Collectible, Mystery */}
      {cardFields.back && cardFields.back.groupTextboxFullCard && (
        <div className={styles.group}>
          <AttributeButton
            label="Textbox"
            className="full-width"
            onClick={() => onBackFieldsChange(CARD_BACK_TYPES.text)}
            disabled={card?.fullCardImage?.active}
          />
          <AttributeButton
            label="Full Card Media"
            className="full-width"
            onClick={onFullCardMediaChange}
            active={card ? !card.fullCardImage.active : true}
            info="This option will remove other content"
          />
        </div>
      )}
      {/* Group: media */}
      {/* Type: Collectible, Mystery */}
      {cardFields.back && cardFields.back.groupImage && (
        <div className={styles.group}>
          <AttributeButton
            info={maxImageSizeMsg}
            label="Image"
            className="full-width"
            onClick={() => onBackFieldsChange(CARD_BACK_TYPES.image)}
            disabled={card?.fullCardImage?.active}
          />
        </div>
      )}
      {/* Group: textbox */}
      {/* Type: Collectible, Mystery */}
      {cardFields.back && cardFields.back.groupVideo && (
        <div className={styles.group}>
          <AttributeButton
            info={maxVideoSizeMsg}
            label="Video"
            className="full-width"
            onClick={() => onBackFieldsChange(CARD_BACK_TYPES.video)}
            disabled={card?.fullCardImage?.active}
          />
        </div>
      )}
      {/* Add new slide */}
      {/* Type: Slide */}
      {cardFields.back && cardFields.back.addNewSlide && (
        <AttributeButton
          label="Add New Slide (Full Card Image)"
          info="Recommend 3:5 Image Ratio"
          className="full-width"
          containerClass={card.slider.length === 10 ? 'notAllowed' : ''}
          onClick={onAddNewSlide}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.slider ?? ''}
        />
      )}
      {/* Slide image caption */}
      {/* Type: Slide */}
      {cardFields.back && cardFields.back.slideImageCaption && card.slider && card.slider.length > 0 && (
        <>
          <Input
            placeholder="Add image caption (optional)"
            variation="cardInput"
            maxLength={200}
            containerClass={`${styles.fullWidth} ${styles.heightInput}`}
            className={styles.defaultInput}
            value={card.slider[card.activeSlide !== '' ? card.activeSlide : 0]?.caption || ''}
            onChange={event =>
              onChangeSliderCaption(card.activeSlide !== '' ? card.activeSlide : 0, event.target.value)
            }
          />
          <Input
            placeholder="Add image link (optional)"
            variation="cardInput"
            maxLength={200}
            containerClass={`${styles.fullWidth} ${styles.heightInput}`}
            className={styles.defaultInput}
            value={card.slider[card.activeSlide !== '' ? card.activeSlide : 0]?.link || ''}
            onChange={event => onChangeSliderLink(card.activeSlide !== '' ? card.activeSlide : 0, event.target.value)}
          />
        </>
      )}
      {/* Byline */}
      {/* Type: Sponsored */}
      {cardFields.back && cardFields.back.byline && (
        <Input
          placeholder="Edit Byline"
          variation="cardInput"
          containerClass={styles.fullWidth}
          inputClass="lgInput"
          className={styles.defaultInput}
          onChange={event => onChange('byline', event.target.value)}
          value={card.byline ?? ''}
        />
      )}
      {/* Add new question (slide) */}
      {/* Type: Double sided trivia */}
      {cardFields.back && cardFields.back.addNewQuestion && (
        <AttributeButton label="Question" className="full-width" onClick={onAddNewQuestion} />
      )}
      {/* Group: true option, false option */}
      {/* Type: Double sided trivia */}
      {cardFields.back && cardFields.back.groupTrueFalseOption && (
        <div className={styles.group}>
          <AttributeButton
            label="True Option"
            className="full-width"
            onClick={() => onAddQuestionOption(card.activeSlide, true)}
            error={requiredFields.error}
            errorMsg={requiredFields.fields?.answers ?? ''}
          />
          <AttributeButton
            label="False Option"
            className="full-width"
            onClick={() => onAddQuestionOption(card.activeSlide, false)}
          />
        </div>
      )}
      {/* Question */}
      {/* Type: Double sided trivia */}
      {cardFields.back && cardFields.back.questionValue && (
        <TextArea
          placeholder="Question"
          textareaClass="textarea-gray-100"
          value={card.questions[card.activeSlide !== '' ? card.activeSlide : 0].question}
          onChange={event => onChangeQuestion(card.activeSlide !== '' ? card.activeSlide : 0, event.target.value)}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.question ?? ''}
        />
      )}
      {/* Explanation reveal */}
      {/* Type: Double sided trivia */}
      {cardFields.back && cardFields.back.explanationReveal && (
        <TextArea
          placeholder="Add Explanation Reveal (Optional)"
          textareaClass="textarea-gray-100"
          value={card.questions[card.activeSlide !== '' ? card.activeSlide : 0].explanation}
          onChange={event =>
            onChangeQuestionExplanation(card.activeSlide !== '' ? card.activeSlide : 0, event.target.value)
          }
          maxLength={130}
        />
      )}
      {cardFields.back && cardFields.back.groupLinkedImage && isAllowedForCurrentUser('linkedImage', subType) && (
        <div className={styles.group}>
          <AttributeButton
            info={maxImageSizeMsg}
            label="Image with link"
            className="full-width"
            onClick={() =>
              isMobile && clickedOnLinkedImage
                ? clickedOnLinkedImage()
                : setOpenLinkedImageModal(prevState => !prevState)
            }
          />
          <div class="full-width"></div>
        </div>
      )}
    </>
  )
}
export default RenderBackFields
