import Button from 'components/button'
import Input from 'components/input'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { sendRespondEmail } from 'store/reducers/auth/thunk'
import { closeModal } from 'store/reducers/modal/modalSlice'
import iconClose from 'assets/icons/iconCloseBlue.svg'
import styles from './contactUsForm.module.scss'
import { CONTACT_US_OPTIONS } from 'staticConstants'

const ContactUsForm = ({ props, activeTab }) => {
  const dispatch = useDispatch()
  const [clicked, setClicked] = useState(false)
  const [flagDisabled, setFlagDisabled] = useState(true)
  const flagRespond = useSelector(state => state.auth.sendRespondEmailStatus)

  const handleAnswer = () => {
    setFlagDisabled(true)
    const emailtext = document.getElementById('answerToContactUs').value
    const obj = {
      text: emailtext,
      email: props.email
    }
    setClicked(true)
    dispatch(sendRespondEmail(obj))
  }

  const handleChange = e => {
    if (e.target.value.trim() !== '') {
      setFlagDisabled(false)
    } else {
      setFlagDisabled(true)
    }
  }

  const updateRespondStatus = async () => {
    const emailtext = document.getElementById('answerToContactUs').value
    const newObj = { ...props }
    newObj.status = false
    newObj.respondedText = emailtext
    await dataService.run('data', 'contactUsQuestion', 'update', newObj)
    dispatch(closeModal())
  }

  const closeModalX = () => {
    dispatch(closeModal())
  }

  useEffect(() => {
    if (flagRespond > 0 && clicked) {
      updateRespondStatus()
    }
  }, [flagRespond])

  return (
    <div className={styles.contactFormWrapper}>
      <div className={styles.header}>
        <div onClick={closeModalX} className={styles.close}>
          <img src={iconClose} alt="Close icon" />
        </div>
      </div>
      <h2 style={{ marginTop: 0 }}>Details</h2>
      <div className={styles.singleInputRow}>
        <Input
          variation="formInput"
          inputClass="customInput"
          placeholderOrentation="placeholderCenter"
          placeholder="date"
          type="date"
          name="date"
          disabled={true}
          value={moment(props.createdAt).format('YYYY-MM-DD')}
        />
        <Input
          variation="formInput"
          inputClass="customInput"
          placeholderOrentation="placeholderCenter"
          placeholder="type"
          type="text"
          name="type"
          value={CONTACT_US_OPTIONS.find(option => option.value === props.help)?.label}
          disabled={true}
        />
      </div>
      <div className={styles.singleInputRow}>
        <Input
          variation="formInput"
          inputClass="customInput"
          placeholderOrentation="placeholderCenter"
          placeholder="email"
          type="email"
          name="email"
          value={props.email}
          disabled={true}
        />
        <Input
          variation="formInput"
          inputClass="customInput"
          placeholderOrentation="placeholderCenter"
          placeholder="name"
          type="text"
          name="name"
          value={props.name}
          disabled={true}
        />
      </div>

      <div className={`${styles.singleInputRow}`}>
        <div className={styles.textAreaContainer}>
          <textarea placeholder="Message" disabled value={props.text}></textarea>
        </div>
      </div>

      <hr></hr>
      <h2>Answer</h2>
      <div>
        <div className={`${styles.singleInputRow}`}>
          <div className={styles.textAreaContainer}>
            <textarea
              placeholder="Type text to answer"
              id="answerToContactUs"
              onChange={handleChange}
              value={props.respondedText}
              disabled={activeTab === 2}
            ></textarea>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button label="Answer" variation="secondary" onClick={handleAnswer} disabled={flagDisabled} />
      </div>
    </div>
  )
}

export default ContactUsForm
