import styles from './toggle.module.scss'
import { appUrl } from 'environment'

const Toggle = ({
  checked = false,
  onChange,
  disabled = false,
  toggleClass = 'toggleLG',
  className,
  label,
  info,
  matureInfo = false
}) => {
  const handleGoToTermsOfService = () => {
    window.open(`${appUrl}/terms-conditions#termsOfService`)
  }
  return (
    <div className={`${styles.toggleWrapper} ${className}`}>
      <div>
        <label className={`${styles[toggleClass]}`}>
          <input
            className={styles.switchCheckbox}
            type="checkbox"
            checked={checked === true}
            onChange={onChange}
            disabled={disabled}
          />
          <div className={styles.switchLabel}>
            <span className={styles.switchButton} />
          </div>
        </label>
      </div>
      <div className={styles.contentBlock}>
        <h3>{label}</h3>
        <p>{info}</p>

        {matureInfo && (
          <p>
            If your card includes relevant, lawful and meaningful content related to Nudity, Alcohol, Drugs or Gambling,
            mark as mature. Otherwise it may be flagged and possibly removed from Wonder Shuffle. Please refer to our
            full{' '}
            <div className={`${styles.linkSpan}`} onClick={handleGoToTermsOfService}>
              card policies
            </div>{' '}
            for more detail on what is allowed in our community.
          </p>
        )}
      </div>
    </div>
  )
}

export default Toggle
