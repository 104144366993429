import Button from 'components/button'
import CardFlip from 'components/cardFlip'
import Pagination from 'components/pagination'
import SimpleSearch from 'components/simpleSearch'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { CARD_TYPES } from 'staticConstants'
import CardScrollableContent from '../scrollableContent'
import styles from './inlinePack.module.scss'

const DEFAULT_FILTER = {
  disabled: null,
  public: true,
  published: true
}

const InlinePack = ({ card, onAdd, ignoreCardIds = [], contentModeretionCardFilter = false }) => {
  const { auth } = useSelector(store => store)
  const [cards, setCards] = useState({ rows: [], total: 0 })
  const [choosenCard, setChoosenCard] = useState()
  const [inlineLoading, setInlineLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  let timer

  const onSelectHandler = selectedCard => {
    setChoosenCard({ ...selectedCard })
  }
  const onAddHandler = () => {
    choosenCard && onAdd && onAdd({ ...choosenCard })
  }
  const onSearchHandler = value => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      setSearchValue(value)
    }, 400)
  }

  const getQueryFilter = () => {
    if (contentModeretionCardFilter) return DEFAULT_FILTER
    else {
      return { userId: auth.user._id, cardType: { $ne: CARD_TYPES.Double }, ...DEFAULT_FILTER }
    }
  }

  useEffect(() => {
    if (card?._id) {
      let tempField
      if (card.backFields) tempField = card.backFields.find(e => e.type === 'inlinePack')
      tempField && setChoosenCard({ ...tempField.content })
    }
  }, [])

  useEffect(() => {
    setInlineLoading(true)
    const fetch = async () => {
      const filter = getQueryFilter()
      const ignoreIds = []
      if (ignoreCardIds?.length) {
        ignoreIds.push(...ignoreCardIds)
      }
      if (card?._id) {
        ignoreIds.push(card?._id)
      }
      if (ignoreIds?.length) {
        filter._id = { $nin: ignoreIds }
      }
      if (searchValue && searchValue !== '') {
        const searchFilter = {
          $or: [
            { heading: { $regex: searchValue, $options: 'i' } },
            { subheading: { $regex: searchValue, $options: 'i' } }
          ]
        }

        if (contentModeretionCardFilter) {
          filter.$and = [searchFilter]
        } else {
          filter.$and = [{ $or: [{ userId: auth.user._id }, { _id: { $in: auth?.user?.savedCards } }] }, searchFilter]
        }
      }

      const res = await dataService.run('data', 'card', 'page', {
        filter,
        pageNumber
      })

      setCards(res)
      setInlineLoading(false)
    }
    fetch()
  }, [searchValue, pageNumber])

  const cardElement = data => (
    <div
      className={`${styles.cardContainer} ${choosenCard && choosenCard._id === data._id ? styles.selected : ''}`}
      onClick={() => onSelectHandler(data)}
      key={data._id}
    >
      <CardFlip data={data} flippable={false} />
      <div className={styles.cardCoverField}></div>
    </div>
  )

  return (
    <div className={styles.wrapper}>
      <span className={styles.header}>Select Card</span>
      <div className={styles.searchContainer}>
        <SimpleSearch placeholder="Search for card" className={styles.search} onSearch={onSearchHandler} />
        <Button
          label="Add"
          className={`${styles.addBtn} ${!choosenCard ? styles.disabled : ''}`}
          onClick={onAddHandler}
        />
      </div>
      <CardScrollableContent className={styles.cardsContainer}>
        {cards?.rows?.length > 0 && cards?.rows?.map(data => <>{cardElement(data)}</>)}
        {inlineLoading && (
          <div className={styles.loadingContainer}>
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
          </div>
        )}
        {!inlineLoading && cards?.rows?.length === 0 && (
          <Typography variation={TYPOGRAPHY_VARIATION.EmptyMessageText} text="There are no cards" />
        )}
      </CardScrollableContent>
      {cards?.total > 0 && <Pagination onPageChange={page => setPageNumber(page)} total={cards?.total} />}
    </div>
  )
}

export default InlinePack
