import React from 'react'
import PropTypes from 'prop-types'
import getCardStyle from 'utils/getCardStyle'
import styles from './card.module.scss'

const Card = ({
  type = 'standard',
  className,
  size = 'normal',
  children,
  double = false,
  additionalBorder = false,
  userType = '',
  staticForNewsletter = false
}) => {
  return (
    <div
      className={`
        ${styles.container}
        ${styles['border-' + type]}
        ${staticForNewsletter && styles.staticBorderForNewsletter}
        ${additionalBorder && styles['additional-border']}
        ${styles['size-' + size]}
        ${styles[double ? 'double' : '']}
        ${className}
        ${styles[className]}
        ${styles[getCardStyle('border', type, userType)]}
        ${(type === 'doubleSidedTrivia' || type === 'oneSidedTrivia') && styles.triviaOverflow}
      `}
      id="exportCanvas"
    >
      {children}
    </div>
  )
}
Card.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.any
}
export default Card
