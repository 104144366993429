import LinkIcon from 'assets/icons/social/36/Hyperlink.png'
import EmailShare from 'components/socialShare/emailShare'
import FacebookShare from 'components/socialShare/facebookShare'
import TwitterShare from 'components/socialShare/twitterShare'
import { url } from 'environment'
import { toastService } from 'services/toastService'
import styles from './shareOptionsUser.module.scss'

const ShareOptionsUser = ({ icons = ['Facebook', 'Twitter', 'Mail', 'Link'], variation = '', user }) => {
  const shareHndler = () => {
    navigator.clipboard.writeText(url + '/public/' + user.username)
    toastService('info', `Copied link for user ${user.username}.`)
  }

  return (
    <div className={`${styles[variation]} ${styles.shareIcon}`}>
      {icons.map(icon => {
        if (icon === 'Facebook') {
          return (
            <FacebookShare
              className={styles.socialIcon}
              url={url + '/public/' + user?.username}
              quote={user?.username}
              key={icon}
            />
          )
        }
        if (icon === 'Twitter') {
          return (
            <TwitterShare
              className={styles.socialIcon}
              url={url + '/public/' + user?.username}
              quote={user?.username}
              key={icon}
            />
          )
        }
        if (icon === 'Mail') {
          return (
            <EmailShare
              className={styles.socialIcon}
              url={url + '/public/' + user?.username}
              subject={user?.username}
              body={''}
              separator={' | '}
              key={icon}
            />
          )
        }
        if (icon === 'Link') {
          return (
            <div className={styles.paddingLastIcon} key={icon}>
              <img onClick={shareHndler} src={LinkIcon} alt="Link" />
            </div>
          )
        }
      })}
    </div>
  )
}

export default ShareOptionsUser
