import Button from 'components/button'
import CardScrollableContent from 'components/card/components/scrollableContent'
import CardFront from 'components/cardFront'
import SimpleSearch from 'components/simpleSearch'
import { useEffect, useState } from 'react'
import { isObjectEmpty } from 'utils/helpers'
import styles from './cardSelection.module.scss'
import Pack from 'components/pack'

const CardSelection = ({
  cards,
  onDoneClick,
  onBackClick,
  title = 'Select Card',
  doneButtonLabel = 'Done',
  className
}) => {
  const [selectedCard, setSelectedCard] = useState({})
  const [shownCards, setShownCards] = useState([])
  const onSelectHandler = card => {
    if (selectedCard?._id === card?._id) setSelectedCard({})
    else setSelectedCard(card)
  }
  const onDoneHandler = () => {
    onDoneClick && onDoneClick(selectedCard)
  }
  const onBackHandler = () => {
    onBackClick && onBackClick()
  }
  const onSearchHandler = value => {
    setShownCards(
      cards.filter(
        c =>
          (c.heading && (c.heading + '').toLocaleLowerCase().includes(value.toLocaleLowerCase())) ||
          (c.name && (c.name + '').toLocaleLowerCase().includes(value.toLocaleLowerCase()))
      )
    )
  }
  useEffect(() => {
    setShownCards(cards)
  }, [cards])
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <span className={styles.headerTitle}>{title}</span>
      <div className={styles.searchContainer}>
        <Button label="Back" className={styles.backBtn} onClick={onBackHandler} />
        <SimpleSearch
          placeholder="Search for card or pack"
          className={styles.search}
          onSearch={event => onSearchHandler(event)}
        />
        <Button
          label={doneButtonLabel}
          className={`${styles.addBtn} ${isObjectEmpty(selectedCard) && styles.disabled}`}
          onClick={onDoneHandler}
        />
      </div>

      <CardScrollableContent className={styles.cardsContainer}>
        {shownCards.length > 0 ? (
          shownCards.map(card => {
            return (
              <div
                className={`${styles.cardContainer} ${selectedCard?._id === card._id && styles.selected}`}
                onClick={() => onSelectHandler(card)}
                key={card._id}
              >
                {card.type === 'card' && <CardFront card={card} />}
                {card.type === 'pack' && <Pack pack={card} />}
                <div className={styles.cardCoverField}></div>
              </div>
            )
          })
        ) : (
          <span> There are no cards</span>
        )}
      </CardScrollableContent>
    </div>
  )
}
export default CardSelection
