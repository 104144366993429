import moment from 'moment'

export const getRecentTime = (time, showHours = false) => {
  const todayInMilliseconds = moment().startOf('day').valueOf()
  const ONE_DAT_IN_MILLISECONDS = 86400000
  const hours = moment
    .unix(time / 1000)
    .format('hh:mm')
    .toString()

  if (time > todayInMilliseconds) return hours
  else if (time < todayInMilliseconds && time > todayInMilliseconds - ONE_DAT_IN_MILLISECONDS)
    return 'Yesterday' + (showHours ? ' ' + hours : '')
  else return moment.unix(time / 1000).format('DD MMMM') + (showHours ? ' ' + hours : '')
}
