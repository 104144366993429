import AnimationLikePoints from 'components/animationLikePoints'
import CardComments from 'components/card/types/comments'
import CardShare from 'components/card/types/share'
import CardFlip from 'components/cardFlip'
import IconComponentFollow from 'components/icons/followIcon'
import IconComponentLike from 'components/icons/likeIcon'
import IconComponentMore from 'components/icons/moreIcon'
import IconComponentSave from 'components/icons/saveIcon'
import IconComponentShare from 'components/icons/shareIcon'
import Login from 'pages/auth/login'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_TYPES, dummyUsers } from 'staticConstants'
// import { cardActions } from 'store/actions/card.actions'
// import { gridActions } from 'store/actions/grid.actions'
// import { packActions } from 'store/actions/pack.actions'
// import { userActions } from 'store/actions/user.actions'
import getCardStyle from 'utils/getCardStyle'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import useWindowDimensions from 'utils/screenSize'
import styles from './cardActionOption.module.scss'

const CardAction = ({
  className,
  type = CARD_TYPES.Create,
  size,
  openComments,
  card,
  // disabled = false,
  likeable = true,
  followable = true,
  commentsable = true
}) => {
  const disabled = true
  const [like, setLikeValue] = useState(false)
  const [triggerLikeAnimation, setTriggerLikeAnimation] = useState(false)
  const [save, setSaveValue] = useState(false)
  const [triggerSaveAnimation, setTriggerSaveAnimation] = useState(false)
  const [isSaveable, setIsSaveable] = useState(false)
  const [isFollowable, setIsFollowable] = useState(false)
  const [follow, setFollowValue] = useState(false)
  const [points] = useState({ like: 'like', share: 'share' })
  const store = useSelector(state => state)
  const dispatch = useDispatch()
  const { isMobile } = useWindowDimensions()

  const onMoreHandler = () => {
    if (isMobile) {
      dispatch(
        openModal(
          <CardComments card={card} />,
          'Test modal',
          '29rem',
          'cardComments',
          null,
          null,
          closeGridCardPreview,
          ''
        )
      )
    } else {
      !disabled && openComments && openComments()
    }
  }

  const onLikeHandler = async () => {
    if (store.auth.user?._id) {
      if (!card?.isLiked) setTriggerLikeAnimation(true)
      // COMMENTED
      // const data = {
      //   cardId: card._id,
      //   authorId: card.userId,
      //   authorUsername: card.username,
      //   userId: store.auth.user?._id,
      //   username: store.auth.user?.username,
      //   cardType: card.cardType,
      //   interactionType: 'like'
      // }
      // dispatch(cardActions.likeCard({ data, card }))
    } else {
      dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
    }
  }
  const onSaveHandler = async () => {
    if (store.auth.user?._id) {
      if (isSaveable) {
        if (!store?.auth?.user?.savedCards?.includes(card._id)) setTriggerSaveAnimation(true)
        if (card.type === 'pack') {
          // dispatch(packActions.savePack({ pack: card }))
        } else {
          // dispatch(cardActions.save({ card: card }))
        }
      }
    } else {
      dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
    }
  }
  const onFollowHandler = async () => {
    if (store.auth?.user) {
      if (isFollowable) {
        // COMMENTED
        // const followUser = {
        //   type: 'followUser',
        //   followed: card.userId,
        //   followedUsername: card.username,
        //   followedBy: store.auth.user?._id,
        //   modeType: 'follow'
        // }
        // dispatch(userActions.followUser({ data: followUser, card }))
      }
    } else {
      dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
    }
  }

  const ShareIdFnc = async (text = '') => {
    await navigator.clipboard.writeText(text)
  }
  const closeGridCardPreview = () => {
    dispatch(closeModal())
    // dispatch(gridActions.closePreviewedCard())
  }
  const closeShareModal = () => {
    if (store?.grid?.isActivated)
      dispatch(
        openModal(
          <CardFlip
            className="gridPreviewCard"
            data={card}
            flipped
            key={'gridPreviewCard' + card._id}
            editable={card.username === store.auth.user.username}
            deletable={card.username === store.auth.user.username}
          />,
          'Card preview',
          '20rem',
          'cardPreviewModal',
          null,
          null,
          closeGridCardPreview,
          ''
        )
      )
  }
  const onShareHandler = async () => {
    if (store.auth.user?._id) {
      dispatch(
        openModal(
          <CardShare
            card={card}
            ShareIdFnc={ShareIdFnc}
            showBtns={false}
            className={styles.cardShare}
            users={dummyUsers}
          />,
          'Card share modal',
          '20rem',
          'cardPreviewModalWithExit',
          null,
          null,
          closeShareModal,
          ''
        )
      )
    } else {
      dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
    }
  }
  useEffect(() => {
    setSaveValue(
      store.auth &&
        card?._id &&
        ((store?.auth?.user?.savedCards && store.auth.user.savedCards.includes(card._id)) ||
          (store?.auth?.user?.savedPacks && store.auth.user.savedPacks.includes(card._id)))
    )
  }, [store.auth?.user])

  useEffect(() => {
    if (store?.auth?.user?._id) {
      setIsSaveable(store?.auth?.user?._id !== card?.userId && !card?.disabled)
      setIsFollowable(store?.auth?.user?._id !== card?.userId)
    }
  }, [store.auth && store.auth.user && store.auth.user.savedCards])

  useEffect(() => {
    setLikeValue(card.isLiked ?? false)
    setFollowValue(card.isFollowed ?? false)
  }, [card])

  useEffect(() => {
    if (triggerLikeAnimation)
      setTimeout(() => {
        setTriggerLikeAnimation(false)
      }, 800)
  }, [triggerLikeAnimation])
  useEffect(() => {
    if (triggerSaveAnimation)
      setTimeout(() => {
        setTriggerSaveAnimation(false)
      }, 800)
  }, [triggerSaveAnimation])
  return (
    <div
      className={`${styles.content} ${className} ${styles[className]} ${styles['color-' + type]} ${
        styles['size-' + size]
      } ${styles[getCardStyle('action', type, card?.userRole)]}`}
    >
      {likeable && (
        <AnimationLikePoints
          points={points.like}
          value={triggerLikeAnimation}
          onClick={() => !disabled && onLikeHandler()}
        >
          <div className={styles.option}>
            <div className={`${styles.iconWrapper} ${styles['activable-icons']} ${styles[like ? 'active' : '']}`}>
              <IconComponentLike />
            </div>
            {disabled && store?.auth?.user && <div className={styles.disabledOption}></div>}
          </div>
        </AnimationLikePoints>
      )}

      <AnimationLikePoints
        points={points.like}
        value={triggerSaveAnimation}
        onClick={() => !disabled && onSaveHandler()}
      >
        <div className={styles.option}>
          <div className={`${styles.iconWrapper} ${styles['activable-icons']} ${styles[save ? 'active' : '']}`}>
            <IconComponentSave />
          </div>
          {disabled && store?.auth?.user && <div className={styles.disabledOption}></div>}
        </div>
      </AnimationLikePoints>

      {followable && (
        <div className={styles.option}>
          <div
            className={`${styles.iconWrapper} ${styles['activable-icons']} ${styles[follow ? 'active' : '']}`}
            onClick={() => !disabled && onFollowHandler()}
          >
            <IconComponentFollow />
          </div>
          {disabled && store?.auth?.user && <div className={styles.disabledOption}></div>}
        </div>
      )}

      <AnimationLikePoints points={points.share} onClick={() => !disabled && onShareHandler()}>
        <div className={styles.option}>
          <div className={styles.iconWrapper}>
            <IconComponentShare />
          </div>
          {disabled && store?.auth?.user && <div className={styles.disabledOption}></div>}
        </div>
      </AnimationLikePoints>

      {commentsable && (
        <div className={styles.option}>
          <div className={styles.iconWrapper} onClick={() => onMoreHandler()}>
            <IconComponentMore />
          </div>
          {disabled && store?.auth?.user && <div className={styles.disabledOption}></div>}
        </div>
      )}
    </div>
  )
}

export default CardAction
