import React, { useEffect, useState } from 'react'
import styles from './map.module.scss'
import Button from 'components/button'
import { useSelector } from 'react-redux'
import { toastService } from 'services/toastService'
import dataService from 'services/data.service'
// import { cardActions } from 'store/actions/card.actions'
import pinIcon from 'assets/images/icons/18/pin.svg'

const WidgetMap = ({ data, type = 'preview', card }) => {
  const isEditOpen = document.getElementById('card-creation')

  // const dispatch = useDispatch()
  const store = useSelector(state => state)
  const user = store.auth.user
  const [adedToMap, setAdedToMap] = useState({})

  useEffect(() => {
    if (store?.card?.savedLocationsMap) {
      setAdedToMap(card._id ? store.card.savedLocationsMap[card._id] || null : null)
    }
  }, [store?.card?.savedLocationsMap])

  const addToMap = async () => {
    if (!card._id) return toastService('success', 'You need to save card.')
    if (!user) return toastService('success', 'You need to have account to add this card to map.')
    const temp = { type: 'map', userId: user._id, cardId: card._id, location: data }
    const res = await dataService.run('data', 'map', 'new', temp)
    if (res) toastService('success', 'Card added to your map.')
    const locationsSaved = await dataService.run('data', 'map', 'all', { filter: [{ userId: user._id }] })
    const tempLocation = {}
    if (locationsSaved)
      locationsSaved.forEach(e => {
        tempLocation[e.cardId] = e
      })

    // dispatch(cardActions.setSavedLocations(tempLocation))
  }
  const renderButtons = () => {
    return (
      <>
        {adedToMap ? (
          <Button label="Added to your map" variation="primary" size="xs" disabled />
        ) : isEditOpen ? (
          <Button label="Add to your map" variation="primary" size="xs" disabled />
        ) : (
          <Button label="Add to your map" variation="primary" size="xs" onClick={addToMap} />
        )}
      </>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={`${type === 'edit' ? styles.edit : styles.preview}`}>
        <div className={styles.details}>
          <img className={styles.icon} src={pinIcon} alt="" />
          <div className={styles.info}>
            <h4 className={styles.event}>{card && card.heading ? card.heading : ''}</h4>

            <p
              className={` ${
                (card?.cardType === 'marketplace' || card?.cardType === 'sponsored') && styles.fontColorMarket
              }`}
            >
              {data.address || 'Unknown'}
            </p>
          </div>
        </div>
        {type === 'preview' && renderButtons()}
      </div>
    </div>
  )
}

export default WidgetMap
