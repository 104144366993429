import Button from 'components/button'
import Input from 'components/input'
import PhoneInput from 'components/input/phoneInput'
import Selector from 'components/select'
import NewRequest from 'pages/verification/newRequest'
import { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toastService } from 'services/toastService'
import { AGE_OPTIONS } from 'staticConstants'
import { changePersonalDetails, checkTimestamp } from 'store/reducers/auth/thunk'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import { getUserForErrorMessage } from 'utils/helpers'
import styles from './changePersonalDetails.module.scss'
import { localStorageService } from 'services/localStorage.service'
import { sessionStorageService } from 'services/sessionStorage.service'
import { setUser } from 'store/reducers/auth/authSlice'

function ChangePersonalDetails({ data = {} }) {
  data = { ...data, email: JSON.parse(atob(data.email)), username: JSON.parse(atob(data.username)) }
  const user = localStorageService.get(`user_${data.username}`)
  const history = useHistory()
  const dispatch = useDispatch()
  const [newDetails, setNewDetails] = useState({
    name: '',
    username: '',
    email: '',
    phoneNumber: '',
    phonePrefix: '',
    phoneSufix: '',
    ageRange: ''
  })
  const [expiredToken, setExpiredToken] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    if (user) {
      dispatch(setUser(user))
      sessionStorageService.set('username', user.username)
    }
  }, [user])
  const validateForm = () => {
    return newDetails.name && newDetails.ageRange && newDetails.phoneSufix && newDetails.phonePrefix
  }

  const submitChangingDetails = async () => {
    try {
      await dispatch(
        changePersonalDetails({
          name: newDetails.name,
          phoneNumber: newDetails.phoneNumber,
          ageRange: newDetails.ageRange,
          email: newDetails.email
        })
      )
      history.push('/home')
      dispatch(closeModal())
      toastService('success', 'Successfully updated details.')
    } catch (error) {
      console.log(error)
    }
  }

  const saveClick = async () => {
    if (validateForm()) {
      submitChangingDetails()
    } else {
      setError(true)
    }
  }

  const changeHandler = e => {
    setError(false)
    if (e.target) {
      const { name, value, prefix = '' } = e.target
      if (name === 'phoneNumber') {
        setNewDetails(newDetails => ({
          ...newDetails,
          phoneNumber: prefix + value,
          phonePrefix: prefix,
          phoneSufix: value
        }))
      } else {
        setNewDetails(newDetails => ({ ...newDetails, [name]: value }))
      }
    }
  }

  useEffect(() => {
    const getResponse = async () => {
      try {
        if (user) {
          setNewDetails({
            name: user.name,
            username: user.username,
            phoneNumber: user.phoneNumber,
            phonePrefix: user.phonePrefix,
            phoneSufix: user.phoneSufix,
            email: user.email,
            ageRange: user.ageRange
          })
        }
        const res = await dispatch(checkTimestamp({ ...data, type: 'confirmPersonalDetailsToken' }))
        if (res && res.error) {
          setExpiredToken(true)
        }
      } catch (error) {
        setExpiredToken(true)
      }
    }
    getResponse()
    handleModal()
  }, [])

  useEffect(() => {
    handleModal()
  }, [expiredToken])

  const handleModal = () => {
    if (expiredToken || data?.email !== user?.email) {
      dispatch(
        openModal({
          content: (
            <NewRequest
              type="Change Personal Details"
              expiredToken={expiredToken}
              currentUser={data?.email !== user?.email}
            />
          ),
          show: true,
          size: 'maxCenter500',
          onclose: () => {
            history.push('/home')
          }
        })
      )
    }
  }
  return (
    <div className={styles.wrapper}>
      <p className={styles.headerText}>Change Personal Details</p>
      <div className={styles.inputDiv}>
        <Input
          onChange={changeHandler}
          label="Full name *"
          name="name"
          className={styles.input}
          value={newDetails.name}
          error={error && !newDetails.name}
          errorMsg={getUserForErrorMessage('name')}
        />
      </div>
      <div className={styles.inputDiv}>
        <Input label="Username" className={styles.input} value={newDetails.username} disabled />
      </div>
      <div className={styles.inputDiv}>
        <Input label="Email" className={styles.input} value={newDetails.email} disabled />
      </div>
      <div className={styles.inputDivPhone}>
        <p className={styles.label}>Age range *</p>
        <Selector
          selectClass={'selectGrayBorder-centered-24'}
          name="ageRange"
          selectedOption={AGE_OPTIONS.find(option => option.value === newDetails.ageRange)}
          handleChangeSelect={event => changeHandler({ target: { name: 'ageRange', value: event.value } })}
          options={AGE_OPTIONS}
          error={error && !newDetails.ageRange}
          errorMsg={getUserForErrorMessage('ageRange')}
        />
      </div>
      <div className={styles.inputDivPhone}>
        <p className={styles.label}>Phone number *</p>
        <PhoneInput
          className={'phoneSelectGray-24'}
          onChange={changeHandler}
          value={newDetails?.phoneSufix}
          defaultPrefixValue={newDetails?.phonePrefix}
          name="phoneNumber"
          placeholder=""
          errorMsg={getUserForErrorMessage('phoneNumber')}
          error={error && (!newDetails.phonePrefix || !newDetails.phoneSufix)}
        />
      </div>
      <div className={styles.inputDiv}>
        <Button variation="secondary" onClick={saveClick} className={styles.btn} label={'Save Changes'} />
      </div>
    </div>
  )
}

export default ChangePersonalDetails
