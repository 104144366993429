import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardHeader from 'components/card/components/header'
import QuestionsSliderEditable from 'components/card/components/questionSlider/questionSliderEditable'
import CardWrapper from 'components/card/components/wrapper'
import OkModal from 'components/okModal'
import cloneDeep from 'lodash/cloneDeep'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import { setRequiredFields, setSelectedCard } from 'store/reducers/card/cardSlice'
import styles from './doubleSidedTriviaBack.module.scss'
export const QUESTIONS_ON_CHANGE_ACTION = {
  QUESTION_CHANGE: 'questionChange',
  OPTION_CHANGE: 'optionChange',
  DELETE_QUESTION: 'deleteQuestion',
  DELETE_OPTION: 'deleteOption'
}
const CardDoubleSidedTriviaBackEditable = ({ className, card }) => {
  const dispatch = useDispatch()
  const { selectedCard, requiredFields } = useSelector(state => state.card)
  const [showInfoModal, setShowInfoModal] = useState({ show: false, message: '' })

  const validateQuestions = questions => {
    let questionsValid = false
    questions.map(q => {
      if (q.question !== '') questionsValid = true
      q.answers.map(ans => {
        if (ans.content !== '') questionsValid = true
      })
    })
    dispatch(
      setRequiredFields({
        ...requiredFields,
        error: !questionsValid,
        fields: { question: questionsValid ? '' : 'Question required' }
      })
    )
  }

  const onChange = (action, value, id) => {
    if (!card.hasAnswers) {
      const tempQuestions = [...cloneDeep(card.questions)]
      let activeSlide = card.activeSlide
      switch (action) {
        case QUESTIONS_ON_CHANGE_ACTION.QUESTION_CHANGE:
          tempQuestions[card.activeSlide].question = value
          break
        case QUESTIONS_ON_CHANGE_ACTION.OPTION_CHANGE:
          tempQuestions[card.activeSlide].answers.map(answer => {
            if (answer.id === id) answer.answer = value
          })
          break
        case QUESTIONS_ON_CHANGE_ACTION.DELETE_QUESTION:
          tempQuestions.splice(card.activeSlide, 1)
          activeSlide > 0 && activeSlide--
          break
        case QUESTIONS_ON_CHANGE_ACTION.DELETE_OPTION:
          tempQuestions[card.activeSlide].answers = tempQuestions[card.activeSlide].answers.filter(
            answer => answer.id !== id
          )
          break
        default:
          break
      }
      validateQuestions(tempQuestions)
      dispatch(setSelectedCard({ ...card, activeSlide: activeSlide, questions: [...tempQuestions] }))
    } else {
      /* eslint-disable */
      setShowInfoModal({ show: true, message: "This card already has answers. Options can't be changed." })
      /* eslint-enable */
    }
  }

  const onSlideChange = index => {
    dispatch(setSelectedCard({ ...card, activeSlide: index }))
  }

  return (
    <CardWrapper className={`${className} ${styles[className]}`}>
      <Card type={CARD_TYPES.DoubleSidedTrivia} size={CARD_SIZE.Large}>
        <CardBody type={CARD_TYPES.DoubleSidedTrivia} size={CARD_SIZE.Large}>
          <CardHeader
            heading={
              card.questions[selectedCard?.activeSlide || 0]?.question === ''
                ? 'Question'
                : card.questions[selectedCard?.activeSlide || 0]?.question
            }
            type={CARD_TYPES.DoubleSidedTrivia}
            size={CARD_SIZE.Normal}
            autoHeight
            className={styles.triviaFront}
          />
          <QuestionsSliderEditable slides={card.questions} onChange={onChange} onSlideChange={onSlideChange} />
          {showInfoModal.show && (
            <OkModal message={showInfoModal.message} onClose={() => setShowInfoModal({ show: false, message: '' })} />
          )}
        </CardBody>
      </Card>
    </CardWrapper>
  )
}
export default CardDoubleSidedTriviaBackEditable
