import AddCard from 'assets/icons/chat/add_cards.svg'
import AvatarWithInfo from 'components/avatarWithInfo'
import Button from 'components/button'
import CardScrollableContent from 'components/card/components/scrollableContent'
import UserCard from 'components/card/types/user'
import CardFront from 'components/cardFront'
import CardSelection from 'components/cardSelection'
import Input from 'components/input'
import Pack from 'components/pack'
import UserAvatar from 'components/userAvatar'
import { awsUrlResize } from 'environment'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRecentTime } from 'utils/getRecentTime'
import useWindowDimensions from 'utils/screenSize'
import { updateArray } from 'utils/updatePagedArray'
import styles from './chat.module.scss'
import backImg from '../../assets/icons/UnionBack.png'
import dataService from 'services/data.service'
import { setNewMessage } from 'store/reducers/event/eventSlice'
import { getAvatarImage } from 'utils/getAvatarImage'
import { openModal as openPackModal } from 'store/reducers/packModal/packModalSlice'

const Chat = ({ user, defaultChat }) => {
  const messageListRef = useRef(null)
  const store = useSelector(state => state)
  const authUser = useSelector(state => state.auth.user)
  const [chatMyCards, setChatMyCards] = useState([])
  const events = useSelector(state => state?.event?.data)
  const [recents, setRecents] = useState([])
  const [messages, setMessages] = useState([])
  const [activeChat, setActiveChat] = useState()
  const [messageInputValue, setMessageInputValue] = useState('')
  const [friendsListOpen, setFriendsListOpen] = useState(false)
  const [friendList, setFriendList] = useState()
  const [showCardSelection, setShowCardSelection] = useState(false)
  const [chatOpened, setChatOpened] = useState(false)
  const { isMobile } = useWindowDimensions()
  const dispatch = useDispatch()
  const [isChatAvaibleForUser, setIsChatAvailableForUser] = useState(true)
  const message = {
    type: 'message',
    subType: 'text',
    data: messageInputValue,
    to: activeChat?.from,
    toId: activeChat?.fromId,
    from: authUser?.username,
    fromId: authUser?._id
  }

  const scrollToBottomSmooth = () => {
    messageListRef.current?.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'end' })
  }
  const scrollToBottom = () => {
    messageListRef.current?.scrollIntoView({ inline: 'nearest', block: 'end' })
  }

  const sendMessageHandler = async () => {
    if (activeChat) {
      const messageWithoutSpaces = messageInputValue?.trim()
      if (messageWithoutSpaces !== '') {
        const messageRes = await dataService.run('chats', 'message', 'new', message, { storeKey: 'user' })
        if (messageRes) {
          setMessageInputValue('')
        }
      }
    } else {
      setMessageInputValue('')
    }
  }

  const onCardSelectionFinish = card => {
    if (activeChat) {
      if (card?._id) {
        dataService.run(
          'chats',
          'message',
          'new',
          { ...message, subType: card?.type, data: { _id: card?._id, userId: card?.userId, username: card?.username } },
          { storeKey: 'user' }
        )
        setShowCardSelection(false)
      }
    }
  }
  const onCardSelectionClose = () => {
    setShowCardSelection(false)
  }

  const dispatchMessageAndRecent = async emptyMessage => {
    await dataService.run('chats', 'message', 'new', emptyMessage, { storeKey: 'user' })
    await dataService.run('chats', 'recents', 'all', { filter: { toId: authUser._id }, sort: { createdAt: -1 } })
  }

  const onNewMessageFriendClicked = index => {
    const chatIndex = recents?.findIndex(chat => chat?.fromId === friendList?.[index]?._id)
    if (chatIndex >= 0) {
      if (recents?.[chatIndex]?._id) {
        handleChatSelect(recents?.[chatIndex])
      }
    } else {
      sendEmptyMessageHandlerToStartRecents(friendList[index]?.username, friendList[index]?._id)
    }
    setFriendsListOpen(false)
  }

  const sendEmptyMessageHandlerToStartRecents = (to, toId) => {
    if (to && toId) {
      const emptyMessage = {
        type: 'message',
        subType: 'text',
        data: '',
        from: authUser?.username,
        fromId: authUser?._id,
        to,
        toId
      }
      dispatchMessageAndRecent(emptyMessage)
    }
  }

  useEffect(() => {
    const getChatCards = async () => {
      const res = await dataService.run('data', 'card', 'getChatCardsAndPacks', {})
      setChatMyCards(res)
    }
    if (showCardSelection) {
      getChatCards()
    }
  }, [showCardSelection])

  const handleChatSelect = async chat => {
    setActiveChat(chat)
    const messages = await dataService.run('chats', 'message', 'user', { userId: chat.fromId })
    await dataService.run('chats', 'recents', 'all', { filter: { toId: authUser._id }, sort: { createdAt: -1 } })

    if (messages) {
      setMessages(messages)
    }
    scrollToBottom()
    setChatOpened(true)

    dispatchEvent(setNewMessage({}))
  }

  const getBubbleStyle = (message, index) => {
    let style = message?.fromId === authUser?._id ? styles.sentMessage : styles.receivedMessage
    if (
      index === 1 ||
      messages[index - 1]?.subType === 'card' ||
      messages[index - 1]?.fromId !== message?.fromId ||
      messages[index - 1]?.data === ''
    )
      style += ' ' + styles.topMessage
    if (
      index === messages.length - 1 ||
      messages[index + 1]?.subType === 'card' ||
      messages[index + 1]?.fromId !== message?.fromId ||
      messages[index + 1]?.data === ''
    )
      style += ' ' + styles.bottomMessage
    return style
  }

  const readChat = async (id, read) => {
    if (!read) await dataService.run('chats', 'recents', 'read', { read: true, _id: id })
  }

  useEffect(() => {
    const getInitData = async () => {
      dataService.run(
        'data',
        'card',
        'all',
        { filter: { userId: authUser?._id, disabled: null } },
        { storeKey: 'chatMyCards' }
      )

      const res = await dataService.run('chats', 'recents', 'all', {
        filter: { toId: authUser._id },
        sort: { createdAt: -1 }
      })
      setRecents(res)
      if (res?.length > 0) {
        const selectedChat = defaultChat ?? res[0]
        const messagesRes = await dataService.run('chats', 'message', 'user', { userId: selectedChat?.fromId })
        setMessages(messagesRes)
        setActiveChat(selectedChat)
      }
      !defaultChat?._id && user && sendEmptyMessageHandlerToStartRecents(user?.username, user?._id)
    }

    const fetchFollowingData = async () => {
      if (authUser) {
        const response = await dataService.run('data', 'followUser', 'getFollowingUsers', {
          userId: authUser._id
        })
        setFriendList(response)
      } else setFriendList([])
    }

    getInitData()
    fetchFollowingData()
    scrollToBottom()
  }, [])

  const isFriend = (fromId, friendList) => {
    return friendList?.some(friend => friend._id === fromId)
  }
  useEffect(() => {
    if (friendList && friendList.length > 0 && activeChat && activeChat.fromId) {
      setIsChatAvailableForUser(isFriend(activeChat.fromId, friendList))
    }
  }, [friendList, activeChat])

  useEffect(() => {
    if (
      events?.type === 'message' &&
      (events?.subType === 'text' || events?.subType === 'card' || events?.subType === 'pack')
    ) {
      const alreadyInArray = messages.find(e => e._id === events?._id)
      if (!alreadyInArray) {
        setMessages(prevState => {
          return [...prevState, events]
        })
      }
    }
    if (events?.type === 'recents' && events?.to === store?.auth?.user?.username) {
      const alreadyInArray = recents.findIndex(e => e._id === events?._id)
      if (activeChat?._id === events?._id) setActiveChat(events)
      if (alreadyInArray >= 0) {
        updateArray(setRecents, recents, events, false, 'start')
      } else {
        setRecents([events, ...recents])
        handleChatSelect(events)
      }
    }
  }, [events])

  useEffect(() => {
    readChat(activeChat?._id, activeChat?.read)
    scrollToBottom()
  }, [activeChat])

  useEffect(() => {
    scrollToBottomSmooth()
  }, [messages])

  return (
    <div className={styles.chatWrapper}>
      <h1 className={styles.chatTitle}>
        {isMobile && chatOpened && (
          <img
            onClick={() => {
              setChatOpened(false)
            }}
            className={styles.backImg}
            src={backImg}
            alt=""
          />
        )}{' '}
        Messages
      </h1>
      <div className={styles.chatColumnsWrapper}>
        {(!isMobile || (isMobile && !chatOpened)) && (
          <div className={styles.friendsList}>
            <div className={styles.newMessageWrapper}>
              <Button
                label="New Message"
                className={styles.newMessageBtn}
                onClick={() => setFriendsListOpen(!friendsListOpen)}
              />
              <div className={`${styles.showFriendList} ${friendsListOpen && styles.active}`}>
                <CardScrollableContent className={styles.newChatListScrollable}>
                  {friendList?.map((item, index) => {
                    return (
                      <div
                        key={item?._id}
                        onClick={() => onNewMessageFriendClicked(index)}
                        className={styles.friendsListRow}
                      >
                        <div className={styles.wrapList}>
                          <UserAvatar width="3.125" height="3.125" imageUrl={awsUrlResize + '/' + item?._id + '.jpg'} />
                          <h6>{item.name}</h6>
                        </div>
                      </div>
                    )
                  })}
                </CardScrollableContent>
              </div>
            </div>
            <CardScrollableContent className={styles.friendsListScrollable}>
              {recents?.map(recent => (
                <AvatarWithInfo
                  key={recent?._id}
                  title={authUser?._id === recent?.fromId ? recent?.to : recent?.from}
                  subtitle={recent?.data || 'No Messages'}
                  info={getRecentTime(recent?.updatedAt)}
                  boldText={!recent?.read}
                  onClick={() => handleChatSelect(recent)}
                  avatar={getAvatarImage(authUser?._id === recent?.fromId ? recent?.toId : recent?.fromId)}
                  active={activeChat?._id === recent?._id}
                  className={styles.marginUnsetForTititle}
                />
              ))}
            </CardScrollableContent>
          </div>
        )}
        {(!isMobile || (isMobile && chatOpened)) && (
          <div className={styles.messageList}>
            <div className={styles.messagesContentWrapper} onClick={() => readChat(activeChat?._id, activeChat?.read)}>
              {messages.map((message, index) => {
                if (!message?.data) return <></>
                if (message?.subType === 'text')
                  return (
                    <div
                      key={message?._id}
                      className={`${styles.messageContainer} ${
                        message?.fromId === authUser?._id ? styles.sentMessage : styles.receivedMessage
                      }`}
                    >
                      <div key={message?._id} className={getBubbleStyle(message, index)}>
                        <p>{message?.data}</p>
                      </div>
                      <span className={styles.messageTime}>{getRecentTime(message?.updatedAt, true)}</span>
                    </div>
                  )
                else if (message?.subType === 'card')
                  return (
                    <div
                      key={message?._id}
                      className={`${styles.messageContainer} ${
                        message?.fromId === authUser?._id ? styles.sentMessage : styles.receivedMessage
                      }`}
                    >
                      <CardFront
                        activeChat={activeChat}
                        openPreviewModalOnClick={activeChat}
                        key={message?._id}
                        cardId={message?.data?._id}
                        className={styles.cardPreviewContainer}
                        cardClassName={`${styles.cardPreviewCard} ${
                          message?.fromId === authUser?._id ? styles.sentCard : styles.receivedCard
                        }`}
                      />
                      <span className={styles.messageTime}>{getRecentTime(message?.updatedAt, true)}</span>
                    </div>
                  )
                else if (message?.subType === 'user')
                  return (
                    <div className={styles.messageContainer} key={message?._id}>
                      <UserCard
                        className={styles.userPreviewContainer}
                        userClassName={`${styles.userPreviewUser} ${
                          message?.fromId === authUser?._id ? styles.sentUser : styles.receivedUser
                        }`}
                        showOption={false}
                        userId={message.data}
                        key={message?._id}
                        trackUser
                      />
                      <span className={styles.messageTime}>{getRecentTime(message?.updatedAt, true)}</span>
                    </div>
                  )
                else if (message?.subType === 'pack')
                  return (
                    <div className={styles.packPreviewContainer} key={message?._id}>
                      <Pack
                        onClick={() => dispatch(openPackModal({ packId: message?.data?._id }))}
                        key={message?._id}
                        packId={message?.data?._id}
                        ignoreUpdate
                        className={`${styles.packPreviewPack} ${
                          message?.fromId === authUser?._id ? styles.sentPack : styles.receivedPack
                        }`}
                      />
                    </div>
                  )
              })}
              <div ref={messageListRef} />
            </div>
            <div className={styles.messagesFooterWrapper}>
              <img
                onClick={() => (isChatAvaibleForUser ? setShowCardSelection(true) : setShowCardSelection(false))}
                src={AddCard}
                alt="add card"
                className={styles.addCardBtn}
              />
              <div className={styles.messageInputWrapper}>
                <Input
                  placeholderOrentation="placeholderStyle"
                  placeholder={''}
                  className={styles.messageInput}
                  inputClass="lgInput"
                  containerClass={styles.messageInputContainer}
                  onKeyPress={e => (e.charCode === 13 ? sendMessageHandler() : null)}
                  onChange={e => setMessageInputValue(e.target.value)}
                  value={messageInputValue}
                  onFocus={() => readChat(activeChat?._id, activeChat?.read)}
                  disabled={!isChatAvaibleForUser}
                />
                <Button
                  onClick={sendMessageHandler}
                  label="Send"
                  className={styles.sendMessageBtn}
                  disabled={!isChatAvaibleForUser}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {showCardSelection && (
        <CardSelection cards={chatMyCards} onDoneClick={onCardSelectionFinish} onBackClick={onCardSelectionClose} />
      )}
    </div>
  )
}

export default Chat
