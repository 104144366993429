import Button from 'components/button'
import CardFront from 'components/cardFront'
import Input from 'components/input'
import PackFlip from 'components/packFlip'
import { appUrl } from 'environment'
import html2canvas from 'html2canvas'
import React, { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { canvasImageLoaded } from 'store/reducers/card/cardSlice'
import { closeModal } from 'store/reducers/modal/modalSlice'
import styles from './linkModal.module.scss'
import { CARD_TYPES } from 'staticConstants'

const LinkModal = ({ quill, selected }) => {
  const loadedBase64Img = useSelector(state => state.card.canvasImageLoaded)
  const dispatch = useDispatch()
  const [card, setCard] = useState({})
  const [loading, setLoading] = useState(false)
  const [linkValue, setLinkValue] = useState(selected)
  const [errMessage, setErrMessage] = useState('')

  const createCanvas = async cardRes => {
    const container = document.getElementById('exportCanvas')
    const pixelRatio = window.devicePixelRatio
    let scale = 1
    if (
      (card?.type === 'card' || cardRes?.type === 'card') &&
      card?.cardType !== CARD_TYPES.Marketplace &&
      cardRes?.cardType !== CARD_TYPES.Marketplace
    ) {
      scale = 1 - pixelRatio / 10
    } else if (card?.cardType === CARD_TYPES.Marketplace || cardRes?.cardType === CARD_TYPES.Marketplace) {
      scale = 1.015 - pixelRatio / 10
    } else {
      scale = 1 - pixelRatio / 10
    }
    const canvas = await html2canvas(container, {
      allowTaint: true,
      useCORS: true,
      backgroundColor: null,
      scale
    })
    const image = await canvas.toDataURL('image/png')
    setLoading(false)
    dispatch(canvasImageLoaded(false))
    dispatch(closeModal())
    quill.format('image', image)
  }

  const checkLink = async () => {
    let appUrlToCheck = appUrl

    // regex to check if the heading is mongo _id property

    const objectIdPattern = /^[0-9a-fA-F]{24}$/
    quill.format('link', linkValue)

    if (!appUrlToCheck.endsWith('/')) {
      appUrlToCheck += '/'
    }

    const linkCard = appUrlToCheck + 'card'
    if (linkValue.startsWith(linkCard)) {
      setLoading(true)
      const uniqueHeading = linkValue.slice(linkCard.length + 1)
      let cardRes
      if (objectIdPattern.test(uniqueHeading)) {
        cardRes = await dataService.run('data', 'pack', 'get', { filter: { _id: uniqueHeading, disabled: null } })
      } else {
        cardRes = await dataService.run('data', 'card', 'getCardsAndPacks', {
          filter: { uniqueHeading, disabled: null }
        })
      }
      if (cardRes.uniqueHeading || cardRes.type === 'pack') {
        setCard(cardRes)
        if (cardRes.cardType === 'oneSidedTrivia' || cardRes.cardType === 'mystery' || cardRes.cardType === 'poll') {
          createCanvas(cardRes)
        }
      }
      if (cardRes.data === null) setErrMessage("Card don't exist")
    } else {
      dispatch(closeModal())
    }
  }

  const onInputChange = e => {
    setErrMessage('')
    setCard({})
    setLoading(false)
    setLinkValue(e.target.value.trim())
  }

  useEffect(() => {
    if (loadedBase64Img) createCanvas()
  }, [loadedBase64Img])

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputFields}>
        <Input
          variation="formInput"
          inputClass="biggerInput"
          placeholder="Link path"
          name="link"
          value={linkValue}
          onChange={onInputChange}
        />
        {errMessage ? (
          <h4>{errMessage}</h4>
        ) : !loading ? (
          <Button label="Save url" variation={'secondary'} onClick={checkLink} />
        ) : (
          <h3>Wait until image is loaded...</h3>
        )}
      </div>
      {card.type === 'card' && card._id && <CardFront card={card} editable={false} forCanvas={true} />}
      {card.type === 'pack' && (
        <PackFlip
          pack={card}
          onEdit={false}
          editable={false}
          openable={false}
          isLoading={false}
          imgCanvasKey={card.logoImage || 'noImage'}
          className={styles.newsletterPack}
        />
      )}
    </div>
  )
}

export default LinkModal
