import CardAnchorFront from 'components/card/types/anchorFront'
import CardEventFront from 'components/card/types/eventFront'
import CardMysteryFront from 'components/card/types/mysteryFront'
import CardOneSidedTriviaFront from 'components/card/types/oneSidedTriviaFront'
import CardPollFront from 'components/card/types/pollFront'
import CardStandardFront from 'components/card/types/standardFront'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import dataService from 'services/data.service'
import { CARD_TYPES } from 'staticConstants'
import { isObjectEmpty } from 'utils/helpers'
import styles from './cardFront.module.scss'
import IconClose from 'assets/icons/closeCircle.png'
import { openModal as openChatModal } from 'store/reducers/chatModal/chatModalSlice'
import { setPreview } from 'store/reducers/previewModal/previewModalSlice'
import Pack from 'components/pack'

export const CardFront = ({
  card = {},
  cardId = '',
  onClick,
  size,
  className,
  cardClassName,
  activeChat,
  onRemoveFromSelection,
  isAddNewCardActive,
  forCanvas = false
}) => {
  const dispatch = useDispatch()
  const [cardData, setCardData] = useState(card)

  const chatModal = () => {
    dispatch(openChatModal({ defaultChat: activeChat ?? null }))
  }

  const openPreviewModal = () => {
    dispatch(
      setPreview({
        item: cardData,
        flipped: true,
        flipWithAnimation: true,
        onClose: chatModal
      })
    )
  }

  const onClickHandler = e => {
    if (activeChat) openPreviewModal()
    onClick && onClick(e)
  }
  useEffect(() => {
    const getCardById = async id => {
      const res = await dataService.run('data', 'card', 'get', { filter: { _id: id } })
      if (res?.type === 'card') setCardData(res)
    }
    if (cardId !== '') getCardById(cardId)
  }, [])

  useEffect(() => {
    if (!isObjectEmpty(card)) setCardData(card)
  }, [card])

  const renderCardByType = () => {
    switch (cardData?.cardType) {
      case CARD_TYPES.Event:
        return <CardEventFront card={cardData} size={size} className={cardClassName} forCanvas={forCanvas} />
      case CARD_TYPES.Anchor:
        return <CardAnchorFront card={cardData} size={size} className={cardClassName} forCanvas={forCanvas} />
      case CARD_TYPES.Poll:
        return <CardPollFront card={cardData} size={size} className={cardClassName} forCanvas={forCanvas} />
      case CARD_TYPES.Mystery:
        return <CardMysteryFront card={cardData} size={size} className={cardClassName} />
      case CARD_TYPES.OneSidedTrivia:
        return <CardOneSidedTriviaFront card={cardData} size={size} className={cardClassName} forCanvas={forCanvas} />
      default:
        return <CardStandardFront card={cardData} size={size} className={cardClassName} forCanvas={forCanvas} />
    }
  }

  const renderPack = () => {
    return <Pack pack={cardData} flippable={false} />
  }
  return (
    <div className={className} onClick={onClickHandler}>
      <div className={styles.wrapper}>
        {cardData?.type === 'card' ? renderCardByType() : renderPack()}
        {onRemoveFromSelection && !isAddNewCardActive && (
          <img
            className={`${styles.bottomBtn}`}
            alt="CloseIcon"
            src={IconClose}
            onClick={() => onRemoveFromSelection(card)}
          />
        )}
      </div>
    </div>
  )
}

export default CardFront
