import iconCloseBlue from 'assets/icons/iconCloseBlue.svg'
import iconClose from 'assets/icons/iconClosePink.svg'
import packBack from 'assets/images/pack/back.svg'
import packUnwrapAqua from 'assets/lottie/pack_unwrap_aqua.json'
import packUnwrapBlue from 'assets/lottie/pack_unwrap_blue.json'
import packUnwrapGold from 'assets/lottie/pack_unwrap_gold.json'
import packUnwrapGray from 'assets/lottie/pack_unwrap_gray.json'
import packUnwrapGreen from 'assets/lottie/pack_unwrap_green.json'
import packUnwrapLime from 'assets/lottie/pack_unwrap_lime.json'
import packUnwrapOrange from 'assets/lottie/pack_unwrap_orange.json'
import packUnwrapPink from 'assets/lottie/pack_unwrap_pink.json'
import packUnwrapRed from 'assets/lottie/pack_unwrap_red.json'
import CardAction from 'components/card/components/actionOption'
import CardFlip from 'components/cardFlip'
import Slider from 'components/slider'
import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedPack } from 'store/reducers/pack/packSlice'
import styles from './packOpened.module.scss'

const PackOpened = ({ onClose, pack = {} }) => {
  const [cards, setCards] = useState([])
  const [myStyle, setMyStyle] = useState(false)
  const [startUnwrap, setStartUnwrap] = useState(false)
  const [showCards, setShowCards] = useState(false)
  const [showSlider, setShowSlider] = useState(false)
  const [middleIndex, setMiddleIndex] = useState(0)
  const [isClicked, setIsClicked] = useState(false)

  const isBussines = true
  const { selectedPack = {} } = useSelector(state => state?.pack)
  const dispatch = useDispatch()

  const setStyle = (index, card) => {
    const newStyle = {
      left: -9 + 'rem',
      zIndex: middleIndex,
      transition: 'left 1s ease',
      transitionDelay: Math.abs(middleIndex - index) * 0.05 + 's',
      transform: 'scale(1.15)'
    }

    if (myStyle && index < middleIndex) {
      newStyle.left = -Math.abs(middleIndex - index) * 24.7 - 10 - Math.abs(middleIndex - index - 1) * 0.4 + 'rem'
      newStyle.zIndex = middleIndex - (middleIndex - index)
      if (card.cardType === 'marketplace') {
        newStyle.top = '1.4' + 'rem'
      }
    }
    if (myStyle && index > middleIndex) {
      newStyle.left = Math.abs(index - middleIndex) * 24.7 - 9 + Math.abs(index - middleIndex - 1) * 0.4 + 'rem'
      newStyle.zIndex = middleIndex - (index - middleIndex)
      if (card.cardType === 'marketplace') {
        newStyle.top = '1.4' + 'rem'
      }
    }

    if (index === middleIndex) {
      newStyle.zIndex = middleIndex * 10
      newStyle.transform = 'scale(1.42)'
      newStyle.left = -9.5 + 'rem'
    }

    return newStyle
  }

  useEffect(() => {
    if (selectedPack?.cards?.length >= 1) {
      handleAnimations()
      setCards(selectedPack.cards ?? [])
    } else {
      handleAnimations()
      setCards([])
    }
  }, [selectedPack?._id])

  useEffect(() => {
    setMiddleIndex(cards?.length > 0 ? Math.floor(cards.length / 2) : 0)
  }, [cards])

  const handleAnimations = () => {
    setStartUnwrap(true)

    setTimeout(() => {
      setShowCards(true)
    }, 100)
    setTimeout(() => {
      setMyStyle(true)
    }, 2000)
    setTimeout(() => {
      setStartUnwrap(false)
    }, 5000)
    setTimeout(() => {
      setShowSlider(true)
    }, 3500)
    setTimeout(() => {
      setShowCards(false)
    }, 3750)
  }

  const getLottieByPackColor = () => {
    switch (pack.color) {
      case '#d20000':
        return packUnwrapRed
      case '#dd0058':
        return packUnwrapPink
      case '#343ec8':
        return packUnwrapBlue
      case '#00aaca':
        return packUnwrapAqua
      case '#00d690':
        return packUnwrapGreen
      case '#5eda29':
        return packUnwrapLime
      case '#cdbb01':
        return packUnwrapGold
      case '#db5700':
        return packUnwrapOrange
      default:
        return packUnwrapGray
    }
  }
  const getClick = click => {
    setIsClicked(click)
  }

  return (
    <div className={styles.wrapper}>
      {startUnwrap && !showSlider && <img src={packBack} alt="" className={styles.packBack} />}
      {startUnwrap && !showSlider && (
        <Lottie className={styles.lottie} animationData={getLottieByPackColor()} autoplay={true} loop={false} />
      )}
      <div
        className={styles.cards}
        style={{
          top: '-4.6rem'
        }}
      >
        {showCards &&
          cards.map((card, index) => (
            <CardFlip
              data={card}
              key={index}
              className={`${styles.card}`}
              flippable={false}
              style={myStyle ? setStyle(index, card) : setStyle(index, card)}
            />
          ))}
      </div>

      <div className={styles.packActions}>
        <CardAction
          card={pack}
          type="pack"
          className={`${styles.packOptions} ${isBussines && !isClicked && styles.packOptionsBlue} ${
            isClicked && isBussines && styles.activateCard
          } `}
          likeable={false}
          followable={false}
          commentsable={false}
          clickSave={getClick}
        />
        <span
          className={`${styles.close} ${isBussines && styles.closeBlue} `}
          onClick={() => {
            dispatch(setSelectedPack({ _id: null }))
            onClose()
          }}
        >
          <img src={isBussines ? iconCloseBlue : iconClose} alt="close modal" />
        </span>
      </div>
      {
        <div
          style={{
            opacity: showSlider ? '1' : '0'
          }}
          className={styles.slider}
        >
          <Slider
            items={cards}
            showWSButton={false}
            goOnIndex={middleIndex}
            openModalOnItemClick
            changeIndexOnGoToIndexChange
            moveByCardClick={!showCards}
            goOnMiddleIndex
          />
        </div>
      }
    </div>
  )
}

export default PackOpened
