// import grid from 'assets/icons/grid.svg'
// import gridActive from 'assets/icons/GridActiveBlue.svg'
// import slider from 'assets/icons/slider.svg'
// import sliderActive from 'assets/icons/sliderActiveBlue.svg'
import CardFlip from 'components/cardFlip'
import Heading from 'components/Heading'
import Pagination from 'components/pagination'
import Search from 'components/search'
// import Slider from 'components/slider'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { setPreview } from 'store/reducers/previewModal/previewModalSlice'
import styles from './savedCards.module.scss'

const SavedCards = () => {
  const user = useSelector(state => state.auth.user)
  const [savedCards, setSavedCards] = useState([])
  const [savedFiltered, setSavedFiltered] = useState([])
  const [deletedCards, setDeletedCards] = useState({ rows: [], total: 0 })
  const [deletedFiltered, setDeletedFiltered] = useState({ rows: [], total: 0 })
  const [searchValue, setSearchValue] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [pageNumberSaved, setPageNumberSaved] = useState(1)
  // const [isGrid, setIsGrid] = useState(true)
  const pageSize = 20
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchSavedCards = async () => {
      if (user && user.savedCards) {
        // the previous mode was ' mode === 'getSavedCards' ' but it work with reports
        // probably that function was removed, so we dont need that mode here, we can search for saved cards on other way
        const response = await dataService.run('data', 'card', 'page', {
          pageSize,
          filter: { _id: { $in: user?.savedCards } },
          pageNumber: pageNumberSaved
        })
        setSavedCards(response)
        setSavedFiltered(response)
      }
    }
    fetchSavedCards()
  }, [pageNumberSaved])

  useEffect(() => {
    const fetchDeletedCards = async () => {
      if (user._id) {
        const res = await dataService.run('archive', 'card', 'page', {
          pageSize,
          filter: { userId: user._id, disabled: null },
          pageNumber
        })
        setDeletedCards(res)
        setDeletedFiltered(res)
      }
    }
    fetchDeletedCards()
  }, [pageNumber])

  const handleInputChange = e => {
    const { value } = e.target
    if (value !== '') {
      setSearchValue(value)
    } else {
      setSearchValue('')
      fetchFilterCards('')
    }
  }

  const fetchFilterCards = value => {
    const inputValue = value.toLowerCase().trim()
    const newSaved = { rows: [], total: 0 }
    const newDeleted = { rows: [], total: 0 }
    if (value !== '') {
      if (savedCards && savedCards?.rows?.length > 0) {
        savedCards?.rows?.map(one => {
          const cardName = one.heading?.toLowerCase()
          const cardSubheading = one.subheading?.toLowerCase()
          if (cardName?.includes(inputValue) || cardSubheading?.includes(inputValue)) {
            newSaved.rows?.push(one)
            newSaved.total++
          }
        })
      }
      if (deletedCards && deletedCards?.rows?.length > 0) {
        deletedCards?.rows.map(one => {
          const cardName = one.heading?.toLowerCase()
          const cardSubheading = one.subheading?.toLowerCase()
          if (cardName?.includes(inputValue) || cardSubheading?.includes(inputValue)) {
            newDeleted.rows.push(one)
            newDeleted.total++
          }
        })
      }
      setSavedFiltered(newSaved)
      setDeletedFiltered(newDeleted)
    } else {
      setSavedFiltered(savedCards)
      setDeletedFiltered(deletedCards)
    }
  }

  const handleEnter = e => {
    if (e.key === 'Enter') {
      fetchFilterCards(e.target.value)
    }
  }

  const openPreviewModal = item => {
    dispatch(
      setPreview({
        item,
        flipped: true,
        flipWithAnimation: true
      })
    )
  }

  return (
    <div className={styles.savedCardWrapper}>
      <Heading
        title="Saved Cards"
        // rightMiddleElement={
        //   <div className={styles.icons}>
        //     <img src={isGrid ? slider : sliderActive} alt="slider" onClick={() => setIsGrid(false)} />
        //     <img src={isGrid ? gridActive : grid} alt="grid" onClick={() => setIsGrid(true)} />
        //   </div>
        // }
        bottomElement={
          <div className={styles.searchContainer}>
            <Search
              value={searchValue}
              placeholder="Enter card name"
              onChange={handleInputChange}
              onKeyDown={handleEnter}
              onSearchClick={() => fetchFilterCards(searchValue)}
            />
          </div>
        }
      />
      <div className={styles.cardsDiv}>
        <div className={styles.savedOrDeletedCards}>
          <Typography
            text={`Saved (${savedFiltered && savedFiltered?.rows?.length ? savedFiltered?.total : 0})`}
            variation={TYPOGRAPHY_VARIATION.SubtitleWithoutMargin}
            className="alignLeft"
          />
          <div className={styles.cards}>
            {/* {isGrid ? (
              savedFiltered &&
              savedFiltered?.rows?.length > 0 &&
              savedFiltered.rows.map((one, i) => {
                return <CardFlip key={i} data={one} flippable={false} overrideOnFlip={() => openPreviewModal(one)} />
              })
            ) : (
              <Slider
                items={savedFiltered.rows}
                firstCard={false}
                moveByCardClick
                openModalOnItemClick
                showWSButton={false}
              />
            )} */}
            {savedFiltered &&
              savedFiltered?.rows?.length > 0 &&
              savedFiltered.rows.map((one, i) => {
                return <CardFlip key={i} data={one} flippable={false} overrideOnFlip={() => openPreviewModal(one)} />
              })}
            {savedFiltered && savedFiltered?.rows?.length === 0 && (
              <Typography
                text="There are no saved cards"
                variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
                className="marginY5"
              />
            )}
            {}
          </div>
          {savedFiltered?.total > 0 && (
            <Pagination
              total={savedFiltered?.total}
              onPageChange={page => setPageNumberSaved(page)}
              pageSize={pageSize}
            />
          )}
        </div>
        <div className={styles.savedOrDeletedCards}>
          <Typography
            text={`Deleted (${deletedFiltered && deletedFiltered?.rows.length ? deletedFiltered?.total : 0})`}
            variation={TYPOGRAPHY_VARIATION.SubtitleWithoutMargin}
            className="alignLeft"
          />
          <div className={styles.cards}>
            {/* {isGrid ? (
              deletedFiltered &&
              deletedFiltered?.rows.length > 0 &&
              deletedFiltered?.rows.map((one, i) => {
                return <CardFlip key={i} data={one} flippable={false} overrideOnFlip={() => openPreviewModal(one)} />
              })
            ) : (
              <Slider
                key={'cardSlider'}
                items={deletedFiltered.rows}
                moveByCardClick
                showWSButton={false}
                openModalOnItemClick
                className={styles.sliderCreated}
              />
            )} */}
            {deletedFiltered &&
              deletedFiltered?.rows.length > 0 &&
              deletedFiltered?.rows.map((one, i) => {
                return <CardFlip key={i} data={one} flippable={false} overrideOnFlip={() => openPreviewModal(one)} />
              })}

            {deletedFiltered && deletedFiltered?.rows.length === 0 && (
              <Typography
                text="There are no deleted cards"
                variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
                className="marginY5"
              />
            )}
          </div>
          {deletedFiltered?.total > 0 && (
            <Pagination total={deletedFiltered?.total} onPageChange={page => setPageNumber(page)} pageSize={pageSize} />
          )}
        </div>
      </div>
    </div>
  )
}

export default SavedCards
