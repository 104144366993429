import React from 'react'
import PropTypes from 'prop-types'
import styles from './switch.module.scss'
import SwitchTab from './tab'
export const SWITCH_SIZE = {
  Normal: 'normal',
  Large: 'large'
}
const SwitchButton = ({
  tabs = [],
  setActiveTab,
  activeTab,
  style = {},
  className,
  size = SWITCH_SIZE.Normal,
  children,
  background = false
}) => {
  const onClickHandler = value => {
    activeTab !== value && setActiveTab(value)
  }
  return (
    <div className={`${background ? styles.container : styles.containerNoBg} ${className}`} style={style}>
      {tabs.map(
        ({ value, title, index, notifications = false, onMouseEnter, onMouseLeave, onTouchStart, onTouchEnd }) => {
          return (
            <SwitchTab
              key={index + title}
              value={value}
              title={title}
              onClick={() => onClickHandler(value)}
              className={activeTab === value ? 'active' : ''}
              tabClass={className}
              parentClass={className}
              size={size}
              notifications={notifications}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onTouchStart={onTouchStart}
              onTouchEnd={onTouchEnd}
            />
          )
        }
      )}
      {children}
    </div>
  )
}
SwitchButton.propTypes = {
  tabs: PropTypes.array,
  setActiveTab: PropTypes.func,
  activeTab: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string
}
export default SwitchButton
