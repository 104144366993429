import iconClose from 'assets/icons/iconCloseBlue.svg'
import Button from 'components/button'
import Input from 'components/input'
import PhoneInput from 'components/input/phoneInput'
import SwitchButton, { SWITCH_SIZE } from 'components/switch'
import TopicSelection from 'components/topicSelection'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ADMIN_FEATURES_OPTIONS,
  ADMIN_FEATURES_ROUTE_LABELS,
  ADMIN_FEATURES_ROUTE_VALUES,
  AGE_OPTIONS,
  USER_TYPE_VALUE
} from 'staticConstants'
import { setErrorMessage, setUserForRegistartion } from 'store/reducers/auth/authSlice'
import { registerCoAdmin } from 'store/reducers/auth/thunk'
import validateEmail, { validateUsername } from 'utils/validateEmail'
import styles from './registerCoAdminForm.module.scss'
import { getObjectKeyByValue } from 'utils/helpers'
import Selector from 'components/select'

const CO_ADMIN_TYPES = [
  { value: 6, title: 'CONTRIBUTOR' },
  { value: 7, title: 'EDITOR' }
]

export const getSelectedOptionsByAdminAccessValues = (adminAccess = []) => {
  return adminAccess?.map(value => ({
    value,
    label: ADMIN_FEATURES_ROUTE_LABELS[getObjectKeyByValue(ADMIN_FEATURES_ROUTE_VALUES, value)]
  }))
}
const RegisterCoAdminForm = ({ subtype = 6, onClickBack = null }) => {
  const dispatch = useDispatch()
  const { userForRegistartion } = useSelector(state => state.auth)
  const errorMsgRegister = useSelector(state => state.auth.errorMessage)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(false)
  const [alreadyExistEmail, setAlreadyExistEmail] = useState(false)
  const [alreadyExistUsername, setAlreadyExistUsername] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState()
  const [userData, setUserData] = useState({
    name: '',
    username: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    phonePrefix: '',
    phoneSufix: '',
    ageRange: '',
    message: '',
    subType: subtype,
    role: 'premiumUser',
    status: 'Pending',
    adminAccess: [],
    news: true
  })

  const errorMsg = (type, data) => {
    let message
    switch (type) {
      case 'email':
        message = 'Email required'
        break
      case 'badEmail':
        if (!validateEmail(data.email)) {
          message = 'Email is not valid'
        } else if (alreadyExistEmail) {
          message = 'Email already exist'
        }
        break
      case 'differentPassword':
        message = 'Password and confirm password are not equal'
        break
      case 'password':
        if (!data?.password) message = 'Password required'
        else if (data?.password.length < 6) message = 'Password must be at least 6 characters long'
        break
      case 'name':
        message = 'Full name required'
        break
      case 'age':
        message = 'Age range required'
        break
      case 'username':
        if (alreadyExistUsername) {
          message = 'Username already exist'
        } else if (!validateUsername(data.username)) {
          message = 'Uppercase letter and special character are not allowed'
        } else if (data.username === '') {
          message = 'Username required'
        }
        break
      case 'confirmPassword':
        if (!data.confirmPassword) message = 'Confirm password required'
        else if (data?.confirmPassword.length < 6) message = 'Confirm password must be at least 6 characters long'
        break
      case 'phoneNumber':
        message = 'Phone number and prefix required'
        break
      default:
        message = 'Error'
        break
    }
    return message
  }

  const handleChange = async e => {
    setError(false)
    if (e.target) {
      const { name, value, prefix = '' } = e.target
      if (name === 'phoneNumber') {
        setPhoneNumber(value)
        setUserData(userData => ({ ...userData, phoneNumber: prefix + value, phonePrefix: prefix, phoneSufix: value }))
        dispatch(
          setUserForRegistartion({ ...userData, phoneNumber: prefix + value, phonePrefix: prefix, phoneSufix: value })
        )
      } else {
        setUserData(userData => ({ ...userData, [name]: value }))
        dispatch(setUserForRegistartion({ ...userData, [name]: value }))
      }
    }
  }
  const handleSwitchSubtypeChange = value => {
    setUserData(userData => ({ ...userData, subType: value }))
  }

  const handleTopicSelectionChange = selectedTopics => {
    const adminAccess = selectedTopics?.length ? selectedTopics.map(el => el.value) : []
    setUserData(userData => ({ ...userData, adminAccess }))
    dispatch(setUserForRegistartion({ ...userData, adminAccess }))
  }

  const validateForm = () => {
    return (
      userData.name &&
      userData.username &&
      userData.email &&
      userData.password &&
      userData.confirmPassword &&
      userData.phoneSufix &&
      userData.phonePrefix &&
      validateEmail(userData.email) &&
      userData.password.length >= 6 &&
      userData.confirmPassword.length >= 6 &&
      userData.password === userData.confirmPassword &&
      validateUsername(userData.username)
    )
  }

  const onSubmitClick = async () => {
    if (validateForm()) {
      try {
        const res = await dispatch(registerCoAdmin({ user: userForRegistartion }))
        if (!res?.error?.name) {
          onClickBackHandle()
        }
      } catch (err) {
        setError(true)
      }
    } else {
      setError(true)
    }
  }

  const onClickBackHandle = () => {
    dispatch(setErrorMessage([]))
    onClickBack && onClickBack()
  }

  const onEyeHandler = () => {
    setShowPassword(!showPassword)
  }

  const onEyeConfHandler = () => {
    setShowConfPassword(!showConfPassword)
  }

  useEffect(() => {
    if (errorMsgRegister && errorMsgRegister.length > 0) {
      if (errorMsgRegister.includes('email')) {
        setError(true)
        setAlreadyExistEmail(true)
      } else {
        setAlreadyExistEmail(false)
      }
      if (errorMsgRegister.includes('username')) {
        setError(true)
        setAlreadyExistUsername(true)
      } else {
        setAlreadyExistUsername(false)
      }
    }
  }, [errorMsgRegister])

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.header}>
        {onClickBack && (
          <div className={styles.close}>
            <img src={iconClose} alt="Close icon" onClick={onClickBackHandle} />
          </div>
        )}
        <div className={styles.title}>Create co-admin</div>
        <div></div>
      </div>
      <div className={styles.applyWrapper}>
        <div className={styles.form}>
          <div className={styles.fieldsContainer}>
            <div className={styles.halfRow}>
              <Input
                variation="formInput"
                inputClass="editUserProfile"
                placeholderOrentation="placeholderCenter"
                placeholder="Full name *"
                type="text"
                name="name"
                value={userData.name}
                onChange={handleChange}
                error={error && !userData.name}
                errorMsg={errorMsg('name', userData)}
                errorMsgStyle={'errorMsgUp'}
              />
              <Input
                variation="formInput"
                inputClass="editUserProfile"
                placeholderOrentation="placeholderCenter"
                placeholder="Username *"
                type="text"
                name="username"
                value={userData.username}
                onChange={handleChange}
                error={error && (!userData.username || alreadyExistUsername || !validateUsername(userData.username))}
                errorMsg={errorMsg('username', userData)}
                errorMsgStyle={'errorMsgUp'}
              />
              <Selector
                selectClass={'selectGrayBorder-centered'}
                placeholder="Age range *"
                name="ageRange"
                selectedOption={AGE_OPTIONS.find(option => option.value === userData.ageRange)}
                handleChangeSelect={event => handleChange({ target: { name: 'ageRange', value: event.value } })}
                options={AGE_OPTIONS}
                error={error && !userData.ageRange}
                errorMsg={errorMsg('age')}
              />
              <Input
                variation="formInput"
                inputClass="editUserProfile"
                placeholderOrentation="placeholderCenter"
                placeholder="Email *"
                type="email"
                name="email"
                value={userData.email}
                onChange={handleChange}
                error={error && (!userData.email || !validateEmail(userData.email) || alreadyExistEmail)}
                errorMsg={!userData.email ? errorMsg('email', userData) : errorMsg('badEmail', userData)}
                errorMsgStyle={'errorMsgUp'}
              />
            </div>
            <div className={styles.halfRow}>
              <Input
                variation="formInput"
                inputClass="editUserProfile"
                placeholderOrentation="placeholderCenter"
                placeholder="Password *"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                hasEye={true}
                onEyeClick={onEyeHandler}
                value={userData.password}
                onChange={handleChange}
                error={
                  error &&
                  (!userData.password || userData.password !== userData.confirmPassword || userData.password.length < 6)
                }
                errorMsg={errorMsg(
                  userData.password !== userData.confirmPassword ? 'differentPassword' : 'password',
                  userData
                )}
                errorMsgStyle={'errorMsgUp'}
              />
              <Input
                variation="formInput"
                inputClass="editUserProfile"
                placeholderOrentation="placeholderCenter"
                placeholder="Confirm password *"
                type={showConfPassword ? 'text' : 'password'}
                hasEye={true}
                onEyeClick={onEyeConfHandler}
                name="confirmPassword"
                value={userData.confirmPassword}
                onChange={handleChange}
                error={(error && !userData.confirmPassword) || (error && userData.confirmPassword.length < 6)}
                errorMsg={errorMsg('confirmPassword', userData)}
                errorMsgStyle={'errorMsgUp'}
              />
              <PhoneInput
                className={'phoneSelectLarge'}
                onChange={handleChange}
                value={phoneNumber}
                name="phoneNumber"
                placeholder="Phone number *"
                errorMsg={errorMsg('phoneNumber')}
                error={error && (!userData.phonePrefix || !userData.phoneSufix)}
              />
            </div>
          </div>
          <div className={styles.topicSelectionSection}>
            <div className={styles.switchContainer}>
              <SwitchButton
                tabs={CO_ADMIN_TYPES}
                activeTab={userData?.subType}
                setActiveTab={handleSwitchSubtypeChange}
                background
                style={{ height: '2.5rem', margin: '1rem 0' }}
                size={SWITCH_SIZE.Normal}
              />
            </div>
            <div className={styles.topicSelectionWrapper}>
              <span>
                *{' '}
                {userData?.subType === USER_TYPE_VALUE.editor
                  ? 'Access to both the admin panel and the application.'
                  : 'Access only to the application (not the admin panel).'}
              </span>
              <span>* Can create any card type.</span>
              {userData?.subType === USER_TYPE_VALUE.editor && (
                <>
                  <span>* Available admin features:</span>
                  <TopicSelection
                    className={styles.topicSelectionContainer}
                    options={ADMIN_FEATURES_OPTIONS}
                    onSelectionChange={handleTopicSelectionChange}
                    selectedOptions={getSelectedOptionsByAdminAccessValues(userData.adminAccess)}
                    placeholder="Choose admin feature"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <Button variation="secondary" label="SUBMIT" size="md" onClick={onSubmitClick} />
      </div>
    </div>
  )
}

export default RegisterCoAdminForm
