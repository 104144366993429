import Button from 'components/button'
import moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from 'store/reducers/modal/modalSlice'
import NewsletterModal from './newsletterModal'
import styles from './singleNewsletter.module.scss'

const SingleNewsletter = ({ props }) => {
  const { createdAt = 0, emailTitle = '' } = props

  const dispatch = useDispatch()

  const openDetails = () => {
    dispatch(openModal({ content: <NewsletterModal props={props} />, size: 'newsLetterDetailModal', show: true }))
  }

  return (
    <div className={styles.wrapperSingleNewsletter}>
      <div className={styles.singleCell} style={{ marginLeft: 0 }}>
        {moment(createdAt).format('DD/MM/YYYY-HH:mm')}{' '}
      </div>
      <div className={styles.singleCell}>{emailTitle}</div>
      <Button label="Details" variation="primary" size="fitContent" onClick={openDetails}></Button>
    </div>
  )
}

export default SingleNewsletter
