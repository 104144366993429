import iconClose from 'assets/icons/iconCloseBlue.svg'
import AttributeButton from 'components/attributeButton'
import Button from 'components/button'
import DatePickerComponent from 'components/datePicker'
import GoogleMapSearch from 'components/googleMapSearch'
import Input from 'components/input'
import OkModal from 'components/okModal'
import Selector from 'components/select'
import TagInput from 'components/tagInput'
import TextArea from 'components/textarea'
import Toggle from 'components/toggle'
import { useEffect, useRef, useState } from 'react'
import { CirclePicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import cloneDeep from 'lodash/cloneDeep'
import {
  ANCHOR_LOCATION_OPTIONS,
  CARD_CREATE_CATEGORY_OPTIONS,
  COLLECTION_OPTIONS,
  CUSTOMER_OPTIONS,
  FREQUENCY_IN_FEEDS_OPTIONS,
  LOCATION_OPTIONS,
  POINTS_ALLOCATION_OPTIONS,
  PRICE_OPTIONS,
  USER_LEVEL_OPTIONS
} from 'staticConstants'
// import { cardActions } from 'store/actions/card.actions'
// import { dataActions } from 'store/actions/data.actions'
import { setRequiredFields, setSelectedCard } from 'store/reducers/card/cardSlice'
import { scrapePage } from 'store/reducers/scrape/thunk'
import { isUrlValid } from 'utils/helpers'
import styles from '../cardCreationForm.module.scss'

const RenderFrontFields = (card = {}, cardFields = {}) => {
  const [categoryOptions] = useState([...CARD_CREATE_CATEGORY_OPTIONS])
  const [marketCategoryOptions, setMarketCategoryOptions] = useState([])
  const [scrapeLoading, setScrapeLoading] = useState(false)
  const [showPicker, setShowPicker] = useState(false)
  const requiredFields = useSelector(state => state.card.requiredFields)
  const { selectedCard } = useSelector(state => state?.card)
  const color = '#fff'
  const dispatch = useDispatch()
  const refMap = useRef(null)
  const [openMap, setOpenMap] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState({ show: false, message: '' })

  const [isBussines] = useState(true)

  const onChange = (key, value, clearError = false) => {
    dispatch(setSelectedCard({ ...card, [key]: value }))
    if (clearError) {
      const tempFields = { ...requiredFields.fields }
      tempFields[key] = ''
      dispatch(setRequiredFields({ ...requiredFields, fields: tempFields }))
    }
  }
  const onDateChange = value => {
    dispatch(setSelectedCard({ ...card, date: value }))
  }
  const handleClickOutside = event => {
    if (refMap.current && !refMap.current.contains(event.target)) {
      setOpenMap(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    const fetchMarketplaceCategories = async () => {
      const res = await dataService.run('data', 'category', 'all', {
        filter: { showOnMarket: true, parent: 'categories' }
      })
      if (res) setMarketCategoryOptions(res.map(category => ({ value: category.value, label: category.name })))
    }
    fetchMarketplaceCategories()
  }, [])

  const handleScrape = async () => {
    if (selectedCard?.scrapeUrl && selectedCard?.scrapeUrl !== '')
      if (isUrlValid(selectedCard?.scrapeUrl)) {
        setScrapeLoading(true)
        await dispatch(scrapePage({ payload: selectedCard.scrapeUrl, card: selectedCard }))
        dispatch(setRequiredFields({ error: false, fields: {} }))
        setScrapeLoading(false)
      } else
        dispatch(
          setRequiredFields({
            error: true,
            fields: { scrapeUrl: 'The URL is not valid and cannot be loaded.' }
          })
        )
    else {
      dispatch(setRequiredFields({ error: true, fields: { scrapeUrl: 'URL requierd.' } }))
    }
  }

  const addLocation = location => {
    dispatch(
      setSelectedCard({
        ...card,
        eventLocation: location
      })
    )
    setOpenMap(false)
  }
  const onAddNewOption = (clearError = false) => {
    if (!card.hasAnswers) {
      if (card.pollOptions.length < 4) {
        const pollOptions = [...card.pollOptions]
        dispatch(
          setSelectedCard({
            ...card,
            pollOptions: [...pollOptions, { id: new Date().getTime(), option: '', answers: 0 }]
          })
        )
      }
      if (clearError) {
        const tempFields = { ...requiredFields.fields }
        tempFields.option = ''
        dispatch(setRequiredFields({ ...requiredFields, fields: tempFields }))
      }
    } else {
      /* eslint-disable */
      setShowInfoModal({ show: true, message: "This card already has answers. Options can't be changed." })
      /* eslint-enable */
    }
  }
  const onAddQuestionOption = correctValue => {
    if (!card.hasAnswers) {
      const questions = [...cloneDeep(card.questions)]
      if (questions[0].answers.length < 4) {
        questions[0].answers.push({ id: new Date().getTime(), answer: '', correct: correctValue })
        dispatch(
          setSelectedCard({
            ...card,
            questions: [...questions]
          })
        )
      }
    } else {
      /* eslint-disable */
      setShowInfoModal({ show: true, message: "This card already has answers. Options can't be changed." })
      /* eslint-enable */
    }
  }
  const onChangeQuestionExplanation = (field, value, clearError = false) => {
    const questions = [...card.questions]
    questions[0] = { ...questions[0], [field]: value }
    dispatch(
      setSelectedCard({
        ...card,
        questions: [...questions]
      })
    )
    if (clearError) {
      const tempFields = { ...requiredFields.fields }
      tempFields[field] = ''
      dispatch(setRequiredFields({ ...requiredFields, fields: tempFields }))
    }
  }

  const onChangeEventLink = linkValue => {
    dispatch(
      setSelectedCard({
        ...card,
        link: linkValue
      })
    )
  }

  return (
    <>
      {showInfoModal.show && (
        <OkModal onClose={() => setShowInfoModal({ show: false, message: '' })} message={showInfoModal.message} />
      )}
      {/* Scrape URL */}
      {/* Type: Instacard */}
      {cardFields.front && cardFields.front.scrapeUrl && (
        <div className={styles.scrapeUrlWrapper}>
          <Input
            placeholder={'Enter your own URL'}
            variation="cardInput"
            containerClass={styles.fullWidth}
            className={styles.defaultInput}
            // onChange={event => setScrapeUrl(event?.target?.value)}
            value={card?.scrapeUrl ?? ''}
            onChange={event => onChange('scrapeUrl', event.target.value, false)}
            error={requiredFields.error}
            errorMsg={requiredFields.fields?.scrapeUrl ?? ''}
            disabled={card && card?._id}
          />
        </div>
      )}
      {/* Heading */}
      {/* Type: Standard, Collectible, Mystery */}
      {cardFields.front && cardFields.front.heading && (
        <Input
          placeholder="Card Title"
          variation="cardInput"
          containerClass={styles.fullWidth}
          className={styles.defaultInput}
          onChange={event => onChange('heading', event.target.value, true)}
          value={card.heading ?? ''}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.heading ?? ''}
        />
      )}
      {/* Subheading */}
      {/* Type: Standard, Collectible, Mystery */}
      {cardFields.front && cardFields.front.subheading && (
        <Input
          placeholder="Sub Heading (optional)"
          variation="cardInput"
          containerClass={styles.fullWidth}
          className={styles.defaultInput}
          onChange={event => onChange('subheading', event.target.value)}
          value={card.subheading ?? ''}
        />
      )}
      {/* Question */}
      {/* Type: One sided trivia */}
      {cardFields.front && cardFields.front.explanationReveal && (
        <TextArea
          placeholder="Question"
          textareaClass="textarea-gray-100"
          value={card.questions[0].question}
          onChange={event => onChangeQuestionExplanation('question', event.target.value, true)}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.question ?? ''}
        />
      )}
      {/* Category */}
      {/* Type: Standard */}
      {cardFields.front && cardFields.front.category && (
        <Selector
          selectClass={'selectGrayInput'}
          placeholder="Category"
          selectedOption={categoryOptions.find(option => option.value?.toLowerCase() === card?.category?.toLowerCase())}
          handleChangeSelect={event => onChange('category', event?.value?.toLowerCase(), true)}
          options={categoryOptions}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.category ?? ''}
        />
      )}
      {/* Collection */}
      {/* Type: Collectible */}
      {cardFields.front && cardFields.front.collection && (
        <Selector
          placeholder="Choose Collection"
          selectedOption={COLLECTION_OPTIONS.find(option => option.value === card.collection)}
          handleChangeSelect={event => onChange('collection', event.value)}
          options={COLLECTION_OPTIONS}
        />
      )}
      {/* Points Allocation */}
      {/* Type: Mystery */}
      {cardFields.front && cardFields.front.pointsAllocation && (
        <Selector
          selectClass={'selectGrayInput'}
          placeholder="Choose Points Allocation"
          selectedOption={POINTS_ALLOCATION_OPTIONS.find(option => option.value === card.pointsAllocation)}
          handleChangeSelect={event => onChange('pointsAllocation', event.value, true)}
          options={POINTS_ALLOCATION_OPTIONS}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.pointsAllocation ?? ''}
        />
      )}
      {/* User level */}
      {/* Type: Collectible, Mystery */}
      {cardFields.front && cardFields.front.userLevel && (
        <Selector
          selectClass={'selectGrayInput'}
          placeholder="User Level"
          selectedOption={USER_LEVEL_OPTIONS.find(option => option.value === card.userLevel)}
          handleChangeSelect={event => onChange('userLevel', event.value, true)}
          options={USER_LEVEL_OPTIONS}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.userLevel ?? ''}
        />
      )}
      {/* Frequency in feeds */}
      {/* Type: Collectible, Mystery */}
      {cardFields.front && cardFields.front.frequencyInFeeds && (
        <Selector
          selectClass={'selectGrayInput'}
          placeholder="Frequency in Feeds"
          selectedOption={FREQUENCY_IN_FEEDS_OPTIONS.find(option => option.value === card.frequencyInFeeds)}
          handleChangeSelect={event => onChange('frequencyInFeeds', event.value, true)}
          options={FREQUENCY_IN_FEEDS_OPTIONS}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.frequencyInFeeds ?? ''}
        />
      )}
      {/* Byline */}
      {/* Type: Sponsored */}
      {cardFields.front && cardFields.front.byline && (
        <Input
          placeholder="Edit Byline"
          variation="cardInput"
          containerClass={styles.fullWidth}
          inputClass="xsInput"
          className={styles.defaultInput}
          onChange={event => onChange('byline', event.target.value, true)}
          value={card.byline ?? ''}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.byline ?? ''}
        />
      )}
      {/* Description */}
      {/* Type: Event */}
      {cardFields.front && cardFields.front.description && (
        <Input
          placeholder="Description (optional)"
          variation="cardInput"
          containerClass={styles.fullWidth}
          className={styles.defaultInput}
          onChange={event => onChange('description', event.target.value)}
          value={card.description ?? ''}
        />
      )}
      {/* Location */}
      {/* Type: Event */}
      {cardFields.front && cardFields.front.eventLocation && (
        <>
          <AttributeButton
            label={card.eventLocation?.address || 'Location'}
            containerClass={card.eventLocation && card.eventLocation.address ? 'mapBtn' : 'mapBtnEmpty'}
            className="full-width"
            onClick={() => setOpenMap(true)}
          />
          {openMap && (
            <div className={styles.googleMapFront} ref={refMap}>
              <img
                className={`${styles.googleClose} ${isBussines && styles.isBussines}`}
                src={iconClose}
                alt="Close icon"
                onClick={() => setOpenMap(false)}
              />
              <GoogleMapSearch mapClass="event-input" onAdd={addLocation} />
            </div>
          )}
        </>
      )}
      {/* Group: time, date */}
      {/* Type: Event */}
      {cardFields.front && cardFields.front.eventDate && (
        <DatePickerComponent
          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
          onDatePickerChange={event => onDateChange(event.getTime())}
          placeholder="Date"
          dateFormat="E, dd MMM yyyy, p"
          showTimeSelect
          selected={card.date !== '' ? new Date(card.date) : ''}
          timeIntervals={5}
        />
      )}
      {/* Category Marketplace  */}
      {/* Type: Marketplace */}
      {cardFields.front && cardFields.front.marketplaceCategory && (
        <Selector
          selectClass={'selectGrayInput'}
          placeholder="Category"
          selectedOption={marketCategoryOptions.find(option => option.value === card.category)}
          handleChangeSelect={event => onChange('category', event?.value?.toLowerCase(), true)}
          options={marketCategoryOptions}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.category ?? ''}
          gridView
        />
      )}
      {/* Group: Price Range, Location* /}
      {/* Type: Marketplace */}
      {cardFields.front && cardFields.front.groupPriceRangeLocation && (
        <div className={styles.group}>
          <Selector
            selectClass={'selectGrayInput'}
            placeholder="Price Range"
            selectedOption={PRICE_OPTIONS.find(option => option.value === card.priceRange)}
            handleChangeSelect={event => onChange('priceRange', event.value)}
            options={PRICE_OPTIONS}
          />
          <Selector
            selectClass={'selectGrayInput'}
            placeholder="Location"
            selectedOption={LOCATION_OPTIONS.find(option => option.value === card.marketLocation)}
            handleChangeSelect={event => onChange('marketLocation', event.value)}
            options={LOCATION_OPTIONS}
          />
        </div>
      )}
      {/* Group: Customer Type, Loyalty */}
      {/* Type: Marketplace */}
      {cardFields.front && cardFields.front.groupCustomerTYpeUserLoyalty && (
        <div className={styles.group}>
          <Selector
            selectClass={'selectGrayInput'}
            placeholder="Customer Type"
            selectedOption={CUSTOMER_OPTIONS.find(option => option.value === card.customerType)}
            handleChangeSelect={event => onChange('customerType', event.value)}
            options={CUSTOMER_OPTIONS}
          />
          <Selector
            selectClass={'selectGrayInput'}
            placeholder="User Loyalty"
            selectedOption={USER_LEVEL_OPTIONS.find(option => option.value === card.userLoyalty)}
            handleChangeSelect={event => onChange('userLoyalty', event.value)}
            options={USER_LEVEL_OPTIONS}
          />
        </div>
      )}
      {/* Add new option */}
      {/* Type: Poll */}
      {cardFields.front && cardFields.front.addNewOption && (
        <AttributeButton
          label="Poll option"
          className="full-width"
          onClick={onAddNewOption}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.option ?? ''}
        />
      )}
      {/* Search tags */}
      {/* Type: Standard */}
      {cardFields.front && cardFields.front.tags && (
        <TagInput
          placeholder="Add extra search terms, including locations and categories, here (optional)"
          tags={card.tags}
          className="full-width-fixed"
          onTagChange={tags => onChange('tags', tags)}
          value={card.tags ?? ''}
        />
      )}
      {cardFields.front && cardFields.front.scrapeUrl && (
        <div className={styles.buttonScrapeDiv}>
          <Button label="Create Now" onClick={handleScrape} spinner={scrapeLoading} disabled={card && card?._id} />
        </div>
      )}
      {/* Location */}
      {/* Type: Anchor */}
      {cardFields.front && cardFields.front.anchorLocation && (
        <Selector
          selectClass={'selectGrayInput'}
          placeholder="Location"
          selectedOption={ANCHOR_LOCATION_OPTIONS.find(option => option.value === card.location)}
          handleChangeSelect={event => onChange('location', event.value)}
          options={ANCHOR_LOCATION_OPTIONS}
        />
      )}
      {cardFields.front && cardFields.front.anchorHeading && (
        <Input
          placeholder="Heading"
          variation="cardInput"
          containerClass={styles.fullWidth}
          className={styles.defaultInput}
          onChange={event => onChange('heading', event.target.value, true)}
          value={card.heading ?? ''}
          maxLength={35}
          error={requiredFields.error}
          errorMsg={requiredFields.fields?.heading ?? ''}
        />
      )}
      {/* Ribbon color */}
      {/* Type: Anchor */}
      {cardFields.front && cardFields.front.ribbonColor && (
        <div style={{ width: '100%' }}>
          <Button
            size="xs"
            variation="ribbonColor"
            label={showPicker ? 'Close ribbon color picker' : ' Ribbon color picker'}
            onClick={() => setShowPicker(!showPicker)}
          ></Button>
          {showPicker && (
            <div style={{ padding: '0.625rem 0 0 0.625rem' }}>
              <CirclePicker color={color} onChange={updatedColor => onChange('ribbonColor', updatedColor.hex)} />
            </div>
          )}
        </div>
        // <Selector
        //   placeholder="Ribbon color"
        //   selectedOption={RIBBON_COLOR.find(option => option.value === card.ribbonColor)}
        //   handleChangeSelect={event => onChange('ribbonColor', event.value)}
        //   options={RIBBON_COLOR}
        // />
      )}
      {/* Page link */}
      {/* Type: Anchor */}
      {cardFields.front && cardFields.front.pageLink && (
        <Input
          placeholder="Page Link"
          variation="cardInput"
          containerClass={styles.fullWidth}
          className={styles.defaultInput}
          onChange={event => onChange('pageLink', event.target.value)}
          value={card.pageLink ?? ''}
        />
      )}
      {/* Group: true option, false option */}
      {/* Type: One sided trivia */}
      {cardFields.front && cardFields.front.groupTrueFalseOption && (
        <div className={styles.group}>
          <AttributeButton label="True Option" className="full-width" onClick={() => onAddQuestionOption(true)} />
          <AttributeButton label="False Option" className="full-width" onClick={() => onAddQuestionOption(false)} />
        </div>
      )}
      {/* Explanation reveal */}
      {/* Type: One sided trivia */}
      {cardFields.front && cardFields.front.explanationReveal && (
        <TextArea
          placeholder="Add Explanation Reveal (Optional)"
          textareaClass="textarea-gray-100"
          value={card.questions[0].explanation}
          onChange={event => onChangeQuestionExplanation('explanation', event.target.value)}
          maxLength={130}
        />
      )}
      {/* Event card caption */}
      {/* Type: Event */}
      {cardFields.front && cardFields.front.eventImageCaption && (
        <Input
          placeholder="Add image link (optional)"
          variation="cardInput"
          maxLength={200}
          containerClass={`${styles.fullWidth}`}
          className={styles.defaultInput}
          value={card.link || ''}
          onChange={e => {
            onChangeEventLink(e?.target?.value)
          }}
        />
      )}
      {/* Mature content */}
      {/* Type: All Types */}
      {cardFields.front && cardFields.front.mature && (
        <Toggle
          className={styles.defaultToggle}
          checked={card.mature}
          label="Mature content?"
          matureInfo
          onChange={event => onChange('mature', event.target.checked)}
        />
      )}
    </>
  )
}
export default RenderFrontFields
