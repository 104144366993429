import React from 'react'
import styles from './moreDetailsModal.module.scss'

const MoreDetailsModal = ({
  showHideModal,
  children = null,
  style = {},
  background = 'transparent',
  opacity = '0',
  position = 'left',
  className,
  hadnleOutsideClick = true,
  isActive = false,
  contentClass
}) => {
  return (
    <div className={`${styles.wrapper} ${className} ${styles[className]} ${isActive && styles.active}`} style={style}>
      <div className={styles.top}></div>
      <div className={`${styles.bottom} ${position === 'right' ? styles.bottom_right : null}`}></div>
      <div className={`${styles.content} ${position === 'right' ? styles.content_right : null} ${contentClass}`}>
        {children}
      </div>
      {hadnleOutsideClick && (
        <div className={styles.outOfModal} onClick={() => showHideModal()} style={{ background, opacity }}></div>
      )}
    </div>
  )
}

export default MoreDetailsModal
