import sort from 'assets/images/sort.svg'
import Button from 'components/button'
import Heading, { TABS } from 'components/Heading'
import Selector from 'components/select'
import CategorySingle from 'components/singleCategory'
import { useEffect, useState } from 'react'
import dataService from 'services/data.service'
import CategoryForm from './categoryForm'
import styles from './categoryTopics.module.scss'
import cloneDeep from 'lodash/cloneDeep'

const activeOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' }
]

const CategoryAndTopics = () => {
  const [categories, setCategories] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [searcIputhValue, setSearcIputhValue] = useState('')
  const [activeTab, setActiveTab] = useState('main')
  const [ascendingSortCriteria, setAscendingSortCriteria] = useState('')
  const [activeStep, setActiveStep] = useState(1)
  const [currentCategory, setCurrentCategory] = useState({})
  const [activeFilter, setActiveFilter] = useState(true)
  const [triggerReload, setTriggerReload] = useState(new Date().getTime())

  const onEnter = event => {
    if (event.key === 'Enter') {
      setSearchValue(searcIputhValue)
    }
  }

  const sortBy = criterium => {
    let newSorted
    setAscendingSortCriteria(!ascendingSortCriteria)
    if (criterium === 'name') {
      if (ascendingSortCriteria) {
        newSorted = categories.sort((a, b) => {
          const fa = a.name.toLowerCase()
          const fb = b.name.toLowerCase()

          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
      } else {
        newSorted = categories.sort((a, b) => {
          const fa = a.name.toLowerCase()
          const fb = b.name.toLowerCase()

          if (fa > fb) {
            return -1
          }
          if (fa < fb) {
            return 1
          }
          return 0
        })
      }
    } else {
      if (ascendingSortCriteria) {
        newSorted = categories.sort((a, b) => a[criterium] - b[criterium])
      } else {
        newSorted = categories.sort((b, a) => a[criterium] - b[criterium])
      }
    }
    setCategories(newSorted)
  }

  const onCategoryEditClick = category => {
    setCurrentCategory(cloneDeep(category))
    setActiveStep(2)
  }

  const handleChangeSelect = e => {
    setActiveFilter(e.value)
  }

  const onBackHandler = (reload = false) => {
    reload && setTriggerReload(new Date().getTime())
    setActiveStep(1)
  }

  useEffect(() => {
    const fetchCategories = async () => {
      let filter = { name: { $regex: searchValue, $options: 'i' } }
      if (activeFilter) {
        filter = { ...filter, active: { $ne: false } }
      } else {
        filter = { ...filter, active: false }
      }
      if (activeTab === 'main') {
        filter = { ...filter, showOnMainDeck: true }
      } else if (activeTab === 'market') {
        filter = { ...filter, showOnMarket: true }
      }
      const res = await dataService.run('data', 'category', 'all', { filter })
      setCategories(res)
    }
    fetchCategories()
  }, [searchValue, activeTab, activeFilter, triggerReload])

  return (
    <div className={styles.wrapper}>
      {activeStep === 1 && (
        <>
          <Heading
            title="Manage Category"
            defaultTab={{ value: activeTab }}
            tabs={TABS.ManageCategoryTabs}
            onTabChange={setActiveTab}
          />
          <div className={styles.filterContainer}>
            <input
              type="text"
              onChange={e => setSearcIputhValue(e.target.value)}
              onKeyUp={onEnter}
              placeholder="Enter category name"
              value={searcIputhValue}
            />
            <div className={styles.selectorDiv}>
              <Selector
                selectClass={'selectGrayHeight100'}
                options={activeOptions}
                selectedOption={activeOptions.find(option => option.value === activeFilter)}
                handleChangeSelect={handleChangeSelect}
              />
            </div>
            <Button label="Add category" variation="secondary" onClick={() => setActiveStep(3)} />
          </div>
          <div className={styles.tableHeader}>
            <div onClick={() => sortBy('name')} className={styles.singleCell}>
              <span>Name</span>
              <img src={sort} alt="sort" />
            </div>
            <div onClick={() => sortBy('showOnReg')} className={styles.singleCell}>
              <span>Registration</span>

              <img src={sort} alt="sort" />
            </div>
            <div onClick={() => sortBy('timesUsed')} className={styles.singleCell}>
              <span>Times Used</span>
              <img src={sort} alt="sort" />
            </div>
            <div onClick={() => sortBy('timesSearched')} className={styles.singleCell}>
              <span>Times Searched</span>
              <img src={sort} alt="sort" />
            </div>
          </div>
          <div className={styles.tableRowsContainer}>
            {categories &&
              categories.length > 0 &&
              categories.map((category, i) => {
                return <CategorySingle key={i} props={category} onEditClick={() => onCategoryEditClick(category)} />
              })}
          </div>
        </>
      )}
      {activeStep === 2 && (
        <CategoryForm
          maindeckCategory={activeTab === 'main'}
          category={currentCategory}
          onBackClick={onBackHandler}
          edit
          key={'edit' + activeStep}
        />
      )}
      {activeStep === 3 && (
        <CategoryForm maindeckCategory={activeTab === 'main'} onBackClick={onBackHandler} key={'edit' + activeStep} />
      )}
    </div>
  )
}

export default CategoryAndTopics
