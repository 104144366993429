import Button from 'components/button'
import CardFlip from 'components/cardFlip'
import Input from 'components/input'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toastService } from 'services/toastService'
import { scrapePageTest } from 'store/reducers/scrape/thunk'
import isURL from 'validator/lib/isURL'
import iconClose from 'assets/icons/iconCloseBlue.svg'
import styles from './testInstacard.module.scss'
import InstacardHelp from 'components/instacardHelp'

const TestInstaCard = () => {
  const dispatch = useDispatch()
  const [scrapeUrl, setScrapeUrl] = useState('')
  const [testing, setTesting] = useState(false)
  const [showScrapedCard, setShowScrapedCard] = useState(false)
  const [explanationScrape, setExplanationScrape] = useState(false)

  const [scrapedCard, setScrapedCard] = useState({})

  const handleChangeScrape = () => {
    const url = document.getElementById('scrapeText').value
    setScrapeUrl(url)
  }

  const scrapeCard = async () => {
    setShowScrapedCard(false)
    setExplanationScrape(false)
    const url = document.getElementById('scrapeText').value
    if (isURL(url)) {
      setTesting(true)
      const response = await dispatch(scrapePageTest({ url }))
      if (response && response.payload && Object.keys(response.payload).length > 0) {
        const card = { ...response.payload }
        setExplanationScrape(false)
        setScrapedCard(card)
        setShowScrapedCard(true)
        console.log(card, 'card__ log')
      }
      setTesting(false)
    } else {
      toastService('error', 'URL is not valid.')
    }
  }

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      scrapeCard()
    }
  }

  const handleExp = flag => {
    setExplanationScrape(flag)
    if (Object.keys(scrapedCard).length > 0) {
      setShowScrapedCard(!flag)
    }
  }

  return (
    <div className={styles.testWrapper}>
      <p className={styles.header}>Test Instacard!</p>
      <div className={styles.inputAndButton}>
        <Input
          name={'scrapeText'}
          placeholderOrentation="placeholderCenter"
          onChange={handleChangeScrape}
          placeholder={'Enter URL'}
          className={'cardInput'}
          size={'xl'}
          onKeyPress={handleKeyUp}
        />
        <div className={styles.divButton}>
          <Button
            label="Test Now"
            size="fitContent"
            onClick={scrapeCard}
            disabled={scrapeUrl === '' || testing}
            spinner={testing}
          />
        </div>
      </div>

      <p className={styles.explanationClick}>
        If you cannot generate an Instacard! to your liking, please
        <span onClick={() => handleExp(true)}>&nbsp;click here</span>. We may have a solution for you.
      </p>
      <div className={styles.heightWrapper}>
        {explanationScrape && (
          <div className={styles.explanation}>
            <div className={styles.innerDiv}>
              <div className={styles.closeExp}>
                <img src={iconClose} alt="" onClick={() => handleExp(false)} />
              </div>
              {/* <p>This is some text that will help you understand creating instacard!</p> */}
              <InstacardHelp />
            </div>
          </div>
        )}
        {showScrapedCard && (
          <div className={styles.previewCard}>
            <CardFlip data={scrapedCard} saveable={false} />
          </div>
        )}
      </div>
    </div>
  )
}

export default TestInstaCard
