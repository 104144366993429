import React from 'react'
import styles from '../questionSlider.module.scss'
import IconClose from 'assets/icons/states/failed.png'
import Input from 'components/input'
import { QUESTIONS_ON_CHANGE_ACTION } from 'components/card/editable/doubleSidedTriviaBack'

const SliderEditableAnswer = ({ answer, index, onChange }) => {
  return (
    <div key={'answer' + answer.id} className={styles.answer}>
      <img
        key={'close' + index}
        className={`${styles.optionClose}`}
        alt="X"
        src={IconClose}
        onClick={() => onChange(QUESTIONS_ON_CHANGE_ACTION.DELETE_OPTION, null, answer.id)}
      />
      <Input
        placeholder={answer.correct ? 'Correct answer' : 'Wrong answer'}
        value={answer.answer}
        maxLength={18}
        onChange={event => onChange(QUESTIONS_ON_CHANGE_ACTION.OPTION_CHANGE, event.target.value, answer.id)}
        className={styles.inputForTriviaCard}
      />
    </div>
  )
}
export default SliderEditableAnswer
