import React, { useState, useEffect } from 'react'
import { defaultAvatarBase64 } from 'components/icons/defaultImage'
import styles from './confirmEmail.module.scss'
import { toastService } from '../../../services/toastService'
import { useDispatch, useSelector } from 'react-redux'
import Loading from 'components/loading'
import Button from 'components/button'
import { confirmEmail, sendConfirmationMail } from 'store/reducers/auth/thunk'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { logOut } from 'store/reducers/auth/authSlice'
import Login from 'pages/auth/login'
import Stripe from 'components/stripe'
import { USER_TYPE_VALUE } from 'staticConstants'
import fileService from 'services/file.service'

const ConfirmMail = ({ data }) => {
  data = { ...data, email: JSON.parse(atob(data.email)) }
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const confirm = useSelector(state => state.auth.confirmEmail)
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const history = useHistory()
  const { email = '', token = 0 } = data

  useEffect(() => {
    const handleConfirmEmail = async (email, token) => {
      try {
        const response = await dispatch(confirmEmail({ email: email, token: token }))
        if (response) {
          if (!response?.payload?.user?.avatarUpdatedAt) {
            await fileService.run(
              's3',
              'upload',
              { data: defaultAvatarBase64, name: `${response.payload.user._id}.jpg`, type: 'base64' },
              null,
              `${response.payload.user._id}.jpg`
            )
          }
          setSuccess(true)
        }
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    }
    if (data && email && token) {
      handleConfirmEmail(email, token)
    } else {
      setLoading(false)
    }
  }, [data && data.email])

  useEffect(() => {
    setLoading(false)
    setSuccess(confirm)
  }, [confirm])

  const sendNewLink = () => {
    if (data.email) {
      dispatch(sendConfirmationMail({ email: data.email, premium: true }))
      toastService('success', `Successfully sent verification link to ${data.email}.`)
    }
  }

  const onCloseHandle = () => {
    dispatch(closeModal())
    // history.push('/home')
    if (user?._id && user?.role !== 'admin' && user?.subType !== USER_TYPE_VALUE.editor) {
      openStripe()
    }
  }

  const closeStripe = () => {
    dispatch(logOut())
    history.push('/register')
    dispatch(openModal({ content: <Login />, show: true, name: 'Login Modal' }))
  }

  const openStripe = () => {
    dispatch(openModal({ show: true, content: <Stripe />, name: 'Stripe modal', onclose: closeStripe, undo: true }))
  }

  return (
    <div className={styles.wrapper}>
      <h2>Email Verification</h2>
      {loading && <Loading height={50} width={50} />}
      {!loading && success && <div>Successfully verified email address.</div>}
      {!loading && !success && <div>Failed to verify your email address.</div>}
      {!loading && !success && (
        <div className={styles.link} onClick={sendNewLink}>
          Request new verification link
        </div>
      )}
      <div>
        <Button disabled={loading} label="Ok" variation="secondary" size="sm" onClick={onCloseHandle} />
      </div>
    </div>
  )
}
export default ConfirmMail
