import React from 'react'

const CloudCorner = ({ color = '#CB1C68', className }) => {
  return (
    <div className={className}>
      <svg width="62" height="21" viewBox="0 0 62 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 17.5V0.500094L61.9893 0.5C51.5114 0.500594 41.1363 2.19716 31.4561 5.49289C21.7726 8.78974 12.9742 13.6222 5.56362 19.7144C5.10794 20.0894 4.52711 20.3449 3.89467 20.4484C3.26222 20.5519 2.60662 20.4989 2.01087 20.296C1.41512 20.0931 0.906034 19.7495 0.548068 19.3086C0.190102 18.8678 -0.000638079 18.03 0 17.5Z"
          fill={color}
        />
      </svg>
    </div>
  )
}
export default CloudCorner
