import ShareUser from 'components/card/types/shareUser/shareUser'
import IconComponentComment from 'components/icons/commentIcon'
import IconComponentFollow from 'components/icons/followIcon'
import IconComponentShare from 'components/icons/shareIcon'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_TYPES } from 'staticConstants'
// import { userActions } from 'store/actions/user.actions'
import { openModal } from 'store/reducers/modal/modalSlice'
import { openModal as openChatModal } from 'store/reducers/chatModal/chatModalSlice'

import styles from './cardActionOption.module.scss'

const UserCardAction = ({ className, type = CARD_TYPES.Create, size, account, isFollowed, userType }) => {
  const dispatch = useDispatch()
  const store = useSelector(state => state)
  const [comment, setComment] = useState(false)

  const handleFollow = () => {
    if (store.auth?.user) {
      // COMMENTED
      // const followUser = {
      //   type: 'followUser',
      //   followed: account._id,
      //   followedUsername: account.username,
      //   followedBy: store.auth.user?._id,
      //   modeType: 'follow'
      // }
      // dispatch(userActions.followUser({ data: followUser }))
    }
  }

  const chatModal = async () => {
    dispatch(openChatModal({ defaultChat: account }))
    setComment(prevState => !prevState)
  }

  const handleClickShare = () => {
    dispatch(openModal({ content: <ShareUser user={account} />, name: 'Block user modal', size: 'max320', show: true }))
  }
  return (
    <div
      className={`${styles.content} ${className} ${styles[className]} ${styles['color-' + type]} ${
        styles['size-' + size]
      }`}
    >
      <div className={`${styles.iconWrapper} ${styles[comment ? 'active' : '']}`} onClick={chatModal}>
        <IconComponentComment onClick={chatModal} />
      </div>
      <div
        className={`${styles.iconWrapper} ${styles['activable-icons']} ${
          userType === 'followers' && isFollowed ? styles.active : ''
        } ${userType === 'following' ? styles.active : ''}`}
        onClick={() => handleFollow()}
      >
        <IconComponentFollow />
      </div>
      <div className={styles.iconWrapper} onClick={handleClickShare}>
        <IconComponentShare />
      </div>
    </div>
  )
}

export default UserCardAction
