import Button from 'components/button'
import React from 'react'
import { useDispatch } from 'react-redux'
import styles from './thanksApply.module.scss'
import { closeModal } from 'store/reducers/modal/modalSlice'

const ThanksApply = () => {
  const dispatch = useDispatch()

  const closeModalClick = () => {
    dispatch(closeModal())
  }

  return (
    <div className={styles.thanksWrapper}>
      <h2>Thanks for Applying</h2>
      <h3>We’ve received your application and you’ll receive an update by e-mail within the next 48 hours. Thanks!</h3>
      <div className={styles.button}>
        <Button variation={'secondary'} label="OK" onClick={closeModalClick} />
      </div>
    </div>
  )
}

export default ThanksApply
