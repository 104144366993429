import DynamicTablePagination from 'components/dynamicTable/pagination'
import Pack from 'components/pack'
import React, { useEffect, useState } from 'react'
import dataService from 'services/data.service'
import { USER_TYPE_VALUE } from 'staticConstants'
import { DEFAULT_PAGE_SIZE } from 'utils'
import styles from './packSelection.module.scss'

const AdminPackSelection = ({ onSelectionChange }) => {
  const [adminPacks, setAdminPacks] = useState({ rows: [], total: 0 })
  const [selectedPack, setSelectedPack] = useState()
  const [nameFilter, setNameFilter] = useState('')
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    const fetchPacks = async () => {
      try {
        const query = {}
        if (nameFilter !== '') query.heading = { $regex: nameFilter, $options: 'i' }
        const res = await dataService.run('data', 'pack', 'page', {
          filter: { userRole: USER_TYPE_VALUE.admin, ...query },
          pageNumber
        })
        if (res) {
          setAdminPacks(res)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchPacks()
  }, [nameFilter, pageNumber])

  const handleSelectedPack = async pack => {
    if (pack?._id) {
      setSelectedPack(pack)
      onSelectionChange && onSelectionChange(pack)
    }
  }

  const handleFilterOnEnter = e => {
    if (e.key === 'Enter') {
      setNameFilter(e.target.value)
    }
  }

  return (
    <div className={styles.wrapper}>
      <input
        type="text"
        onKeyUp={handleFilterOnEnter}
        onBlur={e => setNameFilter(e.target.value)}
        placeholder="Enter pack name"
        name={'heading'}
      />
      <div className={styles.packsContainer}>
        {adminPacks?.rows?.length > 0 &&
          adminPacks.rows.map((pack, index) => (
            <div
              className={`${styles.selectionContainer} ${selectedPack?._id === pack?._id ? styles.selectedPack : ''}`}
              onClick={() => handleSelectedPack(pack)}
              key={index + pack?._id}
            >
              <Pack pack={pack} />
            </div>
          ))}
      </div>
      {adminPacks?.total > DEFAULT_PAGE_SIZE && (
        <DynamicTablePagination total={adminPacks.total} onPageChange={setPageNumber} />
      )}
    </div>
  )
}
export default AdminPackSelection
