import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './cardCreation.module.scss'
import CardStandardBackEditable from 'components/card/editable/standardBack'
import { CARD_TYPES, FORM_SIDE } from 'staticConstants'
import CardCreationForm from './form'
import CardSlideBackEditable from 'components/card/editable/slideBack'
import CardDoubleBackEditable from 'components/card/editable/doubleBack'
import CardDoubleSidedTriviaBackEditable from 'components/card/editable/doubleSidedTriviaBack'

const CardBackLayout = ({ className }) => {
  const card = useSelector(state => state.card.selectedCard)
  return (
    <div className={`${styles.row} ${className}`}>
      {/* LEFT SIDE  */}
      {/* 
        Standard back editable card 
        Type : default, standard, collectible, mystery, sponsored
      */}
      {(card.cardType === '' ||
        card.cardType === CARD_TYPES.Standard ||
        card.cardType === CARD_TYPES.Blank ||
        card.cardType === CARD_TYPES.Collectible ||
        card.cardType === CARD_TYPES.Marketplace ||
        card.cardType === CARD_TYPES.Mystery) && <CardStandardBackEditable />}
      {card.cardType === CARD_TYPES.Sponsored && (
        <CardStandardBackEditable byline={card.byline !== '' ? card.byline : card.fullname} />
      )}
      {/* 
        Slide back editable card 
        Type : slide
      */}
      {card.cardType === CARD_TYPES.Slide && <CardSlideBackEditable />}
      {/* 
        Double back editable card 
        Type : double
      */}
      {card.cardType === CARD_TYPES.Double && <CardDoubleBackEditable />}
      {/* 
        Double sided trivia back editable card 
        Type : doubleSidedTrivia
      */}
      {card.cardType === CARD_TYPES.DoubleSidedTrivia && <CardDoubleSidedTriviaBackEditable card={card} />}
      {/* RIGHT SIDE  */}
      <div className={styles.contentWrapper}>
        <CardCreationForm side={FORM_SIDE.Back} />
      </div>
    </div>
  )
}
CardBackLayout.propTypes = {
  data: PropTypes.array
}
export default CardBackLayout
