import React from 'react'
import styles from './animationLikePoints.module.scss'
import points5 from './../../assets/points/Points5.svg'
import points10 from './../../assets/points/Points10.svg'

function AnimationLikePoints({ children, onClick, value = false, points, className }) {
  const animationMethod = () => {
    onClick && onClick(!value)
  }

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={`${value ? styles.animationPoints : styles.currentPoints}`}>
        {points === 'like' ? (
          <>
            <img className={styles.likeImg} src={points5} alt="" />
          </>
        ) : null}
        {points === 'share' ? (
          <>
            <img className={styles.shareImg} src={points10} alt="" />
          </>
        ) : null}
      </div>
      <div onClick={animationMethod}>{children}</div>
    </div>
  )
}

export default AnimationLikePoints
