import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userTypeLabels: {},
  cardCreation: {}
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setUserSubTypeLabels: (state, action) => {
      state.userTypeLabels = action.payload
    },
    setCardCreation: (state, action) => {
      state.cardCreation = action.payload
    }
  }
})

export default configSlice.reducer
export const { setUserSubTypeLabels, setCardCreation } = configSlice.actions
