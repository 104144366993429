import ApplicationForm from 'components/applicationForm'
import Button from 'components/button'
import ContactUsForm from 'components/contactUsForm'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { CONTACT_US_OPTIONS } from 'staticConstants'
import { openModal } from 'store/reducers/modal/modalSlice'
import styles from './singleApplicant.module.scss'

const SingleApplicant = ({ props, flag = false, history = false, activeTab }) => {
  const { createdAt = 0, name = '', status = 'Pending', subType, email = '', help = '' } = props
  const { userTypeLabels } = useSelector(store => store?.config)

  const dispatch = useDispatch()
  const openApplication = () => {
    if (flag) {
      dispatch(
        openModal({
          content: <ContactUsForm props={props} activeTab={activeTab} />,
          name: 'Contact modal',
          size: 'max900',
          show: true,
          xButton: false
        })
      )
    } else {
      dispatch(
        openModal({
          content: <ApplicationForm props={props} hasButtons={!history} />,
          name: 'Applications modal',
          size: 'max900',
          show: true,
          xButton: false
        })
      )
    }
  }

  return (
    <div className={styles.wrapperSingleApplicant}>
      <div className={styles.singleCell} style={{ marginLeft: 0 }}>
        {moment(createdAt).format('DD/MM/YYYY-HH:mm')}{' '}
      </div>
      <div
        className={styles.singleCell}
        style={{ color: status === 'Rejected' ? 'red' : status === 'Approved' ? 'green' : 'black' }}
      >
        {flag ? email : status}
      </div>
      <div className={styles.singleCell}>{name}</div>
      <div className={styles.singleCell}>
        {flag ? CONTACT_US_OPTIONS.find(option => option.value === help)?.label : userTypeLabels[subType]}
      </div>
      {flag ? (
        <Button label="Details" variation="primary" size="twenty" onClick={openApplication}></Button>
      ) : (
        <Button label="View Application" variation="primary" size="twenty" onClick={openApplication}></Button>
      )}
    </div>
  )
}

export default SingleApplicant
