import React from 'react'
import { Rings } from 'react-loader-spinner'
import './style.scss'

const Loading = ({ className, custom, height, width }) => {
  return (
    // <Loader
    //   className={className + (!custom ? ' app-loader' : '')}
    //   type="Oval"
    //   color="#7B61FF"
    //   height={height || 80}
    //   width={width || 80}
    // />
    <Rings
      className={className + (!custom ? ' app-loader' : '')}
      color="#7B61FF"
      height={height || 80}
      width={width || 80}
    />
  )
}

export default Loading
