import { USER_TYPE_VALUE } from 'staticConstants'

const getCardStyle = (element, type, userType) => {
  switch (userType) {
    case USER_TYPE_VALUE.individual:
      return `${element}-generalUser-${type}`
    case USER_TYPE_VALUE.businessSubscriber:
      return `${element}-businessSubscriber-${type}`
    case USER_TYPE_VALUE.media:
      return `${element}-media-${type}`
    case USER_TYPE_VALUE.premiumAdvertiser:
      return `${element}-primetimeAdvertiser-${type}`
    case USER_TYPE_VALUE.admin:
      return `${element}-admin-${type}`
    case USER_TYPE_VALUE.contributor:
      return `${element}-admin-${type}`
    case USER_TYPE_VALUE.editor:
      return `${element}-admin-${type}`
    default:
      return ''
  }
}

export default getCardStyle
