import React from 'react'

import iconCloseBlue from 'assets/icons/iconCloseBlue.svg'

import styles from './closeButton.module.scss'

const CloseButton = ({ handleClick = () => {}, position = {}, title = 'Close' }) => {
  return (
    <span
      className={styles.close}
      onClick={handleClick}
      style={Object.keys(position).length ? position : {}}
      title={title}
    >
      <img src={iconCloseBlue} alt="close icon" />
    </span>
  )
}

export default CloseButton
