import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardHeader from 'components/card/components/header'
import QuestionsSlider from 'components/card/components/questionSlider/questionSlider'
import QuestionsSliderEditable from 'components/card/components/questionSlider/questionSliderEditable'
import CardWrapper from 'components/card/components/wrapper'
import { QUESTIONS_ON_CHANGE_ACTION } from 'components/card/editable/doubleSidedTriviaBack'
import OkModal from 'components/okModal'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import { updateUser } from 'store/reducers/auth/thunk'
import { setSelectedCard } from 'store/reducers/card/cardSlice'
import styles from './oneSidedTriviaFront.module.scss'
import cloneDeep from 'lodash/cloneDeep'

export const CardOneSidedTriviaFront = ({
  card,
  size = CARD_SIZE.Normal,
  className,
  editable = false,
  saveAnswers = true
}) => {
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()
  const [userAnswer, setUserAnswer] = useState([])
  const [showInfoModal, setShowInfoModal] = useState({ show: false, message: '' })
  const onChange = (action, value, id) => {
    if (!card.hasAnswers) {
      const tempQuestions = [...cloneDeep(card.questions)]
      switch (action) {
        case QUESTIONS_ON_CHANGE_ACTION.OPTION_CHANGE:
          tempQuestions[0].answers.map(answer => {
            if (answer.id === id) answer.answer = value
          })
          break
        case QUESTIONS_ON_CHANGE_ACTION.DELETE_OPTION:
          tempQuestions[0].answers = tempQuestions[0].answers.filter(answer => answer.id !== id)
          break
        default:
          break
      }
      dispatch(setSelectedCard({ ...card, questions: [...tempQuestions] }))
    } else {
      /* eslint-disable */
      setShowInfoModal({ show: true, message: "This card already has answers. Options can't be changed." })
      /* eslint-enable */
    }
  }
  const onFinish = async answers => {
    if (saveAnswers) {
      const data = { cardId: card._id, userId: user._id, answer: answers, answerType: card.cardType }
      const res = await dataService.run('data', 'cardAnswer', 'new', data)
      if (res) {
        if (user && answers[0].correct) {
          dispatch(updateUser({ ...user, points: Number(user.points) + Number(card.pointsAllocation) ?? 0 }))
        }
        toastService('success', 'You successfully finished trivia.')
      }
    }
  }
  useEffect(() => {
    const getUserAnswer = async () => {
      try {
        const res = await dataService.run('data', 'cardAnswer', 'get', {
          filter: { cardId: card._id, userId: user._id }
        })
        setUserAnswer(res.answer)
      } catch (error) {}
    }
    getUserAnswer()
  }, [])
  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card size={size} type={CARD_TYPES.OneSidedTrivia}>
        <CardBody size={size} type={CARD_TYPES.OneSidedTrivia} isMature={card?.mature && !editable}>
          <CardHeader
            heading={card.questions[0].question === '' ? 'Question' : card.questions[0].question}
            type={CARD_TYPES.OneSidedTrivia}
            size={size}
            autoHeight
            className={styles.triviaFront}
          />
          {editable && (
            <QuestionsSliderEditable
              onChange={onChange}
              slides={card.questions}
              showDots={false}
              showQuestion={false}
            />
          )}
          {!editable && (
            <QuestionsSlider
              onChange={onChange}
              slides={card.questions}
              showDots={false}
              onFinish={onFinish}
              defaultAnswers={userAnswer}
              points={card.pointsAllocation}
              size={size}
            />
          )}
          {showInfoModal.show && (
            <OkModal message={showInfoModal.message} onClose={() => setShowInfoModal({ show: false, message: '' })} />
          )}
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CardOneSidedTriviaFront
