import { request } from './http.service'

class FileService {
  async run(type, mode, data = {}, options = {}, fileId) {
    return request(
      'post',
      `/api/file/${type}`,
      {
        mode,
        data,
        fileId
      },
      null,
      { ...options }
    )
  }
}
export default new FileService()
