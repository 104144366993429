import React, { useEffect, useState } from 'react'
import styles from './cardBody.module.scss'
import PropTypes from 'prop-types'
import { CARD_SIZE, USER_TYPE_VALUE } from 'staticConstants'
import getCardStyle from 'utils/getCardStyle'
import fileService from 'services/file.service'
import { canvasImageLoaded } from 'store/reducers/card/cardSlice'
import { useDispatch, useSelector } from 'react-redux'
import Loading from 'components/loading'
import { hideMatureProtection } from 'store/reducers/previewModal/previewModalSlice'
import Button from 'components/button'
import { useLocation } from 'react-router-dom'

const CardBody = ({
  size = CARD_SIZE.Normal,
  children,
  className,
  type = 'standard',
  style,
  imgCanvasKey,
  userType = '',
  defaultBackground = false,
  isMature = false
}) => {
  const dispatch = useDispatch()
  const [imgBase64, setImgBase64] = useState(false)
  const { auth, previewModal } = useSelector(store => store)
  const [showMatureContent, setShowMatureContent] = useState(false)
  const location = useLocation()

  const showMatureContentClick = () => {
    if (previewModal?.item?._id) {
      setShowMatureContent(true)
      dispatch(hideMatureProtection())
    }
  }

  const isMatureContentAvailableForCurrentUser = () => {
    return Boolean(
      auth?.user?.subType === USER_TYPE_VALUE.admin ||
        (auth?.user?._id && auth?.user?.ageRange && auth?.user?.ageRange !== '13-17')
    )
  }

  useEffect(() => {
    const getImage = async () => {
      if (imgCanvasKey === 'noImage') return dispatch(canvasImageLoaded(true))
      const res = await fileService.run('s3', 'getImgBase64', { key: imgCanvasKey })
      setImgBase64(res.body)
      dispatch(canvasImageLoaded(true))
    }
    if (imgCanvasKey) getImage()
  }, [imgCanvasKey])

  if (imgCanvasKey) {
    return (
      <div
        className={`
        canvas-card-body
        ${styles.container}
        ${location?.pathname?.includes('admin/newsletter') && styles.containerNewsletter}
        ${className}
        ${styles['background-' + (defaultBackground ? 'default' : 'default')]}
        ${styles['size-' + size]}
        ${!defaultBackground && styles[getCardStyle('background', type, userType)]}
        ${(type === 'doubleSidedTrivia' || type === 'oneSidedTrivia') && styles.triviaOverflow}
      `}
      >
        {imgBase64 ? (
          <img
            src={imgBase64}
            alt="canvas"
            id="canvasImage"
            style={{
              width: '100%',
              position: 'absolute',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '1.8rem',
              objectPosition: '50% 50%'
            }}
          />
        ) : (
          imgCanvasKey !== 'noImage' && (
            <div className={styles.loader}>
              <Loading />
            </div>
          )
        )}
        {children}
      </div>
    )
  }

  return (
    <>
      <div
        style={style}
        className={`
        ${styles.container}
        ${className}
        ${styles['background-' + (defaultBackground ? 'default' : type)]}
        ${styles['size-' + size]}
        ${isMature && !showMatureContent ? styles.bluryContent : ''}
        ${!defaultBackground && styles[getCardStyle('background', type, userType)]}
        ${(type === 'doubleSidedTrivia' || type === 'oneSidedTrivia') && styles.triviaOverflow}
      `}
      >
        {children}
      </div>
      {isMature && !showMatureContent && (
        <div className={`${styles.matureContent} ${size === 'extraSmall' ? styles.matureExtraSmall : ''}`}>
          <p>
            {isMatureContentAvailableForCurrentUser() ? (
              <>
                This content may contain graphic or adult themes.
                <br />
                <br /> Enter at your own risk.
                <Button
                  label="View Anyway"
                  onClick={showMatureContentClick}
                  className={styles.btn}
                  variation="transparent"
                />
              </>
            ) : (
              <>
                This website contains adult-oriented material.
                <br />
                <br /> You must be 18 years of age or older to view this content.
              </>
            )}
          </p>
        </div>
      )}
    </>
  )
}
CardBody.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object
}
export default CardBody
