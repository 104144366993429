import ChangePassword from 'components/changePassword'
import ChangePersonalDetails from 'components/changePersonalDetails'
import ConfirmMail from 'pages/verification/confirmEmail'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { openModal } from 'store/reducers/modal/modalSlice'
import ChangeAccountType from './changeAccountType'
import ChangeForgotPassword from './changeForgotPassword'
import DeactivatedOrRestoredAccount from './deactivateAccount'
import styles from './verification.module.scss'
import CancelSub from './cancelSub'

const Verification = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const path = history.location.pathname.split('/')[2]
  const type = history.location.pathname.split('/')[5]
  const verificationData = props.match.params ? props.match.params : {}
  const stateAcc = useSelector(state => state.auth.user)

  const register = () => {
    history.push('/register')
  }

  const goToHome = () => {
    history.push('/home')
  }

  useEffect(() => {
    if (path) {
      if (path === 'confirm-email') {
        dispatch(
          openModal({
            content: <ConfirmMail data={verificationData} />,
            size: 'maxCenter500',
            show: true,
            onclose: goToHome
          })
        )
      } else if (path === 'confirm-password') {
        dispatch(
          openModal({
            content: <ChangePassword data={verificationData} />,
            size: 'maxCenter500',
            show: true,
            onclose: goToHome
          })
        )
      }
      if (path === 'confirm-personal-details') {
        dispatch(
          openModal({
            content: <ChangePersonalDetails data={verificationData} />,
            size: 'maxCenter500',
            show: true,
            onclose: goToHome
          })
        )
      }
      if (path === 'confirm-deactivation-or-restore') {
        dispatch(
          openModal({
            content: <DeactivatedOrRestoredAccount data={verificationData} active={stateAcc?.active} />,
            show: true,
            size: 'maxCenter500',
            onclose: goToHome
          })
        )
      }
      if (path === 'confirm-forgot-password-signup') {
        dispatch(
          openModal({
            content: <ChangeForgotPassword data={verificationData} />,
            show: true,
            size: 'maxCenter500',
            onclose: register
          })
        )
      }
      if (path === 'confirm-change-account-type') {
        dispatch(
          openModal({
            content: <ChangeAccountType type={type} data={verificationData} onclickNo={register} />,
            show: true,
            onclose: register
          })
        )
      }
      if (path === 'confirm-cancel-sub') {
        dispatch(
          openModal({
            content: <CancelSub data={verificationData} onclickNo={register} />,
            show: true,
            onclose: register
          })
        )
      }
      // if (path === 'confirm-newsletter-signup') {
      //   dispatch(verificationModalActions.openModal(<ConfirmNewsletterMail data={verificationData} />))
      // }
    }
  }, [path])
  return <div className={styles.wrapper}></div>
}

export default Verification
