import Loading from 'components/loading'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fileService from 'services/file.service'
import { updateUser } from 'store/reducers/auth/thunk'
import { converFileToBase64 } from 'utils/getBaseFromFile'
import { onAvatarDontExist } from 'utils/onAvatarDontExist'
import styles from './avatar.module.scss'

// Avatar component with frame
// size of component define in parent, sending via className, if you don't set them, it will be 7.5x7.5rem
const Avatar = ({ source = '', frameSize = 'medium', status = 'yellow', editable = false, className = false }) => {
  const store = useSelector(state => state)
  const { user } = useSelector(store => store?.auth)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const onSelectedFile = async e => {
    setLoading(true)
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    const file = e.target.files[0]
    converFileToBase64(file).then(base64 => {
      fileService
        .run('s3', 'upload', { data: base64, name: file.name }, null, `${store.auth.user._id}.jpg`)
        .then(() => {
          dispatch(updateUser({ _id: user?._id, avatarUpdatedAt: new Date().getTime() }))
          setLoading(false)
        })
    })
  }

  return (
    <div
      className={`${className || styles.defaultSize} ${styles.wrapper} ${styles[frameSize]} ${styles[status]} ${
        editable && styles.editable
      }`}
    >
      <div>
        {editable && (
          <input
            type="file"
            title=""
            accept="image/*"
            onClick={e => (e.target.value = null)}
            onChange={onSelectedFile}
          ></input>
        )}
        {loading && <Loading className={styles.loader} />}
        <img className={styles.avatar} src={source} alt="" onError={onAvatarDontExist} />
        {editable && <div className={styles.change}>Change</div>}
      </div>
    </div>
  )
}

export default Avatar
