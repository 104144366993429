import AvatarWithInfo from 'components/avatarWithInfo'
import Button from 'components/button'
import AddAccount from 'pages/auth/addAccount'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { openModal } from 'store/reducers/modal/modalSlice'
import iconClose from 'assets/icons/iconCloseRed.svg'
import styles from './switchAccount.module.scss'
import { getAvatarImage } from 'utils/getAvatarImage'
import { adminUrl } from 'environment'

const SwitchAccount = () => {
  const dispatch = useDispatch()
  const [linkedAccounts, setLinkedAccounts] = useState({})
  const { user } = useSelector(store => store?.auth)
  const [isBussines] = useState(true)
  const onAddAccountClick = () => {
    dispatch(
      openModal({
        content: <AddAccount fetchLinkedAccounts={fetchLinkedAccounts} />,
        name: 'Add Account Modal',
        size: 'max600',
        show: true
      })
    )
  }
  const onSwitchAccountClick = userId => {
    window.open(`${adminUrl}/home/?userId=${userId}`, '_blank')
  }

  const onRemoveAccountClick = async (userId, removeAllAccounts = false) => {
    const data = { userId, removeAllAccounts }
    await dataService.run('profile', 'user', 'removeAccount', data)
    const tempIds = linkedAccounts.linkedIds.filter(el => el.userId !== userId)
    if (removeAllAccounts || tempIds.length <= 0) {
      setLinkedAccounts({})
    } else {
      setLinkedAccounts({
        ...linkedAccounts,
        linkedIds: tempIds
      })
    }
  }

  const AvatarWithRemove = ({ userId, email }) => {
    return (
      <div key={userId + 'switch'} className={styles.switchAccountContainer}>
        <AvatarWithInfo
          title="Switch Account"
          subtitle={email}
          titleClass={styles.accountTitle}
          subtitleClass={styles.accountSubtitle}
          onClick={() => onSwitchAccountClick(userId)}
          avatar={getAvatarImage(userId)}
          inModalAccount={true}
          widthHeight={3}
        />
        <Button
          className={`${styles.removeSwitchAccountIcon} ${isBussines && styles.isBussines}`}
          onClick={() => onRemoveAccountClick(userId)}
        >
          <img src={iconClose} alt="Remove icon" className={styles.removeIcon} />
        </Button>
      </div>
    )
  }

  const fetchLinkedAccounts = async () => {
    const res = await dataService.run('data', 'linkedAccounts', 'get', {
      filter: { userId: user?._id.toString() }
    })

    if (res) {
      setLinkedAccounts({ ...res })
    } else {
      setLinkedAccounts({})
    }
  }

  useEffect(() => {
    if (user?._id) {
      fetchLinkedAccounts()
    }
  }, [user?._id])

  return (
    <div className={styles.wrapper}>
      <div className={styles.switchAccountsContainer}>
        {linkedAccounts?.linkedIds?.map(
          ({ userId, email }) => userId !== user?._id && <AvatarWithRemove userId={userId} email={email} />
        )}
      </div>

      {linkedAccounts?.linkedIds?.length > 0 && (
        <div
          className={`${styles.addAccountButton} ${isBussines && styles.isBussines}`}
          onClick={() => onRemoveAccountClick(null, true)}
        >
          <span>Remove All Accounts</span>
        </div>
      )}
      <Button
        className={styles.removeAccountButton}
        label="Add Another Account"
        onClick={onAddAccountClick}
        size="fitContent"
      />
    </div>
  )
}

export default SwitchAccount
