import VideoPickerIcon from 'assets/icons/drag_drop_editor/videoPickerIcon.png'
import IconClose from 'assets/icons/states/failed.png'
import VideoPreview from 'components/videoPreview'
import React, { useRef } from 'react'
import { toastService } from 'services/toastService'
import styles from './videoPicker.module.scss'
import { MAX_SIZE_FOR_VIDEO } from 'utils'
const VideoPicker = ({
  style,
  className,
  acceptedFileType = 'video/mp4,video/x-m4v,video/*',
  onVideoChange,
  defaultVideo,
  removable = true,
  isLoading = false,
  loadedPercent = 0,
  onStopUpload,
  maxSizeInMB = MAX_SIZE_FOR_VIDEO,
  onVideoRemove
}) => {
  const inputRef = useRef(null)
  const stopUpload = () => {
    onStopUpload && onStopUpload()
  }
  const onSelectedFile = e => {
    const files = e.target.files
    if (!files || files.length === 0) {
      return
    }
    const file = files[0]
    const fileSizeInMB = file.size / 1000 / 1000
    if (fileSizeInMB > 0 && fileSizeInMB < maxSizeInMB) onVideoChange(file)
    else {
      toastService('error', 'File too large.')
      if (inputRef && inputRef.current) inputRef.current.title = 'No file chosen'
    }
  }
  const removeVideo = () => {
    onVideoRemove && onVideoRemove()
    if (inputRef && inputRef.current) inputRef.current.title = 'No file chosen'
  }
  return (
    <div className={`${styles.videoPicker} ${styles[className]}`} style={{ ...style }}>
      {defaultVideo && <VideoPreview video={defaultVideo} />}
      {!defaultVideo && (
        <input
          type="file"
          accept={acceptedFileType}
          onClick={e => (e.target.value = null)}
          onChange={onSelectedFile}
          ref={inputRef}
        />
      )}
      {!defaultVideo && <img alt="VideoPicker" src={VideoPickerIcon} className={styles.noVideoIcon} />}
      {defaultVideo && removable && (
        <img className={`${styles.closeIcon}`} alt="CloseIcon" src={IconClose} onClick={removeVideo} />
      )}
      {isLoading && (
        <>
          <div className={styles.loader}>
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
            {loadedPercent < 99 && (
              <img className={styles.closeIcon} alt="CloseIcon" src={IconClose} onClick={stopUpload} />
            )}
          </div>
          <div className={styles.loadingContainer}>
            <div className={styles.loadingBar} style={{ width: `calc(${loadedPercent}% - 3px)` }}>
              {loadedPercent > 20 && (
                <span className={`${styles.loadingText} ${styles.loadingTextInner}`}>{loadedPercent}%</span>
              )}
            </div>
            {loadedPercent <= 20 && (
              <span className={`${styles.loadingText} ${styles.loadingTextOuter}`}>{loadedPercent}%</span>
            )}
          </div>
        </>
      )}
    </div>
  )
}
export default VideoPicker
