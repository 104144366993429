import Selector from 'components/select'
import React, { useEffect, useState } from 'react'
import styles from './phoneInput.module.scss'
import { prefixList } from './prefixList'

const PhoneInput = ({
  onChange,
  disabled = false,
  name,
  placeholder = '*phone number',
  defaultPrefixValue,
  value,
  errorMsg,
  error,
  className
}) => {
  const [prefixValue, setPrefixValue] = useState()
  const [sufixValue, setSufixValue] = useState()

  const handlePrefixChange = event => {
    setPrefixValue(event.value)
    const e = {}
    e.target = {
      prefix: event.value,
      value: sufixValue,
      name: 'phoneNumber'
    }
    onChange && onChange(e)
  }

  const handleSufixChange = value => {
    setSufixValue(value)
    const e = {}
    e.target = {
      prefix: prefixValue,
      value: value,
      name: 'phoneNumber'
    }
    onChange && onChange(e)
  }

  const newSortList = prefixList.sort(function (a, b) {
    return a.value.localeCompare(b.value, undefined, {
      numeric: true,
      sensitivity: 'base'
    })
  })

  newSortList.push(newSortList.shift())

  useEffect(() => {
    if (defaultPrefixValue !== prefixValue && !prefixValue) {
      setPrefixValue(defaultPrefixValue)
    }
  }, [defaultPrefixValue])

  useEffect(() => {
    if (value !== sufixValue && !sufixValue) {
      setSufixValue(value)
    }
  }, [value])

  return (
    <>
      <div className={`${styles.wrapper} ${styles[className]} ${className}`}>
        <Selector
          options={newSortList}
          classNamePrefix="prefix-select"
          className={styles.selectSecondary}
          handleChangeSelect={handlePrefixChange}
          selectedOption={newSortList.find(option => option.value === prefixValue)}
          placeholder="+1"
          selectClass={styles.selectForPhone}
        />
        <input
          onChange={e => handleSufixChange(e.target.value)}
          name={name}
          placeholder={placeholder}
          value={sufixValue}
          type="number"
          disabled={disabled}
          className={styles.input}
          errorMsg={errorMsg}
          error={error}
        />
        <div className={styles.error}> {error && errorMsg && <p>{errorMsg}</p>}</div>
      </div>
    </>
  )
}
export default PhoneInput
