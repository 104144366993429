import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardHeader from 'components/card/components/header'
import CardWrapper from 'components/card/components/wrapper'
import { CARD_SIZE } from 'staticConstants'
import styles from './campaignCard.module.scss'

export const CampaignCard = ({ className, size = CARD_SIZE.Normal, card = {}, onClick = null }) => {
  return (
    <CardWrapper className={styles.cardWrapper} onClick={onClick}>
      <Card className={`${styles.wrapper} ${styles[className]} ${className}`} size={size} type={card.cardType}>
        <CardBody size={size} type={card.cardType} defaultBackground={true}>
          <CardHeader type={card.cardType} size={'normal'} heading={card.heading} />
          <div className={styles.wrapper}>
            <div className={styles.position}>
              <img className={styles.images} src={card.frontImage} alt="Img" />
            </div>
          </div>
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CampaignCard
