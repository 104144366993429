import React, { useEffect, useRef, useState } from 'react'

import { useDispatch } from 'react-redux'

import Button from 'components/button'
import CardFlip from 'components/cardFlip'
import FirstFront from 'components/card/types/firstFront'
import PackFlip from 'components/packFlip'
import PackOpened from 'components/packOpened'
import RegisterCard from 'components/registerCard/registerCard'

import dataService from 'services/data.service'
import { toastService } from 'services/toastService'

import { setPreview } from 'store/reducers/previewModal/previewModalSlice'
import { setSelectedPack } from 'store/reducers/pack/packSlice'
import { closeModal as closePackModal } from 'store/reducers/packModal/packModalSlice'

import { appUrl } from 'environment'

import nextSlideImg from 'assets/icons/nextArrow.png'
import prevSlideImg from 'assets/icons/prevArrow.png'

import styles from './slider.module.scss'
import { sortDataByIds } from '../../utils/getSortedCards'

const handleWindowResize = setPxPerRem => {
  const windowWidth = window.innerWidth
  switch (true) {
    case windowWidth > 1680:
      setPxPerRem(12)
      break
    case windowWidth >= 1600 && windowWidth <= 1680:
      setPxPerRem(11)
      break
    case windowWidth >= 1360 && windowWidth < 1600:
      setPxPerRem(10)
      break
    case windowWidth >= 1024 && windowWidth < 1360:
      setPxPerRem(8)
      break
    case windowWidth >= 768 && windowWidth < 1024:
      setPxPerRem(6)
      break
    case windowWidth >= 320 && windowWidth < 768:
      setPxPerRem(4)
      break
    case windowWidth < 320:
      setPxPerRem(3)
      break
    default:
      setPxPerRem(12)
      break
  }
}
export const Slider = ({
  items = [],
  editable = false,
  className,
  selectable = false,
  showBtns = true,
  deletable = false,
  firstCard = false,
  onSelectionClick = () => {},
  isCardSelected = () => {},
  moveByCardClick = false,
  showWSButton = true,
  moveOnDrag = true,
  showLoadMore = false,
  onLoadMore,
  openModalOnItemClick = false,
  showDetails = false,
  returnIndex,
  showBackButton = false,
  goOnIndex = 0,
  goOnMiddleIndex = false
}) => {
  const dispatch = useDispatch()
  const sliderWrapperRef = useRef()

  const [cardIndex, setCardIndex] = useState(goOnIndex)
  const [selectedCard, setSelectedCard] = useState({ index: firstCard ? 1 : 0, flipped: false })
  const [translateXValue, setTranslateXValue] = useState(0)
  const [pxPerRem, setPxPerRem] = useState(12)
  const [openingPack, setOpeningPack] = useState(false)
  const [packIndex, setPackIndex] = useState(-1)
  const [packAnimationIsActive, setPackAnimationIsActive] = useState(false)
  const [isPackLoading, setIsPackLoading] = useState({ loading: false, packId: '' })
  const [deleted, setDeleted] = useState(false)
  const [deletedIndex, setDeletedIndex] = useState(0)
  const [showActive, setShowActive] = useState(true)

  useEffect(() => {
    if (items[0]?.cardType !== 'registrationCard') {
      setShowActive(true)
      setCardIndex(goOnMiddleIndex ? (items?.length > 0 ? Math.floor(items.length / 2) : 0) : 0)
    }
  }, [items])

  let clickStartPosition
  window.addEventListener('resize', () => handleWindowResize(setPxPerRem))

  const onLoadMoreHandler = () => {
    onLoadMore && onLoadMore()
  }
  const onClickStart = e => {
    clickStartPosition = e.pageX
  }
  const onClickEnd = e => {
    let dragDistance = 0
    if (e.pageX > clickStartPosition) {
      dragDistance = e.pageX - clickStartPosition
    } else {
      dragDistance = -(clickStartPosition - e.pageX)
    }
    if (!(dragDistance > -10 && dragDistance < 10)) {
      if (moveOnDrag) {
        // Move slider by swipe
        if (dragDistance > -300 && dragDistance < 300) {
          // Move by 1 with swipe smaller than card
          if (dragDistance > 0) setCardIndex(cardIndex === 0 ? cardIndex : cardIndex - 1)
          else if (dragDistance < 0) setCardIndex(cardIndex === items.length - 1 ? cardIndex : cardIndex + 1)
        } else {
          // Move by 1 or more items
          const firstCardCorrectionIndex = firstCard ? 1 : 0
          let newIndex = getIndexFromWidth(translateXValue - dragDistance / pxPerRem)
          if (newIndex >= items?.length + firstCardCorrectionIndex - 1)
            newIndex = items.length - 1 + firstCardCorrectionIndex
          else if (newIndex <= 0 + firstCardCorrectionIndex) newIndex = 0 + firstCardCorrectionIndex
          setCardIndex(newIndex)
        }
      }
    }
  }
  const onPackClicked = async (index, pack) => {
    if (!pack) {
      toastService('error', 'Pack not exists anymore, it is deleted or disabled')
      closePackModal()
      return
    }
    setCardIndex(index)
    setIsPackLoading({ loading: true, packId: pack?._id })
    const res = await dataService.run('data', 'card', 'all', { filter: { _id: { $in: pack?.cards }, disabled: null } })
    if (!res?.length) {
      toastService('info', 'Pack is empty.')
    } else {
      const sortedArray = sortDataByIds(res, pack?.cards)
      setIsPackLoading({ loading: false, packId: '' })
      setPackIndex(index)
      setPackAnimationIsActive(true)
      setTimeout(() => {
        setOpeningPack(true)
      }, 1600)
      await setTimeout(async () => {
        await dispatch(setSelectedPack({ ...pack, cards: sortedArray }))
      }, 1000)
    }
  }

  const nextSlide = () => {
    setCardIndex(cardIndex + 1)
  }
  const prevSlide = () => {
    setCardIndex(cardIndex - 1)
  }
  const getIndexFromWidth = (width, directionBack = false) => {
    let index = 0
    for (let i = 0; getTranslateX(index) < width; i++) {
      index++
    }
    return index === 0 ? 0 : index - (directionBack ? 0 : 1)
  }
  const getTranslateX = currentIndex => {
    return currentIndex * 21.875
  }
  const onSelectionClickHandle = event => {
    onSelectionClick && onSelectionClick(event)
  }
  const isCardSelectedHandle = event => {
    isCardSelected && isCardSelected(event)
  }

  const showWonderShuffle = () => {
    window.open(`${appUrl}`, '_blank')
  }

  const onCardClicked = (index, item, event) => {
    let testInsta = false
    if (event && event.target && event.target.id && event.target.id === 'testInsta') {
      testInsta = true
    }
    const newIndex = index + (firstCard ? 1 : 0)
    // Move slider on item click
    if (moveByCardClick && newIndex !== cardIndex) {
      setCardIndex(newIndex)
    } else if (item?.cardType === 'registrationCard') {
      dispatch(
        setPreview({
          item,
          flipped: false,
          flipWithAnimation: false,
          flippable: false,
          cardType: 'registrationCard',
          testInsta: testInsta
        })
      )
    }
  }

  const openPreviewModal = (item, index) => {
    if (index === cardIndex) {
      dispatch(
        setPreview({
          item,
          flipped: true,
          flipWithAnimation: true
        })
      )
    }
  }

  useEffect(() => {
    setTranslateXValue(getTranslateX(cardIndex))
    setSelectedCard({ index: cardIndex, flipped: false })
  }, [cardIndex])
  const setMoveAnimation = index => {
    if (packAnimationIsActive) {
      if (index === packIndex + 1) return styles.goRightFirst
      if (index === packIndex + 2) return styles.goRightS
      if (index > packIndex) return styles.goRight
      if (index === packIndex - 1) return styles.goLeftFirst
      if (index === packIndex - 2) return styles.goLeftS
      if (index < packIndex) return styles.goLeft
    }
    return ''
  }

  const handleDelete = isCardLast => {
    if (isCardLast) {
      prevSlide()
    }
    setDeleted(true)
    setDeletedIndex(cardIndex)
  }

  const changeActiveAfterDeleting = index => {
    if (deleted && deletedIndex > 0 && index - 1 === deletedIndex) {
      setDeletedIndex(-1)
      return styles.active
    }
    return ''
  }

  return (
    items.length > 0 && (
      <div
        ref={sliderWrapperRef}
        className={`${styles.wrapper} ${className} ${styles[className]} ${showWSButton ? '' : styles.noWSButton}`}
        onMouseDown={onClickStart}
        onMouseUp={onClickEnd}
      >
        <div className={styles.slider}>
          {openingPack && (
            <div className={styles.openPack}>
              <PackOpened
                onClose={() => {
                  setPackAnimationIsActive(false)
                  setOpeningPack(false)
                }}
                pack={items[packIndex]}
              />
            </div>
          )}

          <div
            className={styles.content}
            style={{ transform: 'translateX(calc(-' + translateXValue + 'rem + 0.875rem))' }}
          >
            {firstCard && <FirstFront className={` ${packAnimationIsActive ? setMoveAnimation(-1) : ''}`} />}

            {items.map((item, index) => {
              if (item.type === 'card')
                return (
                  <CardFlip
                    showDetails={showDetails}
                    onClick={event => onCardClicked(index, item, event)}
                    editable={editable}
                    data={item}
                    key={'card' + item._id + index}
                    flippable={firstCard ? cardIndex === index + 1 : cardIndex === index}
                    selectable={selectable}
                    dateDiffForDeleting={item.dateDiff}
                    onSelectionClick={onSelectionClickHandle}
                    isCardSelected={isCardSelectedHandle}
                    customFlipLogic
                    isFlippedCustom={
                      !openModalOnItemClick &&
                      selectedCard &&
                      selectedCard.index - (firstCard ? 1 : 0) === index &&
                      selectedCard.flipped
                    }
                    setIsFlippedCustom={flipped =>
                      openModalOnItemClick
                        ? openPreviewModal(item, index)
                        : setSelectedCard({ index: cardIndex, flipped: flipped })
                    }
                    className={`
                      ${styles.sliderElement}
                      ${firstCard && cardIndex === index + 1 && !deleted && showActive ? styles.active : ''}
                      ${!firstCard && cardIndex === index && !deleted && showActive ? styles.active : ''}
                      ${deleted && changeActiveAfterDeleting(index)} 
                      ${packAnimationIsActive ? setMoveAnimation(index) : ''}
                    `}
                    deletable={deletable}
                    handleDelete={() => handleDelete(index === items.length - (firstCard ? 0 : 1))}
                  />
                )

              if (item.type === 'pack')
                return (
                  <div key={'pack' + item._id + index} className={cardIndex === index ? styles.active : ''}>
                    <PackFlip
                      onClick={() => onPackClicked(index, item)}
                      pack={item}
                      onEdit={false}
                      editable={false}
                      className={`
                          ${styles.sliderElement}
                          ${styles.pack}
                          ${cardIndex === index && showActive ? styles.packActive : ''}
                          ${packAnimationIsActive ? setMoveAnimation(index) : ''}
                        `}
                      isFlipped={selectedCard && selectedCard.index === index && packAnimationIsActive}
                      isLoading={isPackLoading.loading && isPackLoading.packId === item?._id}
                      selectable={selectable}
                      isPackSelected={isCardSelectedHandle}
                      onSelectionClick={onSelectionClickHandle}
                      deleteable={false}
                      restorable={false}
                      openable={true}
                    />
                  </div>
                )
              if (item.cardType === 'registrationCard') {
                return (
                  <RegisterCard
                    key={'registerCard' + index}
                    onClick={event => onCardClicked(index, item, event)}
                    cardTitle={item.title}
                    cardContent={item.content}
                    cardPrice={item.price}
                    isHighlited={cardIndex === index}
                    isVideo={item.title === 'video'}
                    isImage={item.title === 'image'}
                    onClickNext={() => returnIndex(item.itemIndex)}
                  />
                )
              }
            })}
            {showLoadMore && <Button className={styles.loadMoreBtn} label="Load More" onClick={onLoadMoreHandler} />}
          </div>
        </div>
        <div className={`${styles.btns}`}>
          {showBtns && packIndex === -1 && (
            <button
              onClick={prevSlide}
              disabled={firstCard ? cardIndex === 1 : cardIndex === 0}
              className={styles.leftArrow}
            >
              <img src={prevSlideImg} alt="Next"></img>
            </button>
          )}
          {showBtns && packIndex === -1 && (
            <button
              onClick={nextSlide}
              disabled={cardIndex === items.length - (firstCard ? 0 : 1)}
              className={styles.rightArrow}
            >
              <img src={nextSlideImg} alt="Next"></img>
            </button>
          )}
        </div>
        {showBackButton && (
          <Button
            className={styles.backToWonder}
            label="Back to Wonder Shuffle"
            variation="primary"
            size="md"
            onClick={showWonderShuffle}
          ></Button>
        )}
      </div>
    )
  )
}

export default Slider
