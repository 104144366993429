import React from 'react'
import Card from 'components/card'
import CardBody from 'components/card/components/body'
import DndFields from 'components/dndFields'
import styles from './cardStandardBackEditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import CardHeader, { HeaderPosition, HeaderSize } from 'components/card/components/header'
import WonderLogo from 'assets/logo/iconShortLogoWhite.svg'
import CardScrollableContent from 'components/card/components/scrollableContent'
import MediaPicker from 'components/mediaPicker'
import CardWrapper from 'components/card/components/wrapper'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import { setSelectedCard } from 'store/reducers/card/cardSlice'

const CardStandardBackEditable = ({ className, byline = '' }) => {
  const dispatch = useDispatch()
  const card = useSelector(state => state.card.selectedCard)
  const onDeleteHandler = position => {
    const tempArray = []
    card.backFields.forEach(({ id, type, content }, index) => {
      if (index !== position) tempArray.push({ id: id, type: type, content: content })
    })
    dispatch(setSelectedCard({ ...card, backFields: tempArray }))
  }
  const onChangeHandler = array => {
    dispatch(setSelectedCard({ ...card, backFields: array }))
  }
  const onImageChange = image => {
    dispatch(setSelectedCard({ ...card, fullCardImage: { active: true, image: image } }))
  }
  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card
        className={className}
        type={card.cardType || CARD_TYPES.Create}
        size={CARD_SIZE.Large}
        userType={card?.userRole}
      >
        <CardBody type={card.cardType || CARD_TYPES.Create} size={CARD_SIZE.Large} userType={card?.userRole}>
          <CardHeader
            type={card.cardType || CARD_TYPES.Create}
            size={HeaderSize.LargeWithLogo}
            position={
              (card.cardType === CARD_TYPES.Collectible || card.cardType === CARD_TYPES.Mystery) &&
              card.fullCardImage.active
                ? HeaderPosition.Floating
                : HeaderPosition.Block
            }
            heading="Wonder Shuffle"
            subheading={byline !== '' ? 'Sponsored by ' + byline : 'Do The Shuffle!'}
            logo={WonderLogo}
            className={
              (card.cardType === CARD_TYPES.Collectible || card.cardType === CARD_TYPES.Mystery) &&
              card.fullCardImage.active
                ? ''
                : 'm-10'
            }
            userType={card?.userRole}
          />
          {/* eslint-disable */}
          {(card.cardType === CARD_TYPES.Collectible || card.cardType === CARD_TYPES.Mystery) &&
            card.fullCardImage.active && (
              <MediaPicker
                className="cardCover"
                onImageChange={onImageChange}
                stateMedia={getMediaFromFileOrString(card.fullCardImage.image)}
              />
            )}
          {/* eslint-enable */}
          {(!(card.cardType === CARD_TYPES.Collectible || card.cardType === CARD_TYPES.Mystery) ||
            !card.fullCardImage.active) && (
            <CardScrollableContent type={card.cardType} className={styles.content}>
              <div className={styles.dndFields}>
                <DndFields
                  fields={card.backFields}
                  onDelete={onDeleteHandler}
                  onChange={onChangeHandler}
                  type={card.cardType}
                />
              </div>
            </CardScrollableContent>
          )}
        </CardBody>
      </Card>
    </CardWrapper>
  )
}
export default CardStandardBackEditable
