import React from 'react'
import styles from './typography.module.scss'

export const TYPOGRAPHY_VARIATION = {
  Title: 'title',
  SubtitleWithoutMargin: 'subtitleWithoutMargin',
  Subtitle: 'subtitle',
  LargerParagraph: 'largerParagraph',
  MediumParagraph: 'lmediumParagraph',
  NormalText: 'normalText',
  EmptyMessageText: 'emptyMessageText',
  ErrorMessageSmall: 'errorMessageSmall'
}

const Typography = ({ text = '', variation = TYPOGRAPHY_VARIATION.NormalText, className }) => {
  return (
    <span
      className={`${styles.typography} ${styles['variation-' + variation]} ${className || ''} ${styles[className]}`}
    >
      {text}
    </span>
  )
}
export default Typography
