import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  item: {},
  editable: false,
  deletable: false,
  saveable: false,
  exitable: true,
  flipped: false,
  flipWithAnimation: false,
  removeableFromCalendarAndMap: false,
  flippable: true,
  //   onClose: () => {},
  closePreview: false,
  previewModalOpened: false,
  showMature: false
}

export const previewModal = createSlice({
  name: 'previewModal',
  initialState,
  reducers: {
    setPreviewedItem: (state, action) => {
      const { item } = action.payload
      state.item = item
    },
    setPreview: (state, action) => {
      if (
        action.payload.item.cardType !== 'event' &&
        action.payload.item.cardType !== 'oneSidedTrivia' &&
        action.payload.item.cardType !== 'poll' &&
        action.payload.item.cardType !== 'anchor'
      ) {
        const newObject = { ...action.payload }
        state.item = newObject.item
        state.previewModalOpened = true
        state.flipped = newObject.flipped
        state.flippable = newObject.flippable
        state.flipWithAnimation = newObject.flipWithAnimation
        if (Object.prototype.hasOwnProperty.call(newObject, 'testInsta')) {
          state.testInsta = newObject.testInsta
        } else {
          state.testInsta = false
        }
      } else {
        const newObject = { ...action.payload }
        state.item = newObject.item
        state.flipped = false
        state.flipWithAnimation = false
        state.flippable = false
        if (Object.prototype.hasOwnProperty.call(action.payload, 'testInsta')) {
          state.testInsta = action.payload.testInsta
        } else {
          state.testInsta = false
        }
      }
    },
    setClosePreview: state => {
      state.closePreview = true
    },
    hideMatureProtection: state => {
      state.showMature = true
    },
    clearPreview: state => {
      state.item = {}
      state.editable = false
      state.deletable = false
      state.saveable = false
      state.exitable = true
      state.flipped = false
      state.flipWithAnimation = false
      state.removeableFromCalendarAndMap = false
      state.flippable = true
      //   state.onClose = () => {}
      state.closePreview = false
      state.previewModalOpened = false
      state.showMature = false
    }
  }
})

export const { setPreviewedItem, setPreview, setClosePreview, hideMatureProtection, clearPreview } =
  previewModal.actions

export default previewModal.reducer
