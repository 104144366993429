import { awsUrl, awsUrlResize } from 'environment'

export const objToArray = obj => {
  if (!obj) return []
  return Object.keys(obj).map(key => ({ key, value: obj[key] }))
}

export const arrayToObj = arr => {
  if (!arr) return {}
  const obj = {}
  arr.forEach(({ key, value }) => {
    if (key && value) {
      obj[key] = value
    }
  })
  return obj
}

export const sortDescendingBy = key => {
  return (a, b) => b[key] - a[key]
}

export const sortAscendingBy = key => {
  return (a, b) => b[key] - a[key]
}

export const isObjectEmpty = obj => {
  if (!obj) return true
  return Object.keys(obj).length === 0
}
export const findObjectById = (array, id) => array.find(object => object.id === id)

export const getAvatarImage = (userId, updatedAt, refreshTime) => {
  const additionalParam = refreshTime ? '?' + refreshTime : updatedAt ? `?${updatedAt}` : ''
  const isImageUpdatedInLast10Secnods = new Date().getTime() - (updatedAt ?? 0) < 3000 // 3 seconds
  const imageName = `${userId}.jpg${additionalParam}`
  return `${isImageUpdatedInLast10Secnods ? awsUrl : awsUrlResize}/${imageName}`
}
export function updateObjectInArray(array, id, update, propName = '_id') {
  const newArray = [...array]
  for (let i = 0; i < newArray.length; i++) {
    if (newArray[i]?.[propName] === id) {
      newArray[i] = { ...newArray[i], ...update }
      return newArray
    }
  }
  return newArray
}

export function upsertObjectToArray(array, object, key = '_id') {
  if (!Array.isArray(array)) {
    return null
  }
  if (!object || typeof object !== 'object') {
    return null
  }
  if (!object?.[key]) {
    return null
  }
  const newArray = [...array]
  const index = newArray.findIndex(obj => obj[key] === object[key])
  if (index === -1) {
    newArray.push(object)
  } else {
    newArray[index] = object
  }
  return newArray
}

export function textToLowerSeparatedWithHyphen(text) {
  if (!text) return ''
  return text
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^a-z0-9]/gi, '-')
    ?.split('-')
    ?.filter(el => el)
    .join('-')
}
