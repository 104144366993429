import Button from 'components/button'
import CardFlip from 'components/cardFlip'
import CardFront from 'components/cardFront'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { CARD_TYPES } from 'staticConstants'
import styles from './widgetInlinePack.module.scss'

const WidgetInlinePack = ({ openable = false, preview = false, inDouble = false, setIsInline, cardId }) => {
  const [openCard, setOpenCard] = useState(false)
  const [isCardFlipped, setIsCardFlipped] = useState(false)
  const [card, setCard] = useState({})
  const { modal } = useSelector(state => state)
  const store = useSelector(state => state)
  const openHandler = () => {
    setOpenCard(true)
    setIsInline && setIsInline({ opened: true, flipped: false })
  }
  const closeHandler = () => {
    setOpenCard(false)
    setIsInline && setIsInline({ opened: false, flipped: false })
  }
  const onFlipHandler = isFlipped => {
    setIsInline && setIsInline({ opened: true, flipped: isFlipped })
    setIsCardFlipped(isFlipped)
  }
  const [current, setCurrent] = useState(0)
  useEffect(() => {
    setCurrent(store.card.openedInlineCards)
  }, [])

  useEffect(() => {
    const fetchCard = async () => {
      try {
        const res = await dataService.run('data', 'card', 'get', { filter: { _id: cardId } })
        if (!res.isAxiosError) {
          setCard(res)
        }
      } catch (err) {
        console.warn('_e', err)
      }
    }
    fetchCard()
  }, [cardId])
  return (
    <div
      key={cardId}
      className={`${styles.wrapper} ${preview ? styles.preview : ''}  ${
        isCardFlipped && card?.cardType === CARD_TYPES.Double ? styles.doubleFlipped : ''
      } ${inDouble ? styles.flippedInDouble : ''}`}
    >
      <div className={styles.bottomContainer}>
        <div className={styles.cardFlipContainer}>
          <CardFront card={card} />
        </div>
      </div>
      <Button
        className={`${styles.openBtn} ${!openable ? styles.hidden : ''}`}
        label="Open"
        size="md"
        onClick={() => openable && !(modal && modal.show && modal.name !== 'previewModal') && openHandler()}
      />
      {openCard && (
        <div className={`${openCard ? styles.cardIsOpen : styles.cardIsClosed}`}>
          <CardFlip
            data={card}
            exitable={openCard}
            onClose={closeHandler}
            onFlipTriggered={event => onFlipHandler(event)}
            customFlipLogic
            isFlippedCustom={isCardFlipped}
            setIsFlippedCustom={isFlipped => onFlipHandler(isFlipped)}
            flippable={openCard}
            saveable={false}
            isCardInline
            numberOfCardsStackForInline={current + 1}
          />
        </div>
      )}
    </div>
  )
}

export default WidgetInlinePack
