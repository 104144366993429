import React from 'react'

const IconComponentLike = ({ width = '26', height = '24', fillIcon = false, color = 'black' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.02852 13.619L13.0001 22.9401L21.951 13.6565C24.0609 11.3821 25 9.27221 25 7.3125C25 5.20129 24.4381 3.68377 23.4327 2.62664C22.4327 1.57517 21.0209 1 19.0647 1C18.5917 1 18.0941 1.088 17.5672 1.27713C17.028 1.47069 16.5285 1.73084 16.0663 2.05824C15.5483 2.42507 15.1126 2.76231 14.7554 3.07011C14.3966 3.37918 14.0558 3.70758 13.7328 4.05545L13 4.84461L12.2672 4.05545C11.9442 3.70758 11.6034 3.37918 11.2446 3.07011C10.8873 2.76231 10.4517 2.42507 9.93375 2.05824C9.47148 1.73085 8.97199 1.47069 8.43278 1.27713C7.90593 1.088 7.40831 1 6.93527 1C4.9791 1 3.56725 1.57517 2.56726 2.62664C1.56189 3.68377 1 5.20129 1 7.3125C1 7.90067 1.09564 8.52034 1.29683 9.17497C1.50927 9.86618 1.74304 10.428 1.99076 10.8727C2.25904 11.3542 2.56408 11.8255 2.90654 12.2865C3.27436 12.7816 3.51749 13.0879 3.65131 13.2361C3.81406 13.4164 3.9131 13.5138 3.96074 13.5549L3.99613 13.5854L4.02852 13.619Z"
        fill={fillIcon ? color : 'transparent'}
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  )
}
export default IconComponentLike
