import Button from 'components/button'
import CardFlip from 'components/cardFlip'
import Heading from 'components/Heading'
import Checkbox from 'components/checkbox'
import PackCreation from 'components/packCreation'
import PackFlip from 'components/packFlip'
import Pagination from 'components/pagination'
import Selector from 'components/select'
import { subDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { setRequiredFields } from 'store/reducers/card/cardSlice'
import { openModal } from 'store/reducers/modal/modalSlice'
import { openModal as openPackModal } from 'store/reducers/packModal/packModalSlice'
import { packDefaultState, setIsPackSelected, setSelectedPack, setSelectionMode } from 'store/reducers/pack/packSlice'
import { setPreview } from 'store/reducers/previewModal/previewModalSlice'
import styles from './contentModeration.module.scss'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'

const durationOptions = [
  { value: 7, label: 'Past 7 days' },
  { value: 14, label: 'Past 14 days' },
  { value: 21, label: 'Past 21 days' },
  { value: 'all', label: 'All time' }
]

const filterCardsOptions = [
  { value: 'reportedBy', label: 'Reported Cards' },
  { value: 'disabled', label: 'Disabled Cards' },
  { value: 'deleted', label: 'Deleted Cards' },
  { value: 'myitems', label: 'My Cards' },
  { value: 'all', label: 'All Cards' }
]

const filterPacksOptions = [
  { value: 'disabled', label: 'Disabled Packs' },
  { value: 'deleted', label: 'Deleted Packs' },
  { value: 'myitems', label: 'My Packs' },
  { value: 'all', label: 'All Packs' }
]

const _getFilterValue = (
  filterSearchCardByHeading,
  filterSearchCardByUsername,
  currentTopic,
  currentDate,
  filterCards,
  userId
) => {
  let timestampValue
  if (currentDate !== '' && currentDate !== 'all') {
    timestampValue = new Date(subDays(new Date(), currentDate).toISOString()).getTime()
  }
  let filter = {}
  if (filterSearchCardByHeading !== '') {
    filter = { ...filter, heading: { $regex: filterSearchCardByHeading, $options: 'i' } }
  }
  if (filterSearchCardByUsername !== '') {
    // filter = { ...filter, username: { $regex: filterSearchCardByUsername, $options: 'i' } }
    filter = { ...filter, username: filterSearchCardByUsername } // set to exact
  }
  if (currentTopic !== '') {
    filter = { ...filter, tags: { $in: [currentTopic] } }
  }
  if (currentDate !== '' && currentDate !== 'all') {
    filter = { ...filter, published: true, createdAt: { $gt: timestampValue } }
  }
  if (filterCards && filterCards === 'myitems' && filterCards !== 'all') {
    filter = { ...filter, userId, disabled: null }
  }
  if (filterCards && filterCards !== 'deleted' && filterCards !== 'all' && filterCards !== 'myitems') {
    filter = { ...filter, [filterCards]: { $ne: null } }
  }
  if (filterCards && filterCards === 'disabled') {
    filter = { ...filter, disabled: { $ne: null }, deletedAt: null }
  }
  return filter
}

const ContentModeration = () => {
  const cardUpdatedAtTimestamp = useSelector(state => state.card?.cardUpdatedAt)
  const packUpdatedAtTimestamp = useSelector(state => state.pack?.packUpdatedAt)
  const [items, setItems] = useState({ rows: [], total: 0 })
  const [topics, setTopics] = useState([])
  const [searchValue, setSearchValue] = useState({ heading: '', username: '' })
  const [filterCards, setFilterCards] = useState('')
  const [filterSearchCardByHeading, setFilterSearchCardByHeading] = useState('')
  const [filterSearchCardByUsername, setFilterSearchCardByUsername] = useState('')
  const [currentTopic, setCurrentTopic] = useState('')
  const [currentDate, setCurrentDate] = useState('all')
  const [pageNumber, setPageNumber] = useState(1)
  const selectedCards = useSelector(store => store?.pack?.selectedPack?.cards ?? [])
  const selectionMode = useSelector(state => state.pack.selectionMode)
  const [checkPacks, setCheckPacks] = useState(false)
  const { pack, auth } = useSelector(state => state)
  const pageSize = 20
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchCards = async () => {
      const filter = _getFilterValue(
        filterSearchCardByHeading,
        filterSearchCardByUsername,
        currentTopic,
        currentDate,
        filterCards,
        auth?.user?._id
      )

      if (filterCards === 'deleted') {
        const res = await dataService.run('archive', 'card', 'all', {
          filter: { ...filter, type: 'card', published: true, disabled: { $ne: null } },
          sort: { likes: -1 }
        })
        setItems({ rows: res, total: res.length })
      } else {
        const res = await dataService.run('data', 'card', 'page', {
          pageSize: pageSize,
          filter: filter,
          sort: { likes: -1 },
          pageNumber
        })
        setItems({ rows: res.rows, total: res.total })
      }
    }
    if (!checkPacks) {
      fetchCards()
    }
  }, [
    currentTopic,
    filterSearchCardByHeading,
    currentDate,
    cardUpdatedAtTimestamp,
    filterCards,
    filterSearchCardByUsername,
    pageNumber,
    checkPacks,
    auth?.user?._id
  ])

  useEffect(() => {
    const fetchPacks = async () => {
      const filter = _getFilterValue(
        filterSearchCardByHeading,
        filterSearchCardByUsername,
        currentTopic,
        currentDate,
        filterCards,
        auth?.user?._id
      )

      if (filterCards === 'deleted') {
        const res = await dataService.run('archive', 'pack', 'all', {
          filter: { ...filter, type: 'pack', published: true, disabled: { $ne: null } },
          sort: { likes: -1 }
        })
        setItems({ rows: res, total: res.length })
      } else {
        const res = await dataService.run('data', 'pack', 'page', {
          pageSize: pageSize,
          filter: filter,
          sort: { likes: -1 },
          pageNumber
        })
        setItems({ rows: res.rows, total: res.total })
      }
    }
    if (checkPacks) {
      fetchPacks()
    }
  }, [
    currentTopic,
    filterSearchCardByHeading,
    currentDate,
    packUpdatedAtTimestamp,
    filterCards,
    filterSearchCardByUsername,
    pageNumber,
    checkPacks,
    auth?.user?._id
  ])

  useEffect(() => {
    const fetchTopics = async () => {
      const res = await dataService.run('data', 'topic', 'all', {
        filter: { value: { $ne: null } },
        sort: { timesSearched: -1 }
      })
      if (res?.length) {
        const uniqueTopicsObject = {}
        const uniqueTopicsArray = [{ value: '', label: 'All Tags' }]
        res.forEach(topic => {
          if (topic?.value && !uniqueTopicsObject[topic.value]) {
            const val = topic.value
            uniqueTopicsObject[val] = { value: val, label: '#' + val }
            uniqueTopicsArray.push({ value: val, label: '#' + val })
          }
        })
        setTopics(uniqueTopicsArray)
      }
    }

    fetchTopics()
  }, [])

  useEffect(() => {
    if (selectionMode) dispatch(setSelectedPack({ ...packDefaultState, cards: [] }))
  }, [selectionMode])

  const onCheckbox = () => {
    setCheckPacks(!checkPacks)
    setFilterCards('')
    setCurrentTopic('')
    setSearchValue(searchData => ({ ...searchData, username: '', heading: '' }))
  }

  const handleFilterOnEnter = e => {
    const { name } = e.target
    if (e.key === 'Enter') {
      if (name === 'username') {
        setFilterSearchCardByUsername(searchValue?.username)
      } else {
        setFilterSearchCardByHeading(searchValue?.heading)
      }
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (value !== '') {
      setSearchValue(searchData => ({ ...searchData, [name]: value }))
    } else {
      if (name === 'username') setFilterSearchCardByUsername('')
      else setFilterSearchCardByHeading('')
      setSearchValue(searchData => ({ ...searchData, [name]: '' }))
    }
  }

  const openPreviewModal = item => {
    dispatch(
      setPreview({
        item,
        flipped: true,
        flipWithAnimation: true,
        flippable: true
      })
    )
  }
  // const onSelectionModeChange = () => {
  //   dispatch(setSelectionMode(!selectionMode))
  // }

  const newPackClicked = () => {
    if (selectedCards?.length > 0) {
      dispatch(setRequiredFields({}))
      dispatch(setIsPackSelected(false))
      dispatch(setSelectionMode(false))
      dispatch(setSelectedPack({ ...packDefaultState, cards: selectedCards }))
      dispatch(openModal({ show: true, content: <PackCreation />, name: 'Pack creation modal', size: 'max1100' }))
    }
  }

  const onPackEdit = data => {
    dispatch(setSelectedPack({ ...data }))
    dispatch(setRequiredFields({}))
    dispatch(setIsPackSelected(true))
    dispatch(openModal({ show: true, content: <PackCreation />, name: 'Pack creation modal', size: 'max1100' }))
  }

  const onSelectionClick = selectedCard => {
    const selectedCards = pack?.selectedPack?.cards ?? []
    if (selectedCard?.type === 'card') {
      if (selectedCard?.published) {
        if (!selectedCard?.disabled) {
          const selectedCardAlreadySelected = selectedCards.find(c => c._id === selectedCard._id)
          if (selectedCardAlreadySelected) {
            const cardsWithoutDeselectedCard = []
            selectedCards.map(c => {
              if (c._id !== selectedCard._id) cardsWithoutDeselectedCard.push(c)
            })
            dispatch(setSelectedPack({ ...(pack?.selectedPack ?? {}), cards: cardsWithoutDeselectedCard }))
          } else {
            dispatch(setSelectedPack({ ...(pack?.selectedPack ?? {}), cards: [...selectedCards, selectedCard] }))
          }
        } else {
          toastService('info', 'Disabled cards are not allowed!')
        }
      } else {
        toastService('info', 'Only published cards are allowed.')
      }
    } else {
      toastService('info', 'Packs are not allowed for selection.')
    }
  }

  const isCardSelected = currentCard => {
    if (pack?.selectedPack?.cards?.length === 0) return false
    return pack?.selectedPack?.cards?.find(c => c._id === currentCard._id)
  }

  return (
    <div className={styles.trendingWrapper}>
      <Heading
        title="Content Moderation"
        leftMiddleElement={
          <div className={styles.packContentPosition}>
            <Typography text="Packs:" variation={TYPOGRAPHY_VARIATION.SubtitleWithoutMargin} />
            <div className={styles.checkBox}>
              <Checkbox onChange={onCheckbox} checked={checkPacks} value={checkPacks} />
            </div>
          </div>
        }
        // rightMiddleElement={
        //   <Button
        //     label={selectionMode ? 'Deselect' : 'Select'}
        //     size="fitContent"
        //     style={{ width: '10rem' }}
        //     className={styles.marginButton}
        //     onClick={() => onSelectionModeChange()}
        //     disabled={items?.rows?.length === 0 || checkPacks}
        //   />
        // }
        bottomElement={
          selectionMode ? (
            <div className={styles.newPack}>
              <Button
                className={`${styles.btn} ${selectedCards?.length === 0 ? styles.disabled : styles.blue}`}
                label="New Pack"
                onClick={newPackClicked}
              />
            </div>
          ) : (
            <></>
          )
        }
      />

      <div className={styles.filter}>
        <div className={styles.third}>
          <Selector
            selectClass={'selectGrayNoMargin'}
            placeholder={`Filter ${checkPacks ? 'packs' : 'cards'}`}
            selectedOption={(checkPacks ? filterPacksOptions : filterCardsOptions).find(
              option => option.value === filterCards
            )}
            options={checkPacks ? filterPacksOptions : filterCardsOptions}
            name={'filter'}
            handleChangeSelect={e => setFilterCards(e.value)}
          />
        </div>
        <div className={styles.third}>
          <input
            type="text"
            onChange={handleChange}
            onKeyUp={handleFilterOnEnter}
            value={searchValue?.heading}
            placeholder={`Enter ${checkPacks ? 'pack' : 'card'} name`}
            name={'heading'}
          />
        </div>
        <div className={styles.third}>
          <input
            type="text"
            onChange={handleChange}
            onKeyUp={handleFilterOnEnter}
            value={searchValue?.username}
            placeholder="Enter username"
            name={'username'}
          />
        </div>
        <div className={styles.third} style={{ width: 'fit-content', minWidth: '15rem' }}>
          <Selector
            selectClass={'selectGrayNoMargin'}
            placeholder={'Select Topic'}
            selectedOption={topics && topics?.length > 0 && topics?.find(option => option?.value === currentTopic)}
            options={topics}
            name={'topics'}
            handleChangeSelect={e => setCurrentTopic(e.value)}
          />
        </div>
        <div className={styles.third}>
          <Selector
            selectClass={'selectGrayNoMargin'}
            placeholder={'Date Range'}
            selectedOption={
              durationOptions &&
              durationOptions?.length > 0 &&
              durationOptions.find(option => option.value === currentDate)
            }
            options={durationOptions}
            name={'duration'}
            handleChangeSelect={e => setCurrentDate(e.value)}
          />
        </div>
      </div>
      <div className={styles.cardsDiv} id="cardsDiv">
        <div className={styles.savedOrDeletedCards}>
          <div className={styles.cards}>
            {items?.rows?.length > 0 &&
              items.rows.map((one, i) => {
                if (one.type === 'card')
                  return (
                    <CardFlip
                      overrideOnFlip={() => openPreviewModal(one)}
                      key={i}
                      data={one}
                      flippable={false}
                      hasAdminEdit={
                        one?.userRole !== 'admin' &&
                        !(filterCards && (filterCards === 'myitems'))
                      }
                      editable={filterCards === 'myitems'}
                      filterDeletedCards
                      onSelectionClick={onSelectionClick}
                      selectable={selectionMode}
                      isCardSelected={isCardSelected}
                    />
                  )
                if (one.type === 'pack')
                  return (
                    <PackFlip
                      onClick={() => dispatch(openPackModal({ packId: one?._id }))}
                      pack={one}
                      onEdit={filterCards === 'myitems' ? () => onPackEdit(one) : null}
                      editable={false}
                      hasAdminEdit={
                        one?.userRole !== 'admin' &&
                        !(filterCards && (filterCards === 'myitems'))
                      }
                      deleteable={false}
                      restorable={false}
                      openable={true}
                    />
                  )
              })}
          </div>
        </div>
        {items?.rows?.length === 0 && (
          <Typography
            variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
            text={`There are no ${checkPacks ? 'packs' : 'cards'} for choosen filters!`}
            className="marginY10"
          />
        )}
      </div>
      {items?.total > 0 && (
        <Pagination total={items?.total} onPageChange={page => setPageNumber(page)} pageSize={pageSize} />
      )}
    </div>
  )
}

export default ContentModeration
