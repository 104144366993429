import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardScrollableContent from 'components/card/components/scrollableContent'
import CardWrapper from 'components/card/components/wrapper'
import Input from 'components/input'

import dataService from 'services/data.service'
// import { dataActions } from 'store/actions/data.actions'
import { getFormattedDate } from 'utils/helpers'

import heart from '../../../../assets/icons/black-heart.svg'
import eye from '../../../../assets/icons/eye.svg'
import save from '../../../../assets/icons/save-black.svg'
import share from '../../../../assets/icons/share-black.svg'
import styles from './comments.module.scss'

import Comment from './comment'
import Thread from './thread'
import { openModal } from 'store/reducers/modal/modalSlice'
import Login from 'pages/auth/login'

const CardComments = ({ card, className }) => {
  const [comments, setComments] = useState([])
  const [commentInput, setCommentInput] = useState('')
  const [threadOpened, setThreadOpened] = useState(null)

  const scrollableContent = useRef(null)

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state?.data?.data)

  useEffect(() => {
    if (data?.comment?.[card?._id]) {
      const reversedComments = cloneDeep(data.comment[card._id])
      setComments(reversedComments.reverse())
    } else {
      setComments([])
    }
  }, [data?.comment?.[card?._id]])

  const onAddCommentHandler = async event => {
    if (!user) {
      dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
      return
    }
    if (event?.charCode === 13) {
      const commentWithoutSpaces = commentInput?.trim()
      if (commentWithoutSpaces !== '') {
        const data = {
          cardId: card?._id,
          authorId: card?.userId,
          authorUsername: card?.username,
          userId: user?._id,
          username: user?.username,
          fullname: user?.name,
          userRole: user?.role,
          avatar: user?.avatar,
          content: commentInput
        }
        let res = null
        if (threadOpened) {
          res = await dataService.run('data', 'comment', 'push-to-array', {
            _id: threadOpened._id,
            commentAuthorId: threadOpened?.userId,
            commentAuthorUsername: threadOpened?.username,
            pushTo: 'thread',
            ...data
          })
        } else {
          res = await dataService.run('data', 'comment', 'new', data)
        }
        if (res) {
          // dispatch(dataActions.unshiftToArrayByType(res, card._id))
          setCommentInput('')
          scrollableContent.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
        }
      }
    }
  }

  const onCommentChangeHandler = event => {
    setCommentInput(event?.target?.value)
  }

  return (
    <CardWrapper className={`${styles.globalWrapper} ${className}`}>
      <Card className={styles.CardWrapper}>
        <CardBody className={styles.bodyComment}>
          <div className={styles.contentWrapper}>
            <div className={styles.informations}>
              {card?.createdAt && (
                <span className={styles.publishDate}>Published {getFormattedDate(card.createdAt)}</span>
              )}
              <div className={styles.icons}>
                <div className={styles.iconContainer}>
                  <img className={styles.viewIcon} src={eye} alt="" />
                  <span>{card?.views ?? 0}</span>
                </div>
                <div className={styles.iconContainer}>
                  <img className={styles.likeIcon} src={heart} alt="" />
                  <span>{card?.likes ?? 0}</span>
                </div>
                <div className={styles.iconContainer}>
                  <img className={styles.saveIcon} src={save} alt="" />
                  <span>{card?.saves ?? 0}</span>
                </div>
                <div className={styles.iconContainer}>
                  <img className={styles.shareIcon} src={share} alt="" />
                  <span>{card?.shares ?? 0}</span>
                </div>
              </div>
            </div>
            <div className={styles.comments}>
              {threadOpened && (
                <span className={styles.backArrow} onClick={() => setThreadOpened(null)}>
                  ←
                </span>
              )}
              <span className={styles.commentsTitle}>{threadOpened ? 'Thread' : 'Comments'}</span>
              <CardScrollableContent elemRef={scrollableContent} className={styles.scrollableContent}>
                {!threadOpened ? (
                  comments?.map(comment => (
                    <Comment
                      comment={comment}
                      key={comment._id}
                      threadOpened={threadOpened?._id}
                      setThreadOpened={setThreadOpened}
                    />
                  ))
                ) : (
                  <Thread
                    comment={comments?.filter(comment => comment._id === threadOpened?._id)}
                    open={threadOpened?._id}
                    setThreadOpened={setThreadOpened}
                  />
                )}
              </CardScrollableContent>
            </div>
          </div>
          <div className={styles.commentsFooter}>
            <Input
              placeholderOrentation="placeholderStyle"
              placeholder={`Add ${threadOpened ? 'Reply' : 'Comment'}`}
              className={styles.inputComment}
              onKeyPress={onAddCommentHandler}
              onChange={onCommentChangeHandler}
              value={commentInput}
            />
            <span className={styles.reportCard}>Report Card!</span>
          </div>
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CardComments
