import Button from 'components/button'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal } from 'store/reducers/modal/modalSlice'
import styles from './okModal.module.scss'

const OkModal = ({ className, message = 'This is info modal!', onClose }) => {
  const dispatch = useDispatch()
  const onCloseHandle = () => {
    if (onClose) {
      onClose()
    } else {
      dispatch(closeModal())
    }
  }

  useEffect(() => {
    document.addEventListener('click', onCloseHandle)
    return () => {
      document.removeEventListener('click', onCloseHandle)
    }
  }, [])
  return (
    <div className={`${styles.confirmationModal} ${className}`}>
      <h3>{message}</h3>
      <div>
        <Button label="Ok" variation="primary" size="sm" onClick={onCloseHandle} />
      </div>
    </div>
  )
}
export default OkModal
