import { createAsyncThunk } from '@reduxjs/toolkit'
import scrapeService from 'services/scrape.service'

export const scrapePage = createAsyncThunk('scrape/scrapePage', async params => {
  return await scrapeService.scrape(params)
})

export const scrapePageTest = createAsyncThunk('scrape/scrapePageTest', async params => {
  return await scrapeService.scrapeTest(params)
})
