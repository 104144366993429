import moment from 'moment'
import { useEffect, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useSelector } from 'react-redux'
import { toastService } from 'services/toastService'
import { openModal } from 'store/reducers/modal/modalSlice'
import store from 'store/store'
import isURL from 'validator/lib/isURL'
import './quillNewsletter.scss'
import LinkModal from './linkModal'

class VideoURLValidator extends Quill.imports['formats/video'] {
  static create(value) {
    if (isURL(value)) {
      return super.create(value)
    } else {
      toastService('error', 'Bad URL.')
      return null
    }
  }
}

Quill.register({
  'formats/video': VideoURLValidator
})

const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'link', 'blockquote', 'code-block', 'strike'],
      [
        { color: [] },
        { background: [] },
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { direction: 'rtl' },
        { align: [] }
      ],
      ['image']
    ],
    handlers: {
      link: function (value) {
        const {
          savedRange: { index, length },
          root
        } = this.quill.selection
        const selText = root.innerText.slice(index, index + length)

        if (value) {
          this.quill.format('link', selText)
          store.dispatch(
            openModal({
              content: <LinkModal quill={this.quill} selected={selText} />,
              show: true,
              name: 'Quill Modal',
              size: 'max700'
            })
          )
        }
      }
    }
  },
  clipboard: {
    matchVisual: false
  }
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'link',
  'video',
  'ordered',
  'bullet',
  'strike',
  'blockquote',
  'code-block',
  'image',
  'color',
  'background',
  'list',
  'align',
  'direction',
  'indent'
]
const QuilFiledForNewsletter = ({ htmlValue = '', onChange, onBlur, onTypingEnd, triggerAfterMS = 500 }) => {
  const [value, setValue] = useState(htmlValue)
  const [timer, setTimer] = useState()
  const { newsletterTimestamp = 0, sendedNewsletter = false } = useSelector(state => state.auth)
  const onBlurHandler = () => {
    onBlur && onBlur(value)
  }
  const onChangeHandler = content => {
    setValue(content)
    onChange && onChange(content)

    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(setTimeout(() => onTimerSubmit(content), triggerAfterMS))
  }

  const onTimerSubmit = value => {
    onTypingEnd && onTypingEnd(value)
    setTimer(null)
  }

  useEffect(() => {
    if (sendedNewsletter && newsletterTimestamp + 10000 > moment().valueOf()) {
      setValue('')
    }
  }, [newsletterTimestamp])

  return (
    <div>
      <div className="quilFieldForNewsletter">
        <ReactQuill
          theme="snow"
          value={value}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          modules={modules}
          formats={formats}
        />
      </div>
    </div>
  )
}

export default QuilFiledForNewsletter
