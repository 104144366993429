import React, { useEffect, useState } from 'react'
import RegisterCard from 'components/registerCard/registerCard'
import styles from './changeAccount.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { sendCancelSub, sendConfirmChangeAccount } from 'store/reducers/auth/thunk'
import { toastService } from 'services/toastService'
import PrimetimeAdvertiserText from 'components/registerText/primetimeAdvertiser'
import WonderPlusText from 'components/registerText/wonderPlus'
import MediaText from 'components/registerText/media'
import Heading from 'components/Heading'
import { USER_TYPE_VALUE } from 'staticConstants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Button from 'components/button'
import moment from 'moment/moment'

const ChangeAccount = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector(state => state.auth.user)
  const { userTypeLabels } = useSelector(state => state.config)
  const [cardsFillingShow, setCardsFillingShow] = useState([])

  const cardsFilling = [
    {
      subtype: USER_TYPE_VALUE.premiumAdvertiser,
      title: userTypeLabels?.[USER_TYPE_VALUE.premiumAdvertiser],
      price: 1000,
      content: <PrimetimeAdvertiserText />,
      cardType: 'registrationCard'
    },
    {
      subtype: USER_TYPE_VALUE.businessSubscriber,
      title: userTypeLabels?.[USER_TYPE_VALUE.businessSubscriber],
      price: 20,
      content: <WonderPlusText />,
      cardType: 'registrationCard'
    },
    {
      subtype: USER_TYPE_VALUE.media,
      title: userTypeLabels?.[USER_TYPE_VALUE.media],
      price: 100,
      content: <MediaText />,
      cardType: 'registrationCard'
    }
  ]

  const sendConfirmationEmail = accountType => {
    const newObj = {
      email: user.email,
      type: accountType.subtype
    }
    dispatch(sendConfirmChangeAccount(newObj))
    toastService('success', 'Successfully sent email.')
  }

  const sendCancelSubEmail = async () => {
    const newObj = {
      email: user.email
    }
    const res = await dispatch(sendCancelSub(newObj))
    if (res?.payload?.success) {
      toastService('success', 'Successfully sent email.')
    }
  }

  useEffect(() => {
    if (cardsFilling && cardsFilling.length > 0) {
      const newCardsFilling = []
      cardsFilling.map(one => {
        if (one.subtype !== Number(user.subType)) {
          newCardsFilling.push(one)
        }
      })
      setCardsFillingShow(newCardsFilling)
    }
    if (user && user.role === 'admin') {
      history.push('/home')
    }
  }, [user?._id])

  return (
    <div className={styles.wrapper}>
      <Heading title="Change Account" />
      <div className={styles.typesContainer}>
        {cardsFillingShow &&
          cardsFillingShow.length > 0 &&
          cardsFillingShow.map((one, i) => {
            return (
              <RegisterCard
                buttonLabel={'Select Account'}
                key={i}
                cardTitle={one.title}
                cardContent={one.content}
                cardPrice={one.price}
                isChangeAccount={true}
                onClickNext={() => sendConfirmationEmail(one)}
              />
            )
          })}
      </div>
      <div className={styles.buttonContainerCenter}>
        <Button
          label="Cancel subscription"
          variation="primary"
          onClick={sendCancelSubEmail}
          checkBussines={false}
          disabled={user?.membership === null && (!user.subscriptionEnds || user.subscriptionEnds > Date.now())}
        />
        {!user.membership && user.subscriptionEnds && user.subscriptionEnds > Date.now() && (
          <h3>Subscription valid till {moment(user.subscriptionEnds).format('DD/MM/YYYY')}</h3>
        )}
      </div>
    </div>
  )
}

export default ChangeAccount
