import { reorder } from 'components/dndFields'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styles from './dndGrid.module.scss'
import IconClose from 'assets/icons/closeCircle.png'
import Item from './item'

const DndGrid = ({ data = [], onRemoveFromSelection, onChange, itemSize }) => {
  const [items, setItems] = useState([...data])
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const temp = reorder(items, result.source.index, result.destination.index)
    setItems(temp)
    onChange && onChange(temp)
  }
  useEffect(() => {
    if (data) setItems(data)
  }, [data])

  return (
    <div className={styles.wrapper}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" className={styles.droppable} direction="horizontal">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item._id + index} draggableId={`${item.type}_${index}`} index={index}>
                  {provided => (
                    <div className={styles.flipContainer} {...provided.dragHandleProps} ref={provided.innerRef}>
                      <Item
                        key={`${item._id}_${index}`}
                        item={item}
                        index={index}
                        {...provided.draggableProps}
                        itemSize={itemSize}
                      />
                      {items.length > 1 && (
                        <>
                          <img
                            className={`${styles.bottomBtn} ${styles.left} ${itemSize && styles.smallCardsLeft}`}
                            alt="CloseIcon"
                            src={IconClose}
                            onClick={() => onRemoveFromSelection(item)}
                          />
                          <i
                            className={`${styles.bottomBtn} ${styles.right} ${styles.faIcon} ${
                              itemSize && styles.smallCardsRight
                            } fa fa-arrows`}
                            aria-hidden="true"
                          ></i>
                        </>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default DndGrid
