import Button from 'components/button'
import Input from 'components/input'
import MediaPicker from 'components/mediaPicker'
import React, { useState } from 'react'
import fileService from 'services/file.service'
import { toastService } from 'services/toastService'
import { converFileToBase64 } from 'utils/getBaseFromFile'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import styles from './imageUploaderToAWS.module.scss'

const ImageUploaderToAWS = ({ showInput = true, textInput = '', inputWidth = 0, saveOnChange = false }) => {
  const [fileName, setFileName] = useState(textInput)
  const [fileImage, setFileImage] = useState(textInput)
  const [imageReload, setImageReload] = useState(new Date().getTime())

  useState(() => {
    if (textInput !== '') {
      setFileName(textInput)
      setFileImage(textInput)
    }
  }, [textInput])
  const onSaveClick = sentFile => {
    let fileLocal
    if (saveOnChange && sentFile) {
      fileLocal = sentFile
    } else {
      fileLocal = fileImage
    }

    if (fileLocal !== '' && fileLocal) {
      converFileToBase64(fileLocal).then(base64 => {
        fileService
          .run('s3', 'upload', { data: base64, name: fileLocal.name }, null, fileName)
          .then(() => {
            toastService('info', 'Image uploaded.')
          })
          .catch(() => {
            toastService('error', 'Error.')
          })
      })
    }
  }

  const onChangeHandler = newImage => {
    setImageReload(null)
    setFileImage(newImage)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.formWrapper}>
        {showInput && (
          <Input
            value={fileName}
            onChange={e => setFileName(e.target.value)}
            className="cardInput"
            label="File Name"
            placeholder="Enter file name..."
          />
        )}
        <MediaPicker
          stateMedia={getMediaFromFileOrString(fileImage) + (imageReload ? '?' + imageReload : '')}
          onImageChange={onChangeHandler}
          onSaveClick={onSaveClick}
          saveOnChange={saveOnChange}
          removable={false}
          style={inputWidth && inputWidth > 0 ? { width: `${inputWidth}rem`, border: 0 } : { border: 0 }}
        />
        {!saveOnChange && (
          <Button
            onClick={onSaveClick}
            disabled={Boolean(fileImage === '' || !fileImage || fileImage === textInput)}
            label="Save"
          />
        )}
      </div>
    </div>
  )
}
export default ImageUploaderToAWS
