import businessCard from 'assets/images/businessCard.png'
import userImg from 'assets/images/shuffleAvatar.png'
// import instacardCard from 'assets/images/instacardCard.png'
import anchorCard from 'assets/images/anchor.png'
import blankCard from 'assets/images/blankCard.png'
import collectibleCard from 'assets/images/collectibleCard.png'
import doubleCard from 'assets/images/doubleCard.png'
import eventCard from 'assets/images/event.svg'
import marketplaceCard from 'assets/images/marketplaceCard.png'
import oneSideTrivia from 'assets/images/oneSideTrivia.png'
import pollCard from 'assets/images/poll.png'
import slideCard from 'assets/images/slideCard.png'
import sponsoredCard from 'assets/images/sponsoredCard.png'
import triviaCard from 'assets/images/triviaCard.png'
import { awsUrl } from 'environment'

export const CARD_TYPES = {
  Sponsored: 'sponsored',
  Event: 'event',
  Poll: 'poll',
  Anchor: 'anchor',
  Marketplace: 'marketplace',
  OneSidedTrivia: 'oneSidedTrivia',
  DoubleSidedTrivia: 'doubleSidedTrivia',
  Mystery: 'mystery',
  Collectible: 'collectible',
  Blank: 'blank',
  Instacard: 'instacard',
  Double: 'double',
  Slide: 'slide',
  Standard: 'standard',
  Create: 'create',
  Share: 'share',
  First: 'first',
  Comment: 'comment',
  partnership: 'partnership',
  Register: 'registrationCard'
}
export const ITEMS_PER_PAGE = 35
const misteryCard = awsUrl + '/mystery_card_slider'
export const CARD_TYPES_OPTIONS = {
  // types by role
  // curently when register user in db we have role:'user'
  // TO DO change this to appropriate users
  user: [
    { value: 'standard', label: 'Article Card' },
    { value: 'double', label: 'Double Card' },
    { value: 'slide', label: 'Slide Card' },
    { value: 'sponsored', label: 'Sponsored Content Card' },
    { value: 'collectible', label: 'Collectible Card' },
    { value: 'mystery', label: 'Mystery Card' },
    { value: 'oneSidedTrivia', label: 'Trivia Card (1 Slided)' },
    { value: 'doubleSidedTrivia', label: 'Trivia Card (2 Slided)' },
    { value: 'marketplace', label: 'Marketplace Card' },
    { value: 'event', label: 'Event Card' },
    { value: 'anchor', label: 'Anchor Card' },
    { value: 'poll', label: 'Poll' },
    { value: 'first', label: 'First Card' }
  ],
  admin: [
    { value: 'standard', label: 'Article Card' },
    { value: 'double', label: 'Double Card' },
    { value: 'slide', label: 'Slide Card' },
    { value: 'sponsored', label: 'Sponsored Content Card' },
    { value: 'collectible', label: 'Collectible Card' },
    { value: 'mystery', label: 'Mystery Card' },
    { value: 'oneSidedTrivia', label: 'Trivia Card (1 Slided)' },
    { value: 'doubleSidedTrivia', label: 'Trivia Card (2 Slided)' },
    { value: 'marketplace', label: 'Marketplace Card' },
    { value: 'event', label: 'Event Card' },
    { value: 'anchor', label: 'Anchor Card' },
    { value: 'poll', label: 'Poll' },
    { value: 'first', label: 'First Card' }
  ],
  contributor: [
    { value: 'article', label: 'Article Card' },
    { value: 'double', label: 'Double Card' },
    { value: 'slide', label: 'Slide Card' },
    { value: 'sponsored', label: 'Sponsored Content Card' },
    { value: 'collectible', label: 'Collectible Card' },
    { value: 'mystery', label: 'Mystery Card' },
    { value: 'oneSidedTrivia', label: 'Trivia Card (1 Slided)' },
    { value: 'doubleSidedTrivia', label: 'Trivia Card (2 Slided)' },
    { value: 'market', label: 'Marketplace Card' },
    { value: 'event', label: 'Event Card' },
    { value: 'anchor', label: 'Anchor Card' },
    { value: 'poll', label: 'Poll' },
    { value: 'first', label: 'First Card' }
  ],
  individual: [{ value: 'article', label: 'Article Card' }],
  premiumUser: [{ value: 'article', label: 'Article Card' }],
  media: [{ value: 'article', label: 'Article Card' }],
  subscribers: [{ value: 'article', label: 'Article Card' }]
}
export const CARD_SIZE = {
  ExtraSmall: 'extraSmall',
  Normal: 'normal',
  Large: 'large'
}

export const CONTACT_US_OPTIONS = [
  { value: 'serviceWithaSmile', label: 'Service With a Smile' },
  { value: 'techIssues', label: 'Tech Issues' },
  { value: 'privacyLegal', label: 'Privacy & Legal' }
]

export const DELETE_ACCOUNT_OPTIONS = [
  { value: 'iHaveaConcern', label: 'I Have a Concern' },
  { value: 'IamNoLongerInterested', label: 'I am No Longer Interested' },
  { value: 'itsPersonal', label: 'It is Personal' },
  { value: 'AllOfTheAbove', label: 'All of the Above' },
  { value: 'NoneOfTheAbove', label: 'None of the Above' }
]
export const CUSTOMER_CATEGORY_OPTIONS = [
  { value: 'culture', label: 'Culture' },
  { value: 'travel', label: 'Travel' },
  { value: 'style', label: 'Style' }
]
export const CUSTOMER_OPTIONS = [
  { value: 'forWomen', label: 'For Women' },
  { value: 'forMen', label: 'For Men' },
  { value: 'forAll', label: 'For All' }
]
export const LOCATION_OPTIONS = [
  { value: 'hongKong', label: 'Hong Kong' },
  { value: 'london', label: 'London' },
  { value: 'losAngeles', label: 'Los Angeles' },
  { value: 'paris', label: 'Paris' },
  { value: 'newYork', label: 'New York' },
  { value: 'singapore', label: 'Singapore' },
  { value: 'online', label: 'Online' },
  { value: 'other', label: 'Other' }
]
export const ANCHOR_LOCATION_OPTIONS = [
  { value: 'hongKong', label: 'Hong Kong' },
  { value: 'london', label: 'London' },
  { value: 'losAngeles', label: 'Los Angeles' },
  { value: 'newYork', label: 'New York' },
  { value: 'paris', label: 'Paris' },
  { value: 'singapore', label: 'Singapore' },
  { value: 'other', label: 'Other' }
]

export const USER_LEVEL_OPTIONS = [
  { value: 0, label: 'All Levels' },
  { value: 1, label: 'Pink & Above' },
  { value: 2, label: 'Silver & Above' },
  { value: 3, label: 'Gold & Above' },
  { value: 4, label: 'Platinum & Above' },
  { value: 5, label: 'Diamond' }
]

export const PRICE_OPTIONS = [
  { value: '0_50', label: 'USD0 - USD50' },
  { value: '51_100', label: 'USD51 - USD100' },
  { value: '101_250', label: 'USD101 - USD250' },
  { value: '251_500', label: 'USD251 - USD500' },
  { value: 'above', label: 'Above' }
]

export const CARD_BACK_TYPES = {
  heading: 'heading',
  image: 'image',
  video: 'video',
  text: 'text',
  carousel: 'carousel',
  calendar: 'calendar',
  map: 'map',
  inlinePack: 'inlinePack',
  fullCardImage: 'fullCardImage',
  linkedImage: 'linkedImage'
}
export const FORM_SIDE = {
  Front: 'front',
  Back: 'back'
}
export const COLLECTION_OPTIONS = [{ value: 'TODO', label: 'todo' }]
export const FREQUENCY_IN_FEEDS_OPTIONS = [
  { value: 'standard', label: 'Standard' },
  { value: 'rare', label: 'Rare' },
  { value: 'superRare', label: 'Super Rare' }
]
export const POINTS_ALLOCATION_OPTIONS = [
  { value: '0', label: 'No Points' },
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' }
]

export const TIME_OPTIONS = [
  { value: '12:00 AM', label: '12:00 AM' },
  { value: '12:30 AM', label: '12:30 AM' },
  { value: '1:00 AM', label: '1:00 AM' },
  { value: '1:30 AM', label: '1:30 AM' },
  { value: '2:00 AM', label: '2:00 AM' },
  { value: '2:30 AM', label: '2:30 AM' },
  { value: '3:00 AM', label: '3:00 AM' },
  { value: '3:30 AM', label: '3:30 AM' },
  { value: '4:00 AM', label: '4:00 AM' },
  { value: '4:30 AM', label: '4:30 AM' },
  { value: '5:00 AM', label: '5:00 AM' },
  { value: '5:30 AM', label: '5:30 AM' },
  { value: '6:00 AM', label: '6:00 AM' },
  { value: '6:30 AM', label: '6:30 AM' },
  { value: '7:00 AM', label: '7:00 AM' },
  { value: '7:30 AM', label: '7:30 AM' },
  { value: '8:00 AM', label: '8:00 AM' },
  { value: '8:30 AM', label: '8:30 AM' },
  { value: '9:00 AM', label: '9:00 AM' },
  { value: '9:30 AM', label: '9:30 AM' },
  { value: '10:00 AM', label: '10:00 AM' },
  { value: '10:30 AM', label: '10:30 AM' },
  { value: '11:00 AM', label: '11:00 AM' },
  { value: '11:30 AM', label: '11:30 AM' },
  { value: '12:00 PM', label: '12:00 PM' },
  { value: '12:30 PM', label: '12:30 PM' },
  { value: '1:00 PM', label: '1:00 PM' },
  { value: '1:30 PM', label: '1:30 PM' },
  { value: '2:00 PM', label: '2:00 PM' },
  { value: '2:30 PM', label: '2:30 PM' },
  { value: '3:00 PM', label: '3:00 PM' },
  { value: '3:30 PM', label: '3:30 PM' },
  { value: '4:00 PM', label: '4:00 PM' },
  { value: '4:30 PM', label: '4:30 PM' },
  { value: '5:00 PM', label: '5:00 PM' },
  { value: '5:30 PM', label: '5:30 PM' },
  { value: '6:00 PM', label: '6:00 PM' },
  { value: '6:30 PM', label: '6:30 PM' },
  { value: '7:00 PM', label: '7:00 PM' },
  { value: '7:30 PM', label: '7:30 PM' },
  { value: '8:00 PM', label: '8:00 PM' },
  { value: '8:30 PM', label: '8:30 PM' },
  { value: '9:00 PM', label: '9:00 PM' },
  { value: '9:30 PM', label: '9:30 PM' },
  { value: '10:00 PM', label: '10:00 PM' },
  { value: '10:30 PM', label: '10:30 PM' },
  { value: '11:00 PM', label: '11:00 PM' },
  { value: '11:30 PM', label: '11:30 PM' }
]

export const dummyUsers = [
  {
    image: userImg,
    name: 'Mark'
  },
  {
    image: userImg,
    name: 'James'
  },
  {
    image: userImg,
    name: 'Phil'
  },
  {
    image: userImg,
    name: 'Mike'
  },
  {
    image: userImg,
    name: 'Mark'
  },
  {
    image: userImg,
    name: 'James'
  },
  {
    image: userImg,
    name: 'Phil'
  },
  {
    image: userImg,
    name: 'Mike'
  }
]

export const dummyTopicUsers = ['ISIS', 'MAGA', '4Chan', 'TikTok', 'UKIP', 'EDL']
export const pickerColors = [
  '#bfbfc1',
  '#d20000',
  '#dd0058',
  '#343ec8',
  '#00aaca',
  '#00d690',
  '#5eda29',
  '#cdbb01',
  '#db5700'
]

export const FRONT_BACK_PREVIEW = [
  { value: 1, title: 'Front' },
  { value: 2, title: 'Back' },
  { value: 3, title: 'Preview' }
]

export const FRONT_PREVIEW = [
  { value: 1, title: 'Front' },
  { value: 3, title: 'Preview' }
]
export const SCRAPE_PREVIEW = [
  { value: 1, title: 'Instacard!' },
  { value: 3, title: 'Preview' }
]

export const USER_TYPE_VALUE = {
  individual: 1,
  businessSubscriber: 2,
  media: 3,
  premiumAdvertiser: 4,
  admin: 5,
  contributor: 6,
  editor: 7
}

export const CONTRIBUTOR_AVAILABLE_ADMIN_FEATURES = ['/admin/moderation']
export const ADMIN_FEATURES_ROUTE_VALUES = {
  applicants: 0,
  contentModeration: 1,
  users: 2,
  trending: 3,
  emptySearches: 4,
  contactUs: 5,
  mysteryCardImage: 6,
  manageCategory: 7,
  anchorCards: 8,
  welcomePack: 9,
  newsletter: 10
}
export const ADMIN_FEATURES_ROUTE_LABELS = {
  applicants: 'Applicants',
  contentModeration: 'Content Moderation',
  users: 'Users',
  trending: 'Trending',
  emptySearches: 'Empty Searches',
  contactUs: 'Contact Us',
  mysteryCardImage: 'Mystery Card Image',
  manageCategory: 'Manage Category',
  anchorCards: 'Anchor Cards',
  welcomePack: 'Welcome Pack',
  newsletter: 'Newsletter'
}

export const ADMIN_FEATURES_OPTIONS = [
  { value: ADMIN_FEATURES_ROUTE_VALUES.applicants, label: ADMIN_FEATURES_ROUTE_LABELS.applicants },
  { value: ADMIN_FEATURES_ROUTE_VALUES.contentModeration, label: ADMIN_FEATURES_ROUTE_LABELS.contentModeration },
  { value: ADMIN_FEATURES_ROUTE_VALUES.users, label: ADMIN_FEATURES_ROUTE_LABELS.users },
  { value: ADMIN_FEATURES_ROUTE_VALUES.trending, label: ADMIN_FEATURES_ROUTE_LABELS.trending },
  { value: ADMIN_FEATURES_ROUTE_VALUES.emptySearches, label: ADMIN_FEATURES_ROUTE_LABELS.emptySearches },
  { value: ADMIN_FEATURES_ROUTE_VALUES.contactUs, label: ADMIN_FEATURES_ROUTE_LABELS.contactUs },
  { value: ADMIN_FEATURES_ROUTE_VALUES.mysteryCardImage, label: ADMIN_FEATURES_ROUTE_LABELS.mysteryCardImage },
  { value: ADMIN_FEATURES_ROUTE_VALUES.manageCategory, label: ADMIN_FEATURES_ROUTE_LABELS.manageCategory },
  { value: ADMIN_FEATURES_ROUTE_VALUES.anchorCards, label: ADMIN_FEATURES_ROUTE_LABELS.anchorCards },
  { value: ADMIN_FEATURES_ROUTE_VALUES.welcomePack, label: ADMIN_FEATURES_ROUTE_LABELS.welcomePack },
  { value: ADMIN_FEATURES_ROUTE_VALUES.newsletter, label: ADMIN_FEATURES_ROUTE_LABELS.newsletter }
]

export const CARD_TYPE_SELECTION_DATA = [
  {
    content: 'Perfect for articles, reviews and opinion pieces',
    label: 'My Card ',
    type: 'standard',
    index: 0,
    picture: businessCard,
    width: '23.75rem',
    height: '22.125rem'
  },
  {
    content: 'Perfect for comic strips, photo essays',
    type: 'slide',
    label: 'My Slide Card',
    index: 1,
    picture: slideCard,
    width: '23.75rem',
    height: '22.125rem'
  },
  {
    content: 'Perfect for photographs, art',
    type: 'double',
    label: 'My Double Card',
    index: 2,
    picture: doubleCard,
    width: '38.813rem',
    height: '22.313rem'
  },
  {
    content: 'Perfect for eye-catching images',
    type: 'blank',
    label: 'My Blank Card',
    index: 4,
    picture: blankCard,
    width: '23.75rem',
    height: '22.125rem'
  },
  {
    content: 'Perfect for surprises',
    type: 'mystery',
    label: 'Mystery Card',
    index: 5,
    picture: misteryCard,
    width: '23.75rem',
    height: '22.125rem'
  },
  {
    content: 'For limited edition, special cards',
    type: 'collectible',
    label: 'Collectible Card',
    index: 6,
    picture: collectibleCard,
    width: '23.75rem',
    height: '22.125rem'
  },
  {
    content: 'For multiple question quizzes or tests',
    type: 'doubleSidedTrivia',
    label: 'Double-Sided Trivia Card',
    index: 7,
    picture: triviaCard,
    width: '23.75rem',
    height: '22.125rem'
  },
  {
    content: 'For one quick question',
    type: 'oneSidedTrivia',
    label: 'One-Sided Trivia Card',
    index: 8,
    picture: oneSideTrivia,
    width: '14.438rem',

    height: '22.125rem'
  },
  {
    content: 'For Marketplace listings',
    type: 'marketplace',
    label: 'Marketplace Card',
    index: 9,
    picture: marketplaceCard,
    width: '23.75rem',
    height: '22.125rem'
  },
  {
    content: 'Create links at the bottom of the pages',
    type: 'anchor',
    label: 'Anchor Card',
    index: 10,
    picture: anchorCard,
    width: '14.438rem',
    height: '22.125rem'
  },
  {
    content: 'Take quick polls and gather opinions',
    type: 'poll',
    label: 'Poll Card',
    index: 11,
    picture: pollCard,
    height: '22.125rem',
    width: '23.75rem'
  },
  {
    content: 'Create an event and add a location and date',
    type: 'event',
    label: 'Event Card',
    index: 12,
    picture: eventCard,
    width: '14.438rem',
    height: '22.125rem'
  },
  {
    content: 'Article card for sponsored content',
    type: 'sponsored',
    label: 'Sponsored Card',
    index: 13,
    picture: sponsoredCard,
    width: '23.75rem',
    height: '22.125rem'
  }
]

export const AGE_OPTIONS = [
  { value: '18-24', label: '18-24' },
  { value: '25-34', label: '25-34' },
  { value: '35-49', label: '35-49' },
  { value: '50-65', label: '50-65' },
  { value: '65+', label: '65+' }
]

export const ADMIN_EMAIL = 'letsplay@wondershuffle.com'

export const CARD_CREATE_CATEGORY_OPTIONS = [
  { value: 'news', label: 'News' },
  {
    value: 'culture',
    label: 'Culture'
  },
  {
    value: 'travel',
    label: 'Travel'
  },
  {
    value: 'style',
    label: 'Style'
  },
  {
    value: 'play',
    label: 'Play'
  }
]
