import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const EventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setNewMessage: (state, action) => {
      state.data = action.payload
    },
    clear: state => {
      delete state.data
    }
  }
})

export default EventSlice.reducer
export const { setNewMessage, clear } = EventSlice.actions
