import Card from 'components/card'
import CardBody from 'components/card/components/body'
import React from 'react'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import styles from './cardMysteryFront.module.scss'
import mysteryImage from 'assets/images/mystery.svg'
import CardWrapper from 'components/card/components/wrapper'

const CardMysteryFront = ({ className, size = CARD_SIZE.Normal, card }) => {
  return (
    <CardWrapper>
      <Card className={`${styles.wrapper} ${styles[className]} ${className}`} size={size} type={CARD_TYPES.Mystery}>
        <CardBody
          size={size}
          style={{ backgroundImage: 'url(' + mysteryImage + ')', backgroundSize: '103%' }}
          type={CARD_TYPES.Mystery}
          isMature={card?.mature}
        ></CardBody>
      </Card>
    </CardWrapper>
  )
}
export default CardMysteryFront
