import React from 'react'

import { useSelector } from 'react-redux'

import Button from 'components/button'

import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'

import styles from './linkedImage.module.scss'

const LinkedImage = ({ data, type }) => {
  const { modal } = useSelector(state => state)

  const handleClick = () => {
    if (type === 'preview' && !(modal && modal?.show && modal?.name !== 'previewModal') && data?.link) {
      window.open(data?.link, '_blank')
    }
  }

  return (
    <div className={styles.wrapper}>
      <img src={getMediaFromFileOrString(data?.image, true)} alt="img" className={styles.image} />
      <div className={styles.buttonWrapper}>
        <Button
          size="small"
          preview
          label={data?.buttonLabel}
          className={styles.button}
          onClick={() => handleClick()}
        />
      </div>
    </div>
  )
}

export default LinkedImage
