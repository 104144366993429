import Heading from 'components/Heading'
import PaymentSingle from 'components/singlePayment'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dataService from 'services/data.service'
import styles from './payments.module.scss'
import { USER_TYPE_VALUE } from 'staticConstants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Payments = () => {
  const [payments, setPayments] = useState([])
  const user = useSelector(state => state.auth.user)
  const history = useHistory()

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await dataService.run('transactions', 'transaction', 'all', {
          filter: { user_id: user._id + '', status: 'SUCCESSFUL' }
        })
        setPayments(response)
      } catch (error) {
        console.log(error)
      }
    }
    if (user?.role === 'admin' || user?.subType === USER_TYPE_VALUE.editor) {
      history.replace('/home')
    }
    fetchPayments()
  }, [user?._id])

  return (
    <div className={styles.wrapper}>
      <Heading title="Payments" />
      <div className={styles.table}>
        <div className={styles.header}>
          <div className={styles.item}>Date</div>
          <div className={styles.item}>Services</div>
          <div className={styles.item}>Status</div>
          <div className={styles.item}>Amount</div>
          <div className={styles.item}>Actions</div>
        </div>
      </div>
      <div className={styles.content}>
        {payments &&
          payments.length > 0 &&
          payments.map((payment, i) => {
            return <PaymentSingle props={payment} key={i} />
          })}
        {(!payments || payments?.length === 0) && (
          <Typography
            text="There are no payments"
            variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
            className="marginY10"
          />
        )}
      </div>
    </div>
  )
}

export default Payments
