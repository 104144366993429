import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setPreviewedCard: (state, action) => {
      state.CHAT_PREVIEWED_CARD = action.payload
    },
    closePreviewedCard: state => {
      state.CLOSE_CHAT_PREVIEWED_CARD = true
    }
  }
})

export default chatSlice.reducer
export const { setPreviewedCard, closePreviewedCard } = chatSlice.actions
