import React from 'react'
import styles from './cardWrapper.module.scss'

const CardWrapper = ({ children, className, style, onClick = null }) => {
  return (
    <div style={style} className={`${styles.wrapper} ${className} ${styles[className]}`} onClick={onClick}>
      {children}
    </div>
  )
}
export default CardWrapper
