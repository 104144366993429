import Button from 'components/button'
import Input from 'components/input'
import PhoneInput from 'components/input/phoneInput'
import Login from 'pages/auth/login'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserForRegistartion } from 'store/reducers/auth/authSlice'
import { openModal } from 'store/reducers/modal/modalSlice'
import validateEmail, { validateUsername } from 'utils/validateEmail'
import iconClose from 'assets/icons/iconCloseBlue.svg'
import styles from './apply.module.scss'
import { AGE_OPTIONS, USER_TYPE_VALUE } from 'staticConstants'
import Selector from 'components/select'
import { getUserForErrorMessage } from 'utils/helpers'

const Apply = ({ subtype = '', title = '', onClickBack = null, onclickNext = null, defaultEmail, defaultUsername }) => {
  const dispatch = useDispatch()
  const errorMsgRegister = useSelector(state => state.auth.errorMessage)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(false)
  const [alreadyExistEmail, setAlreadyExistEmail] = useState(false)
  const [alreadyExistUsername, setAlreadyExistUsername] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState()
  const [userData, setUserData] = useState({
    name: '',
    username: `${defaultUsername}`,
    email: `${defaultEmail}`,
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    phonePrefix: '',
    phoneSufix: '',
    businessName: '',
    businessWebsite: '',
    businessAddress: '',
    message: '',
    subType: subtype,
    role: 'premiumUser',
    status: 'Pending',
    ageRange: '',
    news: true
  })

  const showLogin = () => {
    dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
  }

  const handleChange = async e => {
    setError(false)
    if (e.target) {
      const { name, value, prefix = '' } = e.target
      if (name === 'phoneNumber') {
        setPhoneNumber(value)
        setUserData(userData => ({ ...userData, phoneNumber: prefix + value, phonePrefix: prefix, phoneSufix: value }))
        dispatch(
          setUserForRegistartion({ ...userData, phoneNumber: prefix + value, phonePrefix: prefix, phoneSufix: value })
        )
      } else if (name === 'username') {
        const lowercaseValue = value.toLowerCase()
        setUserData(userData => ({ ...userData, username: lowercaseValue }))
      } else {
        setUserData(userData => ({ ...userData, [name]: value }))
        dispatch(setUserForRegistartion({ ...userData, [name]: value }))
      }
    }
  }

  const validateForm = () => {
    return (
      userData.name &&
      userData.username &&
      userData.email &&
      userData.password &&
      userData.confirmPassword &&
      userData.businessName &&
      userData.businessWebsite &&
      userData.businessAddress &&
      userData.ageRange &&
      userData.phoneSufix &&
      userData.phonePrefix &&
      validateEmail(userData.email) &&
      userData.password.length >= 6 &&
      userData.confirmPassword.length >= 6 &&
      userData.password === userData.confirmPassword &&
      validateUsername(userData.username)
    )
  }

  const handleNext = () => {
    if (validateForm()) {
      onclickNext()
    } else {
      setError(true)
    }
  }

  let titleText = ''
  if (title === USER_TYPE_VALUE.businessSubscriber) {
    titleText = 'For Businesses and Blogs'
  } else if (title === USER_TYPE_VALUE.media) {
    titleText = 'For Media Outlets and Magazines'
  } else if (title === USER_TYPE_VALUE.premiumAdvertiser) {
    titleText = 'Primetime Advertising - Front and Center'
  }

  const onEyeHandler = () => {
    setShowPassword(!showPassword)
  }

  const onEyeConfHandler = () => {
    setShowConfPassword(!showConfPassword)
  }

  useEffect(() => {
    if (defaultEmail !== '' && defaultUsername !== '' && errorMsgRegister && errorMsgRegister.length > 0) {
      setError(true)
      if (errorMsgRegister.includes('email')) {
        setAlreadyExistEmail(true)
      } else {
        setAlreadyExistEmail(false)
      }
      if (errorMsgRegister.includes('username')) {
        setAlreadyExistUsername(true)
      } else {
        setAlreadyExistUsername(false)
      }
    }
  }, [errorMsgRegister])

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.clickBack}>
        <div className={styles.back} onClick={onClickBack}>
          <img src={iconClose} alt="Close icon" />
        </div>
      </div>
      <div className={styles.applyWrapper}>
        <div className={styles.title}>{titleText}</div>
        {defaultUsername && defaultUsername !== '' && defaultEmail && defaultEmail !== '' && (
          <span className={styles.info}>
            If you are upgrading your existing account, please use the same pre-populated username and email address. If
            you wish to create a new account in addition to your current account, please log out and go to Sign Up.
          </span>
        )}
        {!defaultUsername && !defaultEmail && (
          <span className={styles.info}>
            If you are upgrading your existing account, please use your existing username and email address.
          </span>
        )}
        <div className={styles.form}>
          <div className={styles.applyLeftSide}>
            <Input
              variation="formInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="Full name *"
              type="text"
              name="name"
              value={userData.name}
              onChange={handleChange}
              error={error && !userData.name}
              errorMsg={getUserForErrorMessage('name', userData)}
              errorMsgStyle={'errorMsgUp'}
            />
            <Input
              variation="formInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="Username *"
              type="text"
              name="username"
              value={userData.username}
              onChange={handleChange}
              error={error && (!userData.username || alreadyExistUsername || !validateUsername(userData.username))}
              errorMsg={getUserForErrorMessage('username', userData, false, alreadyExistUsername)}
              errorMsgStyle={'errorMsgUp'}
              disabled={defaultUsername}
            />
            <Input
              variation="formInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="Email *"
              type="text"
              name="email"
              value={userData.email}
              onChange={handleChange}
              error={error && (!userData.email || !validateEmail(userData.email) || alreadyExistEmail)}
              errorMsg={
                !userData.email
                  ? getUserForErrorMessage('email', userData)
                  : getUserForErrorMessage('badEmail', userData, alreadyExistEmail)
              }
              errorMsgStyle={'errorMsgUp'}
              disabled={defaultEmail}
            />
            <PhoneInput
              className={'phoneSelect'}
              onChange={handleChange}
              value={phoneNumber}
              name="phoneNumber"
              placeholder="Phone number *"
              defaultPrefixValue={'+1'}
              errorMsg={getUserForErrorMessage('phoneNumber')}
              error={error && (!userData.phonePrefix || !userData.phoneSufix)}
            />
            <Input
              variation="formInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="Password *"
              autoComplete="new-password"
              type={showPassword ? 'text' : 'password'}
              hasEye={true}
              onEyeClick={onEyeHandler}
              name="password"
              value={userData.password}
              onChange={handleChange}
              error={
                error &&
                (!userData.password || userData.password !== userData.confirmPassword || userData.password.length < 6)
              }
              errorMsg={getUserForErrorMessage(
                userData.password !== userData.confirmPassword ? 'differentPassword' : 'password',
                userData
              )}
              errorMsgStyle={'errorMsgUp'}
            />
            <Input
              variation="formInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="Confirm password *"
              type={showConfPassword ? 'text' : 'password'}
              hasEye={true}
              onEyeClick={onEyeConfHandler}
              name="confirmPassword"
              value={userData.confirmPassword}
              onChange={handleChange}
              error={(error && !userData.confirmPassword) || (error && userData.confirmPassword.length < 6)}
              errorMsg={getUserForErrorMessage('confirmPassword', userData)}
              errorMsgStyle={'errorMsgUp'}
            />
            <Selector
              selectClass={'selectGrayBorder-centered'}
              placeholder="Age range *"
              name="ageRange"
              selectedOption={AGE_OPTIONS.find(option => option.value === userData.ageRange)}
              handleChangeSelect={event => handleChange({ target: { name: 'ageRange', value: event.value } })}
              options={AGE_OPTIONS}
              error={error && !userData.ageRange}
              errorMsg={getUserForErrorMessage('ageRange')}
            />
          </div>
          <div className={styles.applyRightSide}>
            <Input
              variation="formInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="Business name *"
              type="text"
              name="businessName"
              value={userData.businessName}
              onChange={handleChange}
              error={error && !userData.businessName}
              errorMsg={getUserForErrorMessage('businessName', userData)}
              errorMsgStyle={'errorMsgUp'}
            />
            <Input
              variation="formInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="Business website *"
              type="text"
              name="businessWebsite"
              value={userData.businessWebsite}
              onChange={handleChange}
              error={error && !userData.businessWebsite}
              errorMsg={getUserForErrorMessage('businessWebsite', userData)}
              errorMsgStyle={'errorMsgUp'}
            />
            <Input
              variation="formInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="Business address *"
              type="text"
              name="businessAddress"
              value={userData.businessAddress}
              onChange={handleChange}
              error={error && !userData.businessAddress}
              errorMsg={getUserForErrorMessage('businessAddress', userData)}
              errorMsgStyle={'errorMsgUp'}
            />
            <textarea
              placeholder="Describe your business. By the way, if you don’t have a separate business entity, you can tell us a little more above yourself and provide social media handles in the spaces provided above. We just want to make sure we are partnering with the best of the best!"
              name="message"
              value={userData.message}
              onChange={handleChange}
            />
          </div>
        </div>
        <Button variation="secondary" label="NEXT" size="md" onClick={handleNext} />
        <div className={styles.toLogin}>
          Already have an account? <span onClick={showLogin}>Log In.</span>
        </div>
      </div>
    </div>
  )
}

export default Apply
