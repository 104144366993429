import Button from 'components/button'
import TextArea from 'components/textarea'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from 'store/reducers/yesNoModal/yesNoModalSlice'
import useWindowDimensions from 'utils/screenSize'
import styles from './yesNoModal.module.scss'

const YesNoModal = ({
  className,
  title = '',
  content = '',
  onsubmit,
  additionalInputPlaceholder,
  yesTitle = 'Yes',
  noTitle = 'No'
}) => {
  const dispatch = useDispatch()

  const [additionalInputValue, setAdditionalInputValue] = useState('')
  const { yesNoModal } = useSelector(store => store)

  const { isMobile } = useWindowDimensions()

  const onCloseHandler = () => {
    if (yesNoModal?.show) dispatch(closeModal())
  }

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={`${styles.confirmationModal} ${className}`}>
        {title && <h3>{title}</h3>}
        {content && <p>{content}</p>}
        {additionalInputPlaceholder !== '' && (
          <TextArea
            placeholder={additionalInputPlaceholder}
            textareaClass="report-input"
            value={additionalInputValue}
            onChange={event => setAdditionalInputValue(event.target.value + '')}
            maxLength={250}
          />
        )}
        <div className={styles.buttonsContainer}>
          <Button
            label={yesTitle}
            variation="primary"
            size={isMobile ? 'md' : 'sm'}
            onClick={() => onsubmit && onsubmit(additionalInputValue)}
          />
          <Button label={noTitle} variation="tertiary" size={isMobile ? 'md' : 'sm'} onClick={onCloseHandler} />
        </div>
      </div>
    </>
  )
}
export default YesNoModal
