import Button from 'components/button'
import Input from 'components/input'
import NewRequest from 'pages/verification/newRequest'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toastService } from 'services/toastService'
import { changePassword, checkTimestamp } from 'store/reducers/auth/thunk'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import styles from './changePassword.module.scss'
import { localStorageService } from 'services/localStorage.service'
import { setUser } from 'store/reducers/auth/authSlice'
import { sessionStorageService } from 'services/sessionStorage.service'

function ChangePassword({ data = {} }) {
  data = { ...data, email: JSON.parse(atob(data.email)), username: JSON.parse(atob(data.username)) }
  const [passwordData, setPasswordData] = useState({ currentPass: '', newPass: '', reEnterPass: '' })
  const [showOldPass, setShowOldPass] = useState(false)
  const [showNewPass, setShowNewPass] = useState(false)
  const history = useHistory()
  const user = localStorageService.get(`user_${data.username}`)
  const [expiredToken, setExpiredToken] = useState(false)
  const [showConfirmPass, setConfirmShowPass] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      dispatch(setUser(user))
      sessionStorageService.set('username', user.username)
    }
  }, [user])
  const submitHandler = async () => {
    try {
      if (passwordData.newPass.length >= 6 && passwordData.reEnterPass.length >= 6) {
        if (passwordData.newPass === passwordData.reEnterPass && passwordData.newPass !== '') {
          dispatch(changePassword(passwordData))
          dispatch(closeModal())
          history.push('/home')
        } else {
          toastService('error', 'Passwords do not match.')
        }
      } else {
        toastService('error', 'Password must be at least 6 characters long.')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onEyeHandler = input => {
    if (input === 'old') {
      setShowOldPass(!showOldPass)
    } else if (input === 'new') {
      setShowNewPass(!showNewPass)
    } else if (input === 'confirm') {
      setConfirmShowPass(!showConfirmPass)
    }
  }

  useEffect(() => {
    const getResponse = async () => {
      try {
        const res = await dispatch(checkTimestamp({ ...data, type: 'confirmChangePasswordToken' }))
        if (res && res.error) {
          setExpiredToken(true)
        }
      } catch (error) {
        setExpiredToken(true)
      }
    }
    getResponse()
    handleModal()
  }, [])

  useEffect(() => {
    handleModal()
  }, [expiredToken])

  const handleModal = () => {
    if (expiredToken || data?.email !== user?.email) {
      console.log('exp', expiredToken)
      dispatch(
        openModal({
          content: (
            <NewRequest
              type="Change Password"
              expiredToken={expiredToken}
              currentUser={data?.email !== user?.email}
              username={data.username}
            />
          ),
          show: true,
          size: 'maxCenter500'
        })
      )
    }
  }
  return (
    <div className={styles.wrapper}>
      <p className={styles.headerText}>Change Password</p>
      <div className={styles.inputDiv}>
        <Input
          onChange={e => setPasswordData({ ...passwordData, currentPass: e.target.value })}
          className={styles.input}
          type={showOldPass ? 'text' : 'password'}
          label={'Enter Current Password'}
          hasEye={true}
          onEyeClick={() => onEyeHandler('old')}
        />
      </div>
      <div className={styles.inputDiv}>
        <Input
          onChange={e => setPasswordData({ ...passwordData, newPass: e.target.value })}
          type={showNewPass ? 'text' : 'password'}
          className={styles.input}
          label={'Enter New Password'}
          hasEye={true}
          onEyeClick={() => onEyeHandler('new')}
        />
      </div>
      <div className={styles.inputDiv}>
        <Input
          onChange={e => setPasswordData({ ...passwordData, reEnterPass: e.target.value })}
          type={showConfirmPass ? 'text' : 'password'}
          className={styles.input}
          label={'Re-Enter New Password'}
          hasEye={true}
          onEyeClick={() => onEyeHandler('confirm')}
        />
      </div>
      <div className={styles.inputDiv}>
        <Button variation="secondary" onClick={submitHandler} className={styles.btn} label={'Change Password'} />
      </div>
    </div>
  )
}

// const ConfirmationModal = () => {
//   const dispatch = useDispatch()

//   const toHomeHandler = () => {
//     dispatch(modalActions.closeModal())
//   }

//   return (
//     <div className={styles.confirmationModal}>
//       <p className={styles.header}>Thanks!</p>
//       <div className={styles.textDiv}>
//         <p>Please check your email and follow the link to edit your account.</p>
//       </div>
//       <Button onClick={toHomeHandler} label={'Ok'} className={styles.btn} />
//     </div>
//   )
// }

export default ChangePassword
