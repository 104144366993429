import CardFlip from 'components/cardFlip'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closePreviewedCard, setPreviewedCard } from 'store/reducers/chat/chatSlice'
import { openModal } from 'store/reducers/modal/modalSlice'
import { isObjectEmpty } from 'utils/helpers'
import { openModal as openChatModal } from 'store/reducers/chatModal/chatModalSlice'

export const closeChatCardPreview = (dispatch, chat) => {
  dispatch(closePreviewedCard())
  dispatch(openChatModal({ defaultChat: chat }))
}
export const setChatCardPreview = (card, dispatch, chat) => {
  dispatch(setPreviewedCard({ card, chat }))
}
const openChatPreviewModal = (card, dispatch, chat) => {
  dispatch(
    openModal({
      content: (
        <CardFlip
          className="gridPreviewCard"
          data={card}
          saveable={false}
          key={'gridPreviewCard' + card._id}
          onFrontOuterCloseClick={() => closeChatCardPreview(dispatch, chat)}
        />
      ),
      name: 'Card preview',
      size: 'max320',
      show: true,
      onclose: closeChatCardPreview(dispatch, chat)
    })
  )
}

const ChatListener = () => {
  const dispatch = useDispatch()
  const { updatedCard } = useSelector(state => state.card)
  const { previewedCard } = useSelector(state => state.chat)

  useEffect(() => {
    if (
      !isObjectEmpty(previewedCard?.card ?? {}) &&
      !isObjectEmpty(updatedCard) &&
      updatedCard?._id === previewedCard?.card?._id
    )
      dispatch(setPreviewedCard({ card: updatedCard, chat: previewedCard.chat }))
  }, [updatedCard])

  useEffect(() => {
    if (!isObjectEmpty(previewedCard?.card ?? {}))
      openChatPreviewModal(previewedCard?.card, dispatch, previewedCard?.chat)
  }, [previewedCard?.card])

  return <></>
}
export default ChatListener
