import Button from 'components/button'
import Heading, { TABS } from 'components/Heading'
import Input from 'components/input'
import QuilFiledForNewsletter from 'components/quillFieldForNewsletter'
import SingleNewsletter from 'components/singleNewsletter'
import UserSingle from 'components/userSingle'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { sendNewsletter } from 'store/reducers/auth/thunk'
import styles from './newsletter.module.scss'
import Pagination from 'components/pagination'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'

const Newsletter = () => {
  const dispatch = useDispatch()
  const { newsletterTimestamp = 0, sendedNewsletter = false } = useSelector(state => state.auth)
  const [pageNumber, setPageNumber] = useState(1)
  const [newsletterUsers, setNewsletterUsers] = useState({ rows: [], total: 0 })
  const [emailTitle, setEmailTitle] = useState('')
  const [emailText, setEmailText] = useState('')
  const [activeTab, setActiveTab] = useState('send')
  const [newsletterHistory, setNewsletterHistory] = useState({ rows: [], total: 0 })
  const [loading, setLoading] = useState(false)

  const sendNewsLetterFunc = async () => {
    try {
      if (emailText.trim() !== '' && emailTitle.trim() !== '') {
        setLoading(true)
        const res = await dispatch(sendNewsletter({ emailText, emailTitle }))
        if (res) {
          setEmailText('')
          setEmailTitle('')
          toastService('success', 'Successfully sent newsletter.')
          setLoading(false)
        }
      } else {
        if (emailText.trim() === '') {
          toastService('error', 'Email text is required.')
        }
        if (emailTitle.trim() === '') {
          toastService('error', 'Email title is required.')
        }
      }
    } catch (error) {
      console.log('_err', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (sendedNewsletter && newsletterTimestamp + 10000 > moment().valueOf()) {
      setEmailText('')
      setEmailTitle('')
    }
  }, [newsletterTimestamp])

  useEffect(() => {
    if (activeTab === 'history') {
      const fetchNewsletterHistory = async () => {
        const res = await dataService.run('data', 'newsletterEmail', 'page', {
          pageSize: 20,
          pageNumber,
          sort: { createdAt: -1 },
          projections: { emailTitle: 1, createdAt: -1 }
        })

        if (res) setNewsletterHistory({ rows: res.rows, total: res.total })
      }
      fetchNewsletterHistory()
    } else if (activeTab === 'users') {
      const fetchUsers = async () => {
        const res = await dataService.run('profiles', 'user', 'page', {
          filter: { type: { $in: ['user', 'newsletter'] }, news: true },
          pageSize: 20,
          pageNumber,
          sort: { createdAt: -1 },
          projections: { name: 1, email: 1 },
          ignoreType: true
        })
        setNewsletterUsers(res)
      }
      fetchUsers()
    }
  }, [activeTab, pageNumber])

  const hangleEmailText = event => {
    setEmailText(event)
  }

  return (
    <div className={styles.wrapper}>
      <Heading title="Newsletter" tabs={TABS.NewsletterTabs} onTabChange={setActiveTab} />
      {activeTab === 'send' && (
        <>
          <div className={styles.inputDiv}>
            <Input
              placeholder={'Enter title for email'}
              className={'cardInput'}
              value={emailTitle}
              maxLength={100}
              onChange={e => setEmailTitle(e.target.value)}
              placeholderOrentation="placeholderCenter"
            />
          </div>
          <div className={styles.emailContent}></div>
          <div className={styles.quillHeight}>
            <QuilFiledForNewsletter htmlValue={emailText} onChange={hangleEmailText} />
          </div>

          <div className={styles.buttonDiv}>
            <Button
              spinner={loading}
              label="Send Newsletter"
              onClick={sendNewsLetterFunc}
              variation={'secondary'}
              disabled={loading}
            />
          </div>
          {loading && (
            <div className={styles.loadingContainer}>
              <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </div>
          )}
        </>
      )}

      {activeTab === 'history' && (
        <>
          <div className={styles.header}>
            <span>Time</span>
            <span style={{ textAlign: 'center' }}>Title</span>
            <span> </span>
          </div>
          <div className={styles.scrollableContentHistory}>
            {newsletterHistory &&
              newsletterHistory.rows &&
              newsletterHistory.rows.length > 0 &&
              newsletterHistory?.rows?.map(one => {
                return <SingleNewsletter key={one._id} props={one} />
              })}
            {(!newsletterHistory?.rows || newsletterHistory?.rows?.length === 0) && (
              <Typography
                text="There are no newsletters"
                variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
                className="marginY10"
              />
            )}
          </div>
          {newsletterHistory && newsletterHistory.total > 20 && (
            <Pagination total={newsletterHistory?.total} onPageChange={setPageNumber} pageSize={20} />
          )}
        </>
      )}

      {activeTab === 'users' && (
        <>
          <div className={styles.header} style={{ justifyContent: 'space-between' }}>
            <span style={{ width: 'fit-content', marginLeft: '2rem' }}>Email</span>
            <span style={{ width: 'fit-content', marginRight: '2rem', textAlign: 'right' }}>Username</span>
          </div>
          <div className={styles.scrollableContent}>
            {newsletterUsers &&
              newsletterUsers.rows &&
              newsletterUsers?.rows?.length > 0 &&
              newsletterUsers?.rows?.map(one => {
                return <UserSingle key={one._id} props={one} showSwitchAndEdit={false} />
              })}
            {(!newsletterUsers?.rows || newsletterUsers?.rows?.length === 0) && (
              <Typography
                text="There are no users"
                variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
                className="marginY10"
              />
            )}
          </div>
          {newsletterUsers && newsletterUsers.total > 20 && (
            <Pagination total={newsletterUsers?.total} onPageChange={setPageNumber} pageSize={20} />
          )}
        </>
      )}
    </div>
  )
}

export default Newsletter
