import React from 'react'

import { useDispatch } from 'react-redux'

import Chat from 'components/chat'
import CloseButton from 'components/closeButton'

import { closeModal } from 'store/reducers/chatModal/chatModalSlice'

import styles from './chatModal.module.scss'

const ChatModal = ({ user = null, defaultChat = null }) => {
  const dispatch = useDispatch()

  const handleCloseModal = () => {
    dispatch(closeModal())
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <CloseButton handleClick={() => handleCloseModal()} />
        <Chat user={user} defaultChat={defaultChat} />
      </div>
    </div>
  )
}

export default ChatModal
