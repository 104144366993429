import Amex from 'assets/images/american-express.png'
import Discover from 'assets/images/discover.png'
import MasterCard from 'assets/images/mastercard.png'
import UnionPay from 'assets/images/union-pay.svg'
import Visa from 'assets/images/visa.png'
import Button from 'components/button'
import Heading from 'components/Heading'
import Stripe from 'components/stripe'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import paymentService from 'services/payment.service'
import { toastService } from 'services/toastService'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import styles from './editCard.module.scss'

export const EditCard = ({}) => {
  const [cards, setCards] = useState([])
  const [addOrRemoveState, setAddOrRemoveState] = useState()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const getCards = async () => {
      try {
        setLoading(true)
        const res = await paymentService.getListOfAllCards()
        if (res) {
          setCards(res)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
    getCards()
  }, [addOrRemoveState])

  const findTypeForCard = name => {
    switch (name) {
      case 'mastercard':
        return MasterCard
      case 'visa':
        return Visa
      case 'amex':
        return Amex
      case 'discover':
        return Discover
      case 'unionpay':
        return UnionPay
      default:
        return Visa
    }
  }

  const closeStripeModal = () => {
    dispatch(closeModal())
  }

  const addCard = async () => {
    if (cards && cards.data && cards.data.length > 0) {
      toastService('info', 'The user already has a card.')
    } else {
      await dispatch(
        openModal({
          show: true,
          content: (
            <Stripe
              trackCardAction={() => {
                setAddOrRemoveState(new Date().getTime())
              }}
            />
          ),
          onclose: closeStripeModal,
          name: 'Stripe modal',
          undo: true
        })
      )
    }
  }

  const removeCard = async id => {
    try {
      const res = await paymentService.removeUserCreditCard({ paymentMethod: { methodId: id } })
      if (res) {
        toastService('success', 'Successfully deleted credit card.')
        setAddOrRemoveState(new Date().getTime())
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={styles.wrapper}>
      <Heading
        title="Payment Settings"
        rightMiddleElement={
          <div className={styles.addNewCard}>
            <Button
              label="Add card"
              size="xl"
              variation="secondary"
              className={`${styles.button} ${cards?.data?.length > 0 && styles.disable}`}
              onClick={addCard}
              disabled={cards?.data?.length > 0}
            />
          </div>
        }
      />
      {loading ? (
        <div className={styles.loadingContainer}>
          <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
      ) : (
        <>
          {cards &&
            cards.data?.map((element, index) => {
              return (
                <div className={styles.container} key={index}>
                  <div className={styles.leftSide}>
                    <div className={styles.singleRow}>
                      <span>Brand</span>
                      <h3>{element?.card?.brand}</h3>
                    </div>
                    <div className={styles.singleRow}>
                      <span>Number of card</span>
                      <h3>XXXX XXXX XXXX {element?.card?.last4}</h3>
                    </div>
                    <div className={styles.singleRow}>
                      <span>Expired date</span>
                      <h3>
                        {element?.card?.exp_month} / {element?.card?.exp_year}
                      </h3>
                    </div>
                  </div>
                  <div className={styles.rightSide}>
                    <div className={styles.cardImg}>
                      <img src={findTypeForCard(element?.card?.brand)} alt="CARD" />
                    </div>
                    <div className={styles.buttons}>
                      <Button
                        label="Remove card"
                        size="xl"
                        variation="secondary"
                        onClick={() => removeCard(element?.id)}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
        </>
      )}
    </div>
  )
}

export default EditCard
