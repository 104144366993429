import { Buffer } from 'buffer'

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const data = reader.result.split(',')[1]
      const binaryBlob = Buffer.from(data, 'base64')
      resolve(binaryBlob)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const converFileToBase64 = async file => {
  try {
    const base64 = await readFileAsync(file)
    return base64
  } catch (err) {
    console.log(err)
  }
}
