import Button from 'components/button'
import CardFront from 'components/cardFront'
import Heading from 'components/Heading'
import Input from 'components/input'
import Selector from 'components/select'
import TagInput from 'components/tagInput'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import moment from 'moment'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { USER_LEVEL_OPTIONS } from 'staticConstants'
import { setEditedCampaign } from 'store/reducers/campaign/campaignSlice'
import { closeModal } from 'store/reducers/modal/modalSlice'
import { LOCATION_OPTIONS, isObjectEmpty } from 'utils'
import styles from './campaignCreation.module.scss'

const ageOptions = [
  { value: 'any', label: 'No Age Restrictions' },
  // { value: '13-17', label: '13-17' },
  { value: '18-24', label: '18-24' },
  { value: '25-34', label: '25-34' },
  { value: '35-49', label: '35-49' },
  { value: '50-65', label: '50-65' },
  { value: '65+', label: '65+' }
]

const errorMsg = type => {
  let message
  switch (type) {
    case 'name':
      message = 'Name required'
      break
    case 'targetUserLevel':
      message = 'User level required'
      break
    case 'ageTarget':
      message = 'Age Range required'
      break
    case 'targetInterests':
      message = 'Ineterests required'
      break
    case 'targetLocation':
      message = 'Target location required'
      break
    default:
      message = 'Error'
      break
  }
  return message
}

const CampaignCreation = ({ campaignEdit, edit = false, onBackClick, selectedCard = {}, type = 'general' }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const [createCampaignLoad, setCreateCampaignLoad] = useState(false)
  const [card, setCard] = useState({})
  const [error, setError] = useState(false)
  const [campaign, setCamapign] = useState({
    type: 'campaign',
    typeOfCampaign: type === 'general' || type === 'media' ? 'general' : type,
    name: '',
    description: '',
    targetUserLevel: '',
    ageTarget: '',
    targetLocation: '',
    cardId: '',
    userId: user?._id,
    duration: 7,
    startDate: moment().valueOf(),
    endDate: moment().valueOf() + 7 * 1000 * 60 * 60 * 24,
    targetInterests: []
  })

  useEffect(() => {
    if (Object.keys(selectedCard).length > 0) {
      setCard(selectedCard)
      setCamapign({ ...campaign, cardId: selectedCard?._id })
    }
  }, [selectedCard?._id])

  useEffect(() => {
    setError(false)
    if (edit) {
      dispatch(setEditedCampaign(false))
      const getCard = async () => {
        const _id = campaignEdit?.cardId
        let res
        if (_id) {
          res = await dataService.run('data', 'card', 'get', { filter: { _id: _id } })
          if (isObjectEmpty(res)) {
            res = await dataService.run('data', 'pack', 'get', { filter: { _id: _id } })
          }
          setCard(res)
        }
      }
      getCard()
      setCamapign(campaignEdit)
    }
  }, [campaignEdit?.cardId, edit])

  const handleBack = () => {
    onBackClick && onBackClick()
  }

  const handleChange = e => {
    setError(false)
    if (e && e.target) {
      const { name, value } = e.target
      setCamapign(campaign => ({ ...campaign, [name]: value }))
    }
  }

  const handleCreateOrEdit = async () => {
    if (validateCampaign()) {
      setCreateCampaignLoad(true)
      try {
        if (edit) {
          const res = await dataService.run('data', 'campaign', 'update', campaign)
          if (res) {
            toastService('success', 'Successfully edited campaign.')
            dispatch(closeModal())
            dispatch(setEditedCampaign(true))
          }
        } else {
          const res = await dataService.run('data', 'campaign', 'new', campaign)
          if (res?._id) {
            toastService('success', 'Successfully created campaign.')
            onBackClick && onBackClick()
          }
        }
        setCreateCampaignLoad(false)
      } catch (error) {
        setCreateCampaignLoad(false)
      }
    } else {
      setError(true)
    }
  }

  const validateCampaign = () => {
    return (
      campaign.name &&
      campaign.targetInterests?.length > 0 &&
      campaign.ageTarget &&
      campaign.targetLocation &&
      campaign.targetUserLevel !== ''
    )
  }

  return (
    <div className={styles.createCampaign}>
      {edit ? (
        <div className={styles.edit}>
          <Typography text="Edit Campaign" variation={TYPOGRAPHY_VARIATION.Title} />
        </div>
      ) : (
        <Heading
          title="Create Campaign"
          leftMiddleElement={
            <div className={styles.back} onClick={handleBack}>
              &#x27A4;
            </div>
          }
        />
      )}
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <div className={styles.formBox}>
            <div className={styles.inputRow}>
              <div className={styles.singleInput}>
                <Input
                  type={'text'}
                  className={'cardInput'}
                  placeholder={'Campaign Name'}
                  name="name"
                  value={campaign.name}
                  onChange={handleChange}
                  error={error && !campaign.name}
                  errorMsg={errorMsg('name')}
                />
              </div>
              <div className={styles.singleInput}>
                <Input
                  type={'text'}
                  className={'cardInput'}
                  placeholder={'Description (Optional)'}
                  name="description"
                  value={campaign.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.singleInput}>
                <Input
                  type={'text'}
                  className={'cardInput'}
                  placeholder={'Duration'}
                  name="duration"
                  value={campaign.duration}
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
              <div className={styles.singleInput}>
                <Selector
                  handleChangeSelect={e => setCamapign({ ...campaign, targetUserLevel: e.value })}
                  selectedOption={USER_LEVEL_OPTIONS.find(option => option.value === campaign.targetUserLevel)}
                  options={USER_LEVEL_OPTIONS}
                  selectClass={'selectGrayNoMargin'}
                  placeholder={'User Level'}
                  name={'targetUserLevel'}
                  error={error && campaign.targetUserLevel === ''}
                  errorMsg={errorMsg('targetUserLevel')}
                />
              </div>
            </div>
          </div>
          <div className={styles.formBoxBig}>
            <div className={styles.inputRow}>
              <div className={styles.singleInput} style={{ width: '98%', margin: 'auto' }}>
                <TagInput
                  placeholder="Add Interests"
                  tags={campaign.targetInterests}
                  className="full-width-fixed"
                  name={'targetInterests'}
                  onTagChange={tags => handleChange({ target: { name: 'targetInterests', value: tags } })}
                  error={error && !(campaign.targetInterests?.length > 0)}
                  errorMsg={errorMsg('targetInterests')}
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={styles.selector} style={{ width: '48%', margin: 'auto' }}>
                <Selector
                  selectClass={'selectGrayNoMargin'}
                  placeholder={'Add Target Locations'}
                  selectedOption={LOCATION_OPTIONS.find(option => option.value === campaign.targetLocation)}
                  options={LOCATION_OPTIONS}
                  name={'targetLocation'}
                  handleChangeSelect={e => setCamapign({ ...campaign, targetLocation: e.value })}
                  error={error && !campaign.targetLocation}
                  errorMsg={errorMsg('targetLocation')}
                />
              </div>
              <div className={styles.selector} style={{ width: '48%', margin: 'auto' }}>
                <Selector
                  selectClass={'selectGrayNoMargin'}
                  placeholder={'Age Range'}
                  selectedOption={ageOptions.find(option => option.value === campaign.ageTarget)}
                  options={ageOptions}
                  name={'ageTarget'}
                  handleChangeSelect={e => setCamapign({ ...campaign, ageTarget: e.value })}
                  error={error && !campaign.ageTarget}
                  errorMsg={errorMsg('ageTarget')}
                />
              </div>
            </div>
            <div className={styles.inputRow} style={{ alignItems: 'center', justifyContent: 'center' }}>
              <div className={styles.singleInput}>
                <Button
                  label={edit ? 'Edit Campaign' : 'Create Campaign'}
                  variation="secondary"
                  onClick={handleCreateOrEdit}
                  className={styles.createBtn}
                  spinner={createCampaignLoad}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          <CardFront card={card} />
          {!edit && (
            <Button label={'Choose New ' + _.upperFirst(card?.type)} variation="secondary" onClick={handleBack} />
          )}
        </div>
      </div>
    </div>
  )
}

export default CampaignCreation
