import styles from './contentViewerForRegistration.module.scss'
import chart from 'assets/images/chart.png'

const video = 'https://www.youtube.com/embed/1c9r8hxoWM4'

const ContentViewerForRegistration = ({
  content = '',
  title = '',
  price = 0,
  isVideo = false,
  isImage = false,
  onClick
}) => {
  return isVideo ? (
    <div className={styles.wrapper} onClick={onClick}>
      <iframe style={{ width: '100%', height: '40vh' }} title=" " src={video + '?autoplay=1'} />
    </div>
  ) : isImage ? (
    <div className={styles.wrapper}>
      <h1 style={{ paddingLeft: '2rem' }}>COMPARING PACKAGES</h1>
      <div className={styles.imgSize}>
        <img src={chart} alt="" />
      </div>
    </div>
  ) : (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.cardTitle}> {title}</div>
        <div className={styles.cardContent}>{content}</div>
        <div className={styles.cardPrice}>
          <p>{'$' + price + ' /month'} </p>
        </div>
      </div>
    </div>
  )
}

export default ContentViewerForRegistration
