import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardHeader from 'components/card/components/header'
import QuestionsSlider from 'components/card/components/questionSlider/questionSlider'
import CardWrapper from 'components/card/components/wrapper'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import { updateUser } from 'store/reducers/auth/thunk'
import styles from './doubleSidedTriviaBack.module.scss'

export const CardDoubleSidedTriviaBack = ({ card, className, saveAnswers = true }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const [userAnswers, setUserAnswers] = useState([])
  const [activeSlide, setActiveSlide] = useState(0)

  const onFinish = async answers => {
    if (saveAnswers) {
      const data = { cardId: card._id, userId: user._id, answer: answers, answerType: card.cardType }
      const res = await dataService.run('data', 'cardAnswer', 'new', data)
      if (res) {
        const correctAnswers = answers.reduce((total, el) => (el.correct ? ++total : total), 0)
        if (user && answers && correctAnswers > 0) {
          dispatch(
            updateUser({
              ...user,
              points: Number(user.points) + correctAnswers * Number(card.pointsAllocation) ?? 0
            })
          )
        }
        toastService('success', 'You successfully finished trivia.')
      }
    }
  }
  useEffect(() => {
    const getUserAnswers = async () => {
      try {
        const res = await dataService.run('data', 'cardAnswer', 'get', {
          filter: { cardId: card._id, userId: user._id }
        })
        setUserAnswers(res.answer)
      } catch (error) {}
    }
    getUserAnswers()
  }, [])

  const handleSlideChange = activeIndex => {
    setActiveSlide(activeIndex)
  }

  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card size={CARD_SIZE.Normal} type={CARD_TYPES.DoubleSidedTrivia}>
        <CardBody size={CARD_SIZE.Normal} type={CARD_TYPES.DoubleSidedTrivia}>
          <CardHeader
            heading={
              card.questions?.[activeSlide || 0]?.question === '' ? '' : card.questions[activeSlide || 0]?.question
            }
            type={CARD_TYPES.DoubleSidedTrivia}
            size={CARD_SIZE.Normal}
            autoHeight
            className={styles.triviaFront}
          />
          <QuestionsSlider
            slides={card.questions}
            onFinish={onFinish}
            defaultAnswers={userAnswers}
            points={card.pointsAllocation}
            onSlideChange={handleSlideChange}
          />
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CardDoubleSidedTriviaBack
