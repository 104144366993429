const defaultFrontFields = {
  // type: true
}
export const cardTypeFields = {
  standard: {
    front: {
      ...defaultFrontFields,
      heading: true,
      subheading: true,
      category: true,
      tags: true,
      mature: true
    },
    back: {
      groupTextboxMap: true,
      groupImageCalendar: true,
      groupVideoInlinePack: true,
      groupLinkedImage: true
    }
  },
  slide: {
    front: {
      ...defaultFrontFields,
      heading: true,
      subheading: true,
      category: true,
      tags: true,
      mature: true
    },
    back: {
      addNewSlide: true,
      slideImageCaption: true
    }
  },
  double: {
    front: {
      ...defaultFrontFields,
      heading: true,
      subheading: true,
      category: true,
      tags: true,
      mature: true
    },
    back: {
      textbox: true,
      image: true,
      video: true,
      map: true,
      calendar: true,
      inlinePack: true,
      linkedImage: true
    }
  },
  instacard: {
    front: {
      ...defaultFrontFields,
      scrapeUrl: true,
      category: true,
      tags: true,
      mature: true
    }
  },
  blank: {
    front: {
      ...defaultFrontFields,
      // comment heading this if need to remove title
      heading: true,
      category: true,
      tags: true,
      mature: true
    },
    back: {
      groupTextboxMap: true,
      groupImageCalendar: true,
      groupVideoInlinePack: true
    }
  },
  collectible: {
    front: {
      ...defaultFrontFields,
      heading: true,
      subheading: true,
      // collection: true,
      userLevel: true,
      frequencyInFeeds: true,
      mature: true
    },
    back: {
      groupTextboxFullCard: true,
      groupImage: true,
      groupVideo: true
    }
  },
  mystery: {
    front: {
      ...defaultFrontFields,
      pointsAllocation: true,
      userLevel: true,
      frequencyInFeeds: true,
      mature: true
    },
    back: {
      groupTextboxFullCard: true,
      groupImage: true,
      groupVideo: true
    }
  },
  oneSidedTrivia: {
    front: {
      ...defaultFrontFields,
      pointsAllocation: true,
      userLevel: true,
      frequencyInFeeds: true,
      groupTrueFalseOption: true,
      explanationReveal: true,
      mature: true
    },
    back: {}
  },
  doubleSidedTrivia: {
    front: {
      ...defaultFrontFields,
      heading: true,
      subheading: true,
      pointsAllocation: true,
      userLevel: true,
      frequencyInFeeds: true,
      mature: true
    },
    back: {
      addNewQuestion: true,
      groupTrueFalseOption: true,
      questionValue: true,
      explanationReveal: true
    }
  },
  sponsored: {
    front: {
      ...defaultFrontFields,
      heading: true,
      subheading: true,
      category: true,
      tags: true,
      mature: true
    },
    back: {
      groupTextboxMap: true,
      groupImageCalendar: true,
      groupVideoInlinePack: true,
      byline: true
    }
  },
  event: {
    front: {
      ...defaultFrontFields,
      heading: true,
      byline: true,
      description: true,
      eventLocation: true,
      eventDate: true,
      mature: true,
      eventImageCaption: true
    }
  },
  marketplace: {
    front: {
      ...defaultFrontFields,
      heading: true,
      subheading: true,
      marketplaceCategory: true,
      groupPriceRangeLocation: true,
      groupCustomerTYpeUserLoyalty: true,
      tags: true
    },
    back: {
      groupTextboxMap: true,
      groupImageCalendar: true,
      groupVideoInlinePack: true,
      groupLinkedImage: true
    }
  },
  poll: {
    front: {
      ...defaultFrontFields,
      heading: true,
      subheading: true,
      addNewOption: true,
      tags: true,
      mature: true
    }
  },
  anchor: {
    front: {
      ...defaultFrontFields,
      anchorLocation: true,
      anchorHeading: true,
      ribbonColor: true,
      pageLink: true,
      mature: true
    }
  }
}
