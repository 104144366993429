import EmailIcon from 'assets/icons/social/36/Mail.png'
import { EmailShareButton } from 'react-share'
import styles from './socialShare.module.scss'

const EmailShare = ({ url, subject, body, separator, onClose, className }) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <EmailShareButton
        onShareWindowClose={() => onClose(true)}
        url={url}
        subject={subject}
        body={body}
        onClick={() => {}}
        openShareDialogOnClick
        separator={separator}
      >
        <img src={EmailIcon} alt="Mail" />
      </EmailShareButton>
    </div>
  )
}

export default EmailShare
