import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  show: false,
  content: {},
  name: '',
  size: '',
  onclose: null,
  xButton: true,
  undo: false,
  overlayClassName: '',
  confirmModalText: 'Are you sure you want to close this dialog?'
}
export const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { content, show, name, size, onclose, xButton, undo, overlayClassName, confirmModalText } = action.payload
      state.content = content
      state.show = show
      state.name = name
      state.size = size
      state.onclose = onclose
      state.xButton = xButton
      state.undo = undo
      state.overlayClassName = overlayClassName
      state.confirmModalText = confirmModalText
    },
    closeModal: state => {
      state.content = {}
      state.show = false
      state.name = ''
      state.size = ''
      state.onclose = null
      state.xButton = true
      state.undo = false
    },
    resizeModal: (state, action) => {
      state.size = action.payload
    }
  }
})

export const { openModal, closeModal, resizeModal } = modal.actions

export default modal.reducer
