import React from 'react'
import { TwitterShareButton } from 'react-share'
import styles from './socialShare.module.scss'
import TwitterIcon from 'assets/icons/social/36/Twitter.png'

const TwitterShare = ({ url, title, via, hashtags, related, onClose, className }) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <TwitterShareButton
        onShareWindowClose={() => onClose(true)}
        url={url}
        title={title}
        via={via}
        hashtags={hashtags}
        related={related}
      >
        <img src={TwitterIcon} alt="Facebook" />
      </TwitterShareButton>
    </div>
  )
}

export default TwitterShare
