import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { wsUrl } from '../environment'
import { setNewMessage } from 'store/reducers/event/eventSlice'

const PING_INTERVAL = 30 * 1000 // 30 sec
const PONG_INTERVAL = 10 * 1000 // 10 sec

const Socket = () => {
  const store = useSelector(state => state)
  let socket
  let live = true
  let reconnectTime = 500
  // const store = useSelector(state => state)

  const dispatch = useDispatch()

  let pingInterval
  let pongInterval

  const ping = () => {
    try {
      socket.send(JSON.stringify({ ping: true }))
      pongInterval = setTimeout(pongNotReceived, PONG_INTERVAL)
    } catch (err) {
      //
    }
  }

  const pongNotReceived = () => {
    socket.close()
  }

  const disconnect = () => {
    live = false
    if (socket) {
      socket.close()
    }
  }

  const connect = username => {
    console.log(new Date() + ' Connecting to WebSocket')
    live = true
    socket = new WebSocket(`${wsUrl}/ws/${username}`)

    socket.onopen = () => {
      console.log(new Date() + ' Connected to WebSocket')
      reconnectTime = 1000
      if (pingInterval) clearInterval(pingInterval)
      pingInterval = setInterval(ping, PING_INTERVAL)
    }

    socket.onclose = reason => {
      console.log(new Date() + ' Closing WebSocket', reason)
      if (!live) return
      setTimeout(() => {
        if (live) connect(username)
      }, reconnectTime)
    }

    socket.onmessage = message => {
      try {
        const event = JSON.parse(message.data)
        if (event) {
          if (event.pong === true) {
            clearTimeout(pongInterval)
            return
          }
          dispatch(setNewMessage(event))
        }
      } catch (err) {
        console.log('socket error parsing websocket data')
      }
    }

    socket.onerror = err => {
      console.log(new Date() + ' Websocket error', err)
      reconnectTime *= 2
      if (reconnectTime > 30000) {
        reconnectTime = 30000
      }
    }
  }

  useEffect(() => {
    if (store.auth.user && store.auth.user.username) {
      connect(store.auth.user.username)
    } else {
      disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.auth.user])

  useEffect(() => {
    return () => disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div />
}

export default Socket
