import axios from 'axios'
import { uploadMedia } from 'components/card/types/share'
import MediaPicker from 'components/mediaPicker'
import QuilFiled from 'components/quilFIeld'
import VideoPicker from 'components/videoPicker'
import WidgetCalendar from 'components/widgetCalendar'
import WidgetInlinePack from 'components/widgetInlinePack'
import WidgetMap from 'components/widgetMap'
import useUploadQue from 'hooks/useUploadQue'
import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { toastService } from 'services/toastService'
import { CARD_BACK_TYPES } from 'staticConstants'
import { setSelectedCard } from 'store/reducers/card/cardSlice'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import LinkedImage from 'components/linkedImage'
import styles from './item.module.scss'
const getComponent = (item, onChange, card, onVideoChange, onVideoRemove, onStopUpload, isLoading, loadedPercent) => {
  switch (item.type) {
    case CARD_BACK_TYPES.text:
      return <QuilFiled htmlValue={item.content} onChange={onChange} triggerAfterMS="300" />
    case CARD_BACK_TYPES.image:
      return (
        <MediaPicker
          stateMedia={getMediaFromFileOrString(item.content)}
          style={{ flexGrow: '4' }}
          className="autoHeight"
          onImageChange={onChange}
          showCrop
          type="dndBack"
          widthForCropDivInsideWrapper="100%"
        />
      )
    case CARD_BACK_TYPES.video:
      return (
        <VideoPicker
          defaultVideo={item.content ? getMediaFromFileOrString(item.content) : null}
          onVideoChange={file => onVideoChange(file)}
          className="autoHeight"
          onVideoRemove={onVideoRemove}
          onStopUpload={onStopUpload}
          isLoading={isLoading}
          loadedPercent={loadedPercent}
        />
      )
    case CARD_BACK_TYPES.calendar:
      return <WidgetCalendar data={item.content} type="edit" card={card} />
    case CARD_BACK_TYPES.map:
      return <WidgetMap data={item.content} type="edit" card={card} />
    case CARD_BACK_TYPES.inlinePack:
      return <WidgetInlinePack cardId={item.content._id} />
    case CARD_BACK_TYPES.linkedImage:
      return <LinkedImage data={item.content} type="edit" card={card} />
    default:
      break
  }
}

// COMMENTED
const getUpdatedArray = (card, position, newContent, newCancelToken) => {
  const tempArray = []
  card.backFields.forEach(({ id, type, content, cancelToken }, index) => {
    if (index === position)
      tempArray.push({ id: id, type: type, content: newContent, cancelToken: newCancelToken ?? cancelToken })
    else tempArray.push({ id: id, type: type, content: content, cancelToken: cancelToken })
  })
  return tempArray
}

const Item = ({ item, index }) => {
  const [backFieldsUploadQue, addToBackFieldsUploadQue, removeFromQue] = useUploadQue('backFields')
  const card = useSelector(state => state.card.selectedCard)
  const dispatch = useDispatch()
  const [triggerUploadPercentage, setTriggerUploadPercentage] = useState()
  const [triggerRemoveFromQue, setTriggerRemoveFromQue] = useState()
  const [triggerUploadCard, setTriggerUploadCard] = useState()
  const onChange = event => {
    dispatch(setSelectedCard({ ...card, backFields: getUpdatedArray(card, index, event) }))
  }
  const onVideoChange = async file => {
    setTriggerRemoveFromQue(null)
    addToBackFieldsUploadQue(item.id, { percent: 0, loading: true })
    uploadMedia(file, true, item.cancelToken.token, loadedPercent => {
      setTriggerUploadPercentage(loadedPercent)
    })
      .then(res => {
        if (res) {
          setTriggerUploadCard(res)
        }
      })
      .catch(err => {
        toastService('error', err.message)
      })
      .finally(() => {
        setTriggerRemoveFromQue(item.id)
      })
  }
  const onStopUpload = () => {
    try {
      item.cancelToken.cancel('Upload is stopped.')
      setTriggerRemoveFromQue(item.id)
      const newCancelToken = axios.CancelToken.source()
      dispatch(setSelectedCard({ ...card, backFields: getUpdatedArray(card, index, null, newCancelToken) }))
    } catch (error) {}
  }
  const onVideoRemove = () => {
    // Delete old video
    const newCancelToken = axios.CancelToken.source()
    dispatch(setSelectedCard({ ...card, backFields: getUpdatedArray(card, index, null, newCancelToken) }))
  }
  useEffect(() => {
    if (triggerUploadPercentage > 0) addToBackFieldsUploadQue(item.id, { percent: triggerUploadPercentage })
  }, [triggerUploadPercentage])
  useEffect(() => {
    if (triggerRemoveFromQue) removeFromQue(triggerRemoveFromQue)
  }, [triggerRemoveFromQue])
  useEffect(() => {
    if (triggerUploadCard)
      dispatch(setSelectedCard({ ...card, backFields: getUpdatedArray(card, index, triggerUploadCard) }))
  }, [triggerUploadCard])
  useEffect(() => {
    if (!item.cancelToken || item.cancelToken === '') {
      const newCancelToken = axios.CancelToken.source()
      dispatch(setSelectedCard({ ...card, backFields: getUpdatedArray(card, index, item.content, newCancelToken) }))
    }
  }, [item])
  return (
    <Draggable draggableId={`${item.type}_${index}`} index={index}>
      {provided => (
        <div className={styles.item} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {getComponent(
            item,
            event => onChange(event),
            card,
            file => onVideoChange(file),
            onVideoRemove,
            onStopUpload,
            backFieldsUploadQue[item.id]?.loading,
            backFieldsUploadQue[item.id]?.percent
          )}
        </div>
      )}
    </Draggable>
  )
}

export default Item
