import React from 'react'
import { Route } from 'react-router-dom'
import PrivateWrapper from './privateWrapper'
export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <PrivateWrapper {...props} {...rest}>
            <Component {...props} {...rest} />
          </PrivateWrapper>
        )
      }}
    />
  )
}
