import IconClose from 'assets/icons/states/failed.png'
import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardHeader from 'components/card/components/header'
import CardScrollableContent from 'components/card/components/scrollableContent'
import CardWrapper from 'components/card/components/wrapper'
import Input from 'components/input'
import OkModal from 'components/okModal'
import Login from 'pages/auth/login'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import { setSelectedCard } from 'store/reducers/card/cardSlice'
// import { cardActions } from 'store/actions/card.actions'
import styles from './pollFront.module.scss'
import cloneDeep from 'lodash/cloneDeep'
import { setPollAnswers } from 'store/reducers/auth/authSlice'

const CardPollFront = ({ size = CARD_SIZE.Normal, card, editable = false, disableAnswers = false, className }) => {
  const dispatch = useDispatch()
  // const [respondedCard, setRespondedCard] = useState()
  const user = useSelector(state => state.auth.user)
  const [userAnswered, setUserAnswered] = useState()
  const [cardData, setCardData] = useState()
  const [answersConstant, setAnswersConstant] = useState(0)
  const [showInfoModal, setShowInfoModal] = useState({ show: false, message: '' })
  const checkPreviewModal = useSelector(state => state?.previewModal?.item)
  const userPollAnswers = useSelector(store => store?.auth?.pollAnswers)
  const openModal = () => {
    /* eslint-disable */
    setShowInfoModal({ show: true, message: "This card already has answers. Options can't be changed." })
    /* eslint-enable */
  }

  const isPollAnswerFromLogedUser = (option, answers) => {
    let isOptionAnsweredByUser = false
    try {
      answers.forEach(answer => {
        if (answer?.answer === option?.id) {
          isOptionAnsweredByUser = true
          return null
        }
      })
    } catch (e) {}
    return isOptionAnsweredByUser
  }

  const handleChange = (curentIndex, value) => {
    if (!card.hasAnswers) {
      const pollOptions = [...cloneDeep(card.pollOptions)]
      pollOptions.map((option, index) => {
        if (index === curentIndex) option.option = value
      })
      dispatch(
        setSelectedCard({
          ...card,
          pollOptions: [...pollOptions]
        })
      )
    } else {
      // openModal()
    }
  }
  const handleDelete = index => {
    if (!card.hasAnswers) {
      const tempOptions = [...cloneDeep(card.pollOptions)]
      tempOptions.splice(index, 1)
      dispatch(
        setSelectedCard({
          ...card,
          pollOptions: [...tempOptions]
        })
      )
    } else {
      // openModal()
    }
  }
  const optionClicked = async optionId => {
    if (user) {
      if (Object.keys(checkPreviewModal).length > 0) {
        if (!userAnswered) {
          const data = { cardId: cardData._id, userId: user._id, answer: optionId, answerType: cardData.cardType }
          const res = await dataService.run('data', 'cardAnswer', 'new', data)
          if (res) {
            setCardData(res?.card)
            setUserAnswered(res?.card)
            getAnswersConstantFromCard(res?.card)
            dispatch(setPollAnswers([...userPollAnswers, res?.answer]))
            toastService('success', 'Your answer is saved.')
          }
        }
      }
    } else {
      dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
    }
  }

  const getAnswersConstantFromCard = cardObj => {
    if (cardObj.pollAnswers && Object.keys(cardObj.pollAnswers).length > 0) {
      let allAnswersCount = 0
      Object.keys(cardObj.pollAnswers).forEach(key => {
        allAnswersCount += cardObj.pollAnswers[key]
      })
      setAnswersConstant(100 / allAnswersCount)
    }
  }
  useEffect(() => {
    const fetch = async () => {
      try {
        const query = { filter: { userId: user?._id, cardId: card._id } }
        const res = await dataService.run('data', 'cardAnswer', 'get', query)
        if (res._id) {
          setUserAnswered(res)
        }
      } catch (error) {
        console.warn('_e', error)
      }
    }
    fetch()
  }, [userPollAnswers])

  useEffect(() => {
    setCardData({ ...card })
    if (card._id) {
      getAnswersConstantFromCard(card)
    }
  }, [card])
  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card className={styles.poll} size={size} type={CARD_TYPES.Poll} userType={card?.userRole}>
        <CardBody
          size={size}
          className={styles.content}
          userType={card?.userRole}
          type={CARD_TYPES.Poll}
          isMature={card?.mature}
        >
          <CardHeader
            heading={card.heading !== '' ? card.heading : 'Question goes here'}
            subheading={card.subheading !== '' ? card.subheading : ''}
            type={CARD_TYPES.Poll}
            size={size}
            autoHeight
            userType={card?.userRole}
          />
          <CardScrollableContent className={styles.scrollableContainer} type={CARD_TYPES.Poll}>
            <div className={styles.optionsWrapper}>
              {editable &&
                card.pollOptions.map((option, index) => {
                  return (
                    <div className={styles.options} key={index}>
                      <img
                        className={`${styles.optionClose}`}
                        alt="X"
                        src={IconClose}
                        onClick={() => handleDelete(index)}
                      />
                      <Input
                        placeholder="Option"
                        maxLength={18}
                        onChange={event => handleChange(index, event.target.value)}
                        value={card.pollOptions[index].option}
                        className={styles.pollInput}
                      />
                    </div>
                  )
                })}
              {!editable &&
                cardData &&
                cardData.pollOptions.map((option, index) => {
                  if (option && option.option !== '') {
                    return (
                      <div className={styles.prevOptions} onClick={() => optionClicked(option.id, index)} key={index}>
                        <div
                          className={`${styles.percentage} ${
                            isPollAnswerFromLogedUser(option, userPollAnswers) ? styles.userAnswer : ''
                          } ${!disableAnswers && userAnswered ? styles.showAnswers : ''}`}
                          style={
                            !disableAnswers && userAnswered
                              ? { width: `${(cardData?.pollAnswers?.[option.id] ?? 0) * answersConstant}%` }
                              : {}
                          }
                        >
                          <span> {option?.option}</span>
                          <p>
                            {Math.round(
                              cardData.hasAnswers && cardData.pollAnswers[option.id]
                                ? cardData.pollAnswers[option.id] * answersConstant * 100
                                : 0
                            ) /
                              100 +
                              '%'}
                          </p>
                        </div>
                      </div>
                    )
                  }
                })}
            </div>
          </CardScrollableContent>
          {showInfoModal.show && (
            <OkModal message={showInfoModal.message} onClose={() => setShowInfoModal({ show: false, message: '' })} />
          )}
        </CardBody>
      </Card>
    </CardWrapper>
  )
}
export default CardPollFront
