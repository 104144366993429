import React from 'react'
import styles from './unauth.module.scss'

const UnauthorizedMsg = ({ text }) => {
  return (
    <div className={styles.wrapper}>
      <h6>{text}</h6>
    </div>
  )
}
export default UnauthorizedMsg
