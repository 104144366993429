// Created by Petar 07.12.23.
// Custom hook that triggers when tab becomes active
import { useEffect } from 'react'
import { sessionStorageService } from 'services/sessionStorage.service'

const useTabVisibility = callback => {
  const handleVisibilityChange = () => {
    if (!document.hidden && sessionStorageService.get('username') !== null) {
      callback()
    }
  }

  const handleTabFocus = () => {
    // Check if the 'username' property is present in session storage
    if (sessionStorageService.get('username') !== null) {
      callback()
    }
  }

  useEffect(() => {
    // Check if the tab is active when the component mounts
    if (!document.hidden && sessionStorageService.get('username') !== null) {
      callback()
    }

    // Add event listeners for visibility change and tab focus
    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('focus', handleTabFocus)

    // Clean up the event listeners when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('focus', handleTabFocus)
    }
  }, [callback])
}

export default useTabVisibility
