import React, { useState } from 'react'
import styles from './topicSelection.module.scss'
import Selector from 'components/select'
import Topic from 'components/topic'

const TopicSelection = ({
  className,
  placeholder = 'Choose option',
  options = [],
  onSelectionChange,
  selectedOptions = []
}) => {
  const [selectedTopics, setSelectedTopics] = useState(selectedOptions)
  const [availableOptions, setAvailableOptions] = useState(
    options?.filter(el => ![...selectedOptions?.map(el => el.value)].includes(el.value))
  )

  const onSelectionChangeHandler = selectedTopic => {
    setSelectedTopics([...selectedTopics, selectedTopic])
    setAvailableOptions(availableOptions.filter(el => el.value !== selectedTopic.value))
    onSelectionChange && onSelectionChange([...selectedTopics, selectedTopic])
  }

  const onTopicCloseHandler = removedTopic => {
    // Add topic to available opitons
    const selectedTopicsValues = [...selectedTopics?.map(el => el?.value)]
    setAvailableOptions(
      options.filter(el => el?.value === removedTopic?.value || !selectedTopicsValues.includes(el?.value))
    )
    // Remove topic from selected options
    const tempSelected = selectedTopics.filter(el => el.value !== removedTopic.value)
    setSelectedTopics(tempSelected)
    onSelectionChange && onSelectionChange(tempSelected || [])
  }

  return (
    <div className={`${styles.wrapper} ${className} ${styles.className}`}>
      <div className={styles.selectedTopicsContainer}>
        {selectedTopics.length > 0 &&
          selectedTopics.map(el => {
            return <Topic label={el.label} onClose={() => onTopicCloseHandler(el)} />
          })}
      </div>
      <Selector
        options={availableOptions}
        selectClass={'selectGrayBorder-centered'}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        handleChangeSelect={onSelectionChangeHandler}
      />
    </div>
  )
}

export default TopicSelection
