import Button from 'components/button'
import 'cropperjs/dist/cropper.css'
import { useCallback, useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import { useDispatch } from 'react-redux'
import { closeModal } from 'store/reducers/modal/modalSlice'
import { getFileFromImage } from 'utils/getFileFromImage'
import styles from './imgResizer.module.scss'
// https://codesandbox.io/s/i7fs3?file=/src/Demo.tsx:744-779
const ImgResizer = ({
  file: fileData,
  width = '100%',
  height = '400px',
  cropW = 16,
  cropH = 9,
  onReady,
  closeCrop,
  previewCrop,
  type = 'card'
}) => {
  const dispatch = useDispatch()
  const [image, setImage] = useState(URL.createObjectURL(fileData))
  const [file, setFile] = useState(fileData)
  const [croppedImage, setCroppedImage] = useState()
  const cropperRef = useRef(null)
  const [croppedImageInstance, setCroppedImageInstance] = useState()
  const [cropDataTriggered, setCropDataTriggered] = useState()
  const [cropLoading, setCropLoading] = useState(false)

  const onChange = useCallback(e => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    setFile(files[0])
    reader.readAsDataURL(files[0])
  }, [])

  const onSubmit = () => {
    const canvasAsImage = croppedImageInstance.toDataURL(file?.type)
    const newImage = getFileFromImage(canvasAsImage, 'frontImage.' + file?.type.replace('image/', ''))
    onReady && onReady(newImage, file.name)
    cropperRef.current.cropper.destroy()
    if (previewCrop) closeCrop(null)
    if (!previewCrop) dispatch(closeModal())
  }
  useEffect(() => {
    setCropLoading(true)
    const updatePreviewTimer = setTimeout(() => {
      setCroppedImage(cropDataTriggered.toDataURL())
      setCroppedImageInstance(cropDataTriggered)
      setCropLoading(false)
    }, 200)
    return () => {
      clearTimeout(updatePreviewTimer)
    }
  }, [cropDataTriggered])

  const getPreviewClass = () => {
    switch (type) {
      case 'pack':
        return styles.previewCropForPack
      case 'dndBack':
        return styles.previewCropDnd
      case 'doubleCard':
        return styles.previewDoubleCard
      default:
        return ''
    }
  }

  const onCrop = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    setCropDataTriggered(cropper.getCroppedCanvas())
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Cropper
          crop={() => onCrop()}
          zoom={() => onCrop()}
          style={{ height, width }}
          zoomTo={0}
          aspectRatio={cropW / cropH}
          initialAspectRatio={1}
          minCropBoxHeight={cropH}
          minCropBoxWidth={cropW}
          src={image}
          viewMode={2}
          modal={false}
          wheelZoomRatio={0.5}
          background={false}
          responsive={true}
          autoCropArea={100}
          ref={cropperRef}
          dragMode="move"
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={instance => {
            setCroppedImageInstance(instance)
          }}
          guides={true}
        />
        {previewCrop && type === 'card' && (
          <div className={styles.previewCropForCard}>
            <img src={croppedImage} alt="cropped" />
            {cropLoading && (
              <div className={styles.loadingContainer}>
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              </div>
            )}
          </div>
        )}
        {((previewCrop && type === 'pack') ||
          (previewCrop && type === 'dndBack') ||
          (previewCrop && type === 'doubleCard')) && (
          <div className={styles.packContent}>
            <img src={croppedImage} alt="cropped" className={getPreviewClass()} />
            {cropLoading && (
              <div className={styles.loadingContainer}>
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.btn}>
        <Button variation="primary" label="Done" onClick={onSubmit} className={styles.move} />
        <div className={styles.position}>
          <input type="file" title=" " onChange={onChange}></input>
          <Button variation="primary" label="Change image"></Button>
        </div>
      </div>
    </div>
  )
}

export default ImgResizer
