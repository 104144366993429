import { filterPollOptions } from 'components/cardCreation/preview'
import { CARD_TYPES } from 'staticConstants'
const commonFields = {
  // USER
  userId: '',
  username: '',
  fullname: '',
  userRole: '',
  published: false,
  public: false,
  type: 'card',
  avatar: '',
  mature: false
}
export const getTypeFields = data => {
  let initial = {}
  switch (data.cardType) {
    case CARD_TYPES.Standard: {
      initial = {
        cardType: CARD_TYPES.Standard,
        ...commonFields,
        // FRONT
        heading: '',
        subheading: '',
        frontImage: '',
        category: '',
        tags: [],
        // BACK
        backFields: []
      }
      break
    }
    case CARD_TYPES.Blank: {
      initial = {
        cardType: CARD_TYPES.Blank,
        ...commonFields,
        // FRONT
        frontImage: '',
        category: '',
        tags: [],
        // BACK
        backFields: []
      }
      break
    }
    case CARD_TYPES.Instacard: {
      initial = {
        cardType: CARD_TYPES.Instacard,
        ...commonFields,
        // FRONT
        heading: '',
        subheading: '',
        frontImage: '',
        category: '',
        tags: [],
        // BACK
        backFields: [],
        isScraped: true,
        scrapeUrl: ''
      }
      break
    }
    case CARD_TYPES.Double: {
      initial = {
        cardType: CARD_TYPES.Double,
        ...commonFields,
        // FRONT
        heading: '',
        subheading: '',
        frontImage: '',
        category: '',
        tags: [],
        // BACK
        backFields: [],
        doubleCardImage: ''
      }
      break
    }
    case CARD_TYPES.Slide: {
      initial = {
        cardType: CARD_TYPES.Slide,
        ...commonFields,
        // FRONT
        heading: '',
        subheading: '',
        frontImage: '',
        category: '',
        tags: [],
        // BACK
        slider: [],
        sliderCaption: ''
      }
      break
    }
    case CARD_TYPES.Sponsored: {
      initial = {
        cardType: CARD_TYPES.Sponsored,
        ...commonFields,
        // FRONT
        heading: '',
        subheading: '',
        frontImage: '',
        category: '',
        tags: [],
        // BACK
        backFields: [],
        byline: ''
      }
      break
    }
    case CARD_TYPES.Collectible: {
      initial = {
        cardType: CARD_TYPES.Collectible,
        ...commonFields,
        // FRONT
        heading: '',
        subheading: '',
        frontImage: '',
        collection: '',
        userLevel: '',
        frequencyInFeeds: '',
        // BACK
        backFields: [],
        fullCardImage: { active: false, image: '' }
      }
      break
    }

    case CARD_TYPES.Mystery: {
      initial = {
        cardType: CARD_TYPES.Mystery,
        ...commonFields,
        // FRONT
        heading: '',
        subheading: '',
        pointsAllocation: '',
        userLevel: '',
        frequencyInFeeds: '',
        // BACK
        backFields: [],
        fullCardImage: { active: false, image: '' }
      }
      break
    }
    case CARD_TYPES.OneSidedTrivia: {
      initial = {
        cardType: CARD_TYPES.OneSidedTrivia,
        ...commonFields,
        // FRONT
        question: '',
        pointsAllocation: '',
        userLevel: '',
        frequencyInFeeds: '',
        questions: [
          // {
          //   question: '',
          //   answers: [
          //     { answer: '', correct: false },
          //     { answer: '', correct: false },
          //     { answer: '', correct: false },
          //   ],
          //   explanation: '',
          // },
        ],
        explanation: '',
        hasAnswers: false
      }
      break
    }
    case CARD_TYPES.DoubleSidedTrivia: {
      initial = {
        cardType: CARD_TYPES.DoubleSidedTrivia,
        ...commonFields,
        // FRONT
        heading: '',
        subheading: '',
        pointsAllocation: '',
        userLevel: '',
        frequencyInFeeds: '',
        frontImage: '',
        // BACK
        questions: [
          // {
          //   question: '',
          //   answers: [
          //     { answer: '', correct: false },
          //     { answer: '', correct: false },
          //     { answer: '', correct: false },
          //   ],
          //   explanation: '',
          // },
        ],
        hasAnswers: false
      }
      break
    }
    case CARD_TYPES.Marketplace: {
      initial = {
        cardType: CARD_TYPES.Marketplace,
        ...commonFields,
        // FRONT
        heading: '',
        subheading: '',
        category: '',
        priceRange: '',
        frontImage: '',
        marketLocation: '',
        customerType: '',
        userLoyalty: '',
        userLevel: 0,
        tags: [],
        // BACK
        backFields: []
      }
      break
    }
    case CARD_TYPES.Event: {
      initial = {
        cardType: CARD_TYPES.Event,
        ...commonFields,
        // FRONT
        heading: '',
        byline: '',
        description: '',
        frontImage: '',
        eventLocation: {},
        time: '',
        date: '',
        addToCalendarMaps: '',
        link: ''
      }
      break
    }
    case CARD_TYPES.Anchor: {
      initial = {
        cardType: CARD_TYPES.Anchor,
        ...commonFields,
        // FRONT
        heading: '',
        location: '',
        ribbonColor: '',
        frontImage: '',
        pageLink: ''
      }
      break
    }
    case CARD_TYPES.Poll: {
      initial = {
        cardType: CARD_TYPES.Poll,
        ...commonFields,
        // FRONT
        heading: '',
        subheading: '',
        pollOptions: [],
        tags: [],
        hasAnswers: false
      }
      break
    }
    default:
      break
  }

  for (const key of Object.keys(initial)) {
    initial[key] = data[key] ?? initial[key]
  }
  if (data._id) initial._id = data._id
  return initial
}

export const validateQuestion = question => {
  let isQuestionValid = true
  let areQuestionAnswersValid = true

  if (!question?.question) {
    // Question is empty
    isQuestionValid = false
  }
  if (!question?.answers?.length) {
    // No answers at all
    areQuestionAnswersValid = false
  } else {
    // There are answers
    const emptyAnswer = question?.answers.find(a => a?.answer && a?.answer !== '')
    if (!emptyAnswer?.id) {
      // But answers value is empty
      areQuestionAnswersValid = false
    }
  }
  return { isQuestionValid, areQuestionAnswersValid }
}

export const validateQuestionsAndAnswers = (questions = []) => {
  if (!questions?.length) return false
  let areQuestionsFilled = true
  let areQuestionsAnswersFilled = true
  let questionErrorIndex = -1
  let allreadyHasError = false

  questions.forEach((q, index) => {
    if (!allreadyHasError) {
      const { isQuestionValid, areQuestionAnswersValid } = validateQuestion(q)
      areQuestionsFilled = isQuestionValid
      areQuestionsAnswersFilled = areQuestionAnswersValid

      if (!areQuestionsFilled || !areQuestionsAnswersFilled) {
        // break if question or answers are invalid
        questionErrorIndex = index
        allreadyHasError = true
      }
    }
  })

  return {
    questionError: !areQuestionsFilled,
    answersError: !areQuestionsAnswersFilled,
    errorSlideIndex: questionErrorIndex
  }
}

export const validateOneSideTriviaQuestion = question => {
  const error = {}
  const { isQuestionValid, areQuestionAnswersValid } = validateQuestion(question)
  if (!isQuestionValid) {
    error.question = requiredMessages.question
  }
  if (!areQuestionAnswersValid) {
    error.answers = requiredMessages.answers
  }
  return error
}

export const validateCardFields = (data, side = 1) => {
  const error = {
    front: {},
    back: {}
  }
  switch (data.cardType) {
    case CARD_TYPES.Standard: {
      if (data.heading === '') error.front.heading = requiredMessages.heading
      if (data.category === '') error.front.category = requiredMessages.category
      break
    }
    case CARD_TYPES.Slide: {
      if (side === 2 || side === 3) {
        if (data.heading === '') error.front.heading = requiredMessages.heading
        if (data.category === '') error.front.category = requiredMessages.category
      }
      if (side === 3) if (data.slider.length === 0) error.back.slider = 'Required slide'
      break
    }
    case CARD_TYPES.Double: {
      if (data.heading === '') error.front.heading = requiredMessages.heading
      if (data.category === '') error.front.category = requiredMessages.category
      break
    }
    case CARD_TYPES.Blank: {
      if (data.category === '') error.front.category = requiredMessages.category
      break
    }
    case CARD_TYPES.Instacard: {
      if (data.scrapeUrl === '') error.front.scrapeUrl = requiredMessages.scrapeUrl
      if (data.category === '') error.front.category = requiredMessages.category
      break
    }
    case CARD_TYPES.Mystery: {
      if (data.pointsAllocation === '') error.front.pointsAllocation = requiredMessages.pointsAllocation
      if (data.userLevel === '') error.front.userLevel = requiredMessages.userLevel
      if (data.frequencyInFeeds === '') error.front.frequencyInFeeds = requiredMessages.frequencyInFeeds
      break
    }
    case CARD_TYPES.Collectible: {
      if (data.heading === '') error.front.heading = requiredMessages.heading
      if (data.userLevel === '') error.front.userLevel = requiredMessages.userLevel
      if (data.frequencyInFeeds === '') error.front.frequencyInFeeds = requiredMessages.frequencyInFeeds
      break
    }
    case CARD_TYPES.DoubleSidedTrivia: {
      if (side === 2 || side === 3) {
        if (data.heading === '') error.front.heading = requiredMessages.heading
        if (data.pointsAllocation === '') error.front.pointsAllocation = requiredMessages.pointsAllocation
        if (data.userLevel === '') error.front.userLevel = requiredMessages.userLevel
        if (data.frequencyInFeeds === '') error.front.frequencyInFeeds = requiredMessages.frequencyInFeeds
      }
      if (side === 3) {
        const { questionError, answersError, errorSlideIndex } = validateQuestionsAndAnswers(data.questions)
        if (questionError) {
          error.back.question = requiredMessages.question
          error.errorIndex = errorSlideIndex
        }
        if (answersError) {
          error.back.answers = requiredMessages.answers
          error.errorIndex = errorSlideIndex
        }
      }
      break
    }
    case CARD_TYPES.OneSidedTrivia: {
      if (data.questions[0].question === '') error.front.question = requiredMessages.question
      if (data.pointsAllocation === '') error.front.pointsAllocation = requiredMessages.pointsAllocation
      if (data.userLevel === '') error.front.userLevel = requiredMessages.userLevel
      if (data.frequencyInFeeds === '') error.front.frequencyInFeeds = requiredMessages.frequencyInFeeds
      break
    }
    case CARD_TYPES.Marketplace: {
      if (data.heading === '') error.front.heading = requiredMessages.heading
      if (data.category === '') error.front.category = requiredMessages.category
      break
    }
    case CARD_TYPES.Poll: {
      if (data.heading === '') error.front.heading = requiredMessages.heading
      if (data.pollOptions.length === 0) {
        error.front.option = 'Required option'
      } else {
        if (filterPollOptions(data.pollOptions)?.length === 0) error.front.option = requiredMessages.optionName
      }
      break
    }
    case CARD_TYPES.Event: {
      if (data.heading === '') error.front.heading = requiredMessages.heading
      if (data.byline === '') error.front.byline = 'Required'
      break
    }
    case CARD_TYPES.Sponsored: {
      if (data.heading === '') error.front.heading = requiredMessages.heading
      if (data.category === '') error.front.category = requiredMessages.category
      break
    }
    case CARD_TYPES.Anchor: {
      if (data.heading === '') error.front.heading = requiredMessages.heading
      break
    }
    default:
      break
  }
  return error
}

export const requiredMessages = {
  heading: 'Heading required',
  category: 'Category required',
  pointsAllocation: 'Points required',
  userLevel: 'Level required',
  scrapeUrl: 'URL required',
  frequencyInFeeds: 'Frequency in feeds required',
  question: 'Question required',
  answers: 'At least one answer required',
  optionName: 'Option name required'
}
