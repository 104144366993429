import React from 'react'
import styles from './search.module.scss'
import SearchIcon from 'assets/images/icons/search.svg'
import PropTypes from 'prop-types'

const Search = ({ value = '', placeholder = '', onChange = null, onKeyDown = null, onSearchClick = null }) => {
  return (
    <div className={`${styles.wrapper}`}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.searchText}>
            <input
              type="text"
              className={styles.input}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
          </div>
          <div className={styles.searchIcon}>
            <img src={SearchIcon} alt="Search" onClick={onSearchClick} />
          </div>
        </div>
      </div>
    </div>
  )
}
Search.propTypes = {
  initValue: PropTypes.string
}

export default Search
