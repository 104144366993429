import SwitchButton, { SWITCH_SIZE } from 'components/switch'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import React, { useState } from 'react'
import styles from './heading.module.scss'

export const TABS = {
  ApplicantsTabs: [
    { value: 'business', title: 'Applicants' },
    { value: 'history', title: 'History' }
  ],
  TrendingTabs: [
    { value: 'cards', title: 'Cards' },
    { value: 'trending', title: 'Topics' }
  ],
  EmptySearchesTabs: [
    { value: 'business', title: 'Business' },
    { value: 'customer', title: 'Customer' }
  ],
  ContactUsTabs: [
    { value: 'contactUs', title: 'Contact Us' },
    { value: 'history', title: 'History' }
  ],
  ManageCategoryTabs: [
    { value: 'main', title: 'Main Deck' },
    { value: 'market', title: 'Marketplace' }
  ],
  AnchorTabs: [
    { value: 'main', title: 'Main Deck' },
    { value: 'market', title: 'Marketplace' }
  ],
  NewsletterTabs: [
    { value: 'send', title: 'Send' },
    { value: 'history', title: 'History' },
    { value: 'users', title: 'Users' }
  ],
  ManageCampaign: [
    { value: '', title: 'Campaigns' },
    { value: 'general', title: 'General' },
    { value: 'marketplace', title: 'Marketplace' }
  ],
  ManageCampaignWithPartnership: [
    { value: '', title: 'Campaigns' },
    { value: 'general', title: 'General' },
    { value: 'marketplace', title: 'Marketplace' },
    { value: 'partnership', title: 'Partnerships' }
  ]
}

const Heading = ({
  title = '',
  tabs = [],
  defaultTab,
  onTabChange,
  leftMiddleElement,
  rightMiddleElement,
  bottomElement,
  titleClass,
  className
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab || (tabs?.length > 0 ? tabs[0] : null))

  const onSwitchChange = value => {
    const selectedTab = tabs?.find(el => el?.value === value)
    onTabChange && onTabChange(value)
    setActiveTab(selectedTab)
  }

  return (
    <div className={`${styles.gridWrapper} ${className || ''}`}>
      <div className={styles.upperRow}>
        {title && <Typography className={titleClass || ''} text={title} variation={TYPOGRAPHY_VARIATION.Title} />}
      </div>
      <div className={styles.leftMiddleRow}>{leftMiddleElement}</div>
      <div className={styles.title}>
        {tabs?.length > 0 && (
          <SwitchButton
            tabs={tabs}
            activeTab={activeTab?.value}
            setActiveTab={onSwitchChange}
            background
            style={{ height: '2.5rem' }}
            size={SWITCH_SIZE.Large}
          />
        )}
      </div>
      <div className={styles.rightMiddleRow}>{rightMiddleElement}</div>
      <div className={styles.bottomRow}>{bottomElement}</div>
    </div>
  )
}
export default Heading
