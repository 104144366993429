const env = process.env.REACT_APP_ENVIROMENT
let url
let wsUrl
let adminUrl
let appUrl
let awsUrl
let googleApiKey = null
let awsUrlResize = null
let stripeKey = ''
if (env === 'prod') {
  url = 'https://www.wondershuffle.com'
  adminUrl = 'https://admin.wondershuffle.com'
  appUrl = 'https://www.wondershuffle.com'
  wsUrl = 'wss://www.wondershuffle.com'
  awsUrl = 'https://wondershuffle.s3.us-east-2.amazonaws.com'
  awsUrlResize = 'https://wondershuffleresized.s3.us-east-2.amazonaws.com'
  googleApiKey = 'AIzaSyBV4-q11M7PDTItIDRHwgC9POiAlH8m_bw'
  stripeKey = 'pk_live_51LIEnIAKOZs80AlTAATvQ6D9RE9sFxaige07LKRFDzSuegIRQ1m2NJ7b7JnLX0V98qfafq7bk8VdFS1f3fic8C6r00yYpW4EDW'
} else if (env === 'staging') {
  url = 'https://wonder.hyperether.com'
  adminUrl = 'https://wonder.admin.hyperether.com'
  appUrl = 'https://wonder.hyperether.com'
  wsUrl = 'wss://wonder.hyperether.com'
  awsUrl = 'https://wonderhe.s3.us-east-2.amazonaws.com'
  awsUrlResize = 'https://wonderheresized.s3.us-east-2.amazonaws.com'
  googleApiKey = 'AIzaSyBV4-q11M7PDTItIDRHwgC9POiAlH8m_bw'
  stripeKey =
    'pk_test_51LG4c8CGhI6aqdE8RwG8SLYlBU1o9mqr0EWfOtmRVJqPpxbnfvFSlh1cz7XNnoWdBH0Ndbs07LGRnHFQkFqPiJLP009UeHU2jB'
} else if (env === 'dev') {
  url = 'https://wondertest.hyperether.com'
  wsUrl = 'wss://wondertest.hyperether.com'
  adminUrl = 'https://wondertest.admin.hyperether.com'
  appUrl = 'https://wondertest.hyperether.com'
  awsUrl = 'https://wonderhe.s3.us-east-2.amazonaws.com'
  awsUrlResize = 'https://wonderheresized.s3.us-east-2.amazonaws.com'
  googleApiKey = 'AIzaSyBV4-q11M7PDTItIDRHwgC9POiAlH8m_bw'
  stripeKey =
    'pk_test_51LG4c8CGhI6aqdE8RwG8SLYlBU1o9mqr0EWfOtmRVJqPpxbnfvFSlh1cz7XNnoWdBH0Ndbs07LGRnHFQkFqPiJLP009UeHU2jB'
} else if (env === 'devTest') {
  url = 'https://wonder-devtest.hyperether.com'
  wsUrl = 'wss://wonder-devtest.hyperether.com'
  adminUrl = 'https://wonder-devtest.admin.hyperether.com'
  appUrl = 'https://wonder-devtest.hyperether.com'
  awsUrl = 'https://wonderhe.s3.us-east-2.amazonaws.com'
  awsUrlResize = 'https://wonderheresized.s3.us-east-2.amazonaws.com'
  googleApiKey = 'AIzaSyBV4-q11M7PDTItIDRHwgC9POiAlH8m_bw'
  stripeKey =
    'pk_test_51LG4c8CGhI6aqdE8RwG8SLYlBU1o9mqr0EWfOtmRVJqPpxbnfvFSlh1cz7XNnoWdBH0Ndbs07LGRnHFQkFqPiJLP009UeHU2jB'
} else if (env === 'seo') {
  url = 'https://wonderseo.hyperether.com'
  wsUrl = 'wss://wonderseo.hyperether.com'
  adminUrl = 'https://wonderseo.admin.hyperether.com'
  appUrl = 'https://wonderseo.hyperether.com'
  awsUrl = 'https://wonderhe.s3.us-east-2.amazonaws.com'
  awsUrlResize = 'https://wonderheresized.s3.us-east-2.amazonaws.com'
  googleApiKey = 'AIzaSyBV4-q11M7PDTItIDRHwgC9POiAlH8m_bw'
  stripeKey =
    'pk_test_51LG4c8CGhI6aqdE8RwG8SLYlBU1o9mqr0EWfOtmRVJqPpxbnfvFSlh1cz7XNnoWdBH0Ndbs07LGRnHFQkFqPiJLP009UeHU2jB'
} else {
  url = 'http://localhost:3000'
  adminUrl = 'http://localhost:3001'
  appUrl = 'http://localhost:3002/'
  wsUrl = 'ws://localhost:3000'
  awsUrl = 'https://wonderhe.s3.us-east-2.amazonaws.com'
  awsUrlResize = 'https://wonderheresized.s3.us-east-2.amazonaws.com'
  googleApiKey = 'AIzaSyBV4-q11M7PDTItIDRHwgC9POiAlH8m_bw'
  stripeKey =
    'pk_test_51LG4c8CGhI6aqdE8RwG8SLYlBU1o9mqr0EWfOtmRVJqPpxbnfvFSlh1cz7XNnoWdBH0Ndbs07LGRnHFQkFqPiJLP009UeHU2jB'
}
export { url, wsUrl, googleApiKey, awsUrl, awsUrlResize, adminUrl, appUrl, stripeKey }
