import React from 'react'
import styles from './textarea.module.scss'

const TextArea = ({
  textareaClass = 'textareaDefault',
  rows = '4',
  type,
  name,
  value,
  placeholder,
  onChange,
  autofocus = false,
  disabled = false,
  label,
  onKeyPress,
  autoComplete = 'off',
  error = false,
  errorMsg,
  style,
  containerStyle,
  numbered,
  className
}) => {
  return (
    <div className={`${styles[textareaClass]} ${className}`} style={containerStyle}>
      {label && (
        <label htmlFor={name}>
          {label} {error && <span className={styles.error}>*</span>}
        </label>
      )}
      <div style={{ position: 'relative' }}>
        <textarea
          className={`${numbered ? styles.textareaNumbered : styles.textarea} ${className}`}
          style={style}
          id={name}
          type={type}
          name={name}
          onKeyPress={onKeyPress}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          autoFocus={autofocus}
          disabled={disabled}
          autoComplete={autoComplete}
          rows={rows}
        />
      </div>

      {error && <span className={styles.errorMsg}>{errorMsg}</span>}
    </div>
  )
}

export default TextArea
