import defaultUser from 'assets/images/defaultUser.png'
import PropTypes from 'prop-types'
import styles from './userAvatar.module.scss'

const UserAvatar = ({ width, height, imageUrl, onClick, className }) => {
  const onImageNonExist = err => {
    err.target.onError = null
    err.target.src = defaultUser
  }
  return (
    <div className={`${styles.content} ${className}`}>
      <img
        alt=""
        src={imageUrl ?? defaultUser}
        style={{ width: `${width}rem`, height: `${height}rem` }}
        onClick={onClick}
        onError={onImageNonExist}
        className={styles.avatar}
      />
    </div>
  )
}

UserAvatar.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
}

export default UserAvatar
