import Button from 'components/button'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toastService } from 'services/toastService'
import { sendMultipleInvites } from 'store/reducers/auth/thunk'
import { closeModal } from 'store/reducers/modal/modalSlice'
import validateEmail from 'utils/validateEmail'
import iconClose from 'assets/icons/iconCloseBlue.svg'
import styles from './inviteUserModal.module.scss'

const InviteUser = () => {
  const dispatch = useDispatch()

  const inputArr = [
    {
      type: 'text',
      id: 1,
      value: ''
    }
  ]
  const [arr, setArr] = useState(inputArr)

  const deleteInput = index => {
    const newArr = [...arr]
    newArr.splice(index, 1)
    setArr(newArr)
  }

  const addInput = () => {
    setArr(s => {
      return [
        ...s,
        {
          type: 'email',
          value: ''
        }
      ]
    })
  }

  const handleChange = e => {
    e.preventDefault()

    const index = e.target.id
    setArr(s => {
      const newArr = s.slice()
      newArr[index].value = e.target.value

      return newArr
    })
  }

  const closeModalX = () => {
    dispatch(closeModal())
  }

  const inviteMultipleUsers = () => {
    const emails = []
    if (arr && arr.length > 0) {
      arr.map(one => {
        if (validateEmail(one.value)) {
          emails.push(one.value)
        }
      })
    }
    if (emails.length === 0) {
      toastService('error', 'Email fields are empty or invalid.')
    } else {
      dispatch(sendMultipleInvites(emails))
      closeModalX()
    }
  }

  return (
    <div className={styles.inviteUserWrapper}>
      <div className={styles.header}>
        <div onClick={closeModalX} className={styles.close}>
          <img src={iconClose} alt="Close icon" />
        </div>
        <div>Invite Users</div>
        <div></div>
      </div>
      <div className={styles.text}>
        Enter as many emails as you like. <br /> Users will be able to signup via a link in the email.
      </div>
      <div className={styles.inputsWrapper}>
        <button onClick={addInput}>+</button>
        {arr && arr.length > 0 && (
          <div className={styles.inputs}>
            {arr &&
              arr.length &&
              arr.length > 0 &&
              arr.map((item, i) => {
                return (
                  <div className={styles.singleInput} key={i}>
                    <input
                      onChange={handleChange}
                      value={item.value}
                      id={i}
                      type={item.type}
                      placeholder="Enter email"
                    />
                    <span onClick={() => deleteInput(i)}>x</span>
                  </div>
                )
              })}
          </div>
        )}
        {arr && arr.length === 0 && <div className={styles.inputs}> </div>}
      </div>
      <div className={styles.buttonDiv}>
        <Button label="Send Invites" variation="secondary" onClick={inviteMultipleUsers} disabled={!arr.length > 0} />
      </div>
    </div>
  )
}

export default InviteUser
