import React, { useEffect, useState } from 'react'
import dataService from 'services/data.service'
import styles from './anchor.module.scss'
import CardFlip from 'components/cardFlip'
import Button from 'components/button'
import { toastService } from 'services/toastService'
import Heading, { TABS } from 'components/Heading'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'

const Anchor = () => {
  const [allAnchorCards, setAllAnchorCards] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [filterSearch, setFilterSearch] = useState('')
  const [activeTab, setActiveTab] = useState('main')
  const [anchorConfig, setAnchorConfig] = useState({})
  const [anchorCards, setAnchorCards] = useState([])
  const [showUpdate, setShowUpadte] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchCards = async () => {
      const name = filterSearch
      let filter = { cardType: 'anchor', public: true }
      if (name !== '') {
        filter = { ...filter, heading: { $regex: name, $options: 'i' } }
      }
      const res = await dataService.run('data', 'card', 'all', {
        filter: filter,
        sort: { views: -1 }
      })

      setAllAnchorCards(res)
    }

    fetchCards()
  }, [filterSearch])

  useEffect(() => {
    const fetchAnchorCards = async () => {
      setShowUpadte(false)
      const config = await dataService.run('config', 'anchorCards', 'get', {})
      setAnchorConfig(config)
      let ids = []
      if (activeTab === 'main') {
        ids = [...config.mainDeck]
      } else if (activeTab === 'market') {
        ids = [...config.marketplace]
      }

      const res = await dataService.run('data', 'card', 'all', {
        filter: { _id: { $in: ids }, disabled: null }
      })
      setAnchorCards(res)
      if (res.length === 0) {
        toastService('info', 'Please select 4 cards.')
        setShowUpadte(true)
      }
    }
    fetchAnchorCards()
  }, [activeTab])

  const handleChange = e => {
    setSearchValue(e.target.value)
  }

  const setValue = e => {
    if (e.key === 'Enter') {
      setFilterSearch(searchValue)
    }
  }

  const handleDeleteClick = card => {
    if (anchorCards && anchorCards.length > 0) {
      const newAnchor = []
      anchorCards.map(one => {
        if (one._id !== card._id) {
          newAnchor.push(one)
        }
      })
      setShowUpadte(true)
      setAnchorCards(newAnchor)
    }
  }

  const isCardSelected = currentCard => {
    if (anchorCards.length === 0) return false
    return anchorCards.find(c => c._id === currentCard._id)
  }

  const onSelectionClick = selectedCard => {
    const alreadySelected = anchorCards.find(c => c._id === selectedCard._id)
    if (alreadySelected) {
      const cardsWithoutDeselectedCard = []
      anchorCards.map(c => {
        if (c._id !== selectedCard._id) cardsWithoutDeselectedCard.push(c)
      })
      setAnchorCards(cardsWithoutDeselectedCard)
    } else {
      setAnchorCards([...anchorCards, selectedCard])
    }
    if (anchorCards.length < 4) {
      setShowUpadte(true)
    }
  }

  const updateConfig = async () => {
    setLoading(true)
    const newAnchorIds = []
    const newConfig = { ...anchorConfig }
    if (anchorCards && anchorCards.length > 0) {
      anchorCards.map(one => {
        newAnchorIds.push(one._id)
      })
    }
    if (activeTab === 'main') {
      newConfig.mainDeck = [...newAnchorIds]
    } else if (activeTab === 'market') {
      newConfig.marketplace = [...newAnchorIds]
    }
    const res = await dataService.run('config', 'anchorCards', 'update', newConfig)

    if (res) {
      toastService('success', 'Successfully updated anchor cards.')
      setShowUpadte(false)
    } else {
      toastService('error', 'Error updating anchor cards.')
    }
    setLoading(false)
  }

  return (
    <div className={styles.trendingWrapper}>
      <Heading
        title="Anchor Cards"
        tabs={TABS.AnchorTabs}
        onTabChange={setActiveTab}
        leftMiddleElement={
          <div className={styles.filter}>
            <div className={styles.third}>
              <input
                type="text"
                onChange={handleChange}
                onKeyUp={setValue}
                value={searchValue}
                placeholder="Enter card name"
              />
            </div>
          </div>
        }
        rightMiddleElement={
          showUpdate && (
            <Button
              label="Update config"
              size="fitContent"
              disabled={anchorCards.length < 4}
              onClick={updateConfig}
              spinner={loading}
            />
          )
        }
      />
      <div className={styles.cardsDiv}>
        <div className={styles.savedOrDeletedCards}>
          <div className={styles.cards}>
            {anchorCards &&
              anchorCards.length > 0 &&
              anchorCards.map(one => {
                return (
                  <CardFlip
                    data={one}
                    key={one._id}
                    handleDelete={() => handleDeleteClick(one)}
                    deletable={true}
                    flippable={false}
                    saveable={false}
                    selectAnchor={true}
                  />
                )
              })}
          </div>
        </div>
      </div>
      {anchorCards && anchorCards.length < 4 && showUpdate && (
        <>
          <div className={styles.cardsDiv}>
            <div className={styles.savedOrDeletedCards}>
              <div className={styles.cards}>
                {allAnchorCards &&
                  allAnchorCards.length > 0 &&
                  allAnchorCards.map(one => {
                    return (
                      <CardFlip
                        data={one}
                        key={one._id}
                        deletable={true}
                        selectable={true}
                        isCardSelected={() => isCardSelected(one)}
                        onSelectionClick={() => onSelectionClick(one)}
                      />
                    )
                  })}
                {allAnchorCards && allAnchorCards.length === 0 && (
                  <Typography
                    text="There are no cards"
                    variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
                    className="marginY10"
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Anchor
