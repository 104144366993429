import Button from 'components/button'
import Input from 'components/input'
import Selector from 'components/select'
import TextArea from 'components/textarea'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toastService } from 'services/toastService'
import userService from 'services/user.service'
import validateEmail from 'utils/validateEmail'
import styles from './contact.module.scss'

const CONTACT_US_OPTIONS = [
  { value: 'serviceWithaSmile', label: 'Service With a Smile' },
  { value: 'techIssues', label: 'Tech Issues' },
  { value: 'privacyLegal', label: 'Privacy & Legal' }
]

const Contact = () => {
  const [error, setError] = useState(false)
  const user = useSelector(state => state.auth.user)
  const [details, setDetails] = useState({ name: '', email: user.email, text: '', help: '' })

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      if (!validateEmail(details.email)) {
        toastService('error', 'Email is not valid.')
      }
      if (validateForm()) {
        setError(false)
        await userService.sendContactUsQuestion(details)
        toastService('success', 'Successfully sent question.')
        setDetails({ name: '', email: user.email, text: '', help: '' })
      } else {
        setError(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const validateForm = () => {
    return details.name && details.email && validateEmail(details.email) && details.text
  }

  return (
    <div className={styles.contactWrapper}>
      <div className={styles.header}>Contact</div>
      <div className={styles.content}>
        <div className={styles.singleRowInput}>
          <span className={error && !details.name ? styles.errorSpan : styles.heading}>Name</span>
          <Input
            variation="cardInput"
            inputClass="lgInputLeft"
            placeholderOrentation="placeholderCenter"
            placeholder="Enter your name"
            type="text"
            name="name"
            value={details.name}
            onChange={e => setDetails({ ...details, name: e.target.value })}
          />
        </div>
        <div className={styles.singleRowInput}>
          <span className={error && !details.email ? styles.errorSpan : styles.heading}>Email</span>
          <Input
            variation="cardInput"
            inputClass="lgInputLeft"
            placeholderOrentation="placeholderCenter"
            placeholder="Enter your email"
            type="email"
            name="email"
            value={details.email}
            onChange={e => setDetails({ ...details, email: e.target.value })}
          />
        </div>
        <div className={styles.singleRowInput}>
          <span className={error && !details.help ? styles.errorSpan : styles.heading}>Help</span>
          <Selector
            handleChangeSelect={e => setDetails({ ...details, help: e.value })}
            selectedOption={CONTACT_US_OPTIONS.find(option => option?.value === details?.help)}
            selectClass={'selectGray'}
            options={CONTACT_US_OPTIONS}
            placeholder={'How can we help?'}
            name="help"
            value={details.help}
          />
        </div>

        <div className={styles.singleRowInput}>
          <span className={error && !details.text ? styles.errorSpan : styles.heading}>Details</span>

          <TextArea
            type="text"
            name="text"
            onChange={e => setDetails({ ...details, text: e.target.value })}
            rows={5}
            className={styles.contInputArea}
            value={details.text}
          />
        </div>
      </div>
      <div className={styles.buttonDiv}>
        <Button label="Submit" onClick={handleSubmit} variation="secondary" />
      </div>
    </div>
  )
}

export default Contact
