import React, { useState } from 'react'
import styles from './simpleSearch.module.scss'
import search from 'assets/images/icons/header/search_icon.png'
import Topic from 'components/topic'

const SimpleSearch = ({ initValue = '', onSearch, name, className, placeholder, selected = '', onSelectionClose }) => {
  const [searchValue, setSearchValue] = useState(initValue)

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onSearchHandler()
    }
  }

  const onChangeHandler = event => {
    if (event.target.value === '') onSearchHandler()
    setSearchValue(event.target.value)
  }

  const onSearchHandler = () => {
    onSearch && onSearch(searchValue)
  }

  return (
    <div className={`${styles.searchWrapper} ${styles[className]} ${className}`}>
      <div className={`${styles.wrapper} ${styles[className]}`}>
        <div className={styles.content}>
          <div className={styles.searchText}>
            {selected !== '' && <Topic className={styles.selectedTopic} label={selected} onClose={onSelectionClose} />}
            <input
              type="text"
              onKeyPress={onKeyPress}
              className={styles.input}
              name={name}
              value={searchValue}
              onChange={onChangeHandler}
              placeholder={placeholder}
            />
          </div>
          <div className={styles.searchIcon} onClick={onSearchHandler}>
            <img src={search} alt="Search" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleSearch
