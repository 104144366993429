import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  show: false,
  onclose: null,
  user: null,
  defaultChat: null
}
export const chatModal = createSlice({
  name: 'chat modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { user = null, defaultChat = null } = action.payload
      state.show = true
      state.user = user
      state.defaultChat = defaultChat
    },
    closeModal: state => {
      state.show = false
      state.onclose = null
      state.user = null
      state.defaultChat = null
    }
  }
})

export const { openModal, closeModal } = chatModal.actions

export default chatModal.reducer
