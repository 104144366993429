import Heading from 'components/Heading'
import ImageUploaderToAWS from 'components/imageUploaderToAWS'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import React from 'react'
import styles from './uploadMysteryImages.module.scss'

const UploadMysteryImages = () => {
  return (
    <div className={styles.wrapper}>
      <Heading title={'Mystery Card Image'} />
      <div className={styles.container}>
        <div className={styles.uploader}>
          <Typography variation={TYPOGRAPHY_VARIATION.Subtitle} text="Front side" />
          <Typography variation={TYPOGRAPHY_VARIATION.LargerParagraph} text="Click on image to select new" />
          <ImageUploaderToAWS textInput="mystery_card" image={true} showInput={false} inputWidth={18} />
        </div>
        <div className={styles.uploader}>
          <Typography variation={TYPOGRAPHY_VARIATION.Subtitle} text="Card type selection slider" />
          <Typography variation={TYPOGRAPHY_VARIATION.LargerParagraph} text="Click on image to select new" />
          <ImageUploaderToAWS textInput="mystery_card_slider" image={true} showInput={false} inputWidth={30} />
        </div>
      </div>
    </div>
  )
}

export default UploadMysteryImages
