import React from 'react'
import PropTypes from 'prop-types'
import AttributeIcon from './icon'
import styles from './attributeButton.module.scss'
const AttributeButton = ({
  label,
  className,
  containerClass,
  onClick,
  style,
  active = true,
  disabled = false,
  info = '',
  error,
  errorMsg
}) => {
  const onClickHandler = e => {
    if (!disabled) onClick && onClick(e)
  }
  return (
    <div
      className={`${styles.attributeContainer} ${disabled && styles.disabled} ${className} ${
        styles[className]
      } ${containerClass} ${styles[containerClass]}`}
      style={style}
      onClick={onClickHandler}
    >
      <div className={styles.content}>
        <div className={styles.icon}>
          {active && <AttributeIcon plus={true} />}
          {!active && <AttributeIcon plus={false} />}
        </div>
        <div className={styles.label}>
          <span>{label}</span>
        </div>
      </div>
      {(info !== '' || (error && errorMsg)) && (
        <div className={styles.info}>
          {!errorMsg && <span>{info}</span>}
          {error && errorMsg && <span className={styles.errorMsg}>{errorMsg}</span>}
        </div>
      )}
    </div>
  )
}

AttributeButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.string,
  state: PropTypes.bool
}
export default AttributeButton
