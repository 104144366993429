import CardStandardFrontEditable from 'components/card/editable/standardFront'
import CardAnchorFront from 'components/card/types/anchorFront'
import CardEventFront from 'components/card/types/eventFront'
import CardMysteryFront from 'components/card/types/mysteryFront'
import CardOneSidedTriviaFront from 'components/card/types/oneSidedTriviaFront'
import CardPollFront from 'components/card/types/pollFront'
import { useSelector } from 'react-redux'
import { CARD_SIZE, CARD_TYPES, FORM_SIDE } from 'staticConstants'
import styles from './cardCreation.module.scss'
import CardCreationForm from './form'

const CardFrontLayout = ({ className }) => {
  const card = useSelector(state => state.card.selectedCard)

  return (
    <>
      <div className={`${styles.row} ${className}`}>
        {/* LEFT SIDE  */}
        {/* 
          Standard front editable card 
          Type : default, standard, slide, double, collectable 
          */}
        {(card.cardType === '' ||
          card.cardType === CARD_TYPES.Create ||
          card.cardType === CARD_TYPES.Standard ||
          card.cardType === CARD_TYPES.Slide ||
          card.cardType === CARD_TYPES.Double ||
          card.cardType === CARD_TYPES.Blank ||
          card.cardType === CARD_TYPES.DoubleSidedTrivia ||
          card.cardType === CARD_TYPES.Collectible ||
          card.cardType === CARD_TYPES.Marketplace ||
          card.cardType === CARD_TYPES.Sponsored) && (
          <CardStandardFrontEditable
            type={card?.cardType || CARD_TYPES.Create}
            card={card}
            hideHeader={card?.cardType === CARD_TYPES.Blank}
          />
        )}
        {/* 
          Mystery front card 
          Type : mystery
          */}
        {card.cardType === CARD_TYPES.Mystery && <CardMysteryFront size={CARD_SIZE.Large} />}
        {/* 
          Poll front card 
          Type : poll
          */}
        {card.cardType === CARD_TYPES.Poll && <CardPollFront size={CARD_SIZE.Large} card={card} editable />}
        {/* 
          One-sided trivia
          Type : oneSidedTrivica
          */}
        {card.cardType === CARD_TYPES.OneSidedTrivia && (
          <CardOneSidedTriviaFront card={card} size={CARD_SIZE.Large} editable />
        )}
        {/* 
          Anchor front card 
          Type : anchor
          */}

        {card.cardType === CARD_TYPES.Anchor && <CardAnchorFront size={CARD_SIZE.Large} card={card} editable />}
        {/* 
          Event front card 
          Type : Event
          */}
        {card.cardType === CARD_TYPES.Event && <CardEventFront editable card={card} size={CARD_SIZE.Large} />}
        {/* RIGHT SIDE  */}
        <div className={styles.contentWrapper}>
          <CardCreationForm side={FORM_SIDE.Front} />
        </div>
      </div>
    </>
  )
}

export default CardFrontLayout
