import React, { useEffect, useState } from 'react'
import styles from './questionSlider.module.scss'
import CardScrollableContent from '../scrollableContent'
import { CARD_TYPES } from 'staticConstants'
import SliderDots from './dots'
import SliderAnswer from './answer'
import SliderSlide from './slide'
import { useDispatch, useSelector } from 'react-redux'
// import Rays from 'components/rays'
import { openModal } from 'store/reducers/modal/modalSlice'
import Login from 'pages/auth/login'

const initialAnswer = { id: 0, answer: '', correct: true }

const QuestionsSlider = ({
  className,
  slides = [],
  defaultAnswers = [],
  onSlideChange,
  showDots = true,
  showQuestion = true,
  onFinish
  // points = 0
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [choosenAnswer, setChoosenAnswer] = useState(initialAnswer)
  const [userAnswers, setUserAnswers] = useState([])
  // const [triggerAnimation, setTriggerAnimation] = useState(false)
  const checkPreviewModal = useSelector(state => state?.previewModal?.item)
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const onMoveDot = index => {
    setCurrentSlideIndex(index)
    onSlideChange && onSlideChange(index)
    userAnswers[index] ? setChoosenAnswer(userAnswers[index]) : setChoosenAnswer(initialAnswer)
  }

  const checkIfQuestionIsAlreadyAnswered = () => {
    return userAnswers.length > currentSlideIndex
  }

  const onAnswerClickedHandler = answer => {
    if (user) {
      if (Object.keys(checkPreviewModal).length > 0) {
        if (!checkIfQuestionIsAlreadyAnswered(answer)) {
          setUserAnswers([...userAnswers, answer])
          setChoosenAnswer(answer)
          // if (answer.correct) {
          //   setTriggerAnimation(true)
          //   setTimeout(() => {
          //     setTriggerAnimation(false)
          //   }, 2500)
          // }
        }
      }
    } else {
      dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
    }
  }

  const getAnswerStyle = answer => {
    if (choosenAnswer.id === 0) {
      return ''
    }
    if (choosenAnswer.id === answer.id) return answer.correct ? styles.correctAnswer : styles.wrongAnswer
    return styles.notChoosenAnswer
  }

  const isDotValid = dotIndex => {
    let isValid = false
    userAnswers.map((answer, index) => {
      if (dotIndex === index + 1) isValid = true
    })
    return dotIndex === 0 ? true : isValid
  }

  useEffect(() => {
    if (defaultAnswers.length > 0) {
      setUserAnswers(defaultAnswers)
      setChoosenAnswer(defaultAnswers[0])
    }
  }, [defaultAnswers])

  useEffect(() => {
    if (userAnswers.length === slides.length && defaultAnswers.length === 0) onFinish && onFinish(userAnswers)
  }, [userAnswers])

  return (
    <div className={`${className} ${styles[className]} ${styles.container}`}>
      {/* Slides */}
      <div className={styles.slidesContainer}>
        {slides.length > 0 &&
          slides.map((slide, index) => {
            if (slide.question !== '' && slide.answers.length > 0)
              return (
                <SliderSlide
                  key={'slide' + index}
                  slide={slide}
                  currentSlideIndex={currentSlideIndex}
                  slideIndex={index}
                  showDots={showDots}
                >
                  {/* Question / Explanation */}

                  {showQuestion && choosenAnswer.id !== 0 ? (
                    <CardScrollableContent className={styles.scrollableQuestionContainer}>
                      <span className={styles.question}>{slide.explanation}</span>
                    </CardScrollableContent>
                  ) : (
                    <div></div>
                  )}
                  {/* Answers */}
                  <CardScrollableContent
                    className={styles.scrollableOptionsContainer}
                    type={CARD_TYPES.DoubleSidedTrivia}
                  >
                    <div className={styles.answersContainer}>
                      {slide.answers.map(
                        answer =>
                          answer.answer !== '' && (
                            <SliderAnswer
                              key={answer.id}
                              answer={answer}
                              onClick={onAnswerClickedHandler}
                              getAnswerStyle={getAnswerStyle}
                            />
                          )
                      )}
                    </div>
                  </CardScrollableContent>
                </SliderSlide>
              )
          })}
      </div>
      {/* Dots  */}
      {showDots && slides.length > 0 && (
        <SliderDots
          slides={slides}
          onChange={onMoveDot}
          currentSlideIndex={currentSlideIndex}
          validateQuestion
          isDotValid={isDotValid}
        />
      )}
      {/* {triggerAnimation && <Rays points={points} />} */}
    </div>
  )
}

export default QuestionsSlider
