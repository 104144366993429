import React from 'react'
import styles from './counter.module.scss'

const Counter = ({ value = 0, className }) => {
  const getFormatedValue = number => {
    return (number / 100000).toFixed(5).replace('.', '').split('')
  }
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.numberContainer}>
        {getFormatedValue(value).map((number, index) => {
          return (
            <div key={index} className={styles.number}>
              {number}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Counter
