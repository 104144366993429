import CardFlip from 'components/cardFlip'
import ContentViewerForRegistration from 'components/ContentViewerForRegistration'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import { clearPreview, setPreview, setClosePreview } from 'store/reducers/previewModal/previewModalSlice'
import { isObjectEmpty } from 'utils/helpers'

const CardPreviewListener = () => {
  const dispatch = useDispatch()
  const { updatedCard } = useSelector(state => state.card)
  const {
    item,
    editable,
    deletable,
    saveable,
    exitable,
    flipped,
    flipWithAnimation,
    onClose,
    closePreview,
    flippable = true,
    previewModalOpened,
    testInsta,
    showMature
  } = useSelector(store => store?.previewModal)

  const closePreviewModal = () => {
    dispatch(setClosePreview())
  }

  const onCloseModal = () => {
    dispatch(clearPreview({}))
  }

  const openPreviewModal = () => {
    if (!testInsta) {
      if (item?.cardType === 'registrationCard') {
        dispatch(
          openModal({
            show: true,
            content: (
              <ContentViewerForRegistration
                title={item?.title}
                content={item?.content}
                price={item?.price}
                isVideo={item?.title === 'video'}
                isImage={item?.title === 'image'}
              />
            ),
            name: 'previewModal',
            size: 'max1000',
            xButton: true,
            onclose: onCloseModal
          })
        )
      } else {
        dispatch(
          openModal({
            show: true,
            content: (
              <CardFlip
                flippable={flippable && (!item?.mature || Boolean(showMature))}
                key={'previewCard' + item?.title}
                className="previewCard"
                data={item}
                exitable={exitable}
                saveable={saveable}
                editable={editable}
                deletable={deletable}
                flipped={flipped && !item?.mature && !showMature}
                flippingAnimation={flipWithAnimation && !item?.mature && !showMature}
                onClose={closePreviewModal}
                previewModalOpened={previewModalOpened}
              />
            ),
            name: 'previewModal',
            size: 'max320WithTransparentBg',
            xButton: false,
            onclose: closePreviewModal
          })
        )
      }
    }
  }

  // Update previewed card/pack in redux
  useEffect(() => {
    if (!isObjectEmpty(item) && !isObjectEmpty(updatedCard) && updatedCard?._id === item?._id)
      dispatch(setPreview({ item: updatedCard }))
  }, [updatedCard])

  // Synchronise previewed item and redux
  useEffect(() => {
    if (!isObjectEmpty(item)) {
      openPreviewModal()
    }
  }, [item, showMature])

  // Closing preview
  useEffect(() => {
    if (closePreview) {
      dispatch(clearPreview({}))
      dispatch(closeModal())
      onClose && onClose()
    }
  }, [closePreview])

  return <></>
}
export default CardPreviewListener
