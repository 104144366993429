import CardScrollableContent from 'components/card/components/scrollableContent'
import DndGrid from 'components/dndGrid'
import { useDispatch } from 'react-redux'
import styles from './packCreation.module.scss'
import { onCardsChange, onRemoveFromSelection } from './selection'

const PackSelectionPreview = ({ pack }) => {
  const dispatch = useDispatch()
  return (
    <div className={`${styles.packSelection} ${styles.container}`}>
      <div className={styles.selectedCardsContainer}>
        <CardScrollableContent className={styles.scrollableContent}>
          {pack.cards.length > 0 ? (
            <DndGrid
              data={pack.cards}
              onRemoveFromSelection={event => onRemoveFromSelection(event, pack, dispatch)}
              onChange={event => onCardsChange(event, pack, dispatch)}
            />
          ) : (
            <span> There are no selected cards!</span>
          )}
        </CardScrollableContent>
      </div>
    </div>
  )
}
export default PackSelectionPreview
