import iconCloseBlue from 'assets/icons/iconCloseBlue.svg'
import AttributeButton from 'components/attributeButton'
import Button from 'components/button'
import InlinePack from 'components/card/components/inlinePack'
import CardScrollableContent from 'components/card/components/scrollableContent'
import CardFront from 'components/cardFront'
import DndGrid from 'components/dndGrid'
import Input from 'components/input'
import Selector from 'components/select'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_CREATE_CATEGORY_OPTIONS, CARD_SIZE } from 'staticConstants'
import { setIsPackSelected, setRequiredFields, setSelectedPack } from 'store/reducers/pack/packSlice'
import useWindowDimensions from 'utils/screenSize'
import styles from './packCreation.module.scss'

export const arePackFieldsValid = (pack, dispatch) => {
  const tempErrorMessages = {}
  let valid = true
  if (pack.heading === '') {
    tempErrorMessages.heading = 'Name required'
    valid = false
  } else {
    tempErrorMessages.heading = ''
  }
  if (pack.category === '') {
    tempErrorMessages.category = 'Category required'
    valid = false
  } else {
    tempErrorMessages.category = ''
  }
  dispatch(setRequiredFields({ error: !valid, errorMessages: tempErrorMessages }))
  return valid
}

export const onRemoveFromSelection = (card, pack, dispatch) => {
  const selectedCardsWithouotDeselectedCard = []

  pack.cards.map(c => {
    if (c._id !== card._id) selectedCardsWithouotDeselectedCard.push(c)
  })
  dispatch(setSelectedPack({ ...pack, cards: selectedCardsWithouotDeselectedCard }))
}
export const onCardsChange = (reorderedCards, pack, dispatch) => {
  dispatch(setSelectedPack({ ...pack, cards: reorderedCards }))
}
const PackSelection = ({ pack }) => {
  const dispatch = useDispatch()
  const { isMobile } = useWindowDimensions()
  const requiredFields = useSelector(state => state.pack.requiredFields)
  const [categoryOptions] = useState([...CARD_CREATE_CATEGORY_OPTIONS])
  const [showNewCard, setShowNewCard] = useState(false)

  const onChange = (key, value) => {
    dispatch(setSelectedPack({ ...pack, [key]: value }))
  }

  const onSubmit = () => {
    dispatch(setIsPackSelected(true))
  }

  const onMobileSubmit = () => {
    if (arePackFieldsValid(pack, dispatch)) {
      dispatch(setIsPackSelected(true))
    }
  }

  const selectedCard = card => {
    if (pack && pack.cards) dispatch(setSelectedPack({ ...pack, cards: [...pack.cards, card] }))
    setShowNewCard(false)
  }

  return (
    <div className={`${styles.packSelection} ${styles.container}`}>
      <div className={styles.headerTitle}>
        <span className={styles.SelectedCards}>Selected Cards ({pack.cards.length})</span>
        {isMobile ? (
          <>
            <Input
              placeholder="Pack Name"
              variation="cardInput"
              containerClass="fullWidth"
              className={isMobile ? 'defaultInputMobile' : 'defaultInput'}
              onChange={event => onChange('heading', event.target.value)}
              value={pack.heading ?? ''}
              error={requiredFields.error}
              errorMsg={requiredFields.errorMessages?.heading ?? ''}
            />
            <Selector
              placeholder="Category"
              selectedOption={categoryOptions.find(option => option.value === pack.category)}
              handleChangeSelect={event => onChange('category', event.value)}
              options={categoryOptions}
              error={requiredFields.error}
              errorMsg={requiredFields.errorMessages?.category ?? ''}
              className={styles.packSelector}
            />
            {pack._id && (
              <AttributeButton
                label="Add new card"
                className="full-width"
                onClick={() => setShowNewCard(true)}
                containerClass={styles.addNewCardBtn}
              />
            )}
          </>
        ) : null}

        {isMobile ? null : <Button label="Create New Pack" className={styles.createBtn} onClick={onSubmit} />}
      </div>
      <div className={styles.selectedCardsContainer}>
        {showNewCard && (
          <div className={styles.addNewCard}>
            <img
              className={styles.closeAddNewCard}
              src={iconCloseBlue}
              alt="Close icon"
              onClick={() => setShowNewCard(false)}
            />
            <InlinePack onAdd={card => selectedCard(card)} />
          </div>
        )}
        <CardScrollableContent className={styles.scrollableContent}>
          {pack.cards?.length > 0 ? (
            isMobile ? (
              <div className={styles.mobileCards}>
                {pack.cards?.map((item, index) => {
                  return (
                    <CardFront
                      className={styles.anchorStyle}
                      card={item}
                      key={item._id + index}
                      size={CARD_SIZE.ExtraSmall}
                      onRemoveFromSelection={event => onRemoveFromSelection(event, pack, dispatch)}
                      isAddNewCardActive={showNewCard}
                    />
                  )
                })}
              </div>
            ) : (
              <DndGrid
                data={pack.cards}
                onRemoveFromSelection={event => onRemoveFromSelection(event, pack, dispatch)}
                onChange={event => onCardsChange(event, pack, dispatch)}
              />
            )
          ) : (
            <span> There are no selected cards!</span>
          )}
        </CardScrollableContent>
      </div>
      {isMobile && (
        <div className={styles.mobileBtns}>
          <Button label="Next" className={styles.createBtnDown} onClick={onMobileSubmit} />
        </div>
      )}
    </div>
  )
}
export default PackSelection
