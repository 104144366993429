import Button from 'components/button'
import { useDispatch } from 'react-redux'
import { closeModal } from 'store/reducers/modal/modalSlice'
import styles from './confirmModal.module.scss'

const ConfirmationModal = ({ type = '' }) => {
  const dispatch = useDispatch()

  const toHomeHandler = () => {
    dispatch(closeModal())
  }

  const handleTypeConfirmation = () => {
    switch (type) {
      case 'Deactivate Account':
        return <p>Please check your email and follow the link to deactivate your account.</p>
      case 'Restore Account':
        return <p>Please check your email and follow the link to restore your account.</p>
      case 'Change Password':
        return <p>Please check your email and follow the link to change your password.</p>
      case 'Change Personal Details':
        return <p>Please check your email and follow the link to change personal details.</p>
      default:
        return <p>Please check your email and follow the link to edit your account.</p>
    }
  }

  return (
    <div className={styles.confirmationModal}>
      <p className={styles.header}>Thanks!</p>
      <div className={styles.textDiv}>
        <p> {handleTypeConfirmation()}</p>
      </div>
      <Button onClick={toHomeHandler} variation="secondary" label={'Ok'} className={styles.btn} />
    </div>
  )
}

export default ConfirmationModal
