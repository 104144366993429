import React from 'react'
import styles from './videoPreview.module.scss'

const VideoPreview = ({ video, className }) => {
  return (
    <>
      {video && (
        <video
          title=" "
          src={video}
          frameBorder="0"
          playsInline
          controls
          allowFullScreen={true}
          className={`${styles.previewVideo} ${className} ${styles[className]}`}
        >
          <source src={video} type="video/mp4" />
        </video>
      )}
    </>
  )
}
export default VideoPreview
