import LinkIcon from 'assets/icons/social/36/Hyperlink.png'
import EmailShare from 'components/socialShare/emailShare'
import FacebookShare from 'components/socialShare/facebookShare'
import TwitterShare from 'components/socialShare/twitterShare'
import { url } from 'environment'
import styles from './shareOptions.module.scss'

const ShareOptions = ({ icons = ['Facebook', 'Twitter', 'Mail', 'Link'], variation = '', ShareIdFnc, card }) => {
  const shareHndler = () => {
    ShareIdFnc(url + '/home/' + card._id)
  }

  return (
    <div className={`${styles[variation]} ${styles.shareIcon}`}>
      {icons.map(icon => {
        if (icon === 'Facebook') {
          return <FacebookShare className={styles.socialIcon} url={url + '/home/' + card?._id} quote={card?.heading} />
        }
        if (icon === 'Twitter') {
          return (
            <TwitterShare
              className={styles.socialIcon}
              url={url + '/home/' + card?._id}
              quote={card?.heading}
              hashtags={card?.tags ?? []}
            />
          )
        }
        if (icon === 'Mail') {
          return (
            <EmailShare
              className={styles.socialIcon}
              url={url + '/home/' + card?._id}
              subject={card?.heading}
              body={''}
              separator={' | '}
            />
          )
        }
        if (icon === 'Link') {
          return (
            <div className={styles.paddingLastIcon} key={icon}>
              <img onClick={shareHndler} src={LinkIcon} alt="Link" />
            </div>
          )
        }
      })}
    </div>
  )
}

export default ShareOptions
