import Button from 'components/button'
import Checkbox from 'components/checkbox'
import Input from 'components/input'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { localStorageService } from 'services/localStorage.service'
import { login } from 'store/reducers/auth/thunk'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import ForgotPassword from '../forgotPassword'
import styles from './login.module.scss'

const Login = ({ setActive }) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const [rememberMe, setRememberMe] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    rememberMe: rememberMe,
    isAddAccount: false
  })

  const errorMsg = type => {
    let message
    switch (type) {
      case 'email':
        message = 'Email required'
        break
      case 'password':
        message = 'Password required'
        break
      default:
        message = 'Error'
        break
    }
    return message
  }

  const handleChange = e => {
    setError(false)
    const { name, value } = e.target
    setUserData(userData => ({ ...userData, [name]: value }))
  }

  const handleChangeCheckBox = () => {
    const newVal = !rememberMe
    setRememberMe(newVal)
    setUserData(userData => ({ ...userData, rememberMe: newVal }))
    localStorageService.set('REMEMBER_ME', newVal)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    if (validateForm()) {
      const res = await dispatch(login(userData))
      if (res?.payload?.user) {
        history.push('/home')
      }
    } else {
      setError(true)
    }
    setLoading(false)
  }

  const validateForm = () => {
    return userData.email && userData.password
  }

  const handleApply = () => {
    dispatch(closeModal())
    setActive && setActive()
    history.push('/register')
  }

  const onEyeHandler = () => {
    setShowPassword(!showPassword)
  }

  const sendForgotPassword = () => {
    dispatch(openModal({ content: <ForgotPassword />, show: true, size: 'max600' }))
  }

  return (
    <form onSubmit={handleSubmit} className="auth-form" style={{ textAlign: 'center' }}>
      <h6 className={styles.authTitle}> {'Log In'} </h6>
      <div className={styles.inputSingle}>
        <Input
          variation="formInput"
          inputClass="lgInputLeft"
          placeholderOrentation="placeholderCenter"
          placeholder="email"
          type="text"
          name="email"
          value={userData.email}
          onChange={handleChange}
          error={error && !userData.email}
          errorMsg={errorMsg('email', userData)}
          className={styles.loginEmail}
        />
      </div>
      <div className={styles.inputSingle2}>
        <Input
          variation="formInput"
          inputClass="lgInputLeft"
          placeholderOrentation="placeholderCenter"
          placeholder="password"
          name="password"
          value={userData.password}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          hasEye={true}
          onEyeClick={onEyeHandler}
          error={error && !userData.password}
          errorMsg={errorMsg('password')}
          className={styles.loginEmail}
        />
      </div>
      <>
        <div className={styles.confirm}>
          <Checkbox
            value={userData.rememberMe}
            checked={userData.rememberMe}
            name="rememberMe"
            variation="formInput"
            inputClass="lgInput"
            onChange={handleChangeCheckBox}
            className={styles.checboks}
          />
          <span>Remember me</span>
        </div>
        <div className={styles.confirm}>
          <span className={styles.forgotPassword} onClick={sendForgotPassword}>
            forgot password?
          </span>
        </div>

        <div className={styles.buttonSingle} style={{ marginBottom: '2rem' }}>
          <Button label="LOG IN" variation="secondary" size="md" onClick={handleSubmit} spinner={loading} />
        </div>
        <div className={styles.goRegister} onClick={handleApply}>
          Don’t have an account? Apply now.
        </div>
      </>
    </form>
  )
}
export default Login
