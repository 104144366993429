import { createSlice } from '@reduxjs/toolkit'

const initialState = { numberOfDays: 30, ageShow: false, genderShow: false }

const FilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilterDays: (state, action) => {
      state.numberOfDays = action.payload
    },
    setShowGenderFilter: (state, action) => {
      state.genderShow = action.payload
    },
    setFilterGender: (state, action) => {
      state.gender = action.payload
    },
    setFilterAge: (state, action) => {
      state.age = action.payload
    },
    setShowAgeFilter: (state, action) => {
      state.ageShow = action.payload
    },
    setSelectedCampaignRedux: (state, action) => {
      state.selectedCampaign = action.payload
    }
  }
})

export default FilterSlice.reducer
export const {
  setFilterDays,
  setFilterGender,
  setFilterAge,
  setShowAgeFilter,
  setShowGenderFilter,
  setSelectedCampaignRedux
} = FilterSlice.actions
