import CardScrollableContent from 'components/card/components/scrollableContent'
import Input from 'components/input'
import moment from 'moment'
import { useEffect, useState } from 'react'
import dataService from 'services/data.service'
import styles from './singleNewsletter.module.scss'

const NewsletterModal = ({ props }) => {
  const [singleNewsletter, setSingleNewsletter] = useState({})
  const [loading, setLoading] = useState(false)
  const { emailTitle = '', createdAt = 0, _id = '' } = props

  useEffect(() => {
    const fetchSingleNewsletter = async () => {
      setLoading(true)
      try {
        const res = await dataService.run('data', 'newsletterEmail', 'get', {
          filter: { _id: _id }
        })
        if (res) {
          setSingleNewsletter(res)
          setLoading(false)
        }
      } catch (error) {
        console.warn(error)
        setLoading(false)
      }
    }
    fetchSingleNewsletter()
  }, [])

  return (
    <div className={styles.wrapper}>
      {loading && (
        <div className={styles.loadingContainer}>
          <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
      )}
      <div className={styles.singleRow}>
        <h3>Email Title</h3>
        <Input disabled={true} value={emailTitle} variation={'cardInput'} />
      </div>
      <div className={styles.singleRow}>
        <h3>Email Text</h3>
        <CardScrollableContent className={styles.scrollContent} type="bussines">
          <p dangerouslySetInnerHTML={{ __html: singleNewsletter?.emailText }} />
        </CardScrollableContent>
      </div>
      <div className={styles.singleRow}>
        <h3>Email sent at</h3>
        <p>{moment(createdAt).format('DD/MM/YYYY-HH:mm')} </p>
      </div>
    </div>
  )
}

export default NewsletterModal
