import React, { useEffect, useState } from 'react'
import styles from './cardTypeSelection.module.scss'
import Button from 'components/button'
import nextArrow from 'assets/icons/nextArrow.png'
import prevArrow from 'assets/icons/prevArrow.png'
import { useDispatch, useSelector } from 'react-redux'
// import { cardActions } from 'store/actions/card.actions'
import { CARD_TYPES, CARD_TYPE_SELECTION_DATA, FRONT_PREVIEW, SCRAPE_PREVIEW } from 'staticConstants'
// import { modalActions } from 'store/actions/modal.actions'
import { setSelectedCard } from 'store/reducers/card/cardSlice'
import { resizeModal } from 'store/reducers/modal/modalSlice'

export const isTypeAvailableForLoggedUser = (cardType, availableTypesForLoggedUser) => {
  let isAvailable = false
  const availableKeys = Object.keys(availableTypesForLoggedUser || {})
  for (let i = 0; i < availableKeys?.length; i++) {
    const timePeriod = availableKeys[i]
    if (availableTypesForLoggedUser[timePeriod]?.[cardType]) {
      isAvailable = true
      break
    }
  }
  return isAvailable
}

export const CardTypeSelection = ({ rememberCardType, setAvailableTabs }) => {
  const dispatch = useDispatch()
  const [cardIndex, setCardIndex] = useState(0)
  const user = useSelector(state => state?.auth?.user)
  const availableTypesForLoggedUser = useSelector(state => state?.config?.cardCreation?.[user?.subType])
  const card = useSelector(state => state.card.selectedCard)
  const cardConfig = useSelector(state => state?.config?.cardCreation?.[user?.subType])
  const currentUser = useSelector(state => state.auth.user)
  const [availableTypeOptions, setAvailableTypeOption] = useState([])
  const [firstSlide, setFirstSlide] = useState(true)
  const [translateXValue, setTranslateXValue] = useState(0)
  const [touchLocation, setTouchLocation] = useState(0)

  const nextSlide = () => {
    setCardIndex(cardIndex + 1)
    firstSlide && setFirstSlide(false)
  }

  const prevSlide = () => {
    setCardIndex(cardIndex - 1)
  }

  const onChange = value => {
    if (
      value === CARD_TYPES.Event ||
      value === CARD_TYPES.Anchor ||
      value === CARD_TYPES.Poll ||
      value === CARD_TYPES.OneSidedTrivia
    )
      setAvailableTabs(FRONT_PREVIEW)
    else if (value === CARD_TYPES.Instacard) {
      setAvailableTabs(SCRAPE_PREVIEW)
    }
    dispatch(setSelectedCard({ ...card, cardType: value }))
    dispatch(resizeModal(value === CARD_TYPES.Double ? 'max1300' : 'max1100'))
  }

  useEffect(() => {
    CARD_TYPE_SELECTION_DATA.map(cardType => {
      if (isTypeAvailableForLoggedUser(cardType?.type, availableTypesForLoggedUser)) {
        setAvailableTypeOption(oldOptions => [...oldOptions, cardType])
      }
    })
  }, [currentUser?._id])

  const getNumberForTranslate = () => {
    let counter = 0
    let passedDouble = 0
    let onDouble = 0
    let passedOne = 0
    let onOne = 0
    availableTypeOptions.map((option, index) => {
      if (option.type === CARD_TYPES.Double) {
        if (cardIndex === index) onDouble += 1
        else if (cardIndex > index) passedDouble += 1
        counter += 9
      } else if (
        option.type === CARD_TYPES.OneSidedTrivia ||
        option.type === CARD_TYPES.Event ||
        option.type === CARD_TYPES.Anchor
      ) {
        if (cardIndex === index) onOne += 1
        else if (cardIndex > index) passedOne += 1
        counter += 4
      } else counter += 6
    })
    const getAdditionalCounter = () => {
      if (cardIndex === 0) return onDouble * -6 + onOne * 6
      else
        return -(100 / counter) * (cardIndex * 6 + passedDouble * 3.1 + onDouble * 1.5 + passedOne * -2 + onOne * -0.92)
    }
    return getAdditionalCounter()
  }
  let startX
  let moveBy = 0

  const start = e => {
    startX = e.pageX
  }
  const end = e => {
    if (e.pageX > startX) {
      moveBy = e.pageX - startX
    } else {
      moveBy = -(startX - e.pageX)
    }
    if (moveBy !== 0) {
      if (moveBy !== 0) {
        if (moveBy > 0) setCardIndex(cardIndex === 0 ? cardIndex : cardIndex - 1)
        else if (moveBy < 0) setCardIndex(cardIndex === availableTypeOptions.length - 1 ? cardIndex : cardIndex + 1)
      } else {
        setCardIndex(getIndexFromWidth(translateXValue - moveBy / 12))
      }
    }
  }

  const getIndexFromWidth = width => {
    let index = 0
    for (let i = 0; i < availableTypeOptions.length; i++) {
      if (getNumberForTranslate(i) < width) {
        index = i
        break
      }
    }
    return index === 0 ? 0 : index - 1
  }

  useEffect(() => {
    setTranslateXValue(getNumberForTranslate(cardIndex + 1))
  }, [cardIndex])

  const handleSwipe = endLocation => {
    if (endLocation === touchLocation || Math.abs(endLocation - touchLocation) < 30) return
    if (endLocation > touchLocation) {
      if (cardIndex > 0) setCardIndex(prevState => prevState - 1)
    } else {
      if (cardIndex < availableTypeOptions.length - 1) setCardIndex(prevState => prevState + 1)
    }
    setTouchLocation(0)
  }

  // find index from availableTypes not from all types (all 13)
  const getCardTypeIndex = (cards, type) => {
    const card = cards?.findIndex(card => card.type === type)
    return card
  }

  useEffect(() => {
    if (rememberCardType && availableTypeOptions?.length > 0) {
      const index = getCardTypeIndex(availableTypeOptions, rememberCardType)
      setCardIndex(index || 0)

      //  it was like this setCardIndex(getCardTypeIndexSelection(rememberCardType))
    }
  }, [rememberCardType, availableTypeOptions])

  const renderSliderFooter = () => {
    return availableTypeOptions?.map((propertyes, index) => {
      const { type, content, label } = propertyes
      return (
        cardIndex === index && (
          <div key={'footer' + type + index} className={styles.position}>
            <div className={styles.info}>
              <p>{content}</p>
            </div>
            <div className={styles.btns}>
              {availableTypeOptions.length > 1 && (
                <button onClick={prevSlide} disabled={cardIndex === 0} className={styles.leftArrow}>
                  <img src={prevArrow} alt="Prev"></img>
                </button>
              )}
              <Button label={label} variation="primary" className={styles.space} onClick={() => onChange(type)} />
              {availableTypeOptions.length > 1 && (
                <button
                  onClick={nextSlide}
                  disabled={cardIndex === availableTypeOptions.length - 1}
                  className={styles.rightArrow}
                >
                  <img src={nextArrow} alt="Next"></img>
                </button>
              )}
            </div>
          </div>
        )
      )
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h1>Choose Card Type</h1>
      </div>
      <div className={styles.container} onMouseDown={start} onMouseUp={end}>
        <div
          className={`${styles.slider} active-slide-${cardIndex}`}
          onTouchStart={touchStartEvent => setTouchLocation(touchStartEvent.changedTouches[0].clientX)}
          onTouchEnd={touchEndEvent => handleSwipe(touchEndEvent.changedTouches[0].clientX)}
        >
          <div
            className={styles.content}
            style={{
              transition: firstSlide && 'transform 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955)',
              transform: `translateX(${getNumberForTranslate()}%)`
            }}
          >
            {availableTypeOptions.map((item, index) => {
              const { type } = item
              return (
                <div
                  key={'cardType' + index}
                  id={`card-${item.index}`}
                  className={`${styles.image} ${cardIndex === index ? styles.active : ''} ${
                    cardIndex === index && item.type === 'double' && styles.doubleSpace
                  }`}
                >
                  {cardConfig && cardConfig.monthly && cardConfig.monthly[type] && (
                    <p>
                      {user[type] ? user[type] : 0} / {cardConfig.monthly[type]}
                      {' monthly'}
                    </p>
                  )}
                  {cardConfig && cardConfig.weekly && cardConfig.weekly[type] && (
                    <p>
                      {user[type] ? user[type] : 0} / {cardConfig.weekly[type]}
                      {' weekly'}
                    </p>
                  )}
                  <img
                    draggable="false"
                    {...(cardIndex === index ? { onClick: () => onChange(type) } : '')}
                    src={item.picture}
                    style={{ width: `${item.width}`, height: `${item.height}` }}
                    alt="Slider"
                  ></img>
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.position}>
          <div className={styles.btns}>{renderSliderFooter()}</div>
        </div>
      </div>
    </div>
  )
}

export default CardTypeSelection
