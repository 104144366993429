import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  edited: false
}

const camapignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setEditedCampaign: (state, action) => {
      state.edited = action.payload
    }
  }
})

export default camapignSlice.reducer
export const { setEditedCampaign } = camapignSlice.actions
