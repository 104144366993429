import React from 'react'

const IconComponentFollow = ({ width = '24', height = '32', color = 'black' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 31C3.23858 31 1 28.7614 1 26C1 19.9249 5.92487 15 12 15C18.0751 15 23 19.9249 23 26C23 28.7614 20.7614 31 18 31H6Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle cx="12" cy="6" r="5" stroke={color} strokeWidth="2" />
      <rect x="10.9971" y="18" width="2" height="10" rx="1" fill={color} />
      <rect x="7" y="24" width="2" height="10" rx="1" transform="rotate(-90 7 24)" fill={color} />
    </svg>
  )
}
export default IconComponentFollow
