const { localStorageService } = require('services/localStorage.service')

// cleaning whole user related local storage
export const clearUserStorage = () => {
  deleteItemsContainingString('user')
  deleteItemsContainingString('token')
  deleteItemsContainingString('refreshToken')
  localStorageService.destroy('unreadNotif')
  localStorageService.destroy('REMEMBER_ME')
  localStorageService.destroy('lastUser')
  localStorageService.destroy('TAB_COUNT_STORAGE')
  sessionStorage.clear()
}

// for logout action
export const logoutSpecificUser = user => {
  localStorageService.destroy(`user_${user.username}`)
  localStorageService.destroy(`token_${user.username}`)
  localStorageService.destroy(`refreshToken_${user.username}`)
  switchLastUser(user.username)

  // clear user storage, if there are no more users in local storage
  const hasUserKey = Object.keys(localStorage).some(key => key.startsWith('user_'))
  if (!hasUserKey) {
    clearUserStorage()
  }
}

const switchLastUser = userLoggingOut => {
  const currentLastUser = localStorageService.get('lastUser')
  // Only switch lastUser if the user being logged out is the current lastUser
  if (`"${userLoggingOut}"` === currentLastUser || userLoggingOut === currentLastUser) {
    // Iterate through keys to find the first matching pattern
    for (const key in localStorage) {
      if (key.startsWith('user_') && key !== currentLastUser) {
        const newLastUser = key.replace('user_', '') // Extract username
        localStorageService.set('lastUser', newLastUser)
        break
      }
    }
  }
}

const deleteItemsContainingString = targetKey => {
  const keys = Object.keys(localStorage)
  keys.forEach(key => {
    if (key.includes(targetKey)) {
      localStorageService.destroy(key)
    }
  })
}
