/* eslint-disable indent */
import IconClose from 'assets/icons/iconClosePink.svg'
import IconCloseBlue from 'assets/icons/iconCloseBlue.svg'
import FlipBack from 'assets/icons/undo.svg'
import FlipBackBlue from 'assets/icons/undoBlue.svg'
import EditIcon from 'assets/icons/edit.svg'
import TrashIcon from 'assets/icons/trash.svg'
import EditIconBlue from 'assets/icons/editBlue.svg'
import TrashIconBlue from 'assets/icons/trashBlue.svg'
import RestoreIcon from 'assets/icons/restorePink.svg'
import RestoreIconBlue from 'assets/icons/restoreBlue.svg'
import AnimationLikePoints from 'components/animationLikePoints'
import Button from 'components/button'
import CardBack from 'components/cardBack'
import CardCreation from 'components/cardCreation'
import CardFront from 'components/cardFront'
import Auth from 'pages/auth'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
// import { cardActions } from 'store/actions/card.actions'
// import { dataActions } from 'store/actions/data.actions'
// import { modalActions } from 'store/actions/modal.actions'
import IconComponentSave from './../icons/saveIcon'
import styles from './cardFlip.module.scss'
import useWindowDimensions from 'utils/screenSize'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import views from 'assets/icons/eye.svg'
import likes from 'assets/icons/Heart.svg'
import shares from 'assets/icons/Share.svg'
import saves from 'assets/icons/save-black.svg'
import adminEdit from 'assets/images/edit.svg'
import AdminEdit from 'components/adminEdit'

export const CardFlip = ({
  className,
  style,
  data = {},
  size = CARD_SIZE.Normal,
  headerSize = CARD_SIZE.Normal,
  isCardInline = false,
  flippable = true,
  flipped = false,
  flippingAnimation = false,
  editable = false,
  exitable = false,
  onFrontOuterCloseClick,
  onClose,
  deletable = false,
  // saveable = 0, // for now is all cards are unsaveable on the admin panel
  // selectable = true - will show overlay on top of card element
  // while this is true card can't be flipped
  selectable = false, //
  // isCardSelected = true - will change color of overlay from transparent to half transparent
  // this is visible only if selectable = true
  isCardSelected = () => false,
  // onSelectionClick - will be triggered if selection overlay is clicked
  // this is triggered only if selectable = true
  onSelectionClick,
  // customFlipLogic = true, will activate custom logic for card flipping
  customFlipLogic = false,
  // isFLippedCustom will now replace isFlippedDefault
  isFlippedCustom = false,
  // setIsFlippedCustom will now replace setIsFlippedDefault
  setIsFlippedCustom,
  // true - on width change (etc double card back) will move elements arround it
  affectOthersWithWidth = false,
  onClick,
  overrideOnFlip,
  dateDiffForDeleting,
  handleDelete = () => {},
  removeableFromCalendarAndMap = false,
  number = 0,
  restorable = false,
  showSingleCardForMapAndCalendar = false,
  showDetails = false,
  hasAdminEdit = false,
  filterDeletedCards = false,
  selectAnchor = false
}) => {
  const store = useSelector(state => state)
  const dispatch = useDispatch()
  const [isFlippedDefault, setIsFlippedDefault] = useState(flippingAnimation ? false : flipped)
  const [isInline, setIsInline] = useState({ opened: false, flipped: false })
  const [isSaved, setIsSaved] = useState(false)
  // const { isBussines } = useSelector(store => store?.navbar)
  const [isBussines] = useState(true)
  const { isMobile } = useWindowDimensions()
  const { grid } = useSelector(state => state)
  const [isSaveable, setIsSaveable] = useState(
    // saveable !== 0
    // ? data.cardType !== CARD_TYPES.Anchor && saveable && !!store?.auth?.user?._id
    // : data.cardType !== CARD_TYPES.Anchor && !!store?.auth?.user?._id
    false
  )
  const [commentsOpened, openComments] = useState(false)
  const removeIconRef = useRef(null)
  const editIconRef = useRef(null)
  const saveIconRef = useRef(null)
  const onCLickHandler = event => {
    const pathname = window.location.pathname
    if (
      pathname.includes('calendar') &&
      !removeIconRef?.current?.contains(event.target) &&
      !editIconRef?.current?.contains(event.target) &&
      !saveIconRef?.current?.contains(event.target)
    ) {
      onClick && onClick(event)
    } else if (!pathname.includes('calendar')) {
      onClick && onClick(event)
    }
  }
  const isDeletable =
    deletable && store?.auth?.user?._id && data?._id && data?.userId && store.auth.user._id === data.userId
  const isRestorable =
    restorable && store?.auth?.user?._id && data?._id && data?.userId && store.auth.user._id === data.userId
  const isEditable = editable && store?.auth?.user?._id && data?._id && data?.userId
  const [deleting, setDeleting] = useState(false)
  const [triggerSaveAnimation, setTriggerSaveAnimation] = useState(false)
  const urlPathname = window.location.pathname

  const onSelectionHandler = () => {
    onSelectionClick && onSelectionClick(data)
  }
  const isCardFlipped = () => {
    return customFlipLogic ? isFlippedCustom : isFlippedDefault
  }
  const onFlip = () => {
    if (overrideOnFlip) {
      overrideOnFlip()
    } else {
      if (flippable) {
        if (customFlipLogic) {
          if (
            data.cardType !== CARD_TYPES.Event &&
            data.cardType !== CARD_TYPES.Poll &&
            data.cardType !== CARD_TYPES.Anchor &&
            data.cardType !== CARD_TYPES.OneSidedTrivia
          ) {
            setIsFlippedCustom && setIsFlippedCustom(!isFlippedCustom)
          }
        } else {
          setIsFlippedDefault(!isFlippedDefault)
        }
        // Create new cardView and increment views on current card
        const fetchInlineCards = () => {
          const inlineItemIds = []
          data?.backFields?.forEach(item => {
            if (item.type === 'inlinePack' && item.content?._id) inlineItemIds.push(item.content._id)
          })
          const res = dataService.run('data', 'card', 'all', {
            filter: { _id: { $in: inlineItemIds } }
          })
          if (res?.length > 0)
            res.forEach(inlineCard => {
              data.backFields.forEach(item => {
                if (item.content?._id === inlineCard._id) item.content = inlineCard
              })
            })
        }
        const addNewViewAndCheckIfCardIsLiked = async () => {
          if (
            data.cardType !== CARD_TYPES.DoubleSidedTrivia &&
            store.auth.user?._id &&
            (customFlipLogic ? !isFlippedCustom : !isFlippedDefault)
          ) {
            // await dispatch(getCardInteractionsAndViewCard({ data: { card: data }, user: store.auth.user }))
            console.log('aaa')
          }
        }
        const fetchCardComments = () => {
          dispatch(dataService.run('data', 'comment', 'all', { filter: { cardId: data._id } }, { storeKey: data._id }))
        }
        if (data.cardType !== CARD_TYPES.DoubleSidedTrivia) {
          fetchCardComments()
          fetchInlineCards()
          addNewViewAndCheckIfCardIsLiked()
        }
      }
    }
  }
  const onEdit = () => {
    dispatch(
      openModal({
        content: <CardCreation id={data._id} withoutTypeSelection={true} />,
        name: 'Card creation modal',
        size: data.cardType === CARD_TYPES.Double ? 'max1300' : 'max1100',
        show: true
      })
    )
  }
  const onCloseHandler = () => {
    onClose && onClose()
  }

  const handleDeletingCard = async () => {
    if (removeableFromCalendarAndMap) {
      await dispatch(openModal({ content: <Confirmation />, name: 'Remove from calendar/map modal', size: 'max320' }))
    } else if (selectAnchor) {
      handleDelete()
    } else if (isDeletable) {
      setDeleting(true)
      handleDelete()
      setTimeout(async () => {
        // await dispatch(deleteCard({ data, user: store.auth.user }))
        console.log('card')
      }, 1400)
    }
  }

  const handleRestoringCard = async () => {
    if (isRestorable) {
      setDeleting(true)
      handleDelete()
      setTimeout(async () => {
        // await dispatch(restoreCard({ data: data, user: store.auth.user }))
        console.log('restore')
      }, 1400)
    }
  }

  const handleSave = async () => {
    if (store.auth.user?._id) {
      if (isSaveable) {
        if (!store?.auth?.user?.savedCards?.includes(data._id)) {
          setTriggerSaveAnimation(true)
        }
        // dispatch(save({ card: data }))
        setIsSaved(prevState => !prevState)
      }
    } else {
      dispatch(openModal({ content: <Auth />, name: 'Login modal', size: 'max600' }))
    }
  }
  useEffect(() => {
    if (flipped && flippingAnimation) setIsFlippedDefault(true)
  }, [flipped])
  useEffect(() => {
    if (store?.auth?.user?._id) {
      setIsSaveable(isSaveable && store?.auth?.user?._id !== data?.userId)
    }
  }, [store.auth && store.auth.user && store.auth.user.savedCards])

  useEffect(() => {
    setIsSaved(
      store.auth && store?.auth?.user?.savedCards && data?._id ? store.auth.user.savedCards.includes(data._id) : false
    )
  }, [data.auth, store?.auth?.user?.savedCards])

  useEffect(() => {
    if (triggerSaveAnimation)
      setTimeout(() => {
        setTriggerSaveAnimation(false)
      }, 800)
  }, [triggerSaveAnimation])

  const onSaveHandler = async () => {}

  const Confirmation = () => {
    const handleConfirmation = async (answer = false) => {
      dispatch(closeModal())
      if (answer) {
        setDeleting(true)
        handleDelete()
        setTimeout(async () => {
          if (urlPathname.includes('/map')) {
            console.log('remove')
            // await dispatch(removeCardFromMapOrCalendar({ type: 'map', cardId: data._id }))
          } else if (urlPathname.includes('/calendar')) {
            console.log('hdfjsdkf')
            // await dispatch(removeCardFromMapOrCalendar({ type: 'calendar', cardId: data._id }))
          }
        }, 1400)
      }
    }
    return (
      <div className={styles.confirmationModal__wrapper}>
        <div className={styles.confirmationModal}>
          <h3>Do you want to remove the card from the {urlPathname.includes('/map') ? 'map' : 'calendar'}?</h3>
          <div>
            <Button label="YES" variation="primary" size="sm" onClick={() => handleConfirmation(true)} />
            <Button label="NO" variation="secondary" size="sm" onClick={() => handleConfirmation(false)} />
          </div>
        </div>
      </div>
    )
  }
  const handleAdminEdit = () => {
    dispatch(
      openModal({ content: <AdminEdit item={data} numberOfFlags={(data?.reportedBy) ? Object.keys(data?.reportedBy).length : 0} type="card" showItemReports />, show: true, size: 'max1300centered' })
    )
  }
  return (
    <div
      style={style}
      className={`
        ${styles.wrapper}
        ${selectable ? styles.posRelative : ''}
        ${className}
        ${styles[className]}
        ${deleting ? styles.cardDelete : ''}
      `}
      onClick={event => onCLickHandler(event)}
    >
      <div
        className={`
          ${styles.flipCard} 
          ${data && data?.cardType === CARD_TYPES.Double ? styles.doubleCard : ''} 
          ${isCardFlipped() && data.cardType === CARD_TYPES.Double ? styles.doubleCardActive : ''} 
          ${affectOthersWithWidth ? styles.affectOthersWithWidth : ''} 
          ${(customFlipLogic ? isFlippedCustom : isFlippedDefault) ? styles.cardIsFlipped : ''}
          ${commentsOpened ? styles.showComments : ''}
          ${isCardInline ? styles.inlineCardWrapper : ''}
          ${size === CARD_SIZE.ExtraSmall ? styles.small : ''}
        `}
        key={data._id}
      >
        {selectable && (
          <div
            className={`
              ${styles.selectionCardCover}
              ${isCardSelected(data) ? styles.selected : ''}
            `}
            onClick={onSelectionHandler}
          ></div>
        )}
        <div className={styles.selectedCardCover}></div>
        <div
          className={`
            ${styles.flipCardInner} 
            ${isCardFlipped() ? styles.flippedCard : ''}  
            ${isCardInline ? styles.inlineShadow : ''}
            ${number > 2 ? styles.withoutTopMargin : ''}
          `}
        >
          {/* ---- Front card ---- */}
          <div
            className={`
              ${styles.flipCardFront} 
              ${data?.cardType === CARD_TYPES.Marketplace ? styles.marketplaceCard : ''}
              ${isCardInline ? styles.inlineCard : ''}
            `}
          >
            <CardFront card={data} editable={isEditable} onClick={onFlip} size={size} />

            {/* -- Date container for deleted card -- */}
            {restorable && dateDiffForDeleting && (
              <span
                className={`${
                  dateDiffForDeleting > 10
                    ? styles.infoContent
                    : dateDiffForDeleting > 5
                    ? styles.colorDays
                    : styles.critical
                }`}
              >
                {dateDiffForDeleting}
                <p>Days</p>
              </span>
            )}

            {/* -- Edit card icon -- */}
            {isEditable && (
              <div
                className={`${styles.editIcon} ${
                  data.cardType === CARD_TYPES.Marketplace ? styles.marketplaceCard : ''
                } ${isMobile && grid.isActivated ? styles.extraSmallDelete : styles.editIcon}`}
                ref={editIconRef}
              >
                <img onClick={onEdit} alt="Edit" src={isBussines ? EditIconBlue : EditIcon} />
              </div>
            )}

            {/* -- Close icon -- */}
            {exitable && (
              <span className={styles.close}>
                <img
                  onClick={onCloseHandler}
                  className={`
                  ${styles.exitIcon}
                  ${size === CARD_SIZE.Large && styles.largeCardExitIcon}
                  ${isCardInline ? styles.inlineCard : ''}
                `}
                  alt="innerCloseIcon"
                  src={isBussines ? IconCloseBlue : IconClose}
                />
              </span>
            )}

            {/* -- Save card icon -- */}
            {isSaveable && !hasAdminEdit && !filterDeletedCards && data.cardType !== CARD_TYPES.Anchor && (
              <div
                className={`${styles.save} ${data.cardType === CARD_TYPES.Marketplace ? styles.marketplaceCard : ''}`}
                onClick={() => {
                  handleSave()
                  onSaveHandler()
                }}
                ref={saveIconRef}
              >
                <AnimationLikePoints
                  points="like"
                  value={triggerSaveAnimation}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  className={styles.pointsContainer}
                >
                  <IconComponentSave height="60%" color={isBussines ? '#00aee5' : '#CD4D68'} fillIcon={isSaved} />
                </AnimationLikePoints>
              </div>
            )}

            {hasAdminEdit && (
              <div
                className={`${styles.adminEdit} ${
                  data.cardType === CARD_TYPES.Marketplace ? styles.marketplaceCard : ''
                }`}
                onClick={handleAdminEdit}
              >
                <img src={adminEdit} alt="" />
              </div>
            )}

            {/* --- Delete card icon --- */}
            {(isDeletable || removeableFromCalendarAndMap || selectAnchor) && (
              <div
                title={
                  !removeableFromCalendarAndMap
                    ? 'Delete the card'
                    : 'Remove the card from the ' + (urlPathname.includes('map') ? 'map' : 'calendar')
                }
                className={`${styles.deleteIcon} ${
                  isMobile && grid.isActivated ? styles.extraSmallDelete : styles.deleteIcon
                } ${
                  data.cardType === CARD_TYPES.Marketplace || showSingleCardForMapAndCalendar
                    ? styles.marketplaceCard
                    : ''
                }`}
                onClick={() => handleDeletingCard()}
                ref={removeIconRef}
              >
                <img alt="deleteIcon" src={isBussines ? TrashIconBlue : TrashIcon} />
              </div>
            )}

            {/* -- Restore card icon -- */}
            {isRestorable && (
              <div
                title={'Restore the card'}
                className={`${styles.restoreIcon} ${
                  data.cardType === CARD_TYPES.Marketplace ? styles.marketplaceCard : ''
                } ${isMobile ? styles.restoreMobile : ''}`}
                onClick={() => handleRestoringCard()}
              >
                <img alt="restoreIcon" src={isBussines ? RestoreIconBlue : RestoreIcon} />
              </div>
            )}

            {/* -- Close icon out of the card -- */}
            {onFrontOuterCloseClick && (
              <img
                onClick={onFrontOuterCloseClick}
                className={`${styles.outerIcon} ${styles.outerCloseIcon} ${
                  size === CARD_SIZE.Large && styles.largeCardOuterIcon
                }`}
                alt="onFrontOuterCloseClick"
                src={IconClose}
              />
            )}
          </div>

          {/* ---- Fliped/Back card ---- */}
          <div
            className={`
              ${styles.flipCardBack}
              ${data?.cardType === CARD_TYPES.Marketplace ? styles.marketplaceCard : ''}
              ${isCardInline && data.cardType !== CARD_TYPES.Double ? styles.inlineCard : ''}
            `}
          >
            {/* {isCardFlipped() && ( */}
            <CardBack
              card={data}
              size={size}
              headerSize={headerSize}
              setIsInline={setIsInline}
              commentsOpened={commentsOpened}
              openComments={openComments}
            />
            {/* )} */}

            {/* -- Flip back icon -- */}
            {!isInline.opened && flippable && (
              <img
                onClick={onFlip}
                className={`
                    ${styles.outerIcon}
                    ${styles.outerFlipBackIcon}
                    ${size === CARD_SIZE.Large && styles.largeCardOuterIcon}
                  `}
                alt="outerFlipBackIcon"
                src={isBussines ? FlipBackBlue : FlipBack}
              />
            )}
          </div>
        </div>
      </div>

      {showDetails && (
        <div className={styles.row}>
          <div className={styles.single}>
            <img src={views} alt="views" />
            {data && data.views ? data.views : 0}
          </div>
          <div className={styles.single}>
            <img src={likes} alt="views" />
            {data && data.likes ? data.likes : 0}
          </div>
          <div className={styles.single}>
            <img src={saves} alt="views" />
            {data && data.saves ? data.saves : 0}
          </div>
          <div className={styles.single}>
            <img src={shares} alt="views" />
            {data && data.shares ? data.shares : 0}
          </div>
        </div>
      )}
    </div>
  )
}

export default CardFlip
