import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  show: false,
  onclose: null,
  packId: null
}
export const packModal = createSlice({
  name: 'pack modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { packId = null } = action.payload
      state.show = true
      state.packId = packId
    },
    closeModal: state => {
      state.show = false
      state.onclose = null
      state.packId = null
    }
  }
})

export const { openModal, closeModal } = packModal.actions

export default packModal.reducer
