import { request } from './http.service'

class ScrapeService {
  async scrape(data = {}) {
    return request('post', `/api/scrape`, {
      mode: 'scrape',
      data
    })
  }

  async scrapeTest(data = {}) {
    return request('post', '/api/scrape/public', {
      mode: 'scrape-test',
      data
    })
  }
}

export default new ScrapeService()
