import CampaignCardSelection from 'components/campaignCardSelection'
import CampaignTypeSelection from 'components/campaignTypeSelection'
import InstacardCreation from 'components/instacardCreation'
import TokenLogin from 'components/tokenLogin'
import Anchor from 'pages/admin/anchor'
import Applicants from 'pages/admin/applicants'
import CategoryAndTopics from 'pages/admin/categoryAndTopics'
import ContentModeration from 'pages/admin/contentModeration'
import ContactUs from 'pages/admin/contsctUs'
import EmptySearches from 'pages/admin/emptySearches'
import Newsletter from 'pages/admin/newsletter'
import Trending from 'pages/admin/trending'
import UploadMysteryImages from 'pages/admin/uploadMysteryImages'
import AdminUsers from 'pages/admin/users'
import WelcomePack from 'pages/admin/welcomePack'
import Register from 'pages/auth/register'
import Contact from 'pages/contact'
import Home from 'pages/home'
import ManageCampaign from 'pages/manageCampaign'
import Reports from 'pages/reports'
import SavedCards from 'pages/savedCards'
import ChangeAccount from 'pages/settings/changeAccount'
import EditCard from 'pages/settings/editCard'
import General from 'pages/settings/general'
import Payments from 'pages/settings/payments'
import Users from 'pages/settings/users'
import Verification from 'pages/verification'
import { Redirect, Switch } from 'react-router-dom'
import { PrivateRoute } from './privateRoute'
import { PublicRoute } from './publicRoute'

const Routes = () => {
  return (
    <Switch>
      <PublicRoute exact path="/" component={Register} />
      <PublicRoute path="/register/:email?/:username?" component={Register} />
      <PublicRoute exact path="/login/:token" component={TokenLogin} />
      <PrivateRoute exact path="/home" component={Home} />
      <PrivateRoute path="/reports/likes" component={Reports} />
      <PrivateRoute path="/reports/views" component={Reports} />
      <PrivateRoute path="/reports/reach" component={Reports} />
      <PrivateRoute path="/reports/shares" component={Reports} />
      <PrivateRoute path="/instacard" component={InstacardCreation} />
      <PrivateRoute path="/campaign/new/:campaignType" component={CampaignCardSelection} />
      <PrivateRoute path="/campaign/manage" component={ManageCampaign} />
      <PrivateRoute path="/campaign" component={CampaignTypeSelection} />
      <PrivateRoute path="/cards" component={SavedCards} />
      <PrivateRoute path="/admin/applicants" component={Applicants} />
      <PrivateRoute path="/admin/moderation" component={ContentModeration} />
      <PrivateRoute path="/admin/users" component={AdminUsers} />
      <PrivateRoute path="/admin/trending" component={Trending} />
      <PrivateRoute path="/admin/empty-searches" component={EmptySearches} />
      <PrivateRoute path="/admin/contact-us" component={ContactUs} />
      <PrivateRoute path="/settings/general" component={General} />
      <PrivateRoute path="/settings/editCard" component={EditCard} />
      <PrivateRoute path="/settings/users" component={Users} />
      <PrivateRoute path="/settings/payments" component={Payments} />
      <PrivateRoute path="/settings/changeAccount" component={ChangeAccount} />
      <PrivateRoute path="/contact" component={Contact} />
      <PrivateRoute path="/admin/mystery" component={UploadMysteryImages} />
      <PrivateRoute path="/admin/categories" component={CategoryAndTopics} />
      <PrivateRoute path="/admin/anchor" component={Anchor} />
      <PrivateRoute path="/admin/welcome-pack" component={WelcomePack} />
      <PrivateRoute path="/admin/newsletter" component={Newsletter} />
      <PublicRoute
        exact
        path="/verification/confirm-change-account-type/:token/:email/:type/:username"
        component={Verification}
        key="changeAccountType"
      />
      ,{/* <PublicRoute exact path="/verification/confirm-email/:token/:email" component={Verification} /> */}
      <PublicRoute
        exact
        path="/verification/confirm-email/:token/:email"
        component={Verification}
        key="emailVerification"
      />
      ,
      <PublicRoute
        exact
        path="/verification/confirm-password/:token/:email/:username"
        component={Verification}
        key="passwordVerification"
      />
      ,
      <PublicRoute
        exact
        path="/verification/confirm-personal-details/:token/:email/:username"
        component={Verification}
        key="personalDetailsVerification"
      />
      ,
      <PublicRoute
        exact
        path="/verification/confirm-deactivation-or-restore/:token/:email/:username"
        component={Verification}
        key="deactivationVerification"
      />
      ,
      <PublicRoute
        exact
        path="/verification/confirm-forgot-password-signup/:token/:email/:username"
        component={Verification}
        key="forgotPasswordChange"
      />
      <PublicRoute
        exact
        path="/verification/confirm-cancel-sub/:token/:email/:username"
        component={Verification}
        key="cancelSubscription"
      />
      ,
      <Redirect from="*" to="/" />
    </Switch>
  )
}

export { Routes }
