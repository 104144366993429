import Button from 'components/button'
import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardWrapper from 'components/card/components/wrapper'
import ShareOptionsUser from 'components/shareOptionsUser'
import SimpleSearch from 'components/simpleSearch'
import User from 'components/user'
import { awsUrlResize } from 'environment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
// import { dataActions } from 'store/actions/data.actions'
import { isObjectEmpty } from 'utils/helpers'
import styles from './shareUser.module.scss'

export const ShareUser = ({ className, cardClass, user }) => {
  const [followUser, setFollowUser] = useState('')
  // const dispatch = useDispatch()
  const [dataFollowing, setDataFollowing] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const authUser = useSelector(state => state.auth.user)
  const store = useSelector(state => state)
  const [loadUser, setLoadUser] = useState(false)

  const message = {
    type: 'message',
    subType: 'text',
    data: user._id,
    to: selectedUser?.username,
    toId: selectedUser?._id,
    from: authUser?.username,
    fromId: authUser?._id
  }

  console.log(message)

  const backFollowing = async () => {
    try {
      const res = await dataService.run('data', 'followUser', 'getFollowingUsers', {
        userId: store.auth.user._id
      })
      if (!res.isAxiosError) {
        setDataFollowing(res)
      }
    } catch (err) {
      console.warn('_e', err)
    }
  }

  const shareUserOnChat = async () => {
    try {
      if (user?._id) {
        setLoadUser(true)
        // await dispatch(
        //   dataActions.run(
        //     'chats',
        //     'message',
        //     'new',
        //     { ...message, subType: 'user', data: user?._id },
        //     { storeKey: 'user' }
        //   )
        // )
      }
    } catch (err) {
      console.warn('_e', err)
      setLoadUser(false)
    }
    setLoadUser(false)
  }

  useEffect(() => {
    backFollowing()
  }, [])

  const onSearchEnd = value => {
    setFollowUser(value)
  }

  return (
    <CardWrapper className={`${styles[className]} ${className} ${styles.wrapper}`}>
      <Card className={cardClass} size={CARD_SIZE.normal} type={CARD_TYPES.Share}>
        <CardBody>
          <div className={styles.content}>
            <div className={styles.header}>
              <p className={styles.backShareCard}>Share User</p>
            </div>
            <div className={styles.icons}>
              <ShareOptionsUser user={user} />
            </div>
            <h6>Share with your contacts</h6>
            <SimpleSearch
              onSearch={onSearchEnd}
              selected={!isObjectEmpty(selectedUser) ? selectedUser?.username : ''}
              onSelectionClose={() => setSelectedUser({})}
            />
            <div className={styles.users}>
              {dataFollowing
                .filter(usr => usr.username.toLowerCase().includes(followUser))
                .map(({ _id, username }, index) => {
                  return (
                    <User
                      key={index}
                      logo={awsUrlResize + '/' + _id + '.jpg'}
                      subtitle={username}
                      className={styles.usersColumn}
                      onClick={() => setSelectedUser(dataFollowing[index])}
                    />
                  )
                })}
            </div>
          </div>
          <Button
            variation="primary"
            label={'Share'}
            size="xs"
            className={styles.buttonPosition}
            disabled={isObjectEmpty(selectedUser)}
            onClick={shareUserOnChat}
            spinner={loadUser}
          />
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default ShareUser
