import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { closeModal } from 'store/reducers/modal/modalSlice'
import { paymentStripe } from 'store/reducers/payment/thunk'
import styles from './stripe.module.scss'
import { toastService } from 'services/toastService'
import { useDispatch, useSelector } from 'react-redux'
import { appUrl, stripeKey } from 'environment'
import { setSucces } from 'store/reducers/payment/paymentSlice'
import Button from 'components/button'

const Stripe = ({ trackCardAction = () => {} }) => {
  const dispatch = useDispatch()
  const paymentSuccess = useSelector(state => state.payment.success)
  const user = useSelector(state => state.auth.user)
  const [loading, setLoading] = useState(false)

  const CheckoutForm = () => {
    const stripe = useStripe()
    const elements = useElements()

    const handleSubmit = async event => {
      event.preventDefault()

      if (elements === null) {
        return null
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      })

      if (error && error.message) {
        toastService('error', error.message)
      } else if (paymentMethod) {
        setLoading(true)
        const newObj = {
          paymentMethod: paymentMethod
        }
        newObj.paymentMethod.service = {
          type: user.subType
          // type: 'dailyTest'
        }
        newObj.paymentMethod.customer = user && user.customer ? user.customer : null
        newObj.mode = 'addCard-subscribe'
        await dispatch(paymentStripe(newObj))
        trackCardAction()
      }
    }

    const contactUsForm = () => {
      window.open(`${appUrl}/contact-us`, '_blank')
    }

    useEffect(() => {
      if (paymentSuccess) {
        setLoading(false)
        dispatch(closeModal())
        dispatch(setSucces(false))
      }
    }, [paymentSuccess])

    return (
      <form onSubmit={handleSubmit} className={styles.form}>
        <h3>Add your credit card</h3>
        <div className={styles.cardElement}>
          <CardElement />
        </div>
        <div className={styles.text}>
          As noted throughout our site, Wonder Shuffle charges for business accounts in exchange for promotional and
          customer acquisition opportunities. For a limited period, we are providing a promotional offer with no monthly
          fee. We will start charging you a monthly fee after 6 months. The monthly fee is according to your subscriber
          level and will be processed through Stripe, a third party vendor, once the promotional period expires. Wonder
          Shuffle does not record or retain any of your credit card information. If you have any questions, don’t
          hesitate to <span onClick={contactUsForm}>contact us</span>.
        </div>
        <div className={styles.button}>
          <Button
            disabled={!stripe || !elements}
            variation={'secondary'}
            size="stripeButton"
            label="I agree"
            spinner={loading}
          />
        </div>
      </form>
    )
  }

  const stripePromise = loadStripe(stripeKey)

  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </>
  )
}

export default Stripe
