import Button from 'components/button'
import Input from 'components/input'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addAccount } from 'store/reducers/auth/thunk'
import styles from '../login/login.module.scss'
import { adminUrl } from 'environment'
import { closeModal } from 'store/reducers/modal/modalSlice'

const AddAccount = ({ fetchLinkedAccounts }) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const { user } = useSelector(state => state.auth)

  const [userData, setUserData] = useState({
    email: '',
    password: '',
    isAddAccount: true
  })

  const errorMsg = type => {
    let message
    switch (type) {
      case 'email':
        message = 'Email required'
        break
      case 'password':
        message = 'Password required'
        break
      default:
        message = 'Error'
        break
    }
    return message
  }

  const handleChange = e => {
    setError(false)
    const { name, value } = e.target
    setUserData(userData => ({ ...userData, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    if (validateForm()) {
      const res = await dispatch(addAccount(userData))
      if (res?.payload?.user) {
        window.open(`${adminUrl}/home/?userId=${res?.payload?.user._id}`, '_blank')
        fetchLinkedAccounts()
        dispatch(closeModal())
      }
    } else {
      setError(true)
    }
    setLoading(false)
  }

  const validateForm = () => {
    return userData.email && userData.password
  }

  const onEyeHandler = () => {
    setShowPassword(!showPassword)
  }

  return (
    <form onSubmit={handleSubmit} className="auth-form" style={{ textAlign: 'center' }}>
      <h6 className={styles.authTitle}> {'Add new account'} </h6>
      <div className={styles.inputSingle}>
        <Input
          variation="formInput"
          inputClass="lgInputLeft"
          placeholderOrentation="placeholderCenter"
          placeholder="email"
          type="text"
          name="email"
          value={userData.email}
          onChange={handleChange}
          error={error && !userData.email}
          errorMsg={errorMsg('email', userData)}
          className={styles.loginEmail}
        />
      </div>
      <div className={styles.inputSingle2}>
        <Input
          variation="formInput"
          inputClass="lgInputLeft"
          placeholderOrentation="placeholderCenter"
          placeholder="password"
          name="password"
          value={userData.password}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          hasEye={true}
          onEyeClick={onEyeHandler}
          error={error && !userData.password}
          errorMsg={errorMsg('password')}
          className={styles.loginEmail}
        />
      </div>
      <div className={styles.buttonSingle} style={{ marginBottom: 0 }}>
        <Button
          label="Add"
          variation="secondary"
          size="md"
          onClick={handleSubmit}
          spinner={loading}
          disabled={user?.email === userData.email}
        />
      </div>
    </form>
  )
}
export default AddAccount
