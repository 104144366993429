import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UnauthorizedMsg from 'components/unauthorizedMsg'
import Login from 'pages/auth/login'
import { useHistory } from 'react-router-dom'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'

const PrivateWrapper = ({ children, unauthMsg }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector(state => state.auth.user)
  useEffect(() => {
    // see if user and if not prompt for login
    if (!user) {
      dispatch(
        openModal({
          content: <Login />,
          name: 'Login Modal',
          size: 'max600',
          show: true
        })
      )
    }
    return () => {
      dispatch(closeModal())
    }
  }, [user?._id, history.location.pathname])

  return (
    <div className="main">
      {user?._id ? (
        children
      ) : unauthMsg ? (
        <UnauthorizedMsg text={unauthMsg} />
      ) : (
        <UnauthorizedMsg text="YOU MUST BE LOGGED IN TO VIEW THIS!" />
      )}
    </div>
  )
}

export default PrivateWrapper
