import editIcon from 'assets/icons/edit.svg'
import aquaPack from 'assets/images/pack/aqua.png'
import bluePack from 'assets/images/pack/blue.png'
import grayPack from 'assets/images/pack/gray.png'
import lightbluePack from 'assets/images/pack/lightblue.png'
import limePack from 'assets/images/pack/lime.png'
import orangePack from 'assets/images/pack/orange.png'
import pinkPack from 'assets/images/pack/pink.png'
import redPack from 'assets/images/pack/red.png'
import yellowPack from 'assets/images/pack/yellow.png'
import CardScrollableContent from 'components/card/components/scrollableContent'
import MediaPicker from 'components/mediaPicker'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// import { useDispatch } from 'react-redux'
import dataService from 'services/data.service'
import { setSelectedPack } from 'store/reducers/pack/packSlice'
// import { packActions } from 'store/actions/pack.actions'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import styles from './pack.module.scss'

const Pack = ({
  packId = '',
  pack = {},
  editable = false,
  onClick,
  onEdit,
  className,
  packClassName,
  ignoreUpdate = false
}) => {
  // const dispatch = useDispatch()
  const [packData, setPackData] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    const getPackById = async id => {
      const res = await dataService.run('data', 'pack', 'get', { filter: { _id: id } })
      if (res?.type === 'pack') setPackData(res)
    }
    if (packId !== '') getPackById(packId)
    else setPackData(pack)
  }, [])

  const onImageChange = image => {
    dispatch(setSelectedPack({ ...packData, logoImage: image }))
  }
  const onClickHandler = () => {
    onClick && onClick()
  }

  const getPackImage = () => {
    switch (packData.color) {
      case '#bfbfc1':
        return grayPack
      case '#d20000':
        return redPack
      case '#dd0058':
        return pinkPack
      case '#343ec8':
        return bluePack
      case '#00aaca':
        return lightbluePack
      case '#00d690':
        return aquaPack
      case '#5eda29':
        return limePack
      case '#cdbb01':
        return yellowPack
      case '#db5700':
        return orangePack
      default:
        return redPack
    }
  }

  useEffect(() => {
    if (!ignoreUpdate) setPackData(pack)
  }, [pack])

  return (
    <div
      onClick={onClickHandler}
      className={`${styles.wrapper} ${className} ${styles[className]} ${packClassName}`}
      style={{ backgroundImage: 'url("' + getPackImage() + '")' }}
    >
      {onEdit && <img className={styles.editIcon} alt="Edit" src={editIcon} onClick={onEdit} />}
      {editable && (
        <MediaPicker
          className="packPicker"
          whiteIcon
          onImageChange={onImageChange}
          stateMedia={
            packData?.logoImage && packData.logoImage !== '' ? getMediaFromFileOrString(packData.logoImage, true) : ''
          }
          showCrop
          type={packData.type}
          widthForCropDivInsideWrapper="100%"
        />
      )}
      {!editable && (
        <div className={styles.logoContainer}>
          {packData?.logoImage && packData?.logoImage !== '' && (
            <img
              className={`${styles.previewLogo}`}
              alt=""
              src={packData?.logoImage}
              onError={e => {
                e.target.onerror = null
                e.target.src = getMediaFromFileOrString(packData?.logoImage, false)
              }}
            />
          )}
        </div>
      )}
      {(editable || packData.heading !== '' || packData.description !== '') && (
        <div className={styles.packDetails}>
          <CardScrollableContent className={styles.scrollableContent}>
            <span className={styles.packName}>
              {packData.heading !== '' || !editable ? packData.heading : 'Pack Name'}
            </span>
            <span className={styles.packDescription}>
              {packData.description !== '' || !editable ? packData.description : 'Description'}
            </span>
          </CardScrollableContent>
        </div>
      )}
    </div>
  )
}
export default Pack
