import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import { setQue } from 'store/reducers/que/queSlice'
function useUploadQue(field = 'general') {
  const uploadQue = useSelector(state => state?.uploadQue?.que)
  const dispatch = useDispatch()
  const addToQue = (fieldId, value = {}) => {
    if (fieldId)
      dispatch(
        setQue({
          ...uploadQue,
          [field]: { ...uploadQue[field], [fieldId]: { ...uploadQue[field][fieldId], ...value } }
        })
      )
  }
  const removeFromQue = id => {
    if (id) {
      const tempFields = cloneDeep(uploadQue[field])
      if (tempFields[id]) {
        delete tempFields[id]
        dispatch(setQue({ ...uploadQue, [field]: { ...tempFields } }))
      }
    }
  }
  useEffect(() => {
    if (!uploadQue[field]) dispatch(setQue({ ...uploadQue, [field]: {} }))
  }, [])
  return [uploadQue[field] ?? {}, addToQue, removeFromQue]
}
export default useUploadQue
