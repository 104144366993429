import React, { useState } from 'react'

import { useDispatch } from 'react-redux'

import Button from 'components/button'
import Input from 'components/input'
import MediaPicker from 'components/mediaPicker'

import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import { isUrlValid } from 'utils/helpers'
import { CARD_BACK_TYPES } from 'staticConstants'
import { setSelectedCard } from 'store/reducers/card/cardSlice'

import styles from './linkedImageForm.module.scss'

const LinkedImageForm = ({ card, closeModal }) => {
  const [link, setLink] = useState('')
  const [buttonLabel, setButtonLabel] = useState('')
  const [linkError, setLinkError] = useState(false)
  const [buttonError, setButtonError] = useState(false)
  const [picture, setPicture] = useState('')
  const [pictureError, setPictureError] = useState(false)
  const dispatch = useDispatch()

  const onImageChange = image => {
    setPicture(image)
  }

  const handleSave = async () => {
    if (!picture || picture.length === 0) {
      setPictureError(true)
      return
    } else if (pictureError) {
      setPictureError(false)
    }
    if (!isUrlValid(link)) {
      setLinkError(true)
      return
    } else if (linkError) {
      setLinkError(false)
    }
    if (!buttonLabel.length) {
      setButtonError(true)
      return
    } else if (buttonError) {
      setButtonError(false)
    }
    await dispatch(
      setSelectedCard({
        ...card,
        backFields: [
          ...card.backFields,
          {
            id: new Date().getTime(),
            type: CARD_BACK_TYPES.linkedImage,
            content: { image: picture, link, buttonLabel }
          }
        ]
      })
    )
    closeModal && closeModal()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.leftContent}>
          <MediaPicker
            onImageChange={image => onImageChange(image)}
            stateMedia={getMediaFromFileOrString(picture)}
            removable={false}
            showCrop
          />
          {buttonLabel.length > 0 && (
            <div className={styles.buttonContainer}>
              <Button size="small" label={buttonLabel} className={styles.button} />
            </div>
          )}
          {pictureError && !picture && <span className={styles.imageError}>Please select a picture*</span>}
        </div>
        <div className={styles.rightContent}>
          <Input
            label="Link"
            type="link"
            placeholder="Enter link here"
            errorMsg="Link field is not valid or is empty"
            className="cardInput"
            onChange={e => setLink(e.target.value)}
            error={linkError}
          />
          <Input
            label="Button label (max 24)"
            type="text"
            placeholder="Enter button label here"
            errorMsg="Button label can not be empty"
            className="cardInput"
            onChange={e => setButtonLabel(e.target.value)}
            maxLength={24}
            error={buttonError}
          />
        </div>
      </div>

      <Button label="Save" onClick={() => handleSave()} />
    </div>
  )
}

export default LinkedImageForm
