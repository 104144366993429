import UserAvatar from 'components/userAvatar'
import React from 'react'
import styles from './cardReport.module.scss'

const CardReport = ({
  className,
  avatar,
  author = '',
  action,
  reason,
  time,
  onClick,
  authorClass,
  reasonClass,
  timeClass
}) => {
  const onClickHandler = () => {
    onClick && onClick()
  }
  return (
    <div className={`${styles.wrapper} ${className}`} onClick={onClickHandler}>
      <UserAvatar width="3.125" height="3.125" imageUrl={avatar} />
      <div className={styles.rightContainer}>
        {author && (
          <div className={styles.nameAndActionContainer}>
            <h6 className={`${styles.author} ${authorClass}`}>{author}</h6>
            {action && <span className={`${styles.actionText} ${styles[action?.toLowerCase()]}`}>{action}</span>}
          </div>
        )}
        {reason && <p className={`${styles.reason} ${reasonClass}`}>{reason}</p>}
        {time && <p className={`${styles.time} ${timeClass}`}>{time}</p>}
      </div>
    </div>
  )
}

export default CardReport
