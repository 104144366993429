import Button from 'components/button'
import CardAnchorFront from 'components/card/types/anchorFront'
import CardDoubleBack from 'components/card/types/doubleBack'
import CardDoubleSidedTriviaBack from 'components/card/types/doubleSidedTriviaBack'
import CardEventFront from 'components/card/types/eventFront'
import FirstFront from 'components/card/types/firstFront'
import CardMysteryFront from 'components/card/types/mysteryFront'
import CardOneSidedTriviaFront from 'components/card/types/oneSidedTriviaFront'
import CardPollFront from 'components/card/types/pollFront'
import CardSlideBack from 'components/card/types/slideBack'
import CardStandardBack from 'components/card/types/standardBack'
import CardStandardFront from 'components/card/types/standardFront'
import Toggle from 'components/toggle'
import useUploadQue from 'hooks/useUploadQue'
import cloneDeep from 'lodash/cloneDeep'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import fileService from 'services/file.service'
import { toastService } from 'services/toastService'
import { CARD_TYPES, CARD_BACK_TYPES } from 'staticConstants'
// import { cardActions } from 'store/actions/card.actions'
// import { modalActions } from 'store/actions/modal.actions'
// import { userActions } from 'store/actions/user.actions'
import { getTypeFields } from 'utils/getCardFields'
import useWindowDimensions from 'utils/screenSize'
import styles from './cardCreation.module.scss'
import CardShare from 'components/card/types/share'
// import Rays from 'components/rays'
import iconClose from 'assets/icons/iconCloseBlue.svg'
import { converFileToBase64 } from 'utils/getBaseFromFile'
import InstacardHelp from 'components/instacardHelp'
import { setCreatedCard, setSelectedCard } from 'store/reducers/card/cardSlice'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'

export const uploadCardImages = async data => {
  const tempData = cloneDeep(data)
  let errorExist = false
  // FRONT IMAGE
  if (tempData.frontImage instanceof File) {
    const res = await uploadMedia(tempData.frontImage)

    if (res) {
      tempData.frontImage = res
    } else {
      errorExist = true
    }
  }
  // BACK FIELDS OR FULL CARD IMAGE
  if (!tempData.fullCardImage || !tempData.fullCardImage.active) {
    tempData.fullCardImage = { active: false, image: '' }
    if (tempData.backFields.length > 0)
      await Promise.all(
        tempData.backFields.map(async field => {
          if (field.type === CARD_BACK_TYPES.image && field.content instanceof File) {
            const res = await uploadMedia(field.content)
            if (res) {
              field.content = res
            } else {
              errorExist = true
            }
          } else if (field.type === CARD_BACK_TYPES.linkedImage && field.content?.image instanceof File) {
            const res = await uploadMedia(field.content.image)
            if (res) {
              field.content.image = res
            } else {
              errorExist = true
            }
          }
        })
      )
  } else {
    tempData.backFields = []
    if (tempData.fullCardImage.image instanceof File) {
      const res = await uploadMedia(tempData.fullCardImage.image)
      if (res) {
        tempData.fullCardImage.image = res
      } else {
        errorExist = true
      }
    }
  }
  // DOUBLE CARD IMAGE
  if (tempData.doubleCardImage instanceof File) {
    const res = await uploadMedia(tempData.doubleCardImage)
    if (res) {
      tempData.doubleCardImage = res
    } else {
      errorExist = true
    }
  }
  // SLIDER
  await Promise.all(
    tempData.slider.map(async slide => {
      if (slide.image instanceof File) {
        const res = await uploadMedia(slide.image)
        if (res) {
          slide.image = res
        } else {
          errorExist = true
        }
      } else if (slide.image instanceof Element) {
        const res = await uploadMedia(slide.image, null, null, null, true)
        if (res) {
          slide.image = res
        } else {
          errorExist = true
        }
      }
    })
  )
  if (errorExist) return null
  else return tempData
}

const getOrientation = async file => {
  return new Promise(resolve => {
    try {
      const reader = new FileReader()
      reader.onload = function (e) {
        const view = new DataView(e.target.result)
        if (view.getUint16(0, false) !== 0xffd8) {
          return resolve(-2)
        }
        const length = view.byteLength
        let offset = 2
        while (offset < length) {
          if (view.getUint16(offset + 2, false) <= 8) return resolve(-1)
          const marker = view.getUint16(offset, false)
          offset += 2
          if (marker === 0xffe1) {
            if (view.getUint32((offset += 2), false) !== 0x45786966) {
              return resolve(-1)
            }

            const little = view.getUint16((offset += 6), false) === 0x4949
            offset += view.getUint32(offset + 4, little)
            const tags = view.getUint16(offset, little)
            offset += 2
            for (const i = 0; i < tags; i++) {
              if (view.getUint16(offset + i * 12, little) === 0x0112) {
                return resolve(view.getUint16(offset + i * 12 + 8, little))
              }
            }
          } else if ((marker & 0xff00) !== 0xff00) {
            break
          } else {
            offset += view.getUint16(offset, false)
          }
        }
        return resolve(-1)
      }
      reader.readAsArrayBuffer(file)
      return resolve(0)
    } catch (error) {
      console.log(error)
      return resolve(0)
    }
  })
}

const getImageBase64 = async imageFile => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(imageFile)

    reader.addEventListener(
      'load',
      () => {
        // reader.result is the image file in base64
        return resolve(reader.result)
      },
      false
    )
  })
}

const getCorrectImageOrientationFromBase64 = async (srcBase64, srcOrientation) => {
  return new Promise(resolve => {
    const img = new Image()

    img.onload = function () {
      const width = img.width
      const height = img.height
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      // set proper canvas dimensions before transform & export
      if (srcOrientation > 4 && srcOrientation < 9) {
        canvas.width = height
        canvas.height = width
      } else {
        canvas.width = width
        canvas.height = height
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0)
          break
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height)
          break
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height)
          break
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0)
          break
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0)
          break
        case 7:
          ctx.transform(0, -1, -1, 0, height, width)
          break
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width)
          break
        default:
          break
      }

      // draw image
      ctx.drawImage(img, 0, 0)

      // export base64
      return resolve(canvas.toDataURL())
    }
    img.src = srcBase64
  })
}

export const uploadMedia = async (file, showProgress = true, cancelToken, setLoader, uploadCanvas = false) => {
  if (!uploadCanvas) {
    let isVideo = false
    if (file?.type.includes('video')) {
      isVideo = true
    }
    let video
    let orientation
    let base64ToRotate
    let base64
    let dataToUpload = { name: file.name }
    if (window.innerWidth < 992) {
      if (isVideo) {
        video = await converFileToBase64(file)
        dataToUpload = { ...dataToUpload, data: video }
      } else {
        orientation = await getOrientation(file)
        base64ToRotate = await getImageBase64(file)
        base64 = await getCorrectImageOrientationFromBase64(base64ToRotate, orientation)
        dataToUpload = { ...dataToUpload, data: base64, type: 'base64' }
      }
    } else {
      const upload = await converFileToBase64(file)
      dataToUpload = { ...dataToUpload, data: upload }
    }

    const options = {}
    if (setLoader) {
      options.onUploadProgress = event => {
        const { loaded, total } = event
        const percent = Math.floor((loaded * 100) / total)
        setLoader(percent === 0 ? 1 : percent)
      }
    }
    if (cancelToken) options.cancelToken = cancelToken

    try {
      const res = await fileService.run('s3', 'upload', dataToUpload, showProgress ? options : {})
      return res.fileId
    } catch (error) {
      console.log(error)
      toastService('error', 'Upload image/video failed!')
      return null
    }
  }
}

export const filterPollOptions = pollOptions => {
  let newArray = []
  newArray = pollOptions.filter(option => option.option !== '')
  return newArray
}

const CardPreviewLayout = ({ className, active, saveDraftActive, onUpdateOrSave, updateCardLoading }) => {
  const dispatch = useDispatch()
  const { auth } = useSelector(state => state)
  const [explanationScrape, setExplanationScrape] = useState(false)
  const card = useSelector(state => state.card.selectedCard)
  const [backFieldsUploadQue] = useUploadQue('backFields')
  const { isMobile } = useWindowDimensions()
  const [publishLoading, setPublishLoading] = useState(false)
  // const [triggerAnimation, setTriggerAnimation] = useState(false)
  const [isBussines] = useState(true)
  const [disableButtons, setDisableButtons] = useState(false)

  const [showCard, setShowCard] = useState(true)

  const onPublicChange = () => {
    dispatch(setSelectedCard({ ...card, public: !card.public }))
  }
  const onPublishHandlerWithAnimation = () => {
    setDisableButtons(true)
    if (!isBussines) {
      // setTriggerAnimation(true)
      onPublishHandler()
      // setTimeout(() => {
      //   onPublishHandler()
      //   setTriggerAnimation(false)
      // }, 2400)
    } else {
      onPublishHandler()
    }
  }
  const onPublishHandler = async () => {
    setDisableButtons(true)
    setPublishLoading(true)
    const cardWithUploadedImages = await uploadCardImages(card)
    if (cardWithUploadedImages) {
      const data = getTypeFields(cloneDeep(cardWithUploadedImages))
      if (!data.cardType) return toastService('error', 'Card type is required.')
      data.published = true
      data.userRole = auth?.user?.subType
      try {
        if (data.cardType === 'poll') {
          const filteredArray = filterPollOptions(data.pollOptions)
          data.pollOptions = filteredArray
        }
        let createdCard = await dataService.run('data', 'card', card._id ? 'update' : 'new', data)
        if (createdCard?._id || createdCard?.data?._id) {
          createdCard = createdCard?.data?._id ? createdCard?.data : createdCard
          dispatch(setCreatedCard(createdCard))
          setPublishLoading(false)
          const ShareIdFnc = async (text = '') => {
            await navigator.clipboard.writeText(text)
          }
          dispatch(
            openModal({
              content: (
                <CardShare
                  card={createdCard}
                  showBtns={false}
                  className={styles.cardShare}
                  ShareIdFnc={ShareIdFnc}
                  onShareClick={closeShareModal}
                />
              ),
              name: 'Card share modal',
              size: 'max300'
            })
          )
        }
      } catch (error) {
        setPublishLoading(false)
        dispatch(closeModal())
      }
    } else {
      setPublishLoading(false)
      setDisableButtons(false)
    }
  }
  const closeShareModal = () => {
    dispatch(closeModal())
  }

  const handleScrape = flag => {
    setExplanationScrape(flag)
    setShowCard(!flag)
  }

  return (
    <>
      <div className={`${styles.prevWrapper} ${className}`}>
        <div className={styles.prevHeader}></div>
        {showCard && (
          <div className={`${styles.prevContent} ${card.cardType === CARD_TYPES.Double && styles.doubleContent}`}>
            {/* FRONT CARD  */}
            {/* 
          Standard front card 
          Type : default, standard, slide, double, collectable, sponsored
          */}
            {(card.cardType === '' ||
              card.cardType === CARD_TYPES.Create ||
              card.cardType === CARD_TYPES.Standard ||
              card.cardType === CARD_TYPES.Slide ||
              card.cardType === CARD_TYPES.Double ||
              card.cardType === CARD_TYPES.Instacard ||
              card.cardType === CARD_TYPES.Blank ||
              card.cardType === CARD_TYPES.Collectible ||
              card.cardType === CARD_TYPES.Marketplace ||
              card.cardType === CARD_TYPES.DoubleSidedTrivia ||
              card.cardType === CARD_TYPES.Sponsored) && <CardStandardFront card={card} />}
            {/* 
            Event front card 
            Type : event
            */}
            {card.cardType === CARD_TYPES.Event && <CardEventFront card={card} />}
            {/* 
            Anchor front card 
            Type : anchor
            */}
            {card.cardType === CARD_TYPES.Anchor && <CardAnchorFront card={card} />}
            {/* 
            First front card 
            Type : First
            */}
            {card.cardType === CARD_TYPES.First && <FirstFront card={card} />}

            {/* 
            Poll front card 
            Type : poll
            */}
            {card.cardType === CARD_TYPES.Poll && <CardPollFront card={card} disableAnswers />}

            {/* 
            Mystery front card 
            Type : mystery
            */}
            {card.cardType === CARD_TYPES.Mystery && <CardMysteryFront card={card} />}
            {/* 
            One sided trivia
            Type : oneSidedTrivia
            */}
            {card.cardType === CARD_TYPES.OneSidedTrivia && (
              <CardOneSidedTriviaFront onPreview={true} card={card} saveAnswers={false} />
            )}
            {/* BACK CARD  */}
            {/* 
          Standard back card 
          Type : default, standard, collectable 
          */}
            {(card.cardType === '' ||
              card.cardType === CARD_TYPES.Create ||
              card.cardType === CARD_TYPES.Standard ||
              card.cardType === CARD_TYPES.Instacard ||
              card.cardType === CARD_TYPES.Blank ||
              card.cardType === CARD_TYPES.Collectible ||
              card.cardType === CARD_TYPES.Marketplace ||
              card.cardType === CARD_TYPES.Mystery) && <CardStandardBack onPreview={true} card={card} disableActions />}
            {card.cardType === CARD_TYPES.Sponsored && (
              <CardStandardBack
                onPreview={true}
                card={card}
                byline={card.byline !== '' ? card.byline : card.fullname}
              />
            )}
            {/* 
          Slide back card 
          Type : slide
           */}
            {card.cardType === CARD_TYPES.Slide && <CardSlideBack onPreview={true} card={card} disableActions />}
            {/* 
          Double back card 
          Type : double
          */}
            {card.cardType === CARD_TYPES.Double && <CardDoubleBack onPreview={true} card={card} disableActions />}
            {/* 
          Double sided trivia back card 
          Type : double
          */}
            {card.cardType === CARD_TYPES.DoubleSidedTrivia && (
              <CardDoubleSidedTriviaBack onPreview={true} card={card} saveAnswers={false} />
            )}
          </div>
        )}

        <div className={styles.wrapFooter}>
          {card.cardType === CARD_TYPES.Instacard && (
            <div className={styles.explanation}>
              If you cannot generate an Instacard to your liking, please &nbsp;
              <span onClick={() => handleScrape(true)}>click here</span>. We may have a solution for you.
            </div>
          )}
          {explanationScrape && card.cardType === CARD_TYPES.Instacard && (
            <div className={styles.explanation}>
              <div className={styles.innerDiv}>
                <div className={styles.closeExp}>
                  <img src={iconClose} alt="" onClick={() => handleScrape(false)} />
                </div>
                <InstacardHelp />
              </div>
            </div>
          )}
          <div className={styles.footer}>
            <Toggle checked={!card.public} onChange={onPublicChange} className={styles.fixMargins} />{' '}
            <span>
              {' '}
              <strong>Make Private? </strong> The card will only be visible to you. You can still share a private card{' '}
              <br />
              if you'd like, but a private card cannot be forwarded by anyone who receives it.
            </span>{' '}
          </div>
          <div className={isMobile ? styles.mobileBtns : ''}>
            {active === 3 && (
              <Button
                label={card && card?._id && card.published ? 'Update card' : 'Publish'}
                disabled={Object.keys(backFieldsUploadQue).length > 0 || disableButtons || saveDraftActive}
                size="xs"
                className={styles.publishBtn}
                spinner={publishLoading || updateCardLoading}
                onClick={() =>
                  card && card?._id && card.published ? onUpdateOrSave() : onPublishHandlerWithAnimation()
                }
                style={!isMobile ? { padding: '0.5rem 7rem' } : {}}
              />
            )}
          </div>
        </div>
      </div>
      {/* {triggerAnimation && (
        <Rays
          points={!auth.user.hasCards ? '100' : card.public ? '25' : 0}
          type={!auth.user.hasCards ? 'gold' : 'pink'}
          showText={false}
        />
      )} */}
    </>
  )
}
export default CardPreviewLayout
