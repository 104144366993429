import React from 'react'
import styles from '../questionSlider.module.scss'

const SliderSlide = ({ slide, currentSlideIndex, slideIndex, children, showDots }) => {
  return (
    <div
      key={'slide' + slide.id + slideIndex}
      className={`${styles.slide} ${currentSlideIndex === slideIndex ? styles.activeAnim : ''} ${
        !showDots ? styles.noDots : ''
      }`}
      style={{ zIndex: currentSlideIndex === slideIndex ? '40' : '35' }}
    >
      {children}
    </div>
  )
}
export default SliderSlide
