import React from 'react'
import styles from './cardScrollableContent.module.scss'
import { useLocation } from 'react-router-dom'

const CardScrollableContent = ({ children, className, style, type, elemRef, onScroll }) => {
  const location = useLocation()
  return (
    <div
      style={{ ...style, maxHeight: location?.pathname?.includes('admin/newsletter') ? '100%' : '60%' }}
      className={`${styles.container}   ${
        location?.pathname?.includes('admin/newsletter') && styles.containerNewsletter
      } ${className} ${styles[className]} ${styles['color-' + type]}`}
      onScroll={onScroll}
    >
      {children}
      <div ref={elemRef ?? null} />
    </div>
  )
}
export default CardScrollableContent
