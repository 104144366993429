import React from 'react'

const IconComponentMore = ({ width = '32', height = '8', color = 'black', onClick }) => {
  return (
    <svg
      onClick={() => onClick && onClick()}
      width={width}
      height={height}
      viewBox="0 0 32 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={color} />
      <circle cx="16" cy="4" r="4" fill={color} />
      <circle cx="28" cy="4" r="4" fill={color} />
    </svg>
  )
}
export default IconComponentMore
