import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  show: false,
  title: '',
  content: '',
  onSubmit: null,
  modalClass: '',
  additionalInputPlaceholder: '',
  yesTitle: 'Yes',
  noTitle: 'No'
}
export const yesNoModal = createSlice({
  name: 'yesNo modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { title, content, onsubmit, modalClass, additionalInputPlaceholder, yesTitle, noTitle } = action.payload
      state.title = title
      state.content = content
      state.onsubmit = onsubmit
      state.show = true
      state.modalClass = modalClass
      state.additionalInputPlaceholder = additionalInputPlaceholder
      state.yesTitle = yesTitle
      state.noTitle = noTitle
    },
    closeModal: state => {
      state.title = initialState.title
      state.content = initialState.content
      state.onSubmit = initialState.onSubmit
      state.show = false
      state.modalClass = initialState.modalClass
      state.additionalInputPlaceholder = initialState.additionalInputPlaceholder
      state.yesTitle = initialState.yesTitle
      state.noTitle = initialState.noTitle
    }
  }
})

export const { openModal, closeModal } = yesNoModal.actions

export default yesNoModal.reducer
