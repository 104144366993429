import moment from 'moment'
import validateEmail, { validateUsername } from './validateEmail'

export const removeEmpty = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => (v && !(v instanceof Date) && typeof v === 'object' ? removeEmpty(v) : v)).filter(v => v)
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [k, v && !(v instanceof Date) && typeof v === 'object' ? removeEmpty(v) : v])
      .reduce((a, [k, v]) => (typeof v !== 'boolean' && !v ? a : ((a[k] = v), a)), {})
  }
}

export const getObjectKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value)
}

export const isObjectEmpty = obj => {
  if (!obj) return true
  return Object.keys(obj).length === 0
}

export const checkTimestampValidity = timestamp => {
  if (!timestamp) return false
  return moment(Number(timestamp)).isValid()
}

export const getFormattedDate = (timestamp, format = 'M/D/YYYY') => {
  if (!timestamp || !checkTimestampValidity(timestamp)) return null
  const timestampInMilliseconds = String(timestamp).length === 13 ? Number(timestamp) : Number(timestamp * 1000)
  return moment(timestampInMilliseconds).format(format)
}

export const termRegex = term => {
  const res = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  return res
}

export const isUrlValid = urlString => {
  let url
  try {
    url = new URL(urlString)
  } catch (error) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}
export function firstLetterUpper(text) {
  if (!text) return ''
  return text.charAt(0).toUpperCase() + text.slice(1)
}
export const getUserForErrorMessage = (type, data, alreadyExistEmail, alreadyExistUsername) => {
  let message
  switch (type) {
    case 'email':
      message = 'Email required'
      break
    case 'badEmail':
      if (!validateEmail(data.email)) {
        message = 'Email is not valid'
      } else if (alreadyExistEmail) {
        message = 'Email already exist'
      }
      break
    case 'differentPassword':
      message = 'Password and confirm password are not equal'
      break
    case 'password':
      if (!data?.password) message = 'Password required'
      else if (data?.password.length < 6) message = 'Password must be at least 6 characters long'
      break
    case 'name':
      message = 'Full name required'
      break
    case 'ageRange':
      message = 'Age range required'
      break
    case 'businessName':
      message = 'Bussiness name required'
      break
    case 'username':
      if (alreadyExistUsername) {
        message = 'Username already exist'
      } else if (!validateUsername(data.username)) {
        message = 'Uppercase letter and special character are not allowed'
      } else if (data.username === '') {
        message = 'Username required'
      }
      break
    case 'businessWebsite':
      message = 'Bussines website required'
      break
    case 'businessAddress':
      message = 'Bussines address required'
      break
    case 'confirmPassword':
      if (!data.confirmPassword) message = 'Confirm password required'
      else if (data?.confirmPassword.length < 6) message = 'Confirm password must be at least 6 characters long'
      break
    case 'phoneNumber':
      message = 'Phone number and prefix required'
      break
    default:
      message = 'Error'
      break
  }
  return message
}
