import Button from 'components/button'
import Input from 'components/input'
import moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'
import { approveUser } from 'store/reducers/auth/thunk'
import { closeModal } from 'store/reducers/modal/modalSlice'
import iconClose from 'assets/icons/iconCloseBlue.svg'
import styles from './applicationForm.module.scss'

const ApplicationForm = ({ props, hasButtons = true }) => {
  const dispatch = useDispatch()

  const { note = 'There is no note for this user' } = props

  const handleApplication = async flag => {
    dispatch(closeModal())
    const note = document.getElementById('adminNote').value

    const data = {
      user: props,
      approve: flag,
      note
    }
    dispatch(approveUser(data))
  }

  const closeModalX = () => {
    dispatch(closeModal())
  }

  const userTypeName = () => {
    let name
    switch (props.subType) {
      case 1:
        name = 'Free'
        break
      case 2:
        name = 'Wonder Shuffle Plus'
        break
      case 3:
        name = 'Media'
        break
      case 4:
        name = 'Primetime Advertiser'
        break
      case 5:
        name = 'Contributor'
        break
      case 6:
        name = 'Editor'
        break
      case 7:
        name = 'Admin'
        break
      default:
        name = props.subType
        break
    }
    return name
  }

  return (
    <div className={styles.ApplicationFormWrapper}>
      <div className={styles.header}>
        <div onClick={closeModalX} className={styles.close}>
          <img src={iconClose} alt="Close icon" />
        </div>
        <div className={styles.title}>Application</div>
        <div></div>
      </div>
      <div className={styles.singleInputRow}>
        <Input
          variation="formInput"
          inputClass="biggerInput"
          placeholderOrentation="placeholderCenter"
          placeholder="date"
          type="date"
          name="date"
          disabled={true}
          value={moment(props.createdAt).format('YYYY-MM-DD')}
        />
        <Input
          variation="formInput"
          inputClass="biggerInput"
          placeholderOrentation="placeholderCenter"
          placeholder="name"
          type="text"
          name="name"
          value={props.name}
          disabled={true}
        />
      </div>
      <div className={styles.singleInputRow}>
        <Input
          variation="formInput"
          inputClass="biggerInput"
          placeholderOrentation="placeholderCenter"
          placeholder="type"
          type="text"
          name="type"
          value={userTypeName()}
          disabled={true}
        />
        <Input
          variation="formInput"
          inputClass="biggerInput"
          placeholderOrentation="placeholderCenter"
          placeholder="status"
          type="text"
          name="status"
          value={props.status}
          disabled={true}
        />
      </div>
      <div className={styles.singleInputRow}>
        <Input
          variation="formInput"
          inputClass="biggerInput"
          placeholderOrentation="placeholderCenter"
          placeholder="email"
          type="email"
          name="email"
          value={props.email}
          disabled={true}
        />
        <Input
          variation="formInput"
          inputClass="biggerInput"
          placeholderOrentation="placeholderCenter"
          placeholder="phoneNumber"
          type="text"
          name="phoneNumber"
          value={props.phoneNumber}
          disabled={true}
        />
      </div>
      <div className={styles.singleInputRow}>
        <Input
          variation="formInput"
          inputClass="biggerInput"
          placeholderOrentation="placeholderCenter"
          placeholder="webiste"
          type="text"
          name="website"
          value={props.businessWebsite}
          disabled={true}
        />
        <Input
          variation="formInput"
          inputClass="biggerInput"
          placeholderOrentation="placeholderCenter"
          placeholder="address"
          type="text"
          name="address"
          value={props.businessAddress}
          disabled={true}
        />
      </div>
      <div className={styles.singleInputRow}>
        <textarea placeholder="Message" disabled value={props.message}></textarea>
      </div>
      <h2 style={{ textAlign: 'center' }}> Admin note</h2>
      <div className={styles.singleInputRow}>
        {hasButtons ? (
          <textarea placeholder={'Admin note'} id={'adminNote'} />
        ) : (
          <textarea placeholder={'Admin note'} value={note} disabled />
        )}
      </div>
      {hasButtons && (
        <div className={styles.buttons}>
          <Button label="Accept" variation="secondary" onClick={() => handleApplication(true)} />
          <Button label="Reject" variation="secondary" onClick={() => handleApplication(false)} />
        </div>
      )}
    </div>
  )
}

export default ApplicationForm
