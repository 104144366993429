import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import dataService from 'services/data.service'
import { logOut } from 'store/reducers/auth/authSlice'
import { clear } from 'store/reducers/event/eventSlice'

const AuthListener = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(store => store?.auth)
  const { event } = useSelector(store => store)
  const history = useHistory()

  useEffect(() => {
    const isUserDisabledOrDeleted = async () => {
      const res = await dataService.run('profile', 'user', 'isUserDisabledOrDeleted', { userId: user?._id })
      if (res?.userIsDisabledOrDeleted) {
        dispatch(logOut())
        history.push('/register')
      }
    }
    if (user?._id) {
      isUserDisabledOrDeleted()
    }
  }, [user?._id])

  useEffect(() => {
    if (event?.data?.type === 'triggerLogout' && event?.data?.data === user?._id) {
      dispatch(clear)
      dispatch(logOut())
      history.push('/register')
    }
  }, [event])

  return <></>
}
export default AuthListener
