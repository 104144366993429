import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from 'store/reducers/modal/modalSlice'
import iconClose from 'assets/icons/iconCloseBlue.svg'
import iconUndo from 'assets/icons/undoBlue.svg'
import styles from './modal.module.scss'
import Button from 'components/button'
import useClickOutside from 'hooks/clickOutside'
import { clearPreview } from 'store/reducers/previewModal/previewModalSlice'

const ModalWrapper = ({
  show,
  content,
  size = 'max500',
  onclose,
  xButton = true,
  overlayClassName = '',
  confirmModalText = 'Are you sure you want to close this dialog?'
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(show)
  const [modalIsOpen] = useState(show)
  const dispatch = useDispatch()
  const undo = useSelector(state => state?.modal?.undo)
  const modalName = useSelector(state => state?.modal?.name)
  const [confirm, setConfirm] = useState(false)
  const onCloseModal = () => {
    if (modalName !== 'Card Creation Modal') dispatch(closeModal())
    if (onclose) {
      onclose()
    }
  }

  const proceed = close => {
    if (close) dispatch(closeModal())
    else {
      setConfirm(false)
      setIsComponentVisible(true)
    }
  }

  const Confirmation = () => {
    return (
      <div className={styles.confirmationModal}>
        <h3>{confirmModalText}</h3>
        <div>
          <Button label="YES" variation="primary" size="sm" onClick={() => proceed(true)} />
          <Button label="NO" variation="secondary" size="sm" onClick={() => proceed(false)} />
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (!isComponentVisible && modalName === 'Card Creation Modal') {
      setConfirm(true)
    }
  }, [isComponentVisible])

  return (
    <div className="modal-c">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={onCloseModal}
        contentLabel="Custom modal"
        className={`${styles.customModal} ${styles[size]}`}
        overlayClassName={`${styles.customOverlay} ${styles[overlayClassName]}`}
        ariaHideApp={false}
      >
        {confirm && <Confirmation />}
        <div className={styles.modalBody} ref={ref}>
          <div className={styles.scrollWrapper}>
            {xButton && (
              <span
                className={undo ? styles.undoClose : styles.close}
                onClick={() => {
                  if (undo) {
                    onclose()
                  } else {
                    dispatch(closeModal())
                    dispatch(clearPreview({}))
                  }
                }}
              >
                {undo ? <img src={iconUndo} alt="Undo icon" /> : <img src={iconClose} alt="Close icon" />}
              </span>
            )}
            {content}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ModalWrapper
