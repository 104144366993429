import styles from '../registerText.module.scss'

const PrimetimeAdvertiserText = () => {
  // const handleTest = () => {
  //   dispatch(openModal({ content: <TestInstaCard />, show: true, size: 'testInstacard' }))
  // }

  return (
    <div className={styles.wrapper}>
      <li>
        Are you a Business, Blog, Media Outlet or Magazine and want broad access to all our Wonder Shufflers, with
        primetime front-and-center positioning of your Cards in our Main Deck?
      </li>
      <li>As a Primetime Advertiser you can:</li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; Open an account to create cards in multiple formats and message all Wonder Shufflers based on their
        interests;
      </li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; Build Standard cards as well as Slider cards (great for photo essays or comic strips), Double Bubble
        cards, a weekly Poll card and Instacard!’s;
      </li>
      <li style={{ paddingLeft: '1rem' }}>&#x2022; Place embedded links and videos inside your cards;</li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022;Reach a wider audience by placing 30 Cards a month in our exciting Marketplace;
      </li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; Target readers with a daily campaign with 1 Card OR 1 Pack (for a duration of one week) either through
        the Main Deck OR Marketplace;
      </li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; Produce exciting content that, as it gains popularity, has the opportunity to be promoted towards the
        front of a Wonder Shuffle user’s Main Deck;
      </li>
      <li style={{ paddingLeft: '1rem' }}>&#x2022; Access backend aggregated analytics;</li>
      <li style={{ paddingLeft: '1rem' }}>&#x2022; Add an advertisement on each card (with an offsite link);</li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; You may even be able to build Instacard!’s for the Main Deck with your own URL links – as long as your
        site is coded to be compatible with us; and
      </li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; Champion People, Places and the Planet by committing to the Wonder Shuffle Pledge.
      </li>
      <li>
        The cost for a Primetime Advertiser Package is $1000/month, with an additional penny per impression after
        100,000 impressions.
      </li>
      <li>Additional partnership opportunities are also available. Please contact us for more details.</li>
    </div>
  )
}

export default PrimetimeAdvertiserText
