export const sessionStorageService = {
  destroy(type) {
    sessionStorage.removeItem(type)
  },

  set(type, data) {
    sessionStorage.setItem(type, JSON.stringify(data))
  },
  get(type) {
    const data = sessionStorage.getItem(type)
    if (!data) return null
    const res = JSON.parse(data)
    return res
  }
}
