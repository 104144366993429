import Chart from 'components/chart'
import moment from 'moment'
import { useEffect, useState } from 'react'
import dataService from 'services/data.service'
import styles from './rateOfChangePreview.module.scss'

const RateOfChangePreview = ({ id, numOfDays = 7 }) => {
  const [chartData, setChartData] = useState({ total: 0, totalBeforeStartingDay: 0, rateOfChange: '0%', data: [] })

  useEffect(() => {
    const fetchRateOfChanges = async () => {
      const res = await dataService.run('reports', 'data', 'getRateOfChange', {
        searchTerm: id,
        numOfDays: Number(numOfDays)
      })
      if (res?.total > 0) {
        setChartData(res)
      }
    }
    if (id) fetchRateOfChanges()
  }, [id])

  return (
    <div className={styles.emptyWrapper}>
      <h2>Rate of change</h2>
      <Chart
        data={chartData.data?.map(el => ({
          date: el.formatedDate,
          dateBottom: moment(el.date).format('DD') + moment(el.date).format('ddd').charAt(0),
          emptySearches: el?.emptySearches
        }))}
        titleName="Empty Searches (per day)"
        dataKey={'emptySearches'}
        selectValue={numOfDays}
        titleNumber={numOfDays + 'D: ' + chartData.rateOfChange + '%'}
        className={styles.chart}
      />
    </div>
  )
}

export default RateOfChangePreview
