import React from 'react'
import edit from '../../assets/images/edit.svg'
import check from 'assets/images/checked.svg'
import crossIcon from 'assets/images/remove.svg'
import styles from './categorySingle.module.scss'

const CategorySingle = ({ props, onEditClick = null }) => {
  const { name = '', showOnReg = false, timesUsed = 0, timesSearched = 0 } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.singleCell} style={{ marginLeft: 0, textAlign: 'left', paddingLeft: 0 }}>
          {name}
        </div>
        <div className={styles.singleCell} style={{ paddingLeft: '5rem' }}>
          <img src={showOnReg ? check : crossIcon} alt="" />
        </div>
        <div className={styles.singleCell} style={{ paddingLeft: '9rem' }}>
          {timesUsed}
        </div>
        <div className={styles.singleCell} style={{ textAlign: 'right' }}>
          {timesSearched}
        </div>
        <div className={styles.edit} onClick={onEditClick}>
          <img src={edit} alt="" />
        </div>
      </div>
    </div>
  )
}

export default CategorySingle
