export const updatePagedArray = (setArray, rows = [], total, element, deleted = false, newElement = false) => {
  const newArray = []
  let alreadyUpdated = false
  if (rows.length > 0)
    rows.forEach(el => {
      if (el._id === element._id) {
        if (!deleted) newArray.push(element)
        alreadyUpdated = true
      } else {
        newArray.push(el)
      }
    })
  if (newElement && !alreadyUpdated) newArray.unshift(element)
  let newTotal = total ?? newArray?.length
  if (deleted) {
    newTotal = newTotal - 1
  } else if (newElement && !alreadyUpdated) {
    newTotal = newTotal + 1
  }
  setArray && setArray({ rows: newArray, total: newTotal })
}
export const updateArray = (setArray, array = [], element, deleted = false, moveUpdatedElement = false) => {
  const newArray = []
  if (moveUpdatedElement === 'start' && !deleted) newArray.push(element)
  if (array.length > 0)
    array.forEach(el =>
      el._id === element._id ? (deleted || moveUpdatedElement ? null : newArray.push(element)) : newArray.push(el)
    )
  if (moveUpdatedElement === 'end' && !deleted) newArray.push(element)
  setArray && setArray(newArray)
}
