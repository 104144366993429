import Selector from 'components/select'
import { useState } from 'react'
import styles from './dynamicFilter.module.scss'

export const FILTER_TYPES = {
  Input: 'input',
  Select: 'select'
}

const handleFilterInputOnEnter = (e, onSubmit, name) => {
  if (e.key === 'Enter') {
    onSubmit && onSubmit(e.target.value, name)
  }
}
const handleFilterInputOnBlur = (e, onSubmit, name) => {
  onSubmit && onSubmit(e.target.value, name)
}
export const FILTER_ALIGNMENT = {
  Left: 'flex-start',
  Right: 'flex-end',
  Center: 'center',
  SpaceBetween: 'space-between',
  SpaceAround: 'space-around'
}
const DynamicFilter = ({ filters = [], className, alignContent = FILTER_ALIGNMENT.Right }) => {
  const [selectedOptions, setSelectedOptions] = useState({})

  const handleFilterSelectOnChange = (e, onSubmit, name) => {
    setSelectedOptions({ ...selectedOptions, [name]: e.value })
    onSubmit && onSubmit(e.value, name)
  }
  const renderFilter = (filter, index) => {
    switch (filter?.type) {
      case FILTER_TYPES.Input:
        return (
          <input
            key={'filterInput' + index}
            className={styles.inputClass}
            styles={filter?.styles}
            type="text"
            onBlur={event => handleFilterInputOnBlur(event, filter?.onChange, filter.name)}
            onKeyUp={event => handleFilterInputOnEnter(event, filter?.onChange, filter.name)}
            placeholder={filter.label}
          />
        )
      case FILTER_TYPES.Select:
        return (
          <Selector
            key={'filterSelect' + index}
            styles={filter?.styles}
            selectClass={filter?.selectClass || 'selectGrayNoMargin'}
            placeholder={filter?.label}
            options={filter?.options}
            selectedOption={
              filter?.options?.length > 0 &&
              filter.options.find(option => option.value === selectedOptions?.[filter?.name])
            }
            handleChangeSelect={event => handleFilterSelectOnChange(event, filter?.onChange, filter?.name)}
          />
        )
      default:
        return <></>
    }
  }
  return (
    <div
      style={{ justifyContent: alignContent }}
      className={`${styles.filtersContainer} ${className} ${styles[className]}`}
    >
      {filters?.length > 0 &&
        filters.map((filter, index) => {
          if (filter?.type) {
            return renderFilter(filter, index)
          }
        })}
    </div>
  )
}
export default DynamicFilter
