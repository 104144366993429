import React, { useEffect, useState } from 'react'
import filter from 'assets/icons/Filter.svg'
import styles from './chartFilter.module.scss'
import Selector from 'components/select'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFilterAge,
  setFilterDays,
  setFilterGender,
  setSelectedCampaignRedux,
  setShowAgeFilter,
  setShowGenderFilter
} from 'store/reducers/filter/filterSlice'
import { openModal } from 'store/reducers/modal/modalSlice'
import dataService from 'services/data.service'
import moment from 'moment'

const selectValues = [
  { value: 7, label: 'Past 7 days' },
  { value: 14, label: 'Past 14 days' },
  { value: 30, label: 'Past 30 days' }
]

const ageOptions = [
  { value: '18-24', label: '18-24' },
  { value: '25-34', label: '25-34' },
  { value: '35-49', label: '35-49' },
  { value: '50-65', label: '50-65' },
  { value: '65+', label: '65+' },
  { value: '', label: 'All' }
]

const genderOptions = [
  { value: 'men', label: 'Men' },
  { value: 'women', label: 'Women' },
  { value: 'all', label: 'All' }
]

const ChartFilter = () => {
  const filterNum = useSelector(state => state.filter.numberOfDays)
  const filterAge = useSelector(state => state.filter.age)
  const filterGender = useSelector(state => state.filter.gender)
  const showAgeFilter = useSelector(state => state.filter.ageShow)
  const showGenderFilter = useSelector(state => state.filter.genderShow)
  const user = useSelector(state => state?.auth?.user)
  const [userCampaigns, setUserCampaigns] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState()
  const [isShowModal, setIsShowModal] = useState(false)
  const dispatch = useDispatch()
  const [lastDays, setLastDays] = useState(30)
  const [ageOption, setAgeOption] = useState('')
  const [selectedGender, setSelectedGender] = useState('')

  const handleChangeCheckBox = name => {
    if (name === 'ageRange') {
      dispatch(setShowAgeFilter(!showAgeFilter))
    } else if (name === 'gender') {
      dispatch(setShowGenderFilter(!showGenderFilter))
    }
  }

  const handleChangeDays = e => {
    dispatch(setFilterDays(e.value))
  }

  const handleChangeGender = e => {
    dispatch(setFilterGender(e.value))
  }

  const handleChangeAge = e => {
    dispatch(setFilterAge(e.value))
  }

  const contentForModal = (
    <div className={styles.contentForModal}>
      <div className={styles.confirm}>
        <div
          className={showAgeFilter ? styles.active : styles.inActive}
          onClick={() => handleChangeCheckBox('ageRange')}
        >
          <div className={styles.inner}></div>
        </div>
        <span>Age Range</span>
      </div>
      <div className={styles.confirm}>
        <div
          className={showGenderFilter ? styles.active : styles.inActive}
          onClick={() => handleChangeCheckBox('gender')}
        >
          <div className={styles.inner}></div>
        </div>
        <span>Gender</span>
      </div>
    </div>
  )

  const openModalFilter = () => {
    setIsShowModal(true)
    dispatch(
      openModal({
        content: contentForModal,
        name: 'Filter modal',
        show: true,
        onclose: () => {
          setIsShowModal(false)
        }
      })
    )
  }

  useEffect(() => {
    if (isShowModal) {
      openModalFilter()
    }
  }, [showAgeFilter, showGenderFilter])

  useEffect(() => {
    setLastDays(filterNum)
    setAgeOption(filterAge)
    setSelectedGender(filterGender)
  }, [filterNum, filterAge, filterGender])

  useEffect(() => {
    const fetchCampaigns = async () => {
      const currentTimestamp = moment().valueOf()
      const response = await dataService.run('data', 'campaign', 'all', {
        filter: { userId: user._id, endDate: { $gt: currentTimestamp } }
      })
      if (response && response.length > 0) {
        const campaignSet = []
        response.forEach(campaign => {
          const obj = { value: campaign?.cardId, label: campaign?.name, startDate: campaign?.startDate }
          campaignSet.push(obj)
        })
        if (campaignSet.length > 0) {
          dispatch(setSelectedCampaignRedux(campaignSet[0]))
          setSelectedCampaign(campaignSet[0])
        }
        setUserCampaigns(campaignSet)
      }
    }
    fetchCampaigns()
  }, [])

  const selectCampaign = campaign => {
    dispatch(setSelectedCampaignRedux(campaign))
    setSelectedCampaign(campaign)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.selectors}>
        <div className={styles.singleSelector}>
          <Selector
            selectClass={'selectGrayLightNoMargin'}
            options={selectValues}
            selectedOption={selectValues.find(option => option.value === lastDays)}
            handleChangeSelect={handleChangeDays}
          />
        </div>
        <div className={styles.singleSelector}>
          <Selector
            selectClass={'selectGrayLightNoMargin'}
            placeholder={'Select Campaign'}
            options={userCampaigns}
            selectedOption={selectedCampaign}
            handleChangeSelect={e => selectCampaign(e)}
          />
        </div>
        {showGenderFilter && (
          <div className={styles.singleSelector}>
            <Selector
              selectClass={'selectGrayLightNoMargin'}
              options={genderOptions}
              selectedOption={genderOptions.find(option => option.value === selectedGender)}
              handleChangeSelect={handleChangeGender}
              placeholder={'Select Gender'}
            />
          </div>
        )}
        {showAgeFilter && (
          <div className={styles.singleSelector}>
            <Selector
              selectClass={'selectGrayLightNoMargin'}
              options={ageOptions}
              selectedOption={ageOptions.find(option => option.value === ageOption)}
              handleChangeSelect={handleChangeAge}
              placeholder="Select Age"
            />
          </div>
        )}
      </div>
      <div className={styles.circle}>
        <img src={filter} alt="filter" onClick={openModalFilter} />
      </div>
    </div>
  )
}

export default ChartFilter
