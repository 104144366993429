import CardComments from 'components/card/types/comments'
import CardDoubleBack from 'components/card/types/doubleBack'
import CardDoubleSidedTriviaBack from 'components/card/types/doubleSidedTriviaBack'
import CardSlideBack from 'components/card/types/slideBack'
import CardStandardBack from 'components/card/types/standardBack'
import React from 'react'
import { CARD_TYPES } from 'staticConstants'
import styles from './cardBack.module.scss'

export const CardBack = ({
  card,
  size,
  setIsInline,
  disableComments = false,
  commentsOpened = false,
  openComments
}) => {
  const openCommentsHandle = () => {
    if (!disableComments) {
      openComments && openComments(!commentsOpened)
    }
  }
  const renderCardByType = () => {
    switch (card.cardType) {
      case CARD_TYPES.Slide:
        return <CardSlideBack card={card} size={size} openComments={openCommentsHandle} />
      case CARD_TYPES.Double:
        return <CardDoubleBack card={card} size={size} setIsInline={setIsInline} openComments={openCommentsHandle} />
      case CARD_TYPES.DoubleSidedTrivia:
        return <CardDoubleSidedTriviaBack card={card} size={size} />
      case CARD_TYPES.Sponsored:
        return (
          <CardStandardBack
            card={card}
            byline={card.byline !== '' ? card.byline : card.fullname}
            size={size}
            setIsInline={setIsInline}
            openComments={openCommentsHandle}
          />
        )
      default:
        return <CardStandardBack card={card} size={size} setIsInline={setIsInline} openComments={openCommentsHandle} />
    }
  }
  return (
    <div
      className={`${styles.wrapper} ${card.cardType === CARD_TYPES.Double ? styles.doubleCardType : ''} ${
        commentsOpened ? styles.showComments : ''
      }`}
    >
      <CardComments card={card} className={`${styles.cardComments}`} />
      {renderCardByType()}
    </div>
  )
}

export default CardBack
