import CampaignCard from 'components/card/types/campaignCard'
import Heading from 'components/Heading'
import TestInstaCard from 'components/testInstaCard'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { openModal } from 'store/reducers/modal/modalSlice'
import styles from './campaignTypeSelection.module.scss'
import imageGeneralCampaign from 'assets/images/general_campaign.svg'
import imageMarketplaceCampaign from 'assets/images/marketplace_campaign.svg'
import imagePartnershipCampaign from 'assets/images/partnership_campaign.svg'
import imageMediaCampaign from 'assets/images/media_capmaign.svg'
import { USER_TYPE_VALUE } from 'staticConstants'

const CAMPAIGN_CARDS = {
  general: {
    heading: 'Main Deck Campaign',
    frontImage: imageGeneralCampaign,
    cardType: 'standard'
  },
  marketplace: {
    heading: 'Marketplace Campaign',
    cardType: 'marketplace',
    frontImage: imageMarketplaceCampaign
  },
  partnership: {
    heading: 'Partnership Campaign',
    cardType: 'partnership',
    frontImage: imagePartnershipCampaign
  },
  media: {
    heading: 'Main Deck Instacard!',
    cardType: 'collectible',
    frontImage: imageMediaCampaign
  }
}

const CampaignTypeSelection = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.auth.user)

  const handleCampaignType = type => {
    if (type) history.push(`/campaign/new/${type}`)
  }

  const handleTest = () => {
    dispatch(openModal({ content: <TestInstaCard />, show: true, size: 'testInstacard' }))
  }

  return (
    <div className={styles.wrapper}>
      <Heading title="Choose campaign type" />
      <div className={styles.content}>
        <div className={styles.cardsCreate}>
          <CampaignCard card={CAMPAIGN_CARDS.general} onClick={() => handleCampaignType('general')} />
          <CampaignCard card={CAMPAIGN_CARDS.marketplace} onClick={() => handleCampaignType('marketplace')} />
          {user && user.role && (user.role === 'admin' || (user.role === 'premiumUser' && user.subType === 7)) && (
            <>
              <CampaignCard card={CAMPAIGN_CARDS.partnership} onClick={() => handleCampaignType('partnership')} />
              <CampaignCard card={CAMPAIGN_CARDS.media} onClick={() => handleCampaignType('media')} />
            </>
          )}
        </div>
        {user && user.role !== 'admin' && (
          <div className={styles.text}>
            {user && user.subType === USER_TYPE_VALUE.premiumAdvertiser && (
              <p>
                &#x2022; Target readers with a daily campaign with 1 Card OR 1 Pack (for a duration of one week) either
                through the Main Deck OR Marketplace.
              </p>
            )}
            {user && user.subType === USER_TYPE_VALUE.media && (
              <p>
                &#x2022; Target readers with a weekly campaign with 1 Card OR 1 Pack (for a duration of one week) either
                through the Main Deck OR Marketplace.
              </p>
            )}
            {user && user.subType === USER_TYPE_VALUE.businessSubscriber && (
              <p>
                &#x2022; Target readers with a weekly campaign with 1 Card through either the Main Deck OR Marketplace.
              </p>
            )}
            {user && (user.subType === USER_TYPE_VALUE.media || user.subType === USER_TYPE_VALUE.premiumAdvertiser) && (
              <p>
                &#x2022; You may even be able to build Instacard!’s for a Main Deck campaign with your own URL links – as
                long as your site is coded to be compatible with us. <span onClick={handleTest}>Click here to test</span>.
              </p>
            )}
            <p>
              We also offer additional Partnership opportunities. If you’d like to learn more, please{' '}
              <span onClick={() => history.push('/contact')}>contact us</span>.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default CampaignTypeSelection
