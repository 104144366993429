import EditUser from 'components/editUserProfile'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from 'store/reducers/modal/modalSlice'
import defaultAvatar from 'assets/images/icons/defaultUser.png'
import edit from '../../assets/images/edit.svg'
import styles from './userSingle.module.scss'
import { awsUrlResize } from 'environment'
import dataService from 'services/data.service'

const UserSingle = ({ props, showSwitchAndEdit = true }) => {
  const { name = '', email = '', role = '', loggedAt = 0, active = false, _id = 0 } = props

  const dispatch = useDispatch()
  const [activeUser, setActiveUser] = useState(active)
  const date = new Date().getTime()

  const openModalEdit = () => {
    dispatch(
      openModal({
        content: <EditUser props={props} />,
        name: 'Edit profile modal',
        size: 'max900',
        show: true,
        xButton: false
      })
    )
  }

  const onImageNonExist = err => {
    err.target.onError = null
    err.target.src = defaultAvatar
  }

  const handleClickActive = async () => {
    const newUser = { ...props }
    newUser.active = !activeUser
    const res = await dataService.run('profiles', newUser.type, 'update', newUser)
    if (Object.keys(res).length > 0) {
      setActiveUser(!activeUser)
    }
  }

  return (
    <div className={showSwitchAndEdit ? styles.userSingleWrapper : styles.userSingleWrapper100}>
      {showSwitchAndEdit && (
        <>
          <div className={styles.innerWrapper}>
            <img
              src={_id && _id !== 0 && awsUrlResize + '/' + _id + '.jpg' + `?${date}`}
              alt="User"
              onError={onImageNonExist}
            />
            <span style={{ textAlign: 'left', width: '20%' }}>{name}</span>
            <span style={{ textAlign: 'center' }}>{email}</span>
            <span style={{ textAlign: 'center' }}>{role}</span>
            <span style={{ textAlign: 'right', width: '10%', wordBreak: 'break-word' }}>
              {loggedAt !== 0 ? moment(loggedAt).fromNow() : 'Never'}
            </span>
            <div className={styles.edit} onClick={openModalEdit}>
              <img src={edit} alt="" />
            </div>
          </div>
          <div className={activeUser ? styles.active : styles.inActive} onClick={handleClickActive}>
            <div className={styles.inner}></div>
          </div>
        </>
      )}
      {!showSwitchAndEdit && (
        <>
          <div className={styles.innerWrapper} style={{ justifyContent: 'space-between' }}>
            <span style={{ width: 'fit-content', marginLeft: '2rem' }}>{email}</span>
            <span style={{ width: 'fit-content', marginRight: '2rem' }}>{name}</span>
          </div>
        </>
      )}
    </div>
  )
}

export default UserSingle
