import { request } from './http.service'
import { localStorageService } from './localStorage.service'

class UserService {
  async login(user) {
    return request('post', '/api/auth', {
      mode: 'login',
      data: {
        user,
        socialType: 'base'
      }
    })
  }

  async logout() {
    localStorageService.destroy('user')
  }

  async checkRegister(data) {
    return request('post', '/api/auth', {
      mode: 'check-register-premium',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async register(data) {
    return request('post', '/api/auth', {
      mode: 'register-premium',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async registerCoAdmin(data) {
    return request('post', '/api/auth', {
      mode: 'register-co-admin',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async sendConfirmEmailLink(data) {
    return request('post', '/api/auth/public', {
      mode: 'send-confirm-email-link',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async confirmEmail(data) {
    return request('post', '/api/auth/public', {
      mode: 'confirm-email',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async sendRespondEmail(data) {
    return request('post', '/api/auth', {
      mode: 'send-contactus-respond',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async approveUser(data) {
    return request('post', '/api/auth', {
      mode: 'approve-user',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async getUserById(id) {
    return request('get', `user/${id}`)
  }

  async listAllCompanyUsers(companyId) {
    return request('get', `user/company/${companyId}`)
  }

  async updateUser(user) {
    return request('post', '/api/auth', {
      mode: 'update',
      data: {
        user,
        socialType: 'base'
      }
    })
  }

  async sendMultipleInvites(emailArr) {
    return request('post', '/api/auth', {
      mode: 'send-multiple-invites',
      data: {
        emailArr,
        socialType: 'base'
      }
    })
  }

  async sendContactUsQuestion(data) {
    return request('post', '/api/auth/public', {
      mode: 'send-contactus-question',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async changePassword(data) {
    return request('post', '/api/auth', {
      mode: 'change-password',
      data: {
        socialType: 'base',
        data
      }
    })
  }

  async sendConfirmPassLink(data) {
    return request('post', '/api/auth', {
      mode: 'send-confirm-pass-link',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async sendConfirmPersonalDetailsLink(data) {
    return request('post', '/api/auth', {
      mode: 'send-confirm-personal-details-link',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async sendDeactivateOrRestoreLink(data) {
    return request('post', '/api/auth', {
      mode: 'send-deactivate-or-restore-link',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async sendForgotPassword(data) {
    return request('post', '/api/auth', {
      mode: 'send-forgot-password-signup-link',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async confirmForgotPassword(data) {
    return request('post', '/api/auth/public', {
      mode: 'confirm-forgot-password-signup',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async sendNewsletter(data) {
    return request('post', '/api/auth', {
      mode: 'send-newsletter',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async sendConfirmChangeAccount(data) {
    return request('post', '/api/auth', {
      mode: 'send-confirm-change-account',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async confirmChangeAccountType(data) {
    return request('post', '/api/auth', {
      mode: 'confirm-change-account-type',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async switchApplicationLogin(data) {
    return request('post', '/api/auth', {
      mode: 'switch-application-login',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async activateOrDeactivateProfile(user) {
    return request('post', '/api/auth', {
      mode: 'activate-or-deactivate-profile',
      data: {
        user,
        socialType: 'base'
      }
    })
  }

  async activateOrDeactivateProfileFromAdmin(user) {
    return request('post', '/api/auth', {
      mode: 'activate-or-deactivate-profile-from-admin',
      data: {
        user,
        socialType: 'base'
      }
    })
  }

  async changePersonalDetails(data) {
    return request('post', '/api/auth', {
      mode: 'change-personal-details',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async checkTimestamp(data) {
    return request('post', '/api/auth', {
      mode: 'check-timestamp',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async sendCancelSub(data) {
    return request('post', '/api/auth', {
      mode: 'send-cancel-sub',
      data: {
        data,
        socialType: 'base'
      }
    })
  }

  async confirmCancelSub(data) {
    return request('post', '/api/auth', {
      mode: 'confirm-cancel-sub',
      data: {
        data,
        socialType: 'base'
      }
    })
  }
}

export default new UserService()
