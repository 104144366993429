import React from 'react'
import AuthListener from './authListener'
import CardPreviewListener from './cardPreviewListener'
import ChatListener from './chatListener'
const Listeners = () => {
  return (
    <>
      <ChatListener />
      <CardPreviewListener />
      <AuthListener />
    </>
  )
}
export default Listeners
