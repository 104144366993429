import React, { useEffect, useState } from 'react'
import Chart from 'components/chart'
import { subDays } from 'date-fns'
import styles from './views.module.scss'
import Button from 'components/button'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import html2canvas from 'html2canvas'
import { openModal } from 'store/reducers/modal/modalSlice'
import ReportsDoc from 'components/reportsPdf'
import Heading from 'components/Heading'

const Reports = () => {
  const location = window.location.pathname
  const filterNum = useSelector(state => state.filter.numberOfDays)
  const filterAge = useSelector(state => state.filter.age)
  const filterGender = useSelector(state => state.filter.gender)
  const selectedCampaignRedux = useSelector(state => state.filter.selectedCampaign)
  // eslint-disable-next-line no-unused-vars
  const [imgData, setImgData] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const param = location.split('/')[2]
  const [typeState, setTypeState] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (param === 'views') {
      setTypeState('view')
    } else if (param === 'shares') {
      setTypeState('share')
    } else if (param === 'likes') {
      setTypeState('like')
    } else {
      setTypeState(param)
    }
  }, [param])

  useEffect(() => {
    const getReports = async () => {
      if (selectedCampaignRedux && Object.keys(selectedCampaignRedux)?.length > 0) {
        const value = subDays(new Date(), filterNum).toISOString()
        const timestampValue = new Date(selectedCampaignRedux.startDate || value).getTime()

        const data = {
          timestampValue,
          value: filterNum,
          cardId: selectedCampaignRedux.value,
          filterAge,
          filterGender,
          type: typeState
        }

        const reports = await dataService.run('data', 'map', 'reportSingle', data)
        setData(reports)
      }
    }
    getReports()
  }, [filterNum, filterAge, filterGender, selectedCampaignRedux, typeState])

  const handleImage = async () => {
    setLoading(true)
    await html2canvas(document.querySelector('#divToPrint'), { allowTaint: true }).then(canvas => {
      setImgData(canvas?.toDataURL('image/jpeg'))
      dispatch(
        openModal({
          show: true,
          content: <ReportsDoc imgSrc={canvas?.toDataURL('image/jpeg')} name={param} />,
          name: 'PDF modal',
          size: 'pdfPreviewModal'
        })
      )
    })
    setLoading(false)
  }

  return (
    <div className={styles.wrapper}>
      <Heading title={param} />
      <Chart
        data={data.reportSingle}
        dataKey={typeState}
        titleNumber={data && data.numberOf}
        widthSize={'large'}
        heightPx={600}
        bottomLegend={true}
        topFilter={true}
        title={false}
      />
      <div className={styles.buttonDiv}>
        <Button
          label="Export Report"
          variation="secondary"
          onClick={() => handleImage()}
          disable={loading}
          spinner={loading}
        />
      </div>
    </div>
  )
}

export default Reports
