import Button from 'components/button'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toastService } from 'services/toastService'
import { activateOrDeactivateProfile, checkTimestamp } from 'store/reducers/auth/thunk'
import { closeModal, openModal } from 'store/reducers/modal/modalSlice'
import NewRequest from '../newRequest'
import styles from './deactivateActivate.module.scss'
import { localStorageService } from 'services/localStorage.service'
import { logoutSpecificUser } from 'utils/logout'
import { getLocalUser, setUser } from 'store/reducers/auth/authSlice'
import { sessionStorageService } from 'services/sessionStorage.service'

function DeactivatedOrRestoredAccount({ data, active }) {
  data = { ...data, email: JSON.parse(atob(data.email)), username: JSON.parse(atob(data.username)) }
  const [loading, setLoading] = useState(false)
  const user = localStorageService.get(`user_${data.username}`)

  const [expiredToken, setExpiredToken] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleYes = async () => {
    setLoading(true)
    const res = await dispatch(activateOrDeactivateProfile({ email: data.email }))
    if (res.active) {
      toastService('success', 'Successfully restored account.')
    } else {
      toastService('success', 'Successfully deactivated account.')
      logoutSpecificUser(getLocalUser())
    }
    setLoading(false)
    dispatch(closeModal())
    history.push('/home')
  }

  useEffect(() => {
    if (user) {
      dispatch(setUser(user))
      sessionStorageService.set('username', user.username)
    }
  }, [user])

  const handleNo = () => {
    dispatch(closeModal())
  }

  useEffect(() => {
    const getResponse = async () => {
      try {
        const res = await dispatch(checkTimestamp({ ...data, type: 'confirmDeactivationOrRestoreToken' }))
        if (res && res.error) {
          setExpiredToken(true)
        }
      } catch (error) {
        setExpiredToken(true)
      }
    }
    getResponse()
    handleModal()
  }, [])

  useEffect(() => {
    handleModal()
  }, [expiredToken])

  const handleModal = () => {
    if (expiredToken || data?.email !== user?.email) {
      if (active) {
        dispatch(
          openModal({
            content: (
              <NewRequest
                type="Deactivate Account"
                expiredToken={expiredToken}
                currentUser={data?.email !== user?.email}
              />
            ),
            show: true,
            size: 'maxCenter500'
          })
        )
      } else {
        dispatch(
          openModal({
            content: (
              <NewRequest
                type="Restore Account"
                expiredToken={expiredToken}
                currentUser={data?.email !== user?.email}
              />
            ),
            show: true,
            size: 'maxCenter500'
          })
        )
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <p>Are you sure that you want to {active ? 'deactivate' : 'restore'} your account?</p>

      <div className={styles.buttons}>
        <Button label="Yes" size="fitContent" onClick={handleYes} spinner={loading} />
        <Button label="No" variation="secondary" size="fitContent" onClick={handleNo} />
      </div>
    </div>
  )
}

export default DeactivatedOrRestoredAccount
