import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './tagInput.module.scss'
import Topic from 'components/topic'
import { toastService } from 'services/toastService'
const TagInput = ({
  name,
  tags = [],
  placeholder = 'Add extra search terms, including locations and categories, here (optional)',
  className,
  inputClass,
  showHash = true,
  error,
  errorMsg,
  tagsLimit = 10,
  onTagChange = array => console.log(array)
}) => {
  const [inputValue, setInputValue] = useState('')
  const [tagsObject, setTagsObject] = useState({})
  const [tagsLoaded, setTagsLoaded] = useState(false)

  const filterValue = value => {
    if (value === '#') return value

    let filteredValue = value.trim()
    filteredValue = filteredValue.split('#')
    filteredValue = filteredValue.join('')
    filteredValue = filteredValue.split(' ')
    filteredValue = filteredValue.join('')

    return value !== '' ? '#' + filteredValue : ''
  }

  const addTag = value => {
    const keysArray = Object.keys(tagsObject || {})
    if (keysArray?.length < tagsLimit) {
      const tagValue = filterValue(value)
      const newObj = { ...(tagsObject || {}), [tagValue.replace('#', '')]: 1 }
      setTagsObject(newObj)
      onTagChange(Object?.keys(newObj || {}))
      setInputValue('')
    } else {
      toastService('info', `The maximum number of tags allowed is ${tagsLimit}.`)
    }
    return null
  }
  const deleteTag = deletedValue => {
    const tempObject = { ...tagsObject }
    delete tempObject?.[deletedValue]
    setTagsObject(tempObject)
    onTagChange(Object?.keys(tempObject || {}))
  }

  const onKeyPress = event => {
    if (event.key === 'Enter' && inputValue !== '') {
      addTag(inputValue)
    }
  }
  const onChangeHandler = event => {
    const tagValue = filterValue(event.target.value)
    setInputValue(tagValue)
  }

  useEffect(() => {
    if (!tagsLoaded && tags?.length > 0) {
      const tagsObj = {}
      for (let i = 0; i < tags.length; i++) {
        tagsObj[tags[i]] = 1
      }
      setTagsObject(tagsObj)
      setTagsLoaded(true)
    }
  }, [tags])

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container}  ${className} ${styles[className]}`}>
        <div className={styles.content}>
          {tagsObject && Object.keys(tagsObject).length > 0 && (
            <div className={styles.tagContainer}>
              {Object.keys(tagsObject || {})?.map((tag, index) => (
                <Topic key={tag + index} onClose={() => deleteTag(tag)} showHash={showHash} label={tag} />
              ))}
            </div>
          )}
          <input
            type="test"
            name={name}
            value={inputValue}
            className={`${inputClass} ${styles[inputClass]}`}
            onKeyUp={onKeyPress}
            onChange={onChangeHandler}
            placeholder={placeholder}
          />
        </div>
      </div>

      {error && <span className="errorMsg">{errorMsg}</span>}
    </div>
  )
}
TagInput.propTypes = {
  className: PropTypes.string,
  inputClass: PropTypes.string,
  onChange: PropTypes.func
}
export default TagInput
