import Selector from 'components/select'
import React, { useEffect, useState } from 'react'
// import grid from 'assets/icons/grid.svg'
// import slider from 'assets/icons/slider.svg'
// import gridActive from 'assets/icons/GridActiveBlue.svg'
// import sliderActive from 'assets/icons/sliderActiveBlue.svg'
import dataService from 'services/data.service'
import styles from './trending.module.scss'
// import Slider from 'components/slider'
import CardFlip from 'components/cardFlip'
import { subDays } from 'date-fns'
import Chart from 'components/chart'
import { toastService } from 'services/toastService'
// import editIcon from 'assets/icons/edit.svg'
// import deleteIcon from 'assets/icons/delete.svg'
import Pagination from 'components/pagination'
import { setPreview } from 'store/reducers/previewModal/previewModalSlice'
import { useDispatch } from 'react-redux'
import Heading, { TABS } from 'components/Heading'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'

const durationOptions = [
  { value: 7, label: 'Past 7 days' },
  { value: 14, label: 'Past 14 days' },
  { value: 21, label: 'Past 21 days' },
  { value: 'all', label: 'All time' }
]

const Trending = () => {
  const [trendingCards, setTrendingCards] = useState({ rows: [], total: 0 })
  const [topics, setTopics] = useState([])
  const [activeTab, setActiveTab] = useState('cards')
  const [searchValue, setSearchValue] = useState('')
  const [filterSearch, setFilterSearch] = useState('')
  // const [isGrid, setIsGrid] = useState(true)
  const [currentTopic, setCurrentTopic] = useState('')
  const [topicsTrending, setTopicsTrending] = useState([])
  const [currentDate, setCurrentDate] = useState('all')
  const [chartData, setChartData] = useState([])
  const [maxName, setMaxName] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const dispatch = useDispatch()

  useEffect(() => {
    if (activeTab === 'trending' && selectedOptions.length === 0) {
      toastService('info', 'Choose up to 3 topics at the same time.')
    }
  }, [activeTab])

  useEffect(() => {
    let curr
    let val
    let timestampValue
    if (currentDate !== '') {
      if (currentDate === 'all') {
        const sortedTopics = topics.sort((a, b) => a.createdAt - b.createdAt)
        const timestamp = (sortedTopics && sortedTopics[0] && sortedTopics[0].createdAt) || 1000 * 60 * 60 * 24 * 30
        const numOfDays = timestamp / (1000 * 60 * 60 * 24)
        curr = Math.ceil(numOfDays)
      } else {
        curr = currentDate
      }
      val = subDays(new Date(), curr).toISOString()
      timestampValue = new Date(val).getTime()
    }
    const fetchTrendingCards = async () => {
      const name = filterSearch
      let filter = {}
      if (name !== '') {
        filter = {
          ...filter,
          $or: [
            { heading: { $regex: name, $options: 'i' } },
            { subheading: { $regex: name, $options: 'i' } }
          ]
        }
      }
      if (currentTopic !== '' && currentTopic !== 'All Tags') {
        filter = { ...filter, tags: currentTopic }
      }
      if (currentDate !== '' && currentDate !== 'all') {
        filter = { ...filter, createdAt: { $gt: timestampValue } }
      }
      const res = await dataService.run('data', 'card', 'page', {
        filter: filter,
        sort: { views: -1 },
        pageNumber,
        pageSize: 20
      })
      setTrendingCards({ rows: res.rows, total: res.total })
    }
    const fetchTopics = async () => {
      const res = await dataService.run('data', 'topic', 'trending', {
        filter: { value: { $ne: null } },
        sort: { timesSearched: -1 }
      })
      // if (res?.length) {
      //   const uniqueTopics = res.reduce((unique, currentValue) => {
      //     unique[currentValue.value] = currentValue
      //     return unique
      //   }, {})
      //   const uniqueTopicsArray = Object.values(uniqueTopics)
      //   setTopics(uniqueTopicsArray)
      // }
      setTopics(res)
    }

    const fetchTopicsMap = async () => {
      const data = {
        timestampValue: timestampValue,
        value: curr,
        topicNames: topicsTrending
      }
      const topicsReport = await dataService.run('data', 'map', 'trending', data)
      if (topicsReport.reports) {
        const sorted = topicsReport.reports.sort((a, b) => a.timesSearched - b.timesSearched)
        setChartData(sorted)
      }
      if (topicsReport.maxName) {
        setMaxName(topicsReport.maxName)
      }
    }

    fetchTopics()
    fetchTrendingCards()
    fetchTopicsMap()
  }, [currentTopic, filterSearch, currentDate, topicsTrending, pageNumber])

  const handleChange = e => {
    setSearchValue(e.target.value)
  }

  const setValue = e => {
    if (e.key === 'Enter') {
      setFilterSearch(searchValue)
    }
  }
  // useEffect(() => {
  //   const doc = document.getElementById('cardsDiv')
  //   if (doc) {
  //     doc.scrollTo(0, 0)
  //   }
  // }, [isGrid])

  const selectCurrentTopics = e => {
    const retVal = []
    if (e.length > 3) {
      toastService('info', 'You can choose up to 3 topics at the same time.')
    } else {
      setSelectedOptions(e)
      if (e && e.length > 0) {
        e.map(topic => {
          retVal.push(topic.value)
        })
      }
      setTopicsTrending(retVal)
    }
  }

  const openPreviewModal = item => {
    dispatch(
      setPreview({
        item,
        flipped: true,
        flipWithAnimation: true
      })
    )
  }
  return (
    <div className={styles.trendingWrapper}>
      <Heading
        title={activeTab && activeTab === 'cards' ? 'Cards' : 'Topics'}
        tabs={TABS.TrendingTabs}
        onTabChange={setActiveTab}
        // rightMiddleElement={
        //   activeTab === 'cards' && (
        //     <div className={styles.icons}>
        //       <img src={isGrid ? slider : sliderActive} alt="slider" onClick={() => setIsGrid(false)} />
        //       <img src={isGrid ? gridActive : grid} alt="grid" onClick={() => setIsGrid(true)} />
        //     </div>
        //   )
        // }
      />
      <div className={styles.filter}>
        <div className={styles.third}>
          <input
            type="text"
            onChange={handleChange}
            onKeyUp={setValue}
            value={searchValue}
            placeholder="Enter card name"
            disabled={activeTab !== 'cards'}
          />
        </div>
        <div className={styles.third} style={{ width: 'fit-content', minWidth: '19rem' }}>
          {activeTab === 'cards' ? (
            <Selector
              selectClass={'selectGrayNoMargin'}
              placeholder={'Select Topic'}
              selectedOption={topics && topics.length > 0 && topics.find(option => option.value === currentTopic)}
              options={[{ value: 'All Tags' }, ...topics]}
              name={'topics'}
              handleChangeSelect={activeTab !== 'cards' ? selectCurrentTopics : e => setCurrentTopic(e.value)}
              customLabel={'value'}
            />
          ) : (
            <Selector
              selectClass={'selectGrayNoMarginFit'}
              placeholder={'Select Topic'}
              selectedOption={selectedOptions}
              options={topics}
              name={'topics'}
              handleChangeSelect={selectCurrentTopics}
              multiselect={true}
              customLabel={'value'}
            />
          )}
        </div>
        <div className={styles.third}>
          <Selector
            selectClass={'selectGrayNoMargin'}
            placeholder={'Date Range'}
            selectedOption={
              durationOptions &&
              durationOptions.length > 0 &&
              durationOptions.find(option => option.value === currentDate)
            }
            options={durationOptions}
            name={'duration'}
            handleChangeSelect={e => setCurrentDate(e.value)}
          />
        </div>
      </div>
      {activeTab === 'cards' ? (
        // <div className={isGrid ? styles.cardsDiv : styles.cardsDivSlider} id="cardsDiv">
        <div className={styles.cardsDiv} id="cardsDiv">
          <div className={styles.savedOrDeletedCards}>
            <div className={styles.cards}>
              {/* {isGrid ? (
                trendingCards &&
                trendingCards.rows &&
                trendingCards.rows.length > 0 &&
                trendingCards.rows.map((one, i) => {
                  return (
                    <CardFlip
                      key={i}
                      data={one}
                      showDetails={true}
                      overrideOnFlip={() => openPreviewModal(one)}
                      flippable={false}
                    />
                  )
                })
              ) : (
                <Slider
                  items={trendingCards?.rows}
                  firstCard={false}
                  moveByCardClick
                  openModalOnItemClick
                  showWSButton={false}
                  showDetails={true}
                />
              )} */}
              {trendingCards &&
                trendingCards.rows &&
                trendingCards.rows.length > 0 &&
                trendingCards.rows.map((one, i) => {
                  return (
                    <CardFlip
                      key={i}
                      data={one}
                      showDetails={true}
                      overrideOnFlip={() => openPreviewModal(one)}
                      flippable={false}
                    />
                  )
                })}
              {trendingCards && trendingCards.rows && trendingCards.rows.length === 0 && (
                <Typography
                  variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
                  text="There are no cards for chosen filters!"
                  className="marginY10"
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.topicsTrending}>
          <div className={styles.chart}>
            <Chart
              data={chartData}
              dataKey={topicsTrending && topicsTrending[0]}
              dataKey2={topicsTrending && topicsTrending[1]}
              dataKey3={topicsTrending && topicsTrending[2]}
              isMulti={true}
              isThird={true}
              widthSize={'large'}
              y={maxName}
              heightPx={600}
              titleName="Topics"
              legendHeader={true}
            />
          </div>
          <div className={styles.tableTopic}>
            <h1>The top three most searched topics:</h1>
            <div className={styles.tableHeader}>
              <span>Rank</span>
              <span>Name</span>
              <span>Search Points</span>
            </div>
            <div className={styles.singleTableRow}>
              <div className={styles.dataRow}>
                <span>#1</span>
                <span>{topics && topics[0] && topics[0].value}</span>
                <span>{topics && topics[0] && topics[0].timesSearched}</span>
              </div>
              <div className={styles.icons}>
                {/* <img src={editIcon} alt="edit" />
                <img src={deleteIcon} alt="delete" /> */}
              </div>
            </div>
            <div className={styles.singleTableRow}>
              <div className={styles.dataRow}>
                <span>#2</span>
                <span>{topics && topics[1] && topics[1].value}</span>
                <span>{topics && topics[1] && topics[1].timesSearched}</span>
              </div>
              <div className={styles.icons}>
                {/* <img src={editIcon} alt="edit" />
                <img src={deleteIcon} alt="delete" /> */}
              </div>
            </div>
            <div className={styles.singleTableRow}>
              <div className={styles.dataRow}>
                <span>#3</span>
                <span>{topics && topics[2] && topics[2].value}</span>
                <span>{topics && topics[2] && topics[2].timesSearched}</span>
              </div>
              <div className={styles.icons}>
                {/* <img src={editIcon} alt="edit" />
                <img src={deleteIcon} alt="delete" /> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === 'cards' && trendingCards && trendingCards.total > 20 && (
        <Pagination total={trendingCards.total} onPageChange={setPageNumber} pageSize={20} />
      )}
    </div>
  )
}

export default Trending
