import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View, Link } from '@react-pdf/renderer'
import logoImg from 'assets/logo/wonderShufflePng.png'
import moment from 'moment'
import Roboto from '../../assets/fonts/roboto/Roboto-Bold.ttf'

export const InvoiceDoc = ({ invoiceData = {}, amount = 0 }) => {
  const { createdAt = 0 } = invoiceData

  // this was function for due date calculation

  // const handleDate = () => {
  //   if (invoiceData && invoiceData.paymentData && invoiceData.paymentData.object) {
  //     const startSubscription =
  //       invoiceData.paymentData.object === 'subscription'
  //         ? invoiceData.paymentData.current_period_start
  //         : invoiceData.paymentData.period_start
  //     const endSubscription =
  //       invoiceData.paymentData.object === 'subscription'
  //         ? invoiceData.paymentData.current_period_end
  //         : invoiceData.paymentData.period_end
  //     const currentPeriodRange = `${moment(startSubscription * 1000).format('MMM DD')} - ${moment(
  //       endSubscription * 1000
  //     ).format('MMM DD YYYY')}`

  //     return currentPeriodRange
  //   }
  // }

  // Font styles
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: Roboto
      }
    ]
  })

  // Create styles
  const styles = StyleSheet.create({
    section: {
      flexGrow: 1,
      display: 'flex'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'space-between',
      width: '100%',
      paddingLeft: '10',
      paddingRight: '40'
    },
    sectionFirstLeft: {
      marginTop: '40',
      backgroundColor: '#e8e8e8',
      flexDirection: 'column',
      display: 'flex',
      width: '100%',
      height: '350',
      position: 'relative'
    },
    sectionFirstRight: {
      marginTop: '40',
      backgroundColor: '#e8e8e8',
      display: 'flex-end',
      width: '15%',
      height: '350',
      position: 'relative',
      marginLeft: '10'
    },
    sectionSecondtLeft: {
      marginTop: '10',
      backgroundColor: '#cecccc',
      flexDirection: 'column',
      display: 'flex',
      width: '100%',
      height: '50',
      position: 'relative'
    },
    sectionSecondRight: {
      marginTop: '10',
      backgroundColor: '#cecccc',
      display: 'flex-end',
      width: '15%',
      height: '50',
      position: 'relative',
      marginLeft: '10'
    },
    page: {
      backgroundColor: '#fff',
      flexDirection: 'column',
      display: 'flex',
      width: '100%',
      padding: '50',
      border: '1px solid black',
      position: 'relative'
    },
    img: {
      display: 'flex',
      marginTop: '8',
      marginBottom: '40'
    },
    text15: {
      fontSize: '15',
      fontFamily: 'Roboto'
    },
    textWidth15: {
      color: '#110f0f',
      fontSize: '15',
      fontFamily: 'Roboto',
      width: '100%'
    },
    footer: {
      textAlign: 'center',
      borderTop: '1px solid #2b5b6c',
      fontSize: '10',
      fontFamily: 'Roboto',
      position: 'absolute',
      bottom: '20',
      left: '50',
      width: '100%',
      paddingTop: '10'
    },
    loadingContainer: {
      position: 'absolute',
      top: '0',
      right: '0',
      left: '0',
      bottom: '0',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '1.875rem'
    }
  })

  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.img} fixed>
            <Image style={{ width: '45%', height: '40' }} source={logoImg} alt="img" />
          </View>
          <View style={styles.section}>
            <View style={styles.header}>
              <Text
                style={{
                  color: '#110f0f',
                  fontSize: '15',
                  fontFamily: 'Roboto',
                  width: '100%'
                }}
              >
                Receipt for:
              </Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.textWidth15}>{invoiceData?.user?.businessName}</Text>
              <Text style={{ fontSize: '15', fontFamily: 'Roboto', marginRight: '22' }}>
                {' '}
                Receipt Date:{createdAt !== 0 ? moment(createdAt).format('DD/MM/YYYY') : '/'}
              </Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.textWidth15}>{invoiceData?.user?.phoneNumber}</Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.textWidth15}>{invoiceData?.user?.email}</Text>
            </View>
            <View style={{ displey: 'flex', width: '100%', flexDirection: 'row' }}>
              <View style={styles.sectionFirstLeft}>
                <View
                  style={{
                    displey: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    justifyContent: 'flex-start',
                    padding: '15'
                  }}
                >
                  <Text style={{ fontSize: '15', fontFamily: 'Roboto' }}>
                    {invoiceData && invoiceData.transactionType}
                  </Text>
                  {/* <Text style={styles.text15}>{handleDate()}</Text> */}
                </View>
              </View>
              <View style={styles.sectionFirstRight}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    paddingTop: '15'
                  }}
                >
                  <Text style={styles.text15}>${amount}</Text>
                </View>
              </View>
            </View>
            <View style={{ displey: 'flex', width: '100%', flexDirection: 'row' }}>
              <View style={styles.sectionSecondtLeft}>
                <View style={{ padding: '15' }}>
                  <Text style={styles.text15}>TOTAL</Text>
                </View>
              </View>
              <View style={styles.sectionSecondRight}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'center',
                    paddingTop: '15'
                  }}
                >
                  <Text style={styles.text15}>${amount}</Text>
                </View>
              </View>
            </View>
            <View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  marginTop: '30'
                }}
              >
                <Text
                  style={{
                    fontSize: '10',
                    fontFamily: 'Roboto'
                  }}
                >
                  Have a question?
                  <Link style={{ color: '#cc2b71', textDecoration: 'none' }} src="mailto:support@wondershuffle.com">
                    <Text style={{ color: '#cc2b71', textDecoration: 'none' }}> support@wondershuffle.com</Text>
                  </Link>
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.footer} render={({ pageNumber }) => `${pageNumber}`} fixed />
      </Page>
    </Document>
  )

  return (
    <PDFViewer
      style={{
        width: '100%',
        height: '80vh'
      }}
    >
      <MyDocument />
    </PDFViewer>
  )
}

export default InvoiceDoc
