// import Search from 'components/search'
import chatImg from 'assets/icons/iconComment.svg'
import defaultAvatar from 'assets/images/icons/defaultUser.png'
import AccountModal from 'components/acountModal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { logOut, setUser, storeUserLocalStorage } from 'store/reducers/auth/authSlice'
import { openModal as openChatModal } from 'store/reducers/chatModal/chatModalSlice'
import { getAvatarImage } from 'utils/getAvatarImage'
import { updateArray } from 'utils/updatePagedArray'
import styles from './navbar.module.scss'
import { localStorageService } from 'services/localStorage.service'

const Navbar = () => {
  const currentUrl = window.location.href
  const url = new URL(currentUrl)
  const userId = url.searchParams.get('userId')

  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth?.user)
  const [notificationMessages, setNotificationMessages] = useState(false)
  const [showMessageNotification, setShowMessageNotification] = useState(false)
  const [recentsEvents, setRecentsEvents] = useState(false)
  const [showLogout, setShowLogOout] = useState(false)
  const events = useSelector(state => state?.event?.data)
  const handleLogOut = () => {
    dispatch(logOut())
    history.push('/')
  }

  const onImageNonExist = err => {
    err.target.onError = null
    err.target.src = defaultAvatar
  }

  const openChat = chat => {
    dispatch(openChatModal({ defaultChat: chat ?? null }))
  }

  useEffect(() => {
    const getInitData = async () => {
      const resMessages = await dataService.run('chats', 'recents', 'all', {
        filter: { toId: user?._id },
        sort: { createdAt: -1 }
      })

      setNotificationMessages(resMessages ?? [])
    }
    if (user?._id) {
      getInitData()
    }
  }, [user?._id])

  useEffect(() => {
    if (notificationMessages?.length) {
      let allRead = true
      notificationMessages.forEach(e => {
        if (!e?.read) {
          allRead = false
        }
      })
      if (showMessageNotification !== !allRead) {
        setShowMessageNotification(!allRead)
      }
    }
  }, [notificationMessages])

  useEffect(() => {
    const handleRecentsEvents = () => {
      const alreadyInNotifications = notificationMessages.findIndex(e => e._id === events?._id)
      if (alreadyInNotifications >= 0) {
        updateArray(setNotificationMessages, notificationMessages, events, false, 'start')
      } else {
        setNotificationMessages([events, ...notificationMessages])
      }

      if (events?.to === user?.username && !events?.read) {
        toastService('info', <div onClick={() => openChat(events)}>{`${events.from}: ${events.data}`}</div>, {
          autoClose: 3000,
          position: 'bottom-right',
          closeButton: true
        })
      }
    }

    if (events?.type === 'recents' && events?.to === user?.username) {
      if (recentsEvents?.data !== events?.data || recentsEvents?.from !== events?.from) {
        handleRecentsEvents()
      }
      setRecentsEvents(events)
    }
  }, [events])

  useEffect(() => {
    document.body.addEventListener('click', e => {
      if (e?.target?.alt !== 'User') {
        setShowLogOout(false)
      }
    })
  }, [])

  const switchUser = async userId => {
    const switchData = await dataService.run('profile', 'user', 'switchAccount', { userId })
    const user = switchData.user ? switchData.user : {}
    const token = switchData.token ? switchData.token : ''
    const refreshToken = switchData.refreshToken ? switchData.refreshToken : ''
    const rememberMe = localStorageService.get('REMEMBER_ME')
    if (Object.keys(user).length > 0 && token !== '' && refreshToken !== '') {
      if (user && user.role && user.role !== 'user') {
        storeUserLocalStorage(user, token, refreshToken, rememberMe)
        dispatch(setUser(user))
      } else {
        toastService('error', 'Failed switching account. User is not premium.')
      }
    }
  }

  useEffect(() => {
    if (userId) {
      switchUser(userId)
    }
  }, [userId])

  return (
    <nav>
      <div className={styles.rightSide}>
        <div className={styles.chat}>
          <img src={chatImg} alt="chat" onClick={() => openChat()} id="openAccount" />
          {showMessageNotification && <div className={styles.point}></div>}
        </div>
        <div className={showLogout ? styles.avatarAndLogoutOpen : styles.avatarAndLogout}>
          <img
            src={getAvatarImage(user?._id, user.avatarUpdatedAt)}
            alt="User"
            onClick={() => setShowLogOout(!showLogout)}
            onError={onImageNonExist}
            className={styles.avatar}
          />

          <div className={styles.bottom}> </div>
          {showLogout && (
            <div className={styles.wrapperModal}>
              <AccountModal handleLogOut={handleLogOut} onAvatarDontExist={onImageNonExist} />
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
