import Button from 'components/button'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toastService } from 'services/toastService'
import {
  sendConfirmDeactivationOrRestore,
  sendConfirmPassLink,
  sendConfirmPersonalDetailsLink,
  sendForgotPasswordLink
} from 'store/reducers/auth/thunk'
import { closeModal } from 'store/reducers/modal/modalSlice'
import styles from './newRequest.module.scss'
import { localStorageService } from 'services/localStorage.service'

export const NewRequest = ({
  type = '',
  className,
  onClickHandler,
  data = '',
  expiredToken = false,
  currentUser = false,
  username = ''
}) => {
  const user = localStorageService.get(`user_${username}`)
  const dispatch = useDispatch()
  const history = useHistory()

  const sendNewLink = type => {
    switch (type) {
      case 'Change Personal Details':
        dispatch(sendConfirmPersonalDetailsLink({ premium: true }))
        break
      case 'Change Password':
        dispatch(sendConfirmPassLink({ premium: true }))
        break
      case 'Deactivate Account':
        dispatch(sendConfirmDeactivationOrRestore({ activeAccount: false, premium: true }))
        break
      case 'Restore Account':
        dispatch(sendConfirmDeactivationOrRestore({ activeAccount: true, premium: true }))
        break
      case 'Forgot Password':
        if (data && data.email) {
          const email = data.email
          dispatch(sendForgotPasswordLink({ email }))
        }

        break
      default:
        break
    }
    if (user) {
      history.push('/home')
    } else {
      history.push('/register')
    }
    dispatch(closeModal())
    toastService('success', 'Successfully sent requested link.')
  }

  const onCloseHandle = () => {
    dispatch(closeModal())
  }

  const handleTypeRequest = () => {
    switch (type) {
      case 'Change Personal Details':
        return (
          <>
            <div className={styles.wrapper}>
              <h2>Change personal details</h2>
              {expiredToken && !currentUser && <div>The email token expired, please request a new one.</div>}
              {!expiredToken && currentUser && <div>The request sent is not for a logged user</div>}
              {!expiredToken && !currentUser && <div>Failed to change personal details.</div>}
              <div className={styles.link} onClick={() => sendNewLink('Change Personal Details')}>
                Request a new link for personal details
              </div>
              <div>
                <Button label="Ok" variation="secondary" size="sm" onClick={onCloseHandle} />
              </div>
            </div>
          </>
        )
      case 'Change Password':
        return (
          <>
            <div className={styles.wrapper}>
              <h2>Change Password</h2>
              {expiredToken && !currentUser && <div>The email token expired, please request a new one.</div>}
              {!expiredToken && currentUser && <div>The request sent is not for a logged user</div>}
              {!expiredToken && !currentUser && <div>Failed to change password.</div>}
              <div className={styles.link} onClick={() => sendNewLink('Change Password')}>
                Request a new link for change password
              </div>
              <div>
                <Button label="Ok" variation="secondary" size="sm" onClick={onCloseHandle} />
              </div>
            </div>
          </>
        )

      case 'Deactivate Account':
        return (
          <>
            <div className={styles.wrapper}>
              <h2>Deactivate Account</h2>
              {expiredToken && !currentUser && <div>The email token expired, please request a new one.</div>}
              {!expiredToken && currentUser && <div>The request sent is not for a logged user</div>}
              {!expiredToken && !currentUser && <div>Failed to deactivate account.</div>}
              <div className={styles.link} onClick={() => sendNewLink('Deactivate Account')}>
                Request a new deactivation link
              </div>
              <div>
                <Button label="Ok" variation="secondary" size="sm" onClick={onCloseHandle} />
              </div>
            </div>
          </>
        )
      case 'Restore Account':
        return (
          <>
            <div className={styles.wrapper}>
              <h2>Restore Account</h2>
              {expiredToken && !currentUser && <div>The email token expired, please request a new one.</div>}
              {!expiredToken && currentUser && <div>The request sent is not for a logged user</div>}
              {!expiredToken && !currentUser && <div>Failed to restore account.</div>}
              <div className={styles.link} onClick={() => sendNewLink('Restore Account')}>
                Request a new restore link
              </div>
              <div>
                <Button label="Ok" variation="secondary" size="sm" onClick={onCloseHandle} />
              </div>
            </div>
          </>
        )
      case 'Forgot Password':
        return (
          <>
            <div className={styles.wrapper}>
              <h2>Forgot Password</h2>
              {currentUser && <div>The request sent is not for a logged user</div>}
              {!currentUser && <div>Failed to restore password.</div>}
              <div className={styles.link} onClick={() => sendNewLink('Forgot Password')}>
                Request a new restore link
              </div>
              <div>
                <Button label="Ok" variation="secondary" size="sm" onClick={onCloseHandle} />
              </div>
            </div>
          </>
        )

      default:
        break
    }
  }

  return (
    <div className={className} onClick={onClickHandler}>
      {handleTypeRequest()}
    </div>
  )
}

export default NewRequest
