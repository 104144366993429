import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardHeader, { HeaderPosition, HeaderSize } from 'components/card/components/header'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import styles from './cardSlideBackEditable.module.scss'
import WonderLogo from 'assets/logo/iconShortLogoWhite.svg'
import CardSliderComponent from 'components/card/components/slider'
import CardWrapper from 'components/card/components/wrapper'
import { setSelectedCard } from 'store/reducers/card/cardSlice'

const CardSlideBackEditable = ({ className }) => {
  const dispatch = useDispatch()
  const card = useSelector(state => state.card.selectedCard)

  const onImageChange = (image, activeIndex) => {
    const tempSlider = [...card.slider]
    tempSlider[activeIndex] = { ...tempSlider[activeIndex], image }
    dispatch(setSelectedCard({ ...card, slider: [...tempSlider] }))
  }

  const onDelete = activeSlideIndex => {
    const tempSlider = [...card.slider]
    tempSlider.splice(activeSlideIndex, 1)
    dispatch(setSelectedCard({ ...card, activeSlide: card?.activeSlide - 1, slider: [...tempSlider] }))
  }

  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card type={CARD_TYPES.Standard} size={CARD_SIZE.Large} userType={card?.userRole}>
        <CardBody type={CARD_TYPES.Standard} size={CARD_SIZE.Large} userType={card?.userRole}>
          <CardHeader
            type={CARD_TYPES.Standard}
            size={HeaderSize.LargeWithLogo}
            position={HeaderPosition.Floating}
            heading="Wonder Shuffle"
            subheading="Do The Shuffle!"
            logo={WonderLogo}
            userType={card?.userRole}
          />
          <CardSliderComponent
            slides={card.slider}
            caption={card.slider[card.activeSlide !== '' ? card.activeSlide : 0]?.caption}
            onImageChange={onImageChange}
            onDelete={onDelete}
            editable
          />
        </CardBody>
      </Card>
    </CardWrapper>
  )
}
export default CardSlideBackEditable
