import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { googleApiKey } from 'environment'
import { isObjectEmpty } from 'utils/helpers'
import iconMarker from 'assets/icons/markerSmall.png'
import AutoComplete from './autocomplete'
import Button from 'components/button'
import styles from './googleMapSerach.module.scss'

const Marker = ({ text, onClick }) => (
  <div alt={text} onClick={onClick} className={styles.marker}>
    <img src={iconMarker} alt="marker" />
  </div>
)
const GoogleMapSearch = ({ mapClass, onAdd }) => {
  const [data, setData] = useState({
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [],
    zoom: 9,
    address: '',
    draggable: true,
    lat: null,
    lng: null
  })

  useEffect(() => {
    const setCurrentLocation = () => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords
            setData({
              ...data,
              center: [latitude, longitude],
              lat: latitude,
              lng: longitude
            })
          },
          () => {
            setData({
              ...data,
              center: [40.73061, -73.935242],
              lat: 40.73061,
              lng: -73.935242
            })
          }
        )
      }
    }
    setCurrentLocation()
  }, [])

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setData({
      ...data,
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
      center: [mouse.lat, mouse.lng]
    })
  }

  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setData({
      ...data,
      draggable: true,
      lat: mouse.lat,
      lng: mouse.lng,
      center: [mouse.lat, mouse.lng]
      // address: childProps.text
    })
  }

  const onClick = value => {
    setData({
      ...data,
      lat: value.lat,
      lng: value.lng
      // zoom: 16
    })
  }

  const apiHasLoaded = (map, maps) => {
    const temp = {
      ...data,
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps
    }
    _generateAddress(temp)
    setData(temp)
  }

  const addPlace = place => {
    const temp = {
      ...data,
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      address: place.formatted_address,
      timestamp: new Date().getTime()
    }
    setData(temp)
    _generateAddress(temp)
  }

  useEffect(() => {
    return () => {
      const el = document.querySelector('.pac-container')
      if (el) el.remove()
    }
  }, [])

  useEffect(() => {
    if (!isObjectEmpty(data.mapApi) && data.lat && data.lng && data.draggable) _generateAddress(data)
  }, [data.lat, data.lng, data.places, data.draggable])

  const areArraysEqual = (a, b) => {
    if (a === b) return true
    if (a === null || b === null) return false
    if (a.length !== b.length) return false

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }
    return true
  }

  const _generateAddress = temp => {
    const originLocationTypes =
      temp.places &&
      temp.places &&
      temp.places[0] &&
      temp.places[0].address_components &&
      temp.places[0].address_components[0] &&
      temp.places[0].address_components[0].types
        ? temp.places[0].address_components[0].types
        : []

    const geocoder = new temp.mapApi.Geocoder()
    geocoder.geocode({ location: { lat: temp.lat, lng: temp.lng } }, (results, status) => {
      if (status === 'OK') {
        let finalLocation = results.filter(el => areArraysEqual(el.types, originLocationTypes))
        if (finalLocation.length === 0) finalLocation = results
        if (finalLocation[0]) {
          setData({ ...temp, address: finalLocation[0].formatted_address, zoom: 14 })
        } else {
          window.alert('No results found')
        }
      } else {
        window.alert('Geocoder failed due to: ' + status)
      }
    })
  }

  const addLocation = () => {
    onAdd({
      lng: data.lng,
      lat: data.lat,
      address: data.address,
      coordinates: [data.lng, data.lat],
      timestamp: new Date().getTime()
    })
  }

  return (
    <div className={styles.wrapper}>
      {data.mapInstance !== null && !isObjectEmpty(data.mapApi) && data.mapApiLoaded && (
        <div className={styles.autocomplete}>
          <AutoComplete map={data.mapInstance} mapApi={data.mapApi} addplace={addPlace} mapClass={mapClass} />
        </div>
      )}
      <div className={styles.map}>
        <GoogleMapReact
          center={data.center}
          zoom={data.zoom}
          draggable={data.draggable}
          onChildMouseDown={onMarkerInteraction}
          onChildMouseUp={onMarkerInteractionMouseUp}
          onChildMouseMove={onMarkerInteraction}
          onClick={onClick}
          bootstrapURLKeys={{
            key: googleApiKey,
            libraries: ['places', 'geometry'],
            mapTypeId: 'roadmap',
            zoomControl: true,
            scrollwheel: false,
            disableDoubleClickZoom: true,
            maxZoom: 25,
            minZoom: 9
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
          <Marker text={data.address} lat={data.lat} lng={data.lng} />
        </GoogleMapReact>
      </div>
      <div className={styles.address}>
        <span>{data.address}</span>
        <Button label="Save" onClick={addLocation} variation="primary" size="xs" />
      </div>
    </div>
  )
}

export default GoogleMapSearch
