import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardWrapper from 'components/card/components/wrapper'
import RibbonComponent from 'components/icons/ribbon'
import MediaPicker from 'components/mediaPicker'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import { setSelectedCard } from 'store/reducers/card/cardSlice'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import { getMediaWithFallbackFromFileOrString } from 'utils/getMediaWithFallbackFromFileOrString'
import useWindowDimensions from 'utils/screenSize'
import styles from './anchorFront.module.scss'

const CardAnchorFront = ({ className, size = CARD_SIZE.Normal, card, editable = false }) => {
  const dispatch = useDispatch()
  const onImageChange = image => {
    dispatch(setSelectedCard({ ...card, frontImage: image }))
  }
  const { isMobile } = useWindowDimensions()
  const { grid } = useSelector(state => state)

  return (
    <CardWrapper>
      <Card
        className={`${styles.wrapper} ${styles[className]} ${className}`}
        size={size}
        type={CARD_TYPES.Anchor}
        additionalBorder
      >
        <CardBody
          size={size}
          className={styles.cardBody}
          style={{
            backgroundImage: getMediaWithFallbackFromFileOrString(card.frontImage)
          }}
          type={CARD_TYPES.Anchor}
          isMature={card?.mature && !editable}
        >
          {editable && (
            <MediaPicker
              className="cardCover"
              onImageChange={onImageChange}
              stateMedia={getMediaFromFileOrString(card.frontImage)}
              showCrop={!isMobile}
            />
          )}
          <div
            className={`${editable ? styles.ribbonContent : styles.prev}`}
            onClick={() => window.open(card.pageLink, '_self', 'noopener noreferrer')}
          >
            {size === 'extraSmall' && grid?.isActivated && (
              <RibbonComponent color={card.ribbonColor} className={styles.smallRibbon}></RibbonComponent>
            )}
            {size !== 'extraSmall' && (
              <RibbonComponent
                color={card.ribbonColor}
                className={`${editable ? styles.ribbon : styles.ribbonPrev}`}
              ></RibbonComponent>
            )}
            {size === 'extraSmall' && grid?.isActivated && (
              <span className={`${styles.anchorSpanSmall} `}>{card.heading !== '' ? card.heading : 'Heading'}</span>
            )}
            {size !== 'extraSmall' && (
              <span className={`${styles.anchorSpan} `}>{card.heading !== '' ? card.heading : 'Heading'}</span>
            )}
          </div>
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CardAnchorFront
