import React, { useState } from 'react'
import eye from '../../assets/icons/passEye.svg'
import styles from './input.module.scss'
import eyeHidden from '../../assets/icons/eyeHidden.png'

const Input = ({
  containerClass,
  inputClass = 'xsInput',
  variation = '',
  type,
  name,
  value,
  placeholder,
  placeholderOrentation = 'placeholderLeft',
  onChange,
  autofocus = false,
  disabled = false,
  label,
  onKeyPress,
  maxLength,
  error,
  title,
  pattern,
  errorMsg,
  style,
  containerStyle,
  className,
  iconLeft,
  iconRight,
  iconLeftOnClick,
  iconRightOnClick,
  errorMsgStyle = 'errorMsg',
  hasEye = false,
  onEyeClick = null,
  showEye = false,
  autoComplete = 'off'
}) => {
  const [showPassword, setShowPassword] = useState(showEye)
  return (
    <div className={`${styles[inputClass]} ${containerClass} ${styles[containerClass]}`}>
      {label && (
        <label htmlFor={name}>
          {label} {error && <span className={styles.error}>*</span>}
        </label>
      )}
      <div className={styles.inputIcons} style={containerStyle}>
        {iconLeft && (
          <div className={`${styles.iconLeft} ${iconLeftOnClick ? styles.iconHover : ''}`} onClick={iconLeftOnClick}>
            <img src={iconLeft} alt={name} />
          </div>
        )}
        {hasEye ? (
          <div className={styles.inputDiv}>
            <input
              className={`${styles.input} ${styles[placeholderOrentation]} ${styles[variation]} ${className} ${styles[className]} `}
              style={style}
              id={name}
              type={type}
              name={name}
              onKeyPress={onKeyPress}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              autoFocus={autofocus}
              disabled={disabled}
              autoComplete={autoComplete}
              maxLength={maxLength}
              pattern={pattern}
              title={title}
            />
            {showPassword ? (
              <img
                className={styles.hiddenEye}
                onClick={() => {
                  onEyeClick && onEyeClick()
                  setShowPassword(prevState => !prevState)
                }}
                src={eyeHidden}
                alt=""
              />
            ) : (
              <img
                src={eye}
                onClick={() => {
                  onEyeClick && onEyeClick()
                  setShowPassword(prevState => !prevState)
                }}
                alt="eye"
              />
            )}
          </div>
        ) : (
          <input
            className={`${styles.input} ${styles[placeholderOrentation]} ${styles[variation]} ${className} ${styles[className]} `}
            style={style}
            id={name}
            type={type}
            name={name}
            onKeyPress={onKeyPress}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            autoFocus={autofocus}
            disabled={disabled}
            autoComplete={autoComplete}
            maxLength={maxLength}
            pattern={pattern}
            title={title}
          />
        )}
        {iconRight && (
          <div className={`${styles.iconRight} ${iconRightOnClick ? styles.iconHover : ''}`} onClick={iconRightOnClick}>
            <img src={iconRight} alt={name} />
          </div>
        )}
      </div>
      {error && errorMsg && <span className={styles[errorMsgStyle]}>{errorMsg}</span>}
    </div>
  )
}

export default Input
