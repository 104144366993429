import UserAvatar from 'components/userAvatar'
import React from 'react'
import styles from './avatarWithInfo.module.scss'

const AvatarWithInfo = ({
  className,
  titleClass,
  subtitleClass,
  infoClass,
  title,
  subtitle,
  info,
  avatar,
  boldText = false,
  onClick,
  active = false,
  inModalAccount = false,
  widthHeight = 3.5
}) => {
  const onClickHandler = e => {
    onClick && onClick(e)
  }
  return (
    <div className={`${active ? styles.activeWrapper : styles.wrapper} ${className}`} onClick={onClickHandler}>
      <UserAvatar width={widthHeight} height={widthHeight} imageUrl={avatar} />
      <div className={styles.rightContainer} style={{ marginLeft: inModalAccount && '0.5rem' }}>
        <h6
          className={`${boldText ? styles.boldTitle : styles.title} ${titleClass}`}
          style={{
            margin: inModalAccount && 0,
            fontSize: inModalAccount && '1rem!important',
            textAlign: inModalAccount && 'left'
          }}
        >
          {title}
        </h6>
        <p
          className={`${boldText ? styles.boldSubtitle : styles.subtitle} ${subtitleClass}`}
          style={{ textAlign: inModalAccount && 'left' }}
        >
          {subtitle}
        </p>
        <p className={`${styles.info} ${infoClass}`}>{info}</p>
      </div>
    </div>
  )
}

export default AvatarWithInfo
