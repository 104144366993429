import React, { useEffect, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import logo from 'assets/images/logo/logo.svg'
import bottomPic from 'assets/images/Promotion.svg'
import styles from './sidebar.module.scss'
import { useSelector } from 'react-redux'
import { ADMIN_FEATURES_ROUTE_LABELS, ADMIN_FEATURES_ROUTE_VALUES, USER_TYPE_VALUE } from 'staticConstants'

const SideBar = () => {
  const history = useHistory()
  const location = useLocation()
  const user = useSelector(state => state.auth.user)
  const [home, setHome] = useState(false)
  const [createCampaign, setCreateCamapin] = useState(false)
  const [manageCampaign, setManageCamapin] = useState(false)
  const [savedCards, setSavedCards] = useState(false)
  const [contact, setContact] = useState(false)
  const [reports, setReports] = useState(false)
  const [admin, setAdmin] = useState(false)
  const [settings, setSettings] = useState(false)

  const closeAll = () => {
    setHome(false)
    setCreateCamapin(false)
    setManageCamapin(false)
    setSavedCards(false)
    setContact(false)
    setSettings(false)
    setReports(false)
    setAdmin(false)
  }

  const onReportsClick = () => {
    closeAll()
    setReports(true)
  }

  const onAdminClick = () => {
    closeAll()
    setAdmin(true)
  }

  const onSettingsClick = () => {
    closeAll()
    setSettings(true)
  }

  const onLogoClick = () => {
    history.push('/home')
  }

  const isAdminRouteAvailable = routeValue => {
    if (user?.subType === USER_TYPE_VALUE.editor && !user?.adminAccess?.length) return false
    if (user?.role === 'admin') {
      return true
    } else if (user?.subType === USER_TYPE_VALUE.editor) {
      return user?.adminAccess?.length && user.adminAccess.includes(Number(routeValue))
    }
    return false
  }

  useEffect(() => {
    const pathname = location.pathname
    closeAll()
    if (pathname.includes('/reports')) {
      setReports(true)
    } else if (pathname.includes('/admin')) {
      setAdmin(true)
    } else if (pathname.includes('/settings')) {
      setSettings(true)
    } else if (pathname.includes('/contact')) {
      setContact(true)
    } else if (pathname.includes('/cards')) {
      setSavedCards(true)
    } else if (pathname.includes('/campaign/manage')) {
      setManageCamapin(true)
    } else if (pathname.includes('/campaign') || pathname.includes('/instacard')) {
      setCreateCamapin(true)
    } else {
      setHome(true)
    }
  }, [location])

  const showAdminRoutes =
    user.role === 'admin' || (user?.subType === USER_TYPE_VALUE.editor && user?.adminAccess?.length > 0)

  return (
    <div className={styles.wrapper}>
      <img src={logo} alt="User" className={styles.wonderLogo} onClick={onLogoClick} />
      <ul>
        <NavLink to="/home" className={home ? styles.active : styles.inactive}>
          Home
        </NavLink>
        <li onClick={() => onReportsClick()} className={reports ? styles.active : styles.inactive}>
          <span>Reports</span>
        </li>
        <div className={reports ? styles.reportsOpened : styles.reportsClosed}>
          <NavLink activeClassName={styles.activeNav} to="/reports/likes">
            Likes
          </NavLink>
          <NavLink activeClassName={styles.activeNav} to="/reports/views">
            Views
          </NavLink>
          <NavLink activeClassName={styles.activeNav} to="/reports/reach">
            Reach
          </NavLink>
          <NavLink activeClassName={styles.activeNav} to="/reports/shares">
            Shares
          </NavLink>
        </div>
        <NavLink to="/campaign" className={createCampaign ? styles.active : styles.inactive}>
          Create Campaign
        </NavLink>
        <NavLink to="/campaign/manage" className={manageCampaign ? styles.active : styles.inactive}>
          Manage Campaigns
        </NavLink>
        <NavLink to="/cards" className={savedCards ? styles.active : styles.inactive}>
          Saved Cards
        </NavLink>
        {user?._id && showAdminRoutes && (
          <>
            <li onClick={() => onAdminClick()} className={admin ? styles.active : styles.inactive}>
              <span>Admin</span>
            </li>
            <div className={admin ? styles.reportsOpened : styles.reportsClosed}>
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.applicants) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/applicants">
                  {ADMIN_FEATURES_ROUTE_LABELS.applicants}
                </NavLink>
              )}
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.contentModeration) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/moderation">
                  {ADMIN_FEATURES_ROUTE_LABELS.contentModeration}
                </NavLink>
              )}
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.users) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/users">
                  {ADMIN_FEATURES_ROUTE_LABELS.users}
                </NavLink>
              )}
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.trending) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/trending">
                  {ADMIN_FEATURES_ROUTE_LABELS.trending}
                </NavLink>
              )}
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.emptySearches) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/empty-searches">
                  {ADMIN_FEATURES_ROUTE_LABELS.emptySearches}
                </NavLink>
              )}
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.contactUs) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/contact-us">
                  {ADMIN_FEATURES_ROUTE_LABELS.contactUs}
                </NavLink>
              )}
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.mysteryCardImage) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/mystery">
                  {ADMIN_FEATURES_ROUTE_LABELS.mysteryCardImage}
                </NavLink>
              )}
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.manageCategory) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/categories">
                  {ADMIN_FEATURES_ROUTE_LABELS.manageCategory}
                </NavLink>
              )}
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.anchorCards) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/anchor">
                  {ADMIN_FEATURES_ROUTE_LABELS.anchorCards}
                </NavLink>
              )}
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.welcomePack) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/welcome-pack">
                  {ADMIN_FEATURES_ROUTE_LABELS.welcomePack}
                </NavLink>
              )}
              {isAdminRouteAvailable(ADMIN_FEATURES_ROUTE_VALUES.newsletter) && (
                <NavLink activeClassName={styles.activeNav} to="/admin/newsletter">
                  {ADMIN_FEATURES_ROUTE_LABELS.newsletter}
                </NavLink>
              )}
            </div>
          </>
        )}
        <li onClick={() => onSettingsClick()} className={settings ? styles.active : styles.inactive}>
          <span>Settings</span>
        </li>
        <div className={settings ? styles.reportsOpened : styles.reportsClosed}>
          <NavLink activeClassName={styles.activeNav} to="/settings/general">
            General
          </NavLink>
          {user.role && user.role !== 'admin' && user?.subType !== USER_TYPE_VALUE.editor}
          {user.role && user.role !== 'admin' && user?.subType !== USER_TYPE_VALUE.editor && (
            <NavLink activeClassName={styles.activeNav} to="/settings/payments">
              Payments
            </NavLink>
          )}
          {user.role && user.role !== 'admin' && user?.subType !== USER_TYPE_VALUE.editor && (
            <NavLink activeClassName={styles.activeNav} to="/settings/editCard">
              Payment Settings
            </NavLink>
          )}

          {user && user.role !== 'admin' && user?.subType !== USER_TYPE_VALUE.editor && (
            <NavLink activeClassName={styles.activeNav} to="/settings/changeAccount">
              Change Account
            </NavLink>
          )}
        </div>
        {user && user.role !== 'admin' && user?.subType !== USER_TYPE_VALUE.editor && (
          <NavLink to="/contact" className={contact ? styles.active : styles.inactive}>
            Contact
          </NavLink>
        )}
      </ul>
      <div
        className={`${styles.sidebarImg} ${user && user._id && user?.subType === 5 && styles.hide}`}
        onClick={() => history.push('/contact')}
      >
        <div className={styles.upperBottom} style={{ backgroundImage: 'url(' + bottomPic + ')' }}></div>
      </div>
    </div>
  )
}

export default SideBar
