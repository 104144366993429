import Input from 'components/input'
import Pack from 'components/pack'
import Selector from 'components/select'
import TagInput from 'components/tagInput'
import { useState } from 'react'
import { CirclePicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_CREATE_CATEGORY_OPTIONS, pickerColors } from 'staticConstants'
import { setIsPackSelected, setSelectedPack } from 'store/reducers/pack/packSlice'
import useWindowDimensions from 'utils/screenSize'
import backImgBlue from '../../assets/icons/UnionBackBlue.svg'
import styles from './packCreation.module.scss'

const PackCreate = ({ pack }) => {
  const dispatch = useDispatch()
  const { isMobile } = useWindowDimensions()
  const [categoryOptions] = useState([...CARD_CREATE_CATEGORY_OPTIONS])
  const requiredFields = useSelector(state => state.pack.requiredFields)
  const onChange = (key, value) => {
    dispatch(setSelectedPack({ ...pack, [key]: value }))
  }

  const onBackHandler = () => {
    dispatch(setIsPackSelected(false))
  }

  return (
    <div className={styles.packCreate}>
      <Pack pack={pack} editable />
      <div className={styles.form}>
        {isMobile ? (
          <div className={styles.headerDivCreation}>
            <img src={backImgBlue} alt="" onClick={onBackHandler} />
            <p>{pack && pack._id ? 'Edit' : 'New'} Pack</p>
          </div>
        ) : null}
        {isMobile ? null : (
          <Input
            placeholder="Pack Name"
            variation="cardInput"
            className={isMobile ? 'defaultInputMobile' : 'defaultInput'}
            containerClass="fullWidth"
            onChange={event => onChange('heading', event.target.value)}
            value={pack.heading ?? ''}
            error={requiredFields.error}
            errorMsg={requiredFields.errorMessages?.heading ?? ''}
          />
        )}

        <Input
          placeholder="Pack Description (optional)"
          variation="cardInput"
          containerClass="fullWidth"
          className={isMobile ? 'defaultInputMobile' : 'defaultInput'}
          onChange={event => onChange('description', event.target.value)}
          value={pack.description ?? ''}
        />
        {isMobile ? null : (
          <Selector
            placeholder="Category"
            selectedOption={categoryOptions.find(option => option.value === pack.category)}
            handleChangeSelect={event => onChange('category', event.value)}
            options={categoryOptions}
            error={requiredFields.error}
            errorMsg={requiredFields.errorMessages?.category ?? ''}
            className={styles.packSelector}
            selectClass={'selectGrayInput'}
          />
        )}

        <TagInput
          placeholder="Add extra search terms, including locations and categories, here (optional)"
          tags={pack.tags}
          className="full-width-fixed"
          onTagChange={tags => onChange('tags', tags)}
        />
        <div className={styles.colorPickerContainer}>
          <CirclePicker
            color={'#fff'}
            colors={pickerColors}
            onChange={updatedColor => onChange('color', updatedColor.hex)}
          />
        </div>
      </div>
    </div>
  )
}
export default PackCreate
