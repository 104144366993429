import { useEffect, useState } from 'react'
import { DEFAULT_PAGE_SIZE } from 'utils'
import styles from './dynamicTablePagination.module.scss'

const PAGES_AROUND_ACTIVE = 5

const DynamicTablePagination = ({ total, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageNumbers, setPageNumbers] = useState([])
  const [visiblePages, setVisiblePages] = useState([])

  useEffect(() => {
    const newNumbers = []
    for (let i = 1; i <= Math.ceil(total / DEFAULT_PAGE_SIZE); i++) {
      newNumbers.push(i)
    }
    onPageChangeHandle(1)
    setPageNumbers(newNumbers)
  }, [total])

  useEffect(() => {
    if (pageNumbers.length > 0) setVisiblePages(renderPages())
  }, [currentPage, pageNumbers])

  const onPageChangeHandle = number => {
    onPageChange && onPageChange(number)
    setCurrentPage(number)
  }

  const renderPages = () => {
    if (currentPage <= PAGES_AROUND_ACTIVE) {
      return pageNumbers.slice(0, PAGES_AROUND_ACTIVE + 1)
    }
    if (currentPage > PAGES_AROUND_ACTIVE) {
      let start = currentPage - Math.floor(PAGES_AROUND_ACTIVE / 2)
      let end = currentPage + Math.floor(PAGES_AROUND_ACTIVE / 2)
      if (start < 1) {
        start = 1
        end = PAGES_AROUND_ACTIVE
      }
      if (end > pageNumbers.length) {
        end = pageNumbers.length
        start = pageNumbers.length - PAGES_AROUND_ACTIVE + 1
      }
      return pageNumbers.slice(start - 1, end)
    }
  }

  return (
    <div className={styles.pagination}>
      <div className={styles.wrap}>
        <li
          key={'first' + 1}
          className={`${styles.pageItem} ${currentPage === 1 ? styles.activePage : ''}`}
          onClick={() => onPageChangeHandle(1)}
        >
          <span>{1}</span>
        </li>
      </div>
      {visiblePages?.length > 0 &&
        visiblePages.map((number, index) => (
          <div className={styles.wrap} key={`key-${number}-${index}`}>
            {index === 0 && number > 2 && (
              <li className={styles.dots} key={'dots1+' + number + index}>
                ...
              </li>
            )}
            {number > 1 && number < pageNumbers.length && (
              <li
                key={new Date().getTime()}
                className={`${styles.pageItem} ${number === currentPage ? styles.activePage : ''}`}
                onClick={() => onPageChangeHandle(number)}
              >
                <span>{number}</span>
              </li>
            )}
            {index === visiblePages.length - 1 && number <= pageNumbers.length - 2 && (
              <li className={styles.dots} key={'dots2' + number + index}>
                ...
              </li>
            )}
          </div>
        ))}
      {pageNumbers?.length > 1 && (
        <div className={styles.wrap}>
          <li
            key={'last' + pageNumbers.length}
            className={`${styles.pageItem} ${pageNumbers.length === currentPage ? styles.activePage : ''}`}
            onClick={() => onPageChangeHandle(pageNumbers.length)}
          >
            <span>{pageNumbers.length}</span>
          </li>
        </div>
      )}
    </div>
  )
}
export default DynamicTablePagination
