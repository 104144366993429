import { configureStore, combineReducers } from '@reduxjs/toolkit'
import authReducer from './reducers/auth/authSlice'
import modalReducer from './reducers/modal/modalSlice'
import paymentReducer from './reducers/payment/paymentSlice'
import logger from 'redux-logger'
import exampleReducer from './reducers/example/exampleSlice'
import chatReducer from './reducers/chat/chatSlice'
import eventReducer from './reducers/event/eventSlice'
import cardReducer from './reducers/card/cardSlice'
import configReducer from './reducers/config/configSlice'
import queReducer from './reducers/que/queSlice'
import campaignReducer from './reducers/campaign/campaignSlice'
import filterReducer from './reducers/filter/filterSlice'
import scrapeReducer from './reducers/scrape/scrapeSlice'
import modalPreviewReducer from './reducers/previewModal/previewModalSlice'
import chatModalReducer from './reducers/chatModal/chatModalSlice'
import packModalSlice from './reducers/packModal/packModalSlice'
import yesNoModalReducer from './reducers/yesNoModal/yesNoModalSlice'
import packSlice from './reducers/pack/packSlice'

const combinedReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  payment: paymentReducer,
  example: exampleReducer,
  chat: chatReducer,
  event: eventReducer,
  card: cardReducer,
  config: configReducer,
  uploadQue: queReducer,
  campaign: campaignReducer,
  filter: filterReducer,
  scrape: scrapeReducer,
  previewModal: modalPreviewReducer,
  yesNoModal: yesNoModalReducer,
  chatModal: chatModalReducer,
  packModal: packModalSlice,
  pack: packSlice
})

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined
  }
  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => [...getDefaultMiddleware({ serializableCheck: false }), logger],
  devTools: process.env.NODE_ENV !== 'prod'
})

export default store
