import Button from 'components/button'
import InviteUser from 'components/inviteUserModal'
import UserSingle from 'components/userSingle'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { openModal } from 'store/reducers/modal/modalSlice'
import styles from './users.module.scss'

const Users = () => {
  const [users, setUsers] = useState([])
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const openModalInvite = () => {
    dispatch(
      openModal({
        content: <InviteUser />,
        name: 'Invite modal',
        size: 'max700',
        show: true,
        xButton: false
      })
    )
  }

  useEffect(() => {
    const fetchUser = async () => {
      const response = await dataService.run('profiles', 'user', 'all', {
        filter: { $and: [{ role: { $ne: 'user' } }, { _id: { $ne: user._id } }] },
        sort: { loggedAt: -1 }
      })
      setUsers(response)
    }

    fetchUser()
  }, [])

  return (
    <div className={styles.usersWrapper}>
      <div className={styles.users}>
        {users &&
          users.length > 0 &&
          users.map((user, i) => {
            return <UserSingle props={user} key={i} />
          })}
      </div>
      <div className={styles.buttonDiv}>
        <Button label="Add user" onClick={openModalInvite} />
      </div>
    </div>
  )
}

export default Users
