import editIconBlue from 'assets/icons/editBlue.svg'
import RestoreIconBlue from 'assets/icons/restoreBlue.svg'
import RestoreIcon from 'assets/icons/restorePink.svg'
import adminEdit from 'assets/images/edit.svg'
import aquaPack from 'assets/images/pack/aqua.png'
import bluePack from 'assets/images/pack/blue.png'
import grayPack from 'assets/images/pack/gray.png'
import lightbluePack from 'assets/images/pack/lightblue.png'
import limePack from 'assets/images/pack/lime.png'
import orangePack from 'assets/images/pack/orange.png'
import pinkPack from 'assets/images/pack/pink.png'
import redPack from 'assets/images/pack/red.png'
import yellowPack from 'assets/images/pack/yellow.png'
import packUnwrapAqua from 'assets/lottie/pack_unwrap_aqua.json'
import packUnwrapBlue from 'assets/lottie/pack_unwrap_blue.json'
import packUnwrapGold from 'assets/lottie/pack_unwrap_gold.json'
import packUnwrapGray from 'assets/lottie/pack_unwrap_gray.json'
import packUnwrapGreen from 'assets/lottie/pack_unwrap_green.json'
import packUnwrapLime from 'assets/lottie/pack_unwrap_lime.json'
import packUnwrapOrange from 'assets/lottie/pack_unwrap_orange.json'
import packUnwrapPink from 'assets/lottie/pack_unwrap_pink.json'
import packUnwrapRed from 'assets/lottie/pack_unwrap_red.json'
import AdminEdit from 'components/adminEdit'
import CardScrollableContent from 'components/card/components/scrollableContent'
import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fileService from 'services/file.service'
import { toastService } from 'services/toastService'
import { canvasImageLoaded } from 'store/reducers/card/cardSlice'
import { openModal } from 'store/reducers/modal/modalSlice'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import useWindowDimensions from 'utils/screenSize'
import styles from './packFlip.module.scss'

const CanvasBlock = (imageObject, pack, imgCanvasKey, loading) => {
  /* eslint-disable */
  return (
    <>
      {imageObject?.imageObject && imageObject.loading ? (
        <div className={styles.loadingContainer}>
          <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
      ) : (
        <div className={styles.logoContainer}>
          {!loading || imgCanvasKey === 'noImage' ? (
            <>
              {imageObject?.imageObject && (
                <img className={`${styles.previewLogo}`} alt="pack-logo" src={imageObject?.imageObject} />
              )}
            </>
          ) : (
            <div className={styles.loader}>{/* <Loading /> */}</div>
          )}
        </div>
      )}
    </>
  )
  /* eslint-enable */
}
const PackFlip = ({
  pack,
  editable = false,
  onEdit,
  onClick,
  className,
  isFlipped = false,
  isLoading = false,
  selectable = false,
  isPackSelected = () => false,
  hasAdminEdit = false,
  onSelectionClick,
  restorable = false,
  dateDiffForDeleting = 0,
  openable = true,
  imgCanvasKey
}) => {
  const dispatch = useDispatch()
  const store = useSelector(state => state)
  const [imgBase64, setImgBase64] = useState(false)
  const isBussines = true
  const { isMobile } = useWindowDimensions()
  const [loading, setLoading] = useState(false)
  const isRestorable =
    restorable && store?.auth?.user?._id && pack?._id && pack?.userId && store.auth.user._id === pack.userId

  useEffect(() => {
    const getImage = async () => {
      try {
        setLoading(true)
        if (imgCanvasKey === 'noImage') return dispatch(canvasImageLoaded(true))
        // if (imgCanvasKey === 'noImage') return true
        const res = await fileService.run('s3', 'getImgBase64', { key: imgCanvasKey })
        if (res) {
          setImgBase64(res.body)
          setLoading(false)
        }
        dispatch(canvasImageLoaded(true))
      } catch (error) {
        console.warn(error)
        setLoading(false)
      }
    }
    if (imgCanvasKey) getImage()
  }, [imgCanvasKey])

  const onEditHandler = () => {
    onEdit && onEdit()
  }

  const handleEvents = event => {
    if (!selectable) {
      switch (event.target.name) {
        case 'editIcon':
          onEditHandler()
          break
        default:
          if (openable) {
            onClickHandler()
          }
          break
      }
    } else {
      toastService('info', 'Packs are not allowed for selection.')
    }
  }
  const onClickHandler = () => {
    onClick && onClick()
  }

  const onSelectionHandler = () => {
    onSelectionClick && onSelectionClick(pack)
  }

  const getPackImage = () => {
    switch (pack.color) {
      case '#bfbfc1':
        return grayPack
      case '#d20000':
        return redPack
      case '#dd0058':
        return pinkPack
      case '#343ec8':
        return bluePack
      case '#00aaca':
        return lightbluePack
      case '#00d690':
        return aquaPack
      case '#5eda29':
        return limePack
      case '#cdbb01':
        return yellowPack
      case '#db5700':
        return orangePack
      default:
        return redPack
    }
  }
  const getLottieByPackColor = () => {
    switch (pack.color) {
      case '#d20000':
        return packUnwrapRed
      case '#dd0058':
        return packUnwrapPink
      case '#343ec8':
        return packUnwrapBlue
      case '#00aaca':
        return packUnwrapAqua
      case '#00d690':
        return packUnwrapGreen
      case '#5eda29':
        return packUnwrapLime
      case '#cdbb01':
        return packUnwrapGold
      case '#db5700':
        return packUnwrapOrange
      default:
        return packUnwrapGray
    }
  }
  const lottieOptions = {
    animationData: getLottieByPackColor(),
    autoplay: false,
    loop: false
  }

  const handleAdminEdit = () => {
    dispatch(
      openModal({
        content: <AdminEdit item={pack} type="pack" showItemReports={true} />,
        show: true,
        size: 'max1300centered'
      })
    )
  }

  const dateColor = () => {
    if (dateDiffForDeleting > 10) {
      return styles.infoContent
    } else if (dateDiffForDeleting > 5) {
      return styles.colorDays
    } else {
      return styles.critical
    }
  }

  return (
    <div className={`${styles.flipPack} ${isFlipped ? styles.flipPackFlipped : ''}`}>
      <div className={`${styles.flipPackInner} ${isFlipped ? styles.flipPackInnerFlipped : ''}`}>
        <div
          onClick={event => handleEvents(event)}
          className={`${styles.flipPackFront} 
          ${selectable ? styles.wrapperSelectable : openable ? styles.wrapper : styles.wrapperNotOpenable}
          ${className} ${styles[className]}`}
          style={{ backgroundImage: 'url("' + getPackImage() + '")' }}
          id="exportCanvas"
        >
          {selectable && (
            <div
              className={`
              ${styles.selectionPackCover}
              ${isPackSelected(pack) ? styles.packSelected : ''}
            `}
              onClick={onSelectionHandler}
            ></div>
          )}
          {onEdit && <img name="editIcon" className={styles.editIcon} alt="Edit" src={editIconBlue} />}

          {!editable && !imgCanvasKey && (
            <div className={styles.logoContainer}>
              {pack?.logoImage && pack?.logoImage !== '' && (
                <img
                  className={`${styles.previewLogo}`}
                  alt="pack-logo"
                  src={getMediaFromFileOrString(pack?.logoImage, true)}
                  onError={e => {
                    e.target.onerror = null
                    e.target.src = getMediaFromFileOrString(pack?.logoImage, false)
                  }}
                />
              )}
            </div>
          )}
          {imgCanvasKey && (
            <CanvasBlock imageObject={imgBase64} pack={pack} imgCanvasKey={imgBase64} loading={loading} />
          )}

          {isRestorable && (
            <div
              title={'Restore the pack'}
              className={`${styles.restoreIcon}
              ${isMobile ? styles.restoreMobile : ''}`}
            >
              <img name="restoreIcon" alt="restoreIcon" src={isBussines ? RestoreIconBlue : RestoreIcon} />
            </div>
          )}
          {(editable || pack.heading !== '' || pack.description !== '') && (
            <div className={styles.packDetails}>
              <CardScrollableContent className={styles.scrollableContent}>
                <span className={styles.packName}>{pack.heading !== '' || !editable ? pack.heading : 'Pack Name'}</span>
                <span className={styles.packDescription}>
                  {pack.description !== '' || !editable ? pack.description : 'Description'}
                </span>
              </CardScrollableContent>
            </div>
          )}
        </div>

        {hasAdminEdit && (
          <div className={styles.adminEdit} onClick={handleAdminEdit}>
            <img src={adminEdit} alt="" />
          </div>
        )}
        {isLoading && (
          <div className={styles.loaderContainer}>
            <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{ color: 'white' }}></i>
          </div>
        )}
        <Lottie className={styles.flipPackBack} {...lottieOptions} />
      </div>

      {restorable && dateDiffForDeleting && (
        <span className={dateColor()}>
          {dateDiffForDeleting}
          <p>Days</p>
        </span>
      )}
    </div>
  )
}

export default PackFlip
