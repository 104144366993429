import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { scrapePage } from './thunk'

const initialState = {
  scrapedCard: {
    cardType: '',
    published: false,
    public: true,
    type: 'card',
    avatar: '',
    userId: '',
    username: '',
    fullname: '',
    userRole: '',
    heading: '',
    subheading: '',
    frontImage: '',
    category: '',
    tags: [],
    mature: false,
    scrapeUrl: '',
    backFields: []
  },
  scrapedAt: 0,
  scrapedFlag: false
}

const scrape = createSlice({
  name: 'scrape',
  initialState,
  reducers: {
    updateScrapedCard(state, action) {
      state.scrapedCard = { ...state.scrapedCard, ...(action?.payload || {}) }
    }
  },
  extraReducers: builder => {
    // add your async reducers here

    builder.addCase(scrapePage.fulfilled, (state, action) => {
      state.scrapedCard = { ...state.scrapedCard, ...(action.payload || {}) }
      state.scrapedFlag = true
      state.scrapedAt = moment().valueOf()
    })

    builder.addCase(scrapePage.rejected, (state, action) => {
      state.scrapedFlag = false
      state.scrapedAt = moment().valueOf()
      state.error = action.error
    })
  }
})

export const { updateScrapedCard } = scrape.actions
export default scrape.reducer
