import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_BACK_TYPES, CARD_TYPES_OPTIONS, FORM_SIDE } from 'staticConstants'
import styles from './cardCreationForm.module.scss'
import { cardTypeFields } from './cardTypeSetup'
import RenderFrontFields from './render/frontFields'
import RenderBackFields from './render/backFields'
import useWindowDimensions from 'utils/screenSize'
import MoreDetailsModal from 'components/moreDetailsModal'
import AttributeIcon from 'components/attributeButton/icon'
import GoogleMapSearch from 'components/googleMapSearch'
// import { cardActions } from 'store/actions/card.actions'
import iconCloseBlue from 'assets/icons/iconCloseBlue.svg'
import InlinePack from 'components/card/components/inlinePack'
import BackFieldsStyles from '../../attributeButton/attributeButton.module.scss'
import { setSelectedCard } from 'store/reducers/card/cardSlice'
import LinkedImageForm from 'components/linkedImageForm'

const CardCreationForm = ({ className, side = FORM_SIDE.Front }) => {
  const card = useSelector(state => state.card.selectedCard)
  const role = useSelector(state => state.auth.user.role)
  const [cardFields, setCardFields] = useState(cardTypeFields[CARD_TYPES_OPTIONS[role][0].value])
  const [showFields, setShowFields] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [showInline, setShowInline] = useState(false)
  const [showLinkedImageForm, setShowLinkedImageForm] = useState(false)
  const dispatch = useDispatch()
  const refMap = useRef(null)
  const [isBussines] = useState(true)

  const addLocationOrInline = (type, value) => {
    setShowMap(false)
    setShowInline(false)
    dispatch(
      setSelectedCard({
        ...card,
        backFields: [
          ...card.backFields,
          {
            id: new Date().getTime(),
            type: type,
            content: value
          }
        ]
      })
    )
  }

  const clickedOnAddMap = () => setShowMap(true)
  const clickedOnAddInline = () => setShowInline(true)
  const clickedOnLinkedImage = () => setShowLinkedImageForm(true)

  useEffect(() => {
    if (card.cardType) setCardFields(cardTypeFields[card.cardType])
  }, [card.cardType])
  const { isMobile } = useWindowDimensions()

  const handleClickOnUserIcon = val => {
    if (val) setShowFields(val === 0)
    else setShowFields(!showFields)
  }

  document
    .querySelectorAll('.' + styles.mobileBackFields + ' .' + BackFieldsStyles.attributeContainer)
    .forEach(el => el.addEventListener('click', () => handleClickOnUserIcon(0)))

  return (
    <div className={`${styles.content} ${className} ${styles[className]}`}>
      <div className={styles.contentBody}>
        {/* 
        FRONT FIELDS  
        */}
        {side === FORM_SIDE.Front && RenderFrontFields(card, cardFields)}
        {/* 
        BACK FIELDS  
        */}
        {side === FORM_SIDE.Back && !isMobile && RenderBackFields(card, cardFields)}
        {side === FORM_SIDE.Back && isMobile && (
          <div className={styles.mobileBackFields}>
            <div className={styles.mobileButton} onClick={() => handleClickOnUserIcon()}>
              <AttributeIcon plus={true} />
            </div>
            <MoreDetailsModal
              showHideModal={() => handleClickOnUserIcon()}
              position="right"
              style={showFields ? { visibility: 'visible' } : { visibility: 'hidden' }}
              className={styles.mobileWrapper}
              contentClass={styles.mobileContent}
              isActive={showFields}
            >
              {RenderBackFields(card, cardFields, clickedOnAddMap, clickedOnAddInline, clickedOnLinkedImage)}
            </MoreDetailsModal>
          </div>
        )}
        {showMap && (
          <div className={styles.googleMapFront} ref={refMap}>
            <img
              className={`${styles.googleClose} ${isBussines && styles.isBussines}`}
              src={iconCloseBlue}
              alt="Close icon"
              onClick={() => setShowMap(false)}
            />
            <GoogleMapSearch mapClass="search-event" onAdd={event => addLocationOrInline(CARD_BACK_TYPES.map, event)} />
          </div>
        )}
        {showInline && (
          <div className={`${styles.inlineBack} ${isBussines && styles.isBussines}`} id="positionInline">
            <img
              className={`${styles.googleClose} ${isBussines && styles.isBussines}`}
              src={iconCloseBlue}
              alt="Close icon"
              onClick={() => setShowInline(false)}
            />
            <InlinePack card={card} onAdd={event => addLocationOrInline(CARD_BACK_TYPES.inlinePack, event)} />
          </div>
        )}
        {showLinkedImageForm && (
          <div className={`${styles.inlineBack} ${isBussines && styles.isBussines}`} id="positionInline">
            <img
              className={`${styles.googleClose} ${isBussines && styles.isBussines}`}
              src={iconCloseBlue}
              alt="Close icon"
              onClick={() => setShowLinkedImageForm(false)}
            />
            <LinkedImageForm closeModal={() => setShowLinkedImageForm(false)} card={card} type="edit" />
          </div>
        )}
      </div>
    </div>
  )
}
export default CardCreationForm
