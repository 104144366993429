import iconClose from 'assets/icons/iconCloseBlue.svg'
import Button from 'components/button'
import Input from 'components/input'
import ReportsList from 'components/reportsList'
import UserAvatar from 'components/userAvatar'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { updateUser } from 'store/reducers/auth/thunk'
import { closeModal } from 'store/reducers/modal/modalSlice'
import { openModal, closeModal as closeYesNoModal } from 'store/reducers/yesNoModal/yesNoModalSlice'
import { getAvatarImage } from 'utils/getAvatarImage'
import styles from './editProfile.module.scss'
import TopicSelection from 'components/topicSelection'
import { ADMIN_FEATURES_OPTIONS, USER_TYPE_VALUE } from 'staticConstants'
import { getSelectedOptionsByAdminAccessValues } from 'components/registerCoAdminForm'
import fileService from 'services/file.service'
import { defaultDeletedUserBase64 } from 'components/icons/defaultDeletedUserImage'

const EditUser = ({ user, triggerUsersReload, disabledBtn = false }) => {
  const { disabled = false, deleted = false } = user
  const dispatch = useDispatch()

  const [userChanged, setUserChanged] = useState(false)
  const [accountDeleted, setAccountDeleted] = useState(deleted)
  const [accountDisabled, setAccountDisabled] = useState(disabled)
  const [userData, setUserData] = useState({ ...user })
  const [userReports, setUserReports] = useState([])
  const [triggerReportsFetch, setTriggerReportsFetch] = useState(new Date().getTime())
  const [showSaveButton, setShowSaveButton] = useState(false)

  useEffect(() => {
    const fetchReports = async () => {
      const res = await dataService.run('reports', 'userReport', 'all', { filter: { userId: user?._id } })
      if (res?.length > 0) setUserReports(res)
      else setUserReports([])
    }
    if (user?._id) fetchReports()
  }, [user?._id, triggerReportsFetch])

  const handleTopicSelectionChange = selectedTopics => {
    setShowSaveButton(true)
    const adminAccess = selectedTopics?.length ? selectedTopics.map(el => el.value) : []
    setUserData(userData => ({ ...userData, adminAccess }))
  }

  const updateUserClick = () => {
    setShowSaveButton(false)
    dispatch(updateUser(userData))
    triggerUsersReload && triggerUsersReload()
  }

  const handleDisableOrDeleteUser = async (status, reportReason) => {
    if (!status) return null
    if (status === 'deleted') {
      await fileService.run(
        's3',
        'upload',
        { data: defaultDeletedUserBase64, name: `${user?._id}.jpg`, type: 'base64' },
        null,
        `${user?._id}.jpg`
      )
    }
    const res = await dataService.run(
      'profile',
      'user',
      status === 'deleted' ? 'deleteAccount' : status === 'enabled' ? 'enableAccount' : 'disableAccount',
      {
        userId: user?._id,
        reportReason
      }
    )
    if (Object.keys(res)?.length) {
      if (status === 'enabled') {
        setAccountDisabled(false)
        setTriggerReportsFetch(new Date().getTime())
      } else {
        setAccountDisabled(true)
        setTriggerReportsFetch(new Date().getTime())
      }
      if (status === 'deleted') {
        setAccountDeleted(true)
      }
      toastService('success', `User "${user.username}" successfully ${status && status !== '' ? status : 'updated'}.`)
    }
    setUserChanged(true)
    dispatch(closeYesNoModal())
  }

  const openYesNoModal = status => {
    dispatch(
      openModal({
        title: `Are you sure you want to ${status?.slice(0, -1) || 'delete'} this user?`,
        content: '',
        onsubmit: reason => handleDisableOrDeleteUser(status, reason),
        additionalInputPlaceholder: 'Why?',
        yesTitle: 'Send',
        noTitle: 'Cancel'
      })
    )
  }

  const closeModalX = () => {
    if (userChanged) {
      triggerUsersReload && triggerUsersReload()
    }
    dispatch(closeModal())
  }

  return (
    <div className={styles.editUserWrapper}>
      <div className={styles.header}>
        <div onClick={closeModalX} className={styles.close}>
          <img src={iconClose} alt="Close icon" />
        </div>
        <div className={styles.editText}>Edit</div>
        <div className={styles.headerButtons}>
          <Button
            className={styles.delBtn}
            label={accountDisabled ? 'Enable Account' : 'Disable Account'}
            variation={'secondary'}
            disabled={accountDeleted || disabledBtn}
            onClick={() => openYesNoModal(accountDisabled ? 'enabled' : 'disabled')}
          />
          <Button
            className={styles.delBtn}
            label="Delete Account"
            size="xxs"
            variation="secondary"
            disabled={accountDeleted || disabledBtn}
            onClick={() => openYesNoModal(accountDeleted ? null : 'deleted')}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.inputs}>
          <div className={styles.avatarContainer}>
            <UserAvatar width="11" height="11" imageUrl={getAvatarImage(userData?._id)} />
          </div>
          <div className={styles.singleRowInput}>
            <Input
              variation="cardInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="name"
              type="text"
              name="name"
              value={userData.name}
              disabled
              className={styles.editInput}
            />
          </div>
          <div className={styles.singleRowInput}>
            <Input
              variation="cardInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="email"
              type="text"
              name="email"
              value={userData.email}
              disabled
              className={styles.editInput}
            />
          </div>
          <div className={styles.singleRowInput}>
            <Input
              variation="cardInput"
              inputClass="customApplyInput"
              placeholderOrentation="placeholderCenter"
              placeholder="type"
              type="text"
              name="role"
              value={userData.role}
              disabled
              className={styles.editInput}
            />
          </div>
          {user?.subType === USER_TYPE_VALUE.editor && (
            <div className={styles.singleRowInput}>
              <TopicSelection
                className={styles.topicSelectionContainer}
                options={ADMIN_FEATURES_OPTIONS}
                onSelectionChange={handleTopicSelectionChange}
                selectedOptions={getSelectedOptionsByAdminAccessValues(userData?.adminAccess)}
                placeholder="Choose admin feature"
              />
            </div>
          )}
        </div>
        {<ReportsList reports={userReports} title="User Reports & Notes:" />}
      </div>
      <div className={styles.buttonDiv}>
        {showSaveButton && <Button label="Save" variation="secondary" onClick={updateUserClick} />}
      </div>
    </div>
  )
}

export default EditUser
