import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'

import Slider from 'components/slider'
import CloseButton from 'components/closeButton'

import dataService from 'services/data.service'
import { closeModal as closePackModal } from 'store/reducers/packModal/packModalSlice'
import { toastService } from 'services/toastService'

import styles from './packModal.module.scss'

const PackModal = ({ packId = '' }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await dataService.run('data', 'pack', 'get', { filter: { _id: packId } })
      if (!res || Object.keys(res).length === 0) {
        toastService('error', 'Pack not exists anymore, it is deleted or disabled')
        closeModal()
      } else {
        setData([res])
      }
    }
    if (packId) fetchData()
  }, [packId])

  const closeModal = () => {
    dispatch(closePackModal())
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.pack}>
        <CloseButton handleClick={() => closeModal()} />
        <Slider items={data} showBtns={false} showWSButton={false} />
      </div>
    </div>
  )
}

export default PackModal
