import backPreviewImg from 'assets/images/defaultUser.png'
import Card from 'components/card'
import CardAction from 'components/card/components/actionOption'
import CardBody from 'components/card/components/body'
import DndPreview from 'components/card/components/dndPreview'
import CardHeader from 'components/card/components/header'
import CardScrollableContent from 'components/card/components/scrollableContent'
import CardWrapper from 'components/card/components/wrapper'
import { awsUrlResize } from 'environment'
import React from 'react'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import { getMediaWithFallbackFromFileOrString } from 'utils/getMediaWithFallbackFromFileOrString'
import styles from './cardDoubleBack.module.scss'
export const CardDoubleBack = ({ card, className, cardClass, setIsInline, openComments, disableActions = false }) => {
  const getBackgroundColor = () => {
    let color = 'transparent'
    card.backFields.map(field => {
      if (field.content !== '') {
        color = 'rgba(255,255,255,0.5)'
      }
    })
    return color
  }
  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card className={cardClass} size={CARD_SIZE.Normal} type={CARD_TYPES.Double} double userType={card?.userRole}>
        <CardBody
          type={CARD_TYPES.Double}
          style={{ backgroundImage: getMediaWithFallbackFromFileOrString(card.doubleCardImage) }}
          userType={card?.userRole}
        >
          <div
            className={styles.container}
            style={{
              width: 'calc(50% - 0.625rem)',
              backgroundColor: getBackgroundColor()
            }}
          >
            <CardHeader
              heading={card.fullname}
              subheading={card.username}
              type={CARD_TYPES.Double}
              logo={card.userId ? awsUrlResize + '/' + card.userId + '.jpg' : backPreviewImg}
              headingClass={styles.headerFont}
              logoClass={styles.avatarSize}
              className="m-5"
              userType={card?.userRole}
              showElipsisForUsername
            />
            <CardScrollableContent type={card.cardType} className={styles.backFields}>
              <div className={styles.activeContent}>
                <DndPreview data={card.backFields} setIsInline={setIsInline} card={card} />
              </div>
            </CardScrollableContent>
            <CardAction
              type={card.cardType}
              size="small"
              openComments={() => openComments && openComments()}
              card={card}
              disabled={disableActions}
            />
          </div>
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CardDoubleBack
