import styles from './instacardHelp.module.scss'

const InstacardHelp = () => {
  return (
    <div className={styles.instaHelpWrapper}>
      <p>If your Instacard! is not to your liking, please make sure your site has the following tags: </p>
      <div className={styles.oneProp}>
        <p className={styles.instcardHelpTitle}>For Title </p>
        <ul>
          <li>
            &#x2022; title tag with content <span>( eg. {' <title>Card title</title>'} )</span> or{' '}
          </li>
          <li>
            &#x2022; meta property="title" <span>( eg. {' <meta property="title" content="card title" />'} ) </span> or
          </li>
          <li>
            &#x2022; meta property="og:title"{' '}
            <span>
              ( eg.
              {' <meta property="og:title" content="card title" />'} )
            </span>
          </li>
        </ul>
      </div>

      <div className={styles.oneProp}>
        <p className={styles.instcardHelpTitle}>For Description </p>
        <ul>
          <li>
            &#x2022; meta property="description"{' '}
            <span>
              ( eg.
              {' <meta property="description" content="card description" />'} )
            </span>{' '}
            or
          </li>
          <li>
            &#x2022; meta property="og:description"{' '}
            <span>
              ( eg.
              {' <meta property="og:description" content="card description" />'} )
            </span>
          </li>
        </ul>
      </div>

      <div className={styles.oneProp}>
        <p className={styles.instcardHelpTitle}>For Image </p>
        <ul>
          <li>
            &#x2022; meta property="image" <span> ( eg.{' <meta property="image" content="card image url" />'} )</span>{' '}
            or
          </li>
          <li>
            &#x2022; meta property="og:image"{' '}
            <span>
              ( eg.
              {' <meta property="og:image" content="card image url" />'} )
            </span>{' '}
            or
          </li>
          <li>
            &#x2022; desired image has alt property just like title
            <span> ( eg. {' <img src="img src" alt="card title" />'} ) </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default InstacardHelp
