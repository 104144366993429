import { request } from './http.service'

class DataService {
  async run(name, type, mode, data = {}) {
    return request('post', `/api/data/${name}/${type}`, {
      mode,
      data
    })
  }
}

export default new DataService()
