import Selector from 'components/select'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './home.module.scss'
import { subDays } from 'date-fns'
import moment from 'moment'
import dataService from 'services/data.service'
import Chart from 'components/chart'
import Table from 'components/customTable'

const selectValues = [
  { value: 1, label: 'Past day' },
  { value: 7, label: 'Past 7 days' },
  { value: 14, label: 'Past 14 days' },
  { value: moment().daysInMonth(), label: 'Past month' }
]

const Home = () => {
  const user = useSelector(state => state.auth.user)
  const [selectedOpt, setSelectedOpt] = useState(selectValues[1])
  const [data, setData] = useState([])

  const handleSelect = option => {
    setSelectedOpt(option)
  }

  const _getFromDB = async value => {
    const val = subDays(new Date(), value).toISOString()
    const timestampValue = new Date(val).getTime()
    const data = {
      timestampValue: timestampValue,
      value: value
    }
    const reports = await dataService.run('data', 'map', 'reports', data)

    setData(reports)
  }

  useEffect(() => {
    _getFromDB(selectedOpt.value)
  }, [selectedOpt])

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.upperHeader}>
          <span>Welcome Back {user.name}! Here are your metrics:</span>
          <div className={styles.select}>
            <Selector
              options={selectValues}
              classNamePrefix="prefix-select"
              selectClass={'selectGrayColorBlue'}
              selectedOption={selectedOpt}
              handleChangeSelect={handleSelect}
              placeholder="Select interval"
            />
          </div>
        </div>
        <div className={styles.charts}>
          <div className={styles.singleRow}>
            <Chart
              data={data.reports}
              titleName="Views"
              dataKey={'view'}
              titleNumber={data && data.numberOf && data.numberOf.view}
              selectValue={selectedOpt}
            />
            <Chart
              data={data.reports}
              titleName="Likes"
              dataKey={'like'}
              titleNumber={data && data.numberOf && data.numberOf.like}
              selectValue={selectedOpt}
            />
            <Chart
              data={data.reports}
              titleName="Shares"
              dataKey={'share'}
              titleNumber={data && data.numberOf && data.numberOf.share}
              selectValue={selectedOpt}
            />
          </div>
          <div className={styles.singleRow}>
            <Chart
              data={data.reports}
              titleName="Reach"
              dataKey={'reach'}
              titleNumber={data && data.numberOf && data.numberOf.reach}
              selectValue={selectedOpt}
            />
            <div className={styles.table}>
              <Table props={data && data.allDetails ? data.allDetails : []} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
