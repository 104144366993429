import Button from 'components/button'
import DynamicTable from 'components/dynamicTable'
// import Heading, { TABS } from 'components/Heading'
import Heading from 'components/Heading'
import Pagination from 'components/pagination'
import Selector from 'components/select'
import { subDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import dataService from 'services/data.service'
import { openModal } from 'store/reducers/modal/modalSlice'
import { EMPTY_SEARCHES_TABLE_COLUMNS } from 'utils'
import deleteCrossIcon from '../../../assets/images/icons/close.svg'
import styles from './emptySearches.module.scss'
import RateOfChangePreview from './rateOfChangePreview'

const EmptySearches = () => {
  const dispatch = useDispatch()
  // const [activeTab, setActiveTab] = useState('business')
  const [emptySearches, setEmptySearches] = useState({ rows: [], total: 0 })
  const [numOfDays, setNumOfDays] = useState(7)
  const [toShow, setToShow] = useState({ rows: [], total: 0 })
  const [searchValue, setSearchValue] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const showFilter = false
  const [triggerRerender, setTriggerRerender] = useState(new Date().getTime())

  const durationOptions = [
    { value: 7, label: 'Past 7 days' },
    { value: 14, label: 'Past 14 days' },
    { value: 21, label: 'Past 21 days' },
    { value: 365, label: 'All time' }
  ]

  const handleDelete = async searchTerm => {
    if (!searchTerm) return
    const response = await dataService.run('reports', 'data', 'deleteEmptySearches', { searchTerm })
    if (response > 0) {
      setTriggerRerender(new Date().getTime())
    }
  }

  useEffect(() => {
    const fetchEmptySearch = async () => {
      let timestampValue = 0
      if (numOfDays !== 'all' && numOfDays !== 365) {
        const val = subDays(new Date(), numOfDays).toISOString()
        timestampValue = new Date(val).getTime()
      }
      const filter = {
        // filter: { userRole: activeTab === 'business' ? 'premiumUser' : 'user', createdAt: { $gt: timestampValue } },
        filter: { createdAt: { $gt: timestampValue } },
        pageNumber,
        pageSize: 20
      }

      const response = await dataService.run('reports', 'data', 'getEmptySearches', filter)

      setEmptySearches({ rows: response.rows, total: response.total })
      setToShow({ rows: response.rows, total: response.total })
    }
    fetchEmptySearch()
  }, [triggerRerender, numOfDays, pageNumber])

  const handleChange = e => {
    const inputValue = e.target.value
    setSearchValue(inputValue)
    const newEmptySearches = { rows: [], total: 0 }
    if (emptySearches && emptySearches.rows && emptySearches.rows.length) {
      if (inputValue.trim() === '') {
        setToShow(emptySearches.rows)
      } else {
        emptySearches.rows.forEach(oneSearch => {
          if (oneSearch.category) {
            const categoryLower = oneSearch.category.toLowerCase().trim()
            const inputLower = inputValue.toLowerCase().trim()
            if (categoryLower.includes(inputLower)) {
              if (!_checkIfInclude(oneSearch, newEmptySearches.rows)) {
                newEmptySearches.rows.push(oneSearch)
              }
            }
          }
          if (oneSearch.topics && oneSearch.topics.length > 0) {
            if (!_checkIfInclude(oneSearch, newEmptySearches.rows)) {
              const toCompare = (inputValue.charAt(0).toUpperCase() + inputValue.slice(1)).trim()
              oneSearch.topics.forEach(one => {
                if (one.includes(toCompare)) {
                  newEmptySearches.rows.push(oneSearch)
                }
              })
            }
          }
        })
        setToShow(newEmptySearches.rows)
      }
    }
  }

  const _checkIfInclude = (object, array) => {
    if (array.length > 0 && object && object._id) {
      array.forEach(one => {
        if (one._id && one._id === object._id) {
          return true
        }
      })
      return false
    } else {
      return false
    }
  }

  const changeNumOfDays = e => {
    setNumOfDays(e)
  }

  const buttonStyle = () => {
    if (numOfDays === 'all') {
      return styles.buttonDisable
    } else {
      return styles.viewRocButton
    }
  }

  const onViewROCClick = id => {
    dispatch(
      openModal({
        content: <RateOfChangePreview id={id} numOfDays={numOfDays} />,
        size: 'max800',
        show: true,
        name: 'rocPreview'
      })
    )
  }

  return (
    <div className={styles.wrapper}>
      {/* <Heading title="Empty Searches" tabs={TABS.ApplicantsTabs} onTabChange={setActiveTab} /> */}
      <Heading title="Empty Searches" />
      <div className={styles.filter}>
        <div className={styles.range}>
          <Selector
            selectClass={'selectGrayNoMargin'}
            placeholder={'Date Range'}
            selectedOption={
              durationOptions &&
              durationOptions?.length > 0 &&
              durationOptions.find(option => option.value === numOfDays)
            }
            options={durationOptions}
            name={'filter'}
            handleChangeSelect={e => changeNumOfDays(e.value)}
          />
        </div>
        {showFilter && (
          <div className={styles.search}>
            <input type="text" onChange={handleChange} value={searchValue} placeholder="Enter category or topic" />
          </div>
        )}
      </div>
      <DynamicTable
        className={styles.centeredTable}
        columns={EMPTY_SEARCHES_TABLE_COLUMNS}
        total={toShow.total}
        emptyMessage="There are no empty searches"
        showPagination={false}
        currentPageData={toShow?.rows?.map(el => ({
          ...el,
          roc: <Button className={buttonStyle()} label="View" onClick={() => onViewROCClick(el?._id)} />,
          actionButtons: (
            <div className={styles.deleteEmptySearchIcon} onClick={() => handleDelete(el?._id)}>
              <img src={deleteCrossIcon} alt="x" />
            </div>
          )
        }))}
      />
      {toShow && toShow.total > 20 && <Pagination total={toShow.total} onPageChange={setPageNumber} pageSize={20} />}
    </div>
  )
}

export default EmptySearches
