import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './button.module.scss'

const Button = ({
  leftIcon,
  rightIcon,
  label,
  variation = 'primary',
  size = 'md',
  btnClass,
  disabled = false,
  spinner = false,
  onClick,
  style,
  className,
  type,
  name,
  id,
  children,
  checkBussines = true,
  preview = false
}) => {
  // const { isBussines } = useSelector(store => store?.navbar)
  const [isBussines] = useState(true)
  return (
    <>
      {!spinner ? (
        <button
          id={id}
          name={name}
          className={`${styles[variation]} ${styles[size]} ${className} ${styles[btnClass]} ${
            checkBussines && isBussines ? styles.isBussines : ''
          }`}
          style={style}
          onClick={onClick}
          disabled={disabled}
          type={type}
        >
          {leftIcon && <img className={styles.icon} src={leftIcon} alt="" />}
          {label && (
            <span id={id} name={name} className={preview && styles.labelMax}>
              {label}
            </span>
          )}
          {rightIcon && <img src={rightIcon} alt="" />}
          {children}
        </button>
      ) : (
        <button
          name={name}
          className={`${styles[variation]} ${styles[size]} ${className} ${styles[btnClass]} ${
            isBussines ? styles.isBussines : ''
          }`}
          style={style}
          disabled={disabled || spinner}
          type={type}
        >
          {label && (
            <span id={id} name={name} className={preview && styles.labelMax}>
              {label}
            </span>
          )}
          <i
            className="fa fa-spinner fa-spin"
            aria-hidden="true"
            style={{ color: btnClass === 'primary' ? 'white' : 'black' }}
          ></i>
        </button>
      )}
    </>
  )
}

Button.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  btnClass: PropTypes.string,
  border: PropTypes.string,
  variation: PropTypes.string,
  spinner: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

export default Button
