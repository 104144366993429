import React, { useEffect, useRef, useState } from 'react'
import styles from './googleMapSerach.module.scss'
const options = {
  // restrict your search to a specific type of result
  fields: ['address_component', 'geometry', 'name'],
  types: [],
  strictBounds: false
  // restrict your search to a specific country, or an array of countries
  // componentRestrictions: { country: ['gb', 'us'] },
}
let autoComplete

const AutoComplete = ({ mapApi, map, addplace, mapClass = 'search-input' }) => {
  const ref = useRef()
  const [value, setValue] = useState('')
  const clearSearchBox = () => {
    setValue('')
  }
  const onChange = e => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (mapApi.places) {
      const input = document.getElementById(`${mapClass}`)
      autoComplete = new mapApi.places.Autocomplete(input, options)
      autoComplete.addListener('place_changed', onPlaceChanged)
      autoComplete.bindTo('bounds', map)
    }
  }, [mapApi.places])

  const pacContainer = document.querySelector('.pac-container')

  useEffect(() => {
    if (ref && pacContainer) {
      const el = document.querySelector('.pac-container')
      const input = document.querySelector(`#${mapClass}`)
      const topPos = input.getBoundingClientRect().top
      // const leftPos = input.getBoundingClientRect().left
      el.style.top = `${topPos + 20}px`
      // el.style.left = `${leftPos - 0}px`
      // el.style.width = 'calc(100% - 50px)'
    }
  }, [ref, pacContainer, value])

  const onPlaceChanged = () => {
    const place = autoComplete.getPlace()
    if (!place.geometry) return
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport)
    } else {
      map.setCenter(place.geometry.location)
      map.setZoom(17)
    }

    addplace(place)
    setValue('')
  }
  return (
    <div className={styles.autocompleteWrapper}>
      <input
        ref={ref}
        id={mapClass}
        value={value}
        type="text"
        onFocus={clearSearchBox}
        onChange={onChange}
        placeholder="Enter a location"
      />
    </div>
  )
}
export default AutoComplete
