import { createSlice } from '@reduxjs/toolkit'
import { localStorageService } from 'services/localStorage.service'
import { toastService } from 'services/toastService'
import { paymentStripe } from './thunk'
const initialState = {
  success: false
}

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setSucces(state, action) {
      state.success = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(paymentStripe.fulfilled, (state, action) => {
      toastService('success', action.payload.message)
      state.payment = action.payload
      state.success = true
      if (action && action.payload && action.payload.user) {
        localStorageService.set(`user_${action.payload.user.username}`, action.payload.user)
      }
    })

    builder.addCase(paymentStripe.rejected, (state, action) => {
      toastService('error', action.error)
      state.error = action.error
      state.success = false
    })
  }
})

export const { setSucces } = paymentSlice.actions
export default paymentSlice.reducer
