import Button from 'components/button'
import SwitchAccount from 'components/switchAccount'
import { appUrl, awsUrlResize } from 'environment'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import dataService from 'services/data.service'
import styles from './acountModal.module.scss'

const AccountModal = ({ handleLogOut = null, onAvatarDontExist = null }) => {
  const [imageKey, setImageKey] = useState(moment().valueOf())
  const history = useHistory()
  const store = useSelector(state => state)
  const { user = {} } = store.auth

  useEffect(() => {
    setImageKey(moment().valueOf())
  }, [store && store.auth && store.auth.changedAvatar])

  const switchToWebApplication = async () => {
    const _createSwitchObject = async () => {
      const res = await dataService.run('data', 'switchApplication', 'newSwitchApplication', {})
      return res
    }
    const _redirectToAdminAutoLogin = token => {
      window.location.replace(`${appUrl}/login/${token}`)
    }
    const token = await _createSwitchObject()
    _redirectToAdminAutoLogin(token)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.details}>
        <img
          key={'image-' + imageKey}
          src={
            awsUrlResize +
            '/' +
            store.auth?.user?._id +
            '.jpg' +
            '?' +
            (store?.auth?.user?.avatarUpdatedAt ? '?' + store.auth.user.avatarUpdatedAt : '')
          }
          alt="User avatar"
          className={styles.avatar}
          onError={onAvatarDontExist}
        />
        <div className={styles.acount_details}>
          <h4 className={styles.username}>{user && user.name ? user.name : ''}</h4>
          <button className={styles.btn__logout} onClick={() => handleLogOut()}>
            Log Out
          </button>
        </div>
      </div>

      <div className={styles.btns}>
        <Button
          label="Account Settings"
          className={styles.btn}
          variation="transparent"
          size="fitContent"
          onClick={() => history.push('/settings/general')}
        />
        {/* <Button label="Privacy & Security" className={styles.btn} variation="transparent" size="fitContent" /> */}
      </div>

      {user && user.role && user.role !== 'admin' && <SwitchAccount />}

      <div className={styles.otherAcoutn} onClick={switchToWebApplication}>
        <span>Switch to Frontend</span>
      </div>
    </div>
  )
}

export default AccountModal
