import React, { useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import isURL from 'validator/lib/isURL'
import './quill.scss'
import { toastService } from 'services/toastService'

class VideoURLValidator extends Quill.imports['formats/video'] {
  static create(value) {
    if (isURL(value)) {
      return super.create(value)
    } else {
      toastService('error', 'Bad URL.')
      return null
    }
  }
}

Quill.register({
  'formats/video': VideoURLValidator
})

const modules = {
  toolbar: [[{ header: [3, false] }], ['bold', 'italic', 'underline', 'link', 'video']]
}
const formats = ['header', 'bold', 'italic', 'underline', 'link', 'video']
const QuilFiled = ({ htmlValue = '', onChange, onBlur, onTypingEnd, triggerAfterMS = 500 }) => {
  const [value, setValue] = useState(htmlValue)
  const [timer, setTimer] = useState()
  const onBlurHandler = () => {
    onBlur && onBlur(value)
  }
  const onChangeHandler = event => {
    setValue(event)
    onChange && onChange(event)

    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(setTimeout(() => onTimerSubmit(event), triggerAfterMS))
  }

  const onTimerSubmit = value => {
    onTypingEnd && onTypingEnd(value)
    setTimer(null)
  }
  return (
    <div>
      <div className="quilField">
        <ReactQuill
          theme="snow"
          value={value}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          modules={modules}
          formats={formats}
        />
      </div>
    </div>
  )
}

export default QuilFiled
