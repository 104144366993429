import { createSlice } from '@reduxjs/toolkit'

const cardDefaultState = {
  cardType: '',
  published: false,
  public: false,
  type: 'card',
  avatar: '',
  // USER
  userId: '',
  username: '',
  fullname: '',
  userRole: '',
  // FRONT
  heading: '',
  subheading: '',
  frontImage: '',
  category: '',
  tags: [],
  mature: false,
  collection: '',
  userLevel: '',
  frequencyInFeeds: '',
  pointsAllocation: '',
  priceRange: '',
  eventLocation: {},
  customerType: '',
  userLoyalty: '',
  description: '',
  date: '',
  addToCalendarMaps: '',
  ribbonColor: '',
  pageLink: '',
  pollOptions: [
    // { option: '', percentage: 0 }
  ],
  pollAnswers: [
    // { user_id: '', answer_index: '' }
  ],
  // BACK
  backFields: [],
  doubleCardImage: '',
  slider: [
    // { id: '', image: ''}
  ],
  caption: '',
  byline: '',
  fullCardImage: { active: false, image: '' },
  activeSlide: 0,
  triggerSlideSwitch: -1,
  questions: [
    {
      question: '',
      answers: [
        // { id: new Date().getTime(), answer: '', correct: true },
      ],
      explanation: ''
    }
  ],
  tempImages: {},
  hasAnswers: false,
  link: ''
}
const initialState = {
  selectedCard: cardDefaultState,
  mapSelectedCard: {},
  savedLocationsMap: {},
  savedDate: {},
  requiredFields: {
    error: false,
    fields: {}
  },
  updatedCard: {},
  createdCard: {},
  cardUpdatedAt: 0,
  canvasImageLoaded: false
}

const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setSelectedCard: (state, action) => {
      if (!action.payload || !action.payload.cardType) {
        state.selectedCard = {
          ...cardDefaultState,
          questions: [
            {
              question: '',
              answers: [],
              explanation: ''
            }
          ]
        }
      } else {
        state.selectedCard = {
          ...state.selectedCard,
          ...action.payload
        }
      }
    },
    setRequiredFields: (state, action) => {
      state.requiredFields = action.payload
    },
    setDefaultCard: state => {
      state.selectedCard = cardDefaultState
    },
    setCreatedCard: (state, action) => {
      state.createdCard = action.payload
    },
    setUpdatedCardAt: (state, action) => {
      state.cardUpdatedAt = action.payload
    },
    canvasImageLoaded: (state, action) => {
      state.canvasImageLoaded = action.payload
    }
  }
})

export default cardSlice.reducer
export const {
  setSelectedCard,
  setRequiredFields,
  setDefaultCard,
  setCreatedCard,
  setUpdatedCardAt,
  canvasImageLoaded
} = cardSlice.actions
