import ImageViewer from 'components/imageViewer'
import VideoPreview from 'components/videoPreview'
import WidgetCalendar from 'components/widgetCalendar'
import WidgetInlinePack from 'components/widgetInlinePack'
import WidgetMap from 'components/widgetMap'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CARD_BACK_TYPES } from 'staticConstants'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import styles from './dndPreview.module.scss'
import { url } from 'environment'
import dataService from 'services/data.service'
import CardFlip from 'components/cardFlip'
import { toastService } from 'services/toastService'
import { openModal } from 'store/reducers/modal/modalSlice'
import LinkedImage from 'components/linkedImage'
import { setClosePreview } from 'store/reducers/previewModal/previewModalSlice'

const _getClickEventHref = event => {
  if (!event || !event.target) return
  let href
  if (event.target.href) href = event.target.href
  else {
    const computedStyle = window.getComputedStyle(event.target) // Get the computed style of the element

    const isItalic = computedStyle.fontStyle === 'italic' // Check if the font style is italic
    const isUnderline = computedStyle.textDecoration.includes('underline') // Check if the font style is underline
    const isBold = computedStyle.fontWeight === 'bold' || parseInt(computedStyle.fontWeight) >= 700 // Check if the font weight is bold (700 or more is considered bold)

    if (isItalic || isBold || isUnderline) {
      // If the element is either italic or bold
      const parentLink = event.target.closest('a') // Find the closest parent <a> element
      if (parentLink) {
        // If a parent <a> element is found
        href = parentLink.getAttribute('href') // Get the href attribute of the parent <a> element
      }
    }
  }
  return href
}

const _isCardInCardClick = event => {
  const href = _getClickEventHref(event)
  return href && href.includes(url) && href.includes('/card')
}

const DndPreview = ({ data, setIsInline, card }) => {
  const dispatch = useDispatch()
  const [openCard, setOpenCard] = useState({})
  const [isCardOpened, setIsCardOpened] = useState(false)
  const [isCardFlipped, setIsCardFlipped] = useState(false)
  const renderImage = (imageClass, image) => {
    const onClickHandler = () => {
      const fullImage = getMediaFromFileOrString(image, false)
      dispatch(
        openModal({
          content: <ImageViewer fullImage={fullImage} />,
          name: 'Image viewer modal',
          show: true,
          size: 'imgPreviewModal',
          overlayClassName: 'overlayImgPreview',
          onclose: () => closePreviewModal()
        })
      )
    }

    const closePreviewModal = () => {
      dispatch(setClosePreview())
    }

    return (
      <img
        className={imageClass}
        onClick={onClickHandler}
        src={getMediaFromFileOrString(image, true)}
        onError={e => {
          e.target.onerror = null
          e.target.src = getMediaFromFileOrString(image, false)
        }}
        alt=""
      ></img>
    )
  }

  const closeHandler = () => {
    setIsCardOpened(false)
    setIsInline && setIsInline({ opened: false, flipped: false })
  }
  const onFlipHandler = isFlipped => {
    setIsInline && setIsInline({ opened: true, flipped: isFlipped })
    setIsCardFlipped(isFlipped)
  }
  const handleClick = async event => {
    event.preventDefault()
    const href = _getClickEventHref(event)

    if (openCard && openCard._id) {
      setIsInline && setIsInline({ opened: true, flipped: false })
      setIsCardOpened(true)
    } else {
      const cardId = href.split('/').pop()
      const res = await dataService.run('data', 'card', 'get', { filter: { _id: cardId } })
      if (res && res._id) {
        setOpenCard(res)
        setIsInline && setIsInline({ opened: true, flipped: false })
        setIsCardOpened(true)
      } else {
        toastService('warning', 'This card does not exist.')
      }
    }
  }

  return (
    <>
      {data &&
        data.map((item, index) => {
          return (
            <div key={index}>
              {item.type === CARD_BACK_TYPES.heading && <h6> {item.content}</h6>}
              {item.type === CARD_BACK_TYPES.image && renderImage(styles.showImg, item.content)}
              {item.type === CARD_BACK_TYPES.video && item.content && (
                <VideoPreview video={getMediaFromFileOrString(item.content, false)} className={styles.borderRadius1} />
              )}
              {item.type === CARD_BACK_TYPES.text && (
                <p
                  style={{ position: 'relative', zIndex: 2 }}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                  onClick={event => (_isCardInCardClick(event) ? handleClick(event) : '')}
                />
              )}
              {item.type === CARD_BACK_TYPES.calendar && (
                <WidgetCalendar data={item.content} type="preview" card={card} />
              )}
              {item.type === CARD_BACK_TYPES.map && <WidgetMap data={item.content} card={card} type="preview" />}
              {item.content && item.type === CARD_BACK_TYPES.inlinePack && (
                <WidgetInlinePack
                  cardId={item.content._id}
                  preview
                  openable
                  inDouble={false}
                  setIsInline={setIsInline}
                />
              )}
              {item.type === CARD_BACK_TYPES.linkedImage && <LinkedImage data={item?.content} type="preview" />}
            </div>
          )
        })}
      <div className={`${isCardOpened ? styles.openCardConainer : ''}`}>
        <div className={`${isCardOpened ? styles.cardIsOpen : styles.cardIsClosed}`}>
          <CardFlip
            key={openCard._id}
            data={openCard}
            exitable={isCardOpened}
            onClose={closeHandler}
            onFlipTriggered={event => onFlipHandler(event)}
            customFlipLogic
            isFlippedCustom={isCardFlipped}
            setIsFlippedCustom={isFlipped => onFlipHandler(isFlipped)}
            flippable={isCardOpened}
            saveable={false}
          />
        </div>
      </div>
    </>
  )
}
export default DndPreview
