import React from 'react'

const IconComponentShare = ({ width = '26', height = '24', color = 'black' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 6.54688H5.51595V8.75325H4.20638C3.10181 8.75325 2.20638 9.64869 2.20638 10.7533V22.1979C2.20638 23.3025 3.10181 24.1979 4.20638 24.1979H15.651C16.7556 24.1979 17.651 23.3025 17.651 22.1979V10.7533C17.651 9.64868 16.7556 8.75325 15.651 8.75325H14.3415V6.54688H15.8574C18.0666 6.54688 19.8574 8.33774 19.8574 10.5469V22.4043C19.8574 24.6134 18.0666 26.4043 15.8574 26.4043H4C1.79086 26.4043 0 24.6134 0 22.4043V10.5469C0 8.33774 1.79086 6.54688 4 6.54688Z"
        fill={color}
      />
      <path d="M8.82552 16.5478V4.41276H6.61914L9.92871 0L13.2383 4.41276H11.0319V16.5478H8.82552Z" fill={color} />
    </svg>
  )
}
export default IconComponentShare
