import MediaPickerIcon from 'assets/icons/drag_drop_editor/MediaPickerIcon.png'
import MediaPickerIconWhite from 'assets/icons/drag_drop_editor/MediaPickerIconWhite.svg'
import iconCloseCropBlue from 'assets/icons/iconCloseBlue.svg'
import ImgResizer from 'components/imgResizer'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { toastService } from 'services/toastService'
import useWindowDimensions from 'utils/screenSize'
import styles from './mediaPicker.module.scss'
import { MAX_SIZE_FOR_IMAGE } from 'utils'

const MediaPicker = ({
  style,
  className,
  size,
  acceptedFileType = 'image/png, image/gif, image/jpeg',
  onImageChange,
  stateMedia,
  removable = true,
  whiteIcon = false,
  showCrop = false,
  maxSizeInMbForMediaFiles = MAX_SIZE_FOR_IMAGE,
  type = 'card',
  widthForCropDivInsideWrapper = '70%'
}) => {
  const [file, setFile] = useState()
  const { isMobile } = useWindowDimensions()
  const onImageCropHandler = canvas => {
    onImageChange && onImageChange(canvas)
  }
  const onSelectedFile = e => {
    const files = e.target.files
    if (!files || files.length === 0) {
      return
    }
    const file = files[0]
    const fileSizeInMbForGif = file.size / 1000 / 1000
    if (
      file.type === 'image/gif' ||
      file.type === 'image/png' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg'
    ) {
      if (fileSizeInMbForGif > 0 && fileSizeInMbForGif < maxSizeInMbForMediaFiles) {
        onImageChange(file)
        setFile(file)
      } else {
        toastService('error', 'File too large.')
      }
    } else {
      onImageChange(file)
      setFile(file)
    }
  }

  const clearState = () => {
    onImageChange && onImageChange('')
  }
  return (
    <div
      className={`${styles.mediaPicker} ${styles.prevFoto} ${styles[className]} ${styles[size]}`}
      style={{ ...style }}
    >
      {stateMedia && <img alt="" src={stateMedia} className={styles.previewImage} />}
      <input type="file" accept={acceptedFileType} onClick={e => (e.target.value = null)} onChange={onSelectedFile} />
      {!stateMedia && (
        <img
          alt="MediaPicker"
          src={whiteIcon ? MediaPickerIconWhite : MediaPickerIcon}
          className={styles.noImageIcon}
        />
      )}
      {stateMedia && removable && (
        <img className={`${styles.closeIcon}`} alt="CloseIcon" src={iconCloseCropBlue} onClick={clearState} />
      )}
      {showCrop && file && file.type !== 'image/gif' && !isMobile && (
        <div className={`${styles.imgResize} ${styles.isBussines}`}>
          <img className={styles.resizeClose} alt="Close icon" src={iconCloseCropBlue} onClick={() => setFile(null)} />
          <ImgResizer
            file={file}
            type={type}
            width={widthForCropDivInsideWrapper}
            height="25rem"
            cropH={0}
            cropW={0}
            onReady={onImageCropHandler}
            closeCrop={setFile}
            previewCrop
          />
        </div>
      )}
    </div>
  )
}

MediaPicker.propTypes = {
  style: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  acceptedFileType: PropTypes.object,
  onImageChange: PropTypes.func
}

export default MediaPicker
