import { createAsyncThunk } from '@reduxjs/toolkit'
import userService from 'services/user.service'

export const login = createAsyncThunk('auth/login', async params => {
  return await userService.login(params)
})
export const addAccount = createAsyncThunk('auth/addAccount', async params => {
  return await userService.login(params)
})
export const checkRegister = createAsyncThunk('auth/checkRegister', async params => {
  return await userService.checkRegister(params)
})
export const register = createAsyncThunk('auth/register', async params => {
  return await userService.register(params)
})
export const registerCoAdmin = createAsyncThunk('auth/registerCoAdmin', async params => {
  return await userService.registerCoAdmin(params)
})
export const sendConfirmationMail = createAsyncThunk('auth/sendConfirmationMail', async params => {
  return await userService.sendConfirmEmailLink(params)
})
export const confirmEmail = createAsyncThunk('auth/confirmEmail', async params => {
  return await userService.confirmEmail(params)
})
export const sendConfirmPersonalDetailsLink = createAsyncThunk('auth/sendConfirmPersonalDetailsLink', async params => {
  return await userService.sendConfirmPersonalDetailsLink(params)
})
export const sendConfirmPassLink = createAsyncThunk('auth/sendConfirmPassLink', async params => {
  return await userService.sendConfirmPassLink(params)
})
export const changePassword = createAsyncThunk('auth/changePassword', async params => {
  return await userService.changePassword(params)
})

export const sendConfirmDeactivationOrRestore = createAsyncThunk(
  'auth/sendConfirmDeactivationOrRestore',
  async params => {
    return await userService.sendDeactivateOrRestoreLink(params)
  }
)

export const sendForgotPasswordLink = createAsyncThunk('auth/sendForgotPasswordLink', async params => {
  return await userService.sendForgotPassword(params)
})
export const confirmForgotPasswordChange = createAsyncThunk('auth/confirmForgotPasswordChange', async params => {
  return await userService.confirmForgotPassword(params)
})

export const approveUser = createAsyncThunk('auth/approveUser', async params => {
  return await userService.approveUser(params)
})
export const sendRespondEmail = createAsyncThunk('auth/sendRespondEmail', async params => {
  return await userService.sendRespondEmail(params)
})
export const updateUser = createAsyncThunk('auth/updateUser', async params => {
  return await userService.updateUser(params)
})

export const activateOrDeactivateProfile = createAsyncThunk('auth/activateOrDeactivateProfile', async params => {
  return await userService.activateOrDeactivateProfile(params)
})

export const activateOrDeactivateProfileFromAdmin = createAsyncThunk(
  'auth/activateOrDeactivateProfileFromAdmin',
  async params => {
    return await userService.activateOrDeactivateProfileFromAdmin(params)
  }
)

export const sendMultipleInvites = createAsyncThunk('auth/sendMultipleInvites', async params => {
  return await userService.sendMultipleInvites(params)
})

export const sendNewsletter = createAsyncThunk('auth/sendNewsletter', async params => {
  return await userService.sendNewsletter(params)
})

export const sendConfirmChangeAccount = createAsyncThunk('auth/sendConfirmChangeAccount', async params => {
  return await userService.sendConfirmChangeAccount(params)
})
export const confirmChangeAccountType = createAsyncThunk('auth/confirmChangeAccountType', async params => {
  return await userService.confirmChangeAccountType(params)
})
export const switchApplicationLogin = createAsyncThunk('auth/switchApplicationLogin', async params => {
  return await userService.switchApplicationLogin(params)
})
export const checkTimestamp = createAsyncThunk('auth/checkTimestamp', async params => {
  return await userService.checkTimestamp(params)
})
export const changePersonalDetails = createAsyncThunk('auth/changePersonalDetails', async params => {
  return await userService.changePersonalDetails(params)
})
export const sendCancelSub = createAsyncThunk('auth/sendCancelSub', async params => {
  return await userService.sendCancelSub(params)
})
export const confirmCancelSub = createAsyncThunk('auth/confirmCancelSub', async params => {
  return await userService.confirmCancelSub(params)
})
