import React from 'react'
import CardReport from 'components/cardReport'
import moment from 'moment'
import { getAvatarImage } from 'utils/getAvatarImage'
import styles from './reportsList.module.scss'

const ReportsList = ({ reports = [], title, className }) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <h2>{title}</h2>
      <div className={styles.reportsContainer}>
        {reports?.length > 0 &&
          reports?.map(report => (
            <CardReport
              key={report.createdAt}
              avatar={getAvatarImage(report.fromId)}
              action={report.action}
              author={report.from}
              reason={report.reason}
              time={report.createdAt ? moment(report.createdAt).format('DD.MM.YYYY ddd HH:mm') : null}
            />
          ))}
      </div>
    </div>
  )
}

export default ReportsList
