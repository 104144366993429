import React, { useEffect } from 'react'
import Button from 'components/button'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { confirmCancelSub } from 'store/reducers/auth/thunk'
import { closeModal } from 'store/reducers/modal/modalSlice'
import styles from './cancelSub.module.scss'
import { localStorageService } from 'services/localStorage.service'
import { setUser } from 'store/reducers/auth/authSlice'
import { sessionStorageService } from 'services/sessionStorage.service'

const CancelSub = ({ data }) => {
  data = { ...data, email: JSON.parse(atob(data.email)), username: JSON.parse(atob(data.username)) }
  const history = useHistory()
  const dispatch = useDispatch()
  const user = localStorageService.get(`user_${data.username}`)

  useEffect(() => {
    if (user) {
      dispatch(setUser(user))
      sessionStorageService.set('username', user.username)
    }
  }, [user])

  const handleNo = () => {
    dispatch(closeModal())
    history.push('/home')
  }

  const handleYes = () => {
    dispatch(confirmCancelSub({ data }))
    dispatch(closeModal())
    history.push('/home')
  }

  return (
    <div className={styles.wrapper}>
      <h1>Are you sure that you want to cancel subscription?</h1>
      <div className={styles.buttons}>
        <Button label="Yes" size="fitContent" onClick={handleYes} />
        <Button label="No" variation="secondary" size="fitContent" onClick={handleNo} />
      </div>
    </div>
  )
}

export default CancelSub
