import Heading, { TABS } from 'components/Heading'
import Pagination from 'components/pagination'
import SingleApplicant from 'components/singleApplicant'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dataService from 'services/data.service'
import styles from './applicants.module.scss'

const Applicants = () => {
  const [show, setShow] = useState({ rows: [], total: 0 })
  const [activeTab, setActiveTab] = useState('applicants')
  const modal = useSelector(store => store.modal)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        let filter = {}
        if (activeTab === 'applicants') {
          filter = { role: 'premiumUser', approved: false, status: 'Pending' }
        } else if (activeTab === 'history') {
          filter = { status: { $in: ['Approved', 'Rejected'] }, type: 'user' }
        }
        const response = await dataService.run('profiles', 'user', 'page', {
          filter: filter,
          sort: { createdAt: -1 },
          pageNumber,
          pageSize: 20
        })
        if (response && response.rows && response.rows.length > 0) {
          setShow({ rows: response?.rows, total: response?.total })
        } else {
          setShow({ rows: [], total: 0 })
        }
      } catch (err) {
        console.warn('_e', err)
      }
    }
    fetchUser()
  }, [activeTab, modal, pageNumber])

  return (
    <div className={styles.ApplicantsWrapper}>
      <Heading title={'Applicants'} tabs={TABS.ApplicantsTabs} onTabChange={setActiveTab} />
      {activeTab && activeTab === 'applicants' && (
        <>
          <div className={styles.wrapperSingleApplicant}>
            <div className={styles.singleCell}>Date</div>
            <div className={styles.singleCell}>Status</div>
            <div className={styles.singleCell}>Name</div>
            <div className={styles.singleCell}>Type</div>
            <div className={styles.showButton}>Action</div>
          </div>
          <div className={styles.applicants}>
            {show &&
              show?.total > 0 &&
              show?.rows.map(one => {
                return <SingleApplicant props={one} key={one?._id} />
              })}
          </div>
          {show && show.total > 20 && <Pagination total={show?.total} onPageChange={setPageNumber} pageSize={20} />}
        </>
      )}

      {activeTab && activeTab === 'history' && (
        <>
          <div className={styles.wrapperSingleApplicant}>
            <div className={styles.singleCell}>Date</div>
            <div className={styles.singleCell}>Status</div>
            <div className={styles.singleCell}>Name</div>
            <div className={styles.singleCell}>Type</div>
            <div className={styles.showButton}>Action</div>
          </div>
          <div className={styles.applicants}>
            {show &&
              show?.total > 0 &&
              show?.rows.map(one => {
                return <SingleApplicant props={one} key={one?._id} history={true} />
              })}
          </div>
          {show && show.total > 20 && <Pagination total={show?.total} onPageChange={setPageNumber} />}
        </>
      )}
    </div>
  )
}

export default Applicants
