import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MediaPicker from 'components/mediaPicker'
import Card from 'components/card'
import CardHeader, { HeaderPosition } from '../../components/header'
import CardBody from 'components/card/components/body'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import CardWrapper from 'components/card/components/wrapper'
import styles from './cardStandardFrontEditable.module.scss'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import { setSelectedCard } from 'store/reducers/card/cardSlice'
import { useDispatch } from 'react-redux'

const CardStandardFrontEditable = ({ type = CARD_TYPES.Create, className, card, hideHeader = false }) => {
  const dispatch = useDispatch()
  const [previewImage, setPreviewImage] = useState('')

  const onImageChangeHandler = image => {
    dispatch(setSelectedCard({ ...card, frontImage: image }))
  }

  useEffect(() => {
    setPreviewImage(getMediaFromFileOrString(card.frontImage))
  }, [card.frontImage])
  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card className={className} type={type} size={CARD_SIZE.Large} userType={card?.userRole}>
        <CardBody type={type} size={CARD_SIZE.Large} userType={card?.userRole}>
          {!hideHeader && (
            <CardHeader
              type={type}
              size={CARD_SIZE.Large}
              position={HeaderPosition.Floating}
              heading={card.heading === '' ? 'Header' : card.heading}
              subheading={card.subheading === '' ? 'Sub Heading' : card.subheading}
              autoHeight={type === CARD_TYPES.DoubleSidedTrivia}
              userType={card?.userRole}
            />
          )}
          <MediaPicker className="cardCover" onImageChange={onImageChangeHandler} stateMedia={previewImage} showCrop />
        </CardBody>
      </Card>
    </CardWrapper>
  )
}
CardStandardFrontEditable.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onImageChange: PropTypes.func,
  heading: PropTypes.string,
  subheading: PropTypes.string
}
export default CardStandardFrontEditable
