import { awsUrl, awsUrlResize } from 'environment'

export const getMediaFromFileOrString = (image, resizeUrl = false) => {
  if (!image && image === '') return ''
  else if (image instanceof File) {
    return URL.createObjectURL(image)
  } else if (image instanceof Element) {
    return image.toDataURL()
  } else {
    return (resizeUrl ? awsUrlResize : awsUrl) + '/' + image
  }
}
