import axios from 'axios'
import { logoutSpecificUser } from 'utils/logout'
import { url } from '../environment'
import { localStorageService } from './localStorage.service'
import { toastService } from './toastService'
import { sessionStorageService } from './sessionStorage.service'
import { getLocalUser } from 'store/reducers/auth/authSlice'

axios.interceptors.response.use(
  response => {
    // Returns response body
    return response.data.data ? response.data.data : response.data
  },
  async error => {
    // Handle response error
    _handleError(error)
    return Promise.reject(error)
    // }
  }
)
// const refreshToken = async (err) => {
//     let response = await request('get', 'user/token/refreshToken');
//     if (response) {
//         sessionService.updateUser(response);
//         let cutUrl = getRoute(err.config.url, '/', 3);
//         let url = err.config.url.slice(cutUrl + 1);
//         let data = (err.config.data ? JSON.parse(err.config.data) : null)
//         return await request(err.config.method, url, data);
//     } else {
//         _handleError(err);
//     }
// }

// const getRoute = (string, subString, index) => {
//     return string.split(subString, index).join(subString).length;
// }
const request = async (method, apiUrl, body, headers, options) => {
  let requestHeaders, requestOptions, username, lastUser
  try {
    username = sessionStorageService.get('username')
    lastUser = localStorageService.get('lastUser')
    const apiToken = localStorageService.get(`token_${username ?? lastUser}`)
    const refreshToken = localStorageService.get(`refreshToken_${username ?? lastUser}`)

    requestHeaders = !headers ? {} : headers
    requestOptions = !options ? {} : options
    if (apiToken) requestHeaders['x-access-token'] = apiToken
    if (refreshToken) requestHeaders['x-refresh-access-token'] = refreshToken

    let response
    if (method === 'get' || method === 'delete')
      response = await axios[method](url + apiUrl, { headers: requestHeaders })
    else if (method === 'post' || method === 'put' || method === 'patch')
      response = await axios[method](url + apiUrl, body, { headers: requestHeaders, ...requestOptions })
    return response
  } catch (err) {
    const error = _handleError(err, true)
    if (error === 'expired') {
      const result = await axios.post(
        url + '/api/auth/refresh',
        { mode: 'refresh_token' },
        { headers: requestHeaders, ...requestOptions }
      )
      if (result && result.token && result.refreshToken) {
        localStorageService.set(`token_${username ?? lastUser}`, result.token)
        localStorageService.set(`refreshToken_${username ?? lastUser}`, result.refreshToken)
        return request(method, apiUrl, body, headers, options)
      }
    }
  }
}

const outsideRequest = async (method, url, body, headers) => {
  try {
    const requestHeaders = !headers ? {} : headers
    if (method === 'get' || method === 'delete') return axios[method](url, { headers: requestHeaders })
    else if (method === 'post' || method === 'put') return axios[method](url, body, { headers })
  } catch (err) {
    _handleError(err)
  }
}

const _handleError = (err, donShowMessage) => {
  if (err && err.response) {
    if (err.response.status === 403) {
      // expired access token
      return 'expired'
    } else if (err.response.status === 405) {
      // localStorageService.destroy()
      logoutSpecificUser(getLocalUser())
      window.location.replace('/login')
    }
    let errorText
    if (err.response.data && err.response.data.error && err.response.data.error.message) {
      errorText = err.response.data.error.message
    } else {
      errorText = err.response.statusText
    }
    if (
      err &&
      err.response &&
      err.response.data &&
      err.response.data.error &&
      err.response.data.error.data &&
      err.response.data.error.data.length > 0
    ) {
      localStorageService.set('errorMsg', err.response.data.error.data)
    }
    if (!donShowMessage && err.response?.status !== 404) {
      if (!err?.response?.config?.url?.includes('api/file/s3')) toastService('error', errorText)
    }
  }

  return err
}

export { request, outsideRequest }
