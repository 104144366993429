import Heading, { TABS } from 'components/Heading'
import Pagination from 'components/pagination'
import SingleApplicant from 'components/singleApplicant'
import Typography, { TYPOGRAPHY_VARIATION } from 'components/typography'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dataService from 'services/data.service'
import styles from './contactUs.module.scss'

const ContactUs = () => {
  const [contactsLi, setContactsLi] = useState({ rows: [], total: 0 })
  const flagRespond = useSelector(state => state.auth.sendRespondEmailStatus)
  const modal = useSelector(state => state.modal)
  const [activeTab, setActiveTab] = useState('contactUs')
  const [pageNumber, setPageNumber] = useState(1)

  const fetchContact = async () => {
    try {
      let filter = {}
      if (activeTab === 'contactUs') {
        filter = { status: { $ne: false } }
      } else {
        filter = { status: false }
      }
      const res = await dataService.run('data', 'contactUsQuestion', 'page', {
        filter: filter,
        pageNumber,
        pageSize: 20
      })
      setContactsLi({ rows: res.rows, total: res.total })
    } catch (error) {
      console.log('__log', error)
    }
  }

  useEffect(() => {
    fetchContact()
  }, [activeTab])

  useEffect(() => {
    if (flagRespond > 0 && modal.show === false) {
      fetchContact()
    }
  }, [modal, pageNumber])

  return (
    <div className={styles.contactUsWrapper}>
      <Heading title="Contact Us" tabs={TABS.ContactUsTabs} onTabChange={setActiveTab} />
      <div className={styles.header}>
        <span>Time</span>
        <span>Email</span>
        <span>Name</span>
        <span>Type</span>
        <span className={styles.details}>Details</span>
      </div>
      <div className={styles.forms}>
        {contactsLi &&
          contactsLi.rows &&
          contactsLi.rows.length > 0 &&
          contactsLi.rows.map((one, i) => {
            return <SingleApplicant props={one} key={i} flag={true} activeTab={activeTab} />
          })}
        {(!contactsLi?.rows || contactsLi?.rows?.length === 0) && (
          <Typography
            text="There are no messages"
            variation={TYPOGRAPHY_VARIATION.EmptyMessageText}
            className="marginY10"
          />
        )}
      </div>
      {contactsLi && contactsLi.total > 20 && (
        <Pagination total={contactsLi.total} onPageChange={setPageNumber} pageSize={20} />
      )}
    </div>
  )
}
export default ContactUs
