import Button from 'components/button'
import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardHeader, { HeaderPosition } from 'components/card/components/header'
import CardScrollableContent from 'components/card/components/scrollableContent'
import CardWrapper from 'components/card/components/wrapper'
import Loading from 'components/loading'
import MediaPicker from 'components/mediaPicker'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fileService from 'services/file.service'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import { canvasImageLoaded, setSelectedCard } from 'store/reducers/card/cardSlice'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import { getMediaWithFallbackFromFileOrString } from 'utils/getMediaWithFallbackFromFileOrString'
import styles from './cardEventFront.module.scss'

const CardEventFront = ({ className, card, editable = false, size = CARD_SIZE.Normal, forCanvas, disabled = true }) => {
  const dispatch = useDispatch()
  const options = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }
  const formatedDate = new Date(card.date).toLocaleDateString('en-GB', options)
  const store = useSelector(state => state)
  const [addedToCalendar, setAddedToCalendar] = useState(null)
  const [imgBase64, setImgBase64] = useState(false)
  useEffect(() => {
    if (store?.card?.savedDate) {
      setAddedToCalendar(card._id ? store.card.savedDate[card._id] || null : null)
    }
  }, [store?.card?.savedDate])

  useEffect(() => {
    const getImage = async () => {
      if (!card.frontImage) return dispatch(canvasImageLoaded(true))
      const res = await fileService.run('s3', 'getImgBase64', { key: card.frontImage })
      setImgBase64(res.body)
      dispatch(canvasImageLoaded(true))
    }
    if (forCanvas) getImage()
  }, [forCanvas, card.frontImage])

  const addToCalendarHandler = async () => {
    // No functionality on panel
    return true
  }

  const onImageChange = image => {
    dispatch(setSelectedCard({ ...card, frontImage: image }))
  }

  const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
  const openLink = link => {
    console.log(card, '___link')
    if (urlPattern.test(link?.trim())) {
      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        // Check the protocol of the target website
        fetch(link, { method: 'HEAD' })
          .then(response => {
            const targetProtocol = response.url.startsWith('https://') ? 'https://' : 'http://'
            window.open(`${targetProtocol}${link}`)
          })
          .catch(error => {
            console.error('Error checking protocol:', error)
          })
      } else {
        window.open(link)
      }
    } else {
      // Handle invalid URL (e.g., show an error message)
      console.error('Invalid URL:', link)
    }
  }

  return (
    <CardWrapper className={`${className} ${styles[className]} ${!editable && styles.previewTab}`}>
      <Card type={CARD_TYPES.Event} size={size}>
        <CardBody type={CARD_TYPES.Event} size={size} isMature={card?.mature && !editable}>
          <CardHeader
            type={CARD_TYPES.Event}
            size={size}
            position={HeaderPosition.Floating}
            heading={card.heading === '' ? 'Header' : card.heading}
            subheading={card.byline === '' ? 'invite from @UserName' : card.byline}
          />
          {editable && (
            <MediaPicker
              className="halfCard"
              stateMedia={getMediaFromFileOrString(card.frontImage)}
              onImageChange={onImageChange}
              showCrop
            />
          )}

          {!editable && card.frontImage !== '' && !forCanvas && (
            <img
              className={styles.image}
              alt=""
              style={{
                backgroundImage: getMediaWithFallbackFromFileOrString(card.frontImage)
              }}
            />
          )}
          {forCanvas &&
            (imgBase64 ? (
              <img
                className={styles.image}
                alt="event"
                src={imgBase64}
                style={{
                  width: '100%',
                  objectFit: 'cover'
                }}
              />
            ) : (
              card.frontImage && (
                <div className={styles.loader}>
                  <Loading />
                </div>
              )
            ))}

          {!editable && card.frontImage === '' && <div className={styles.emptyDiv}></div>}
          <CardScrollableContent className={`${styles.scrollable} ${card.date ? styles.withFooterButton : ''}`}>
            <span key="desc" className={styles.description}>
              {card.description}
            </span>
            <span key="location" className={styles.location}>
              {card.eventLocation?.address}
            </span>
            <span key="timeAndDate" className={styles.timeAndDate}>
              {card.date && formatedDate}
            </span>
            {!editable && card && card.link && (
              <div className={`${styles.captionContainer} ${editable ? styles.bottomBorder : ''}`}>
                <Button size="xs" className={styles.openLinkButton} onClick={() => openLink(card.link)}>
                  Open Link
                </Button>
              </div>
            )}
          </CardScrollableContent>
          {addedToCalendar ? (
            <Button
              className={styles.footerButton}
              label="Added to your calendar"
              variation="primary"
              size="xs"
              disabled
            />
          ) : card.date ? (
            <Button
              className={styles.footerButton}
              label="Add to your calendar"
              variation="primary"
              size="xs"
              onClick={addToCalendarHandler}
              disabled={editable || disabled}
            />
          ) : (
            <Button className={styles.footerButton} label="Date not chosen" variation="primary" size="xs" disabled />
          )}
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CardEventFront
