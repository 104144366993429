import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  que: {}
}

const queSlice = createSlice({
  name: 'que',
  initialState,
  reducers: {
    setQue: (state, action) => {
      state.que = action.payload
    }
  }
})

export default queSlice.reducer
export const { setQue } = queSlice.actions
