import Button from 'components/button'
import CardFront from 'components/cardFront'
import ReportsList from 'components/reportsList'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { setUpdatedCardAt } from 'store/reducers/card/cardSlice'
import { closeModal } from 'store/reducers/modal/modalSlice'
import { closeModal as closeYesNoModal, openModal } from 'store/reducers/yesNoModal/yesNoModalSlice'
import styles from './adminEdit.module.scss'
import Pack from 'components/pack'
import { firstLetterUpper } from 'utils/helpers'
import { setUpdatedPackAt } from 'store/reducers/pack/packSlice'
import { USER_TYPE_VALUE } from 'staticConstants'
import { defaultDeletedUserBase64 } from 'components/icons/defaultDeletedUserImage'
import fileService from 'services/file.service'

const AdminEdit = ({ item = {}, numberOfFlags = 0, type = 'card', showItemReports = true }) => {
  const dispatch = useDispatch()
  const { user } = useSelector(store => store?.auth)
  const [itemUser, setItemUser] = useState({})
  const [itemDisabled, setItemDisabled] = useState(false)
  const [accountDeleted, setAccountDeleted] = useState(false)
  const [accountDisabled, setAccountDisabled] = useState(false)
  const [itemDeleted, setItemDeleted] = useState(false)
  const [itemReports, setItemReports] = useState([])

  const handelUpdateItem = async (status, reportReason) => {
    if (status) {
      let itemResponse = {}
      if (status === 'deleted') {
        if (type === 'card') {
          itemResponse = await dataService.run('data', 'card', 'delete', { ...item, reportReason })
        } else {
          itemResponse = await dataService.run('data', 'pack', 'deletePack', { packId: item?._id, adminDelete: true, reportReason })
        }
      } else {
        const res = await dataService.run('data', type, 'adminItemDisable', {
          itemId: item?._id,
          status,
          reportReason
        })
        if (res?.[type]?._id) itemResponse = res?.[type]
      }

      if (itemResponse?._id) {
        setItemDisabled(true)
        if (status === 'deleted') setItemDeleted(true)
        if (type === 'card') {
          dispatch(setUpdatedCardAt(moment().valueOf()))
        } else {
          dispatch(setUpdatedPackAt(moment().valueOf()))
        }
        toastService(
          'success',
          `${firstLetterUpper(type)} "${item.heading}" successfully ${status && status !== '' ? status : 'updated'}.`
        )
        dispatch(closeModal())
      }
      dispatch(closeYesNoModal())
    }
  }

  const openYesNoModal = status => {
    dispatch(
      openModal({
        title: `Are you sure you want to ${status?.slice(0, -1) || 'delete'} this ${type}?`,
        content: '',
        onsubmit: reason => handelUpdateItem(status, reason),
        additionalInputPlaceholder: 'Why?',
        yesTitle: 'Send',
        noTitle: 'Cancel'
      })
    )
  }

  const openYesNoModalUser = status => {
    dispatch(
      openModal({
        title: `Are you sure you want to ${status?.slice(0, -1) || 'delete'} this user?`,
        additionalInputPlaceholder: '',
        onsubmit: () => handleUpdateUser(status),
        yesTitle: 'Send',
        noTitle: 'Cancel'
      })
    )
  }

  const isUserAuthorizedForManagingItemAuthor = () => {
    if (user?.role === 'admin') return true
    if (
      Number(user?.subType) === USER_TYPE_VALUE.editor &&
      ![USER_TYPE_VALUE.admin, USER_TYPE_VALUE.editor, USER_TYPE_VALUE.contributor].includes(Number(item?.userRole))
    ) {
      return true
    }
    return false
  }

  const isUserAuthorizedForManagingItem = () => {
    if (user?.role === 'admin') return true
    if (
      Number(user?.subType) === USER_TYPE_VALUE.editor &&
      ![USER_TYPE_VALUE.admin, USER_TYPE_VALUE.editor].includes(Number(item?.userRole))
    ) {
      return true
    }
    return false
  }

  const handleUpdateUser = async status => {
    if (status === 'deleted') {
      await fileService.run(
        's3',
        'upload',
        { data: defaultDeletedUserBase64, name: `${itemUser?._id}.jpg`, type: 'base64' },
        null,
        `${itemUser?._id}.jpg`
      )
    }
    const res = await dataService.run(
      'profile',
      'user',
      status === 'deleted' ? 'deleteAccount' : status === 'enabled' ? 'enableAccount' : 'disableAccount',
      {
        userId: itemUser?._id
      }
    )
    if (Object.keys(res)?.length) {
      if (status === 'enabled') {
        setAccountDisabled(true)
      } else {
        setAccountDisabled(true)
      }
      if (status === 'deleted') {
        setAccountDeleted(true)
      }
      dispatch(setUpdatedCardAt(moment().valueOf()))
      toastService(
        'success',
        `User "${itemUser.username}" successfully ${status && status !== '' ? status : 'updated'}.`
      )
    }
    dispatch(closeYesNoModal())
  }

  const openYesNoModalClearFlags = () => {
    dispatch(
      openModal({
        title: 'Are you sure you want to clear the flags?',
        additionalInputPlaceholder: '',
        onsubmit: () => handleClearFlags(),
        yesTitle: 'Yes',
        noTitle: 'Cancel'
      })
    )
  }

  const handleClearFlags = async () => {
    const res = await dataService.run(
      'data',
      'card',
      'clearFlags',
      {
        cardId: item?._id,
        reason: 'Admin cleared account'
      }
    )
    if (res?.card?._id) {
      dispatch(setUpdatedCardAt(moment().valueOf()))
      toastService('success', 'Flags cleared successfully.')
      dispatch(closeModal())
    }
    dispatch(closeYesNoModal())
  }

  useEffect(() => {
    const fetchUser = async () => {
      const res = await dataService.run('profiles', 'user', 'get', { filter: { _id: item.userId } })
      setItemUser(res)
      if (res?._id) {
        if (res?.disabled) setAccountDisabled(true)
        if (res?.deleted) setAccountDeleted(true)
      } else {
        setAccountDisabled(true)
        setAccountDeleted(true)
      }
    }
    const fetchItemReports = async () => {
      const res = await dataService.run('reports', 'cardReport', 'all', {
        filter: { cardId: item?._id.toString() }
      })
      if (res?.length > 0) {
        setItemReports(res)
      }
    }
    if (item?._id) {
      fetchItemReports()
      fetchUser()
    }
    if (item?.disabled) setItemDisabled(true)
  }, [item?._id])

  return (
    <div className={styles.wrapper}>
      <div className={styles.itemEdit}>
        <div className={styles.header}>
          <h2>Edit {firstLetterUpper(type)}</h2>
          <h3>
            This {type} has raised {numberOfFlags} Flags in the community, what would you like to do?
          </h3>
        </div>
        <div className={styles.content}>
          <div className={styles.leftSide}>{type === 'card' ? <CardFront card={item} /> : <Pack pack={item} />}</div>
          <div className={styles.rightSide}>
            <div className={styles.buttonAndDescription}>
              <Button label="Clear Flags" disabled={!item?.reportedBy} variation={'secondary'} onClick={() => openYesNoModalClearFlags()} />
              <span>This will remove any flags from this {type} if you deem that it is following guidelines.</span>
            </div>
            <div className={styles.buttonAndDescription}>
              <Button
                label={itemDisabled ? `Enable ${firstLetterUpper(type)}` : `Disable ${firstLetterUpper(type)}`}
                variation={'secondary'}
                disabled={item?.deletedAt || itemDeleted || !isUserAuthorizedForManagingItem()}
                onClick={() => openYesNoModal(itemDisabled ? 'enabled' : 'disabled')}
              />
              {itemDisabled ? (
                <span>
                  Enabling the {type} will make it visible and accessible to other users, allowing them to view and
                  interact with the content, making it useful for sharing information and promoting a product or
                  service.
                </span>
              ) : (
                <span>
                  The {type} will not be visible to other users, but creator can still see it and edit to follow
                  guidelines.
                </span>
              )}
            </div>
            <div className={styles.buttonAndDescription}>
              <Button
                label={itemDeleted ? `${firstLetterUpper(type)} Deleted` : `Delete ${firstLetterUpper(type)}`}
                variation={'secondary'}
                disabled={item?.deletedAt || itemDeleted || !isUserAuthorizedForManagingItem()}
                onClick={() => openYesNoModal(itemDeleted ? null : 'deleted')}
              />
              <span>This will remove the {type} from Wonder Shuffle and will not be recoverable.</span>
            </div>
            <div className={styles.buttonAndDescription}>
              <Button
                label={accountDisabled ? 'Enable Account' : 'Disable Account'}
                variation={'secondary'}
                disabled={accountDeleted || !isUserAuthorizedForManagingItemAuthor()}
                onClick={() => openYesNoModalUser(accountDisabled ? 'enabled' : 'disabled')}
              />
              {accountDisabled ? (
                <span>This action will enable the account and send an email to the user’s account.</span>
              ) : (
                <span>This action will disable the account for 30 days and send an email to the user’s account.</span>
              )}
            </div>

            <div className={styles.buttonAndDescription}>
              <Button
                label={accountDeleted ? 'Account Deleted' : 'Delete Account'}
                variation={'secondary'}
                disabled={accountDeleted || !isUserAuthorizedForManagingItemAuthor()}
                onClick={() => openYesNoModalUser(accountDeleted ? null : 'deleted')}
              />
              <span>
                This will permanently delete the account and the created cards and packs. The user will receive an email
                informing them of this decision and no longer be able to access the site.
              </span>
            </div>
          </div>
        </div>
      </div>
      {showItemReports && <ReportsList reports={itemReports} title={`${firstLetterUpper(type)} Reports & Notes:`} />}
    </div>
  )
}

export default AdminEdit
