import { useEffect, useState } from 'react'
import styles from './table.module.scss'

const Table = ({ props }) => {
  const [cardArray, setCardArray] = useState([])
  useEffect(() => {
    if (props && props.returnArray && props.allIds) {
      const newCardArr = []
      props.allIds.map(id => {
        newCardArr.push(props.returnArray[id])
      })

      const sorted = newCardArr.sort((a, b) => {
        return b.view - a.view
      })
      setCardArray(sorted)
    } else {
      setCardArray([])
    }
  }, [props])

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.header}>Top Performers</div>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <span>Name</span>
          <span>Views</span>
          <span>Likes</span>
          <span>Shares</span>
          <span>Saves</span>
        </div>
        <div className={styles.cards}>
          {cardArray &&
            cardArray.length > 0 &&
            cardArray.map((one, index) => {
              return (
                <div key={`key-${one.cardName}-${index}`}>
                  <div className={styles.contentHeader}>
                    <div style={{ paddingLeft: 0 }}>{one.cardName}</div>
                    <div>{one.view}</div>
                    <div>{one.like}</div>
                    <div style={{ paddingLeft: '1.5rem' }}>{one.share}</div>
                    <div style={{ paddingLeft: '1.5rem' }}>{one.save}</div>
                  </div>
                  <hr key={`hr-${one.cardName}-${index}`} />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default Table
