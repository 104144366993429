import { USER_TYPE_VALUE } from 'staticConstants'

const allowedFieldsPerUser = {
  linkedImage: [USER_TYPE_VALUE.admin, USER_TYPE_VALUE.media, USER_TYPE_VALUE.premiumAdvertiser]
}

const isAllowedForCurrentUser = (field, userType) => {
  if (!field || !userType) return false

  switch (field) {
    case 'linkedImage':
      return allowedFieldsPerUser[field]?.includes(userType)
    default:
      return false
  }
}

export default isAllowedForCurrentUser
