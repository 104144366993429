import ChartFilter from 'components/chartFilter'
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import styles from './chart.module.scss'

export const Chart = ({
  data = [],
  widthSize = 'small',
  heightPx = 300,
  dataKey = '',
  dataKey2 = '',
  dataKey3 = '',
  titleName = '',
  titleNumber = '',
  isMulti = false,
  isThird = false,
  legendHeader = false,
  bottomLegend = false,
  topFilter = false,
  y = '',
  title = true,
  className
}) => {
  const CustomTooltip = ({ active, payload }) => {
    let ret = ''
    if (payload && payload.length > 0) {
      payload.map(one => {
        ret += one.name + ' : ' + one.value + ' '
      })
    }
    if (active) {
      return (
        <div className={styles.customTooltip}>
          <p>{payload && payload[0] && payload[0].payload.date}</p>
          <p className={styles.label}>{ret}</p>
        </div>
      )
    }

    return null
  }

  const replaceText = text => {
    if (text.includes('_')) {
      return text.replace('_', ' ')
    }
    return text
  }

  //   colors = ['#D457EA', '#FF5C00', '#00AEE5']

  return (
    <div className={`${styles[widthSize]} ${className}`}>
      {title && (titleName !== '' || titleNumber !== '') && (
        <div className={styles.chartTitle}>
          <span>{titleName}</span>
          <span>{titleNumber}</span>
        </div>
      )}
      {legendHeader && (
        <div className={styles.legendHeader}>
          {dataKey !== '' && (
            <div className={styles.single}>
              <div className={styles.singleBox} style={{ background: '#00AEE5' }}></div>
              <span>{dataKey}</span>
            </div>
          )}
          {isMulti && dataKey2 !== '' && (
            <div className={styles.single}>
              <div className={styles.singleBox} style={{ background: '#D457EA' }}></div>
              <span>{dataKey2}</span>
            </div>
          )}
          {isThird && dataKey3 !== '' && (
            <div className={styles.single}>
              <div className={styles.singleBox} style={{ background: '#FF5C00' }}></div>
              <span>{dataKey3}</span>
            </div>
          )}
        </div>
      )}
      {topFilter && <ChartFilter />}

      <div className={styles.chart} id="divToPrint">
        <ResponsiveContainer width="100%" height={heightPx}>
          <AreaChart data={data}>
            <defs>
              <linearGradient id="color1" x1={'0'} y1="0" x2={'0'} y2="1">
                <stop offset="0%" stopColor="#00AEE5" stopOpacity={1} />
                <stop offset="100%" stopColor="#00AEE5" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="color2" x1={'0'} y1="0" x2={'0'} y2="1">
                <stop offset="0%" stopColor="#D457EA" stopOpacity={1} />
                <stop offset="100%" stopColor="#D457EA" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="color3" x1={'0'} y1="0" x2={'0'} y2="1">
                <stop offset="0%" stopColor="#FF5C00" stopOpacity={1} />
                <stop offset="100%" stopColor="#FF5C00" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area alt="second" dataKey={dataKey} stroke="#00AEE5" fill="url(#color1)" />
            {isMulti && <Area alt="second" dataKey={dataKey2} stroke="#D457EA" fill="url(#color2)" />}
            {isThird && <Area alt="second" dataKey={dataKey3} stroke="#FF5C00" fill="url(#color3)" />}
            <XAxis dataKey="dateBottom" axisLine={false} />
            <YAxis dataKey={y === '' ? dataKey : y} axisLine={false} tickLine={false} />
            <Tooltip content={CustomTooltip} />
            <CartesianGrid opacity={0.7} vertical={false} />
          </AreaChart>
        </ResponsiveContainer>
        {bottomLegend && (
          <div className={styles.legendHeader}>
            {dataKey !== '' && (
              <div className={styles.single}>
                <div className={styles.singleBox} style={{ background: '#00AEE5' }}></div>
                <span>{replaceText(dataKey)}</span>
              </div>
            )}
            {isMulti && dataKey2 !== '' && (
              <div className={styles.single}>
                <div className={styles.singleBox} style={{ background: '#D457EA' }}></div>
                <span>{replaceText(dataKey2)}</span>
              </div>
            )}
            {isThird && dataKey3 !== '' && (
              <div className={styles.single}>
                <div className={styles.singleBox} style={{ background: '#FF5C00' }}></div>
                <span>{replaceText(dataKey3)}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Chart
