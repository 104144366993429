import React from 'react'
import styles from '../questionSlider.module.scss'

const SliderAnswer = ({ answer, onClick, getAnswerStyle }) => {
  return (
    <div
      key={'answer' + answer.id}
      className={`${styles.answer} ${styles.clickable} ${getAnswerStyle(answer)}`}
      onClick={() => onClick(answer)}
    >
      <span>{answer.answer}</span>
    </div>
  )
}
export default SliderAnswer
