import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardWrapper from 'components/card/components/wrapper'
import React from 'react'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import styles from './firstFront.module.scss'
import inlineImg from 'assets/images/InlineEvent.svg'
import Button from 'components/button'
import CardCreation from 'components/cardCreation'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from 'store/reducers/modal/modalSlice'
import Login from 'pages/auth/login'

export const FirstFront = ({ className, size = CARD_SIZE.Normal }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const creationCardModal = () => {
    if (user && user._id) {
      dispatch(
        openModal({ content: <CardCreation id={null} />, name: 'Card creation modal', size: 'max1100', show: true })
      )
    } else {
      dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
    }
  }
  return (
    <CardWrapper className={styles.cardWrapper}>
      <Card className={`${styles.wrapper} ${styles[className]} ${className}`} size={size} type={CARD_TYPES.First}>
        <CardBody size={size} type={CARD_TYPES.First}>
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <p>LET’S CREATE YOUR FIRST CARD!</p>
            </div>
            <div className={styles.spanContent}>
              <span>
                Got somewhere cool to share or a story to tell? Build your first card to share with the community or
                contacts. Get started below!
              </span>
            </div>
            <div className={styles.position}>
              <img src={inlineImg} alt="Img" />
              <Button
                variation="primary"
                label="Create a card"
                className={styles.btnCard}
                onClick={creationCardModal}
                size="xs"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default FirstFront
