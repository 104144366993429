import Card from 'components/card'
import CardBody from 'components/card/components/body'
import CardHeader from 'components/card/components/header'
import CardWrapper from 'components/card/components/wrapper'
import React from 'react'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import { getMediaWithFallbackFromFileOrString } from 'utils/getMediaWithFallbackFromFileOrString'
import styles from './cardStandardFront.module.scss'

export const CardStandardFront = ({ className, card, cardClass, size = CARD_SIZE.Normal, forCanvas }) => {
  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card
        className={cardClass}
        size={size}
        type={card.cardType && card.cardType !== '' ? card.cardType : CARD_TYPES.Standard}
        userType={card?.userRole}
        staticForNewsletter={forCanvas}
      >
        <CardBody
          imgCanvasKey={forCanvas ? card.frontImage || 'noImage' : false}
          style={{
            backgroundImage: getMediaWithFallbackFromFileOrString(card.frontImage)
          }}
          size={size}
          userType={card?.userRole}
          type={card?.cardType}
          isMature={card?.mature}
        >
          {(card.heading || card.subheading) && card?.cardType !== 'blank' && (
            <CardHeader
              heading={card.heading}
              subheading={card.subheading}
              type={card.cardType && card.cardType !== '' ? card.cardType : CARD_TYPES.Standard}
              size={size}
              autoHeight={card.cardType && card.cardType === CARD_TYPES.DoubleSidedTrivia}
              userType={card?.userRole}
              staticForNewsletter={forCanvas}
            />
          )}
        </CardBody>
      </Card>
    </CardWrapper>
  )
}

export default CardStandardFront
