import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
import Select from 'react-select'
import './select.scss'

const Selector = ({
  selectClassname = 'selectPrimary',
  selectClass,
  styles,
  options,
  label,
  selectedOption = '',
  handleChangeSelect,
  placeholder,
  multiselect = false,
  disabled = false,
  error = false,
  errorMsg,
  customLabel,
  gridView = false,
  closeMenuOnSelect = true
}) => {
  // const { isBussines } = useSelector(store => store?.navbar)
  const [isBussines] = useState(true)
  // eslint-disable-next-line
  const selectorClass = selectClassname.split("'").join('')
  return (
    <div
      className={`${selectClassname} ${selectClass} ${gridView ? 'gridView-menuList' : ''} ${disabled && 'isDisabled'}`}
    >
      {label && (
        <label className={selectClassname}>{/* {label} {error && <span className="selectErr"> </span>} */}</label>
      )}
      {/* {!label && error && <span className="selectErr"> {errorMsg}</span>} */}
      <Select
        styles={styles}
        isMulti={multiselect}
        className={`${selectorClass} ${isBussines && 'isBussines'}`}
        classNamePrefix={selectorClass}
        value={selectedOption}
        onChange={handleChangeSelect}
        selected={selectedOption}
        options={options}
        getOptionLabel={option => option[customLabel || 'label']}
        placeholder={placeholder}
        isDisabled={disabled}
        closeMenuOnSelect={closeMenuOnSelect}
      />
      {error && <span className="errorMsg">{errorMsg}</span>}
    </div>
  )
}

export default Selector
