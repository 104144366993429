import React, { useCallback, useEffect, useState } from 'react'
import styles from './topic.module.scss'
import close from 'assets/images/icons/10/close.svg'
import iconClosePink from 'assets/icons/iconClosePink.svg'

const Topic = ({ id, onClick, label, onClose, className = '', showHash, props, showPinkClose }) => {
  const [locLabel, setLabel] = useState()
  const isTopicClicable = () => {
    return onClick ? 'clickable' : ' '
  }

  const handleLabel = useCallback(() => {
    if (typeof label === 'string' || label instanceof String) {
      setLabel(label)
      return
    }
    setLabel(label.name)
  }, [label])

  useEffect(() => {
    handleLabel()
  }, [handleLabel, label])

  return (
    <div
      id={id}
      className={`${styles.topicContainer} ${styles[isTopicClicable()]} ${styles[className]} ${className}`}
      {...props}
    >
      <span onClick={onClick} className={styles.label}>
        {showHash === true ? (locLabel?.indexOf('#') ? `#${locLabel}` : locLabel) : locLabel}
      </span>
      {onClose && (
        <div onClick={() => onClose(id) ?? null} className={styles.closeIcon}>
          {showPinkClose ? <img src={iconClosePink} alt="X" /> : <img src={close} alt="X" />}
        </div>
      )}
    </div>
  )
}

export default Topic
