import React, { forwardRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import Input from 'components/input'
import './datePicker.scss'

const DatePickerComponent = ({
  customInput,
  selected,
  onDatePickerChange,
  minDate,
  maxDate,
  icon,
  onInputChange,
  label,
  name,
  placeholder,
  showTimeSelect = false,
  error,
  errorMsg,
  timeIntervals = 10,
  open = false,
  datePickerNoInput = false,
  dateFormat
}) => {
  const [isOpen, setIsOpen] = useState(open)
  const DatePickerInput = forwardRef((props, ref) => {
    const { onClick, ...remaining } = props
    return (
      <Input
        label={label}
        name={name}
        onChange={val => onInputChange(val, true)}
        iconRight={icon}
        iconRightOnClick={onClick}
        ref={ref}
        error={error}
        errorMsg={errorMsg}
        containerStyle={isOpen ? { border: '0.063rem solid #7B61FF' } : {}}
        {...remaining}
      />
    )
  })
  return (
    <>
      {datePickerNoInput ? (
        <DatePicker
          className="hide-input"
          wrapperClassName="wrapper-hide-input"
          selected={selected}
          onChange={onDatePickerChange}
          strictParsing={true}
          placeholderText={placeholder}
          dateFormat={dateFormat ?? 'MM/dd/yyyy'}
          maxDate={maxDate || new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
          minDate={minDate || new Date('January 01, 1920')}
          portalId="root-portal"
          showTimeSelect={showTimeSelect}
          timeIntervals={timeIntervals}
          open={open}
          showYearDropdown
          dropdownMode="select"
        />
      ) : (
        <DatePicker
          selected={selected}
          onChange={onDatePickerChange}
          strictParsing={true}
          onCalendarOpen={() => setIsOpen(true)}
          onCalendarClose={() => setIsOpen(false)}
          placeholderText={placeholder}
          dateFormat={dateFormat ?? 'dd MMM yyyy'}
          customInput={customInput ? <DatePickerInput /> : null}
          showTimeSelect={showTimeSelect}
          maxDate={maxDate || new Date()}
          minDate={minDate || new Date('January 01, 1920')}
          portalId="root-portal"
        />
      )}
    </>
  )
}

export default DatePickerComponent
