import InvoiceDoc from 'components/invoicePdf'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from 'store/reducers/modal/modalSlice'
import styles from './singlePayment.module.scss'
import Button from 'components/button'

const PaymentSingle = ({ props }) => {
  const { createdAt = 0, service = {} } = props

  const { userTypeLabels } = useSelector(store => store?.config)

  const dispatch = useDispatch()

  const viewPDF = () => {
    dispatch(
      openModal({
        show: true,
        content: <InvoiceDoc invoiceData={props} amount={props?.amount} />,
        name: 'PDF modal',
        size: 'pdfPreviewModal'
      })
    )
  }
  console.log(service, userTypeLabels?.[service?.type])

  return (
    <div className={styles.row}>
      <div className={`${styles.item}`}> {createdAt !== 0 ? moment(createdAt).format('DD/MM/YYYY') : '/'}</div>
      <div className={`${styles.item} `}>
        {' '}
        {service && service.type && `Subscription for ${userTypeLabels?.[service?.type] || 'USER'}`}{' '}
      </div>
      <div className={`${styles.item} `}>{props?.status}</div>
      <div className={`${styles.item} `}>${props?.amount}</div>
      <div className={`${styles.item} `}>
        {' '}
        <Button size="xs" label="View PDF" onClick={viewPDF} />
      </div>
    </div>
  )
}

export default PaymentSingle
