import CloudCorner from 'components/icons/cloudCorner'
import UserAvatar from 'components/userAvatar'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ReactTooltip from 'react-tooltip'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import { closeModal } from 'store/reducers/modal/modalSlice'
// import { modalActions } from 'store/actions/modal.actions'
// import { previewActions } from 'store/actions/preview.actions'
import getCardStyle from 'utils/getCardStyle'
import CardScrollableContent from '../scrollableContent'
import styles from './cardHeader.module.scss'

export const HeaderPosition = {
  Block: 'block',
  Floating: 'floating'
}
export const HeaderSize = {
  Normal: 'normal',
  Large: 'large',
  LargeWithLogo: 'large-with-logo'
}
const CardHeader = ({
  className,
  logoClass,
  headingClass,
  subheadingClass,
  position = 'block',
  type = CARD_TYPES.Create,
  size = CARD_SIZE.Normal,
  heading,
  subheading,
  autoHeight = false,
  logo,
  username = '',
  userType = '',
  showElipsisForUsername = false,
  staticForNewsletter = false
}) => {
  const [randomHeading] = useState(String(Math.random()))
  const [randomSubheading] = useState(String(Math.random()))
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <div
      className={`${styles.container} ${staticForNewsletter && styles.staticBoxShadowForNewsletter} ${
        styles['position-' + position]
      } ${styles['color-' + type]} ${styles['size-' + size]} ${className} ${styles[className]} ${
        autoHeight ? styles.autoHeight : ''
      } ${styles[getCardStyle('color', type, userType)]}`}
      onClick={() => {
        if (username.length > 0 && logo) {
          history.push(`/user/${username}`)
          dispatch(closeModal())
          // dispatch(closePreview())
        }
      }}
      style={username.length > 0 ? { cursor: 'pointer' } : {}}
    >
      <CardScrollableContent type={type} className={styles.scrollableContent}>
        <div className={styles.row}>
          {logo && (
            <div style={{ cursor: 'pointer', borderRadius: '50%' }}>
              <UserAvatar width="3.438" height="3.438" className={logoClass} imageUrl={logo} />
            </div>
          )}
          <div className={styles.headingContainer}>
            <span
              data-tip={heading}
              data-for={randomHeading}
              className={`${styles.heading} ${headingClass} ${showElipsisForUsername && styles.showElipsisForUsername}`}
            >
              {heading}
              {heading?.length < 45 || type === CARD_TYPES.Poll || type === CARD_TYPES.OneSidedTrivia ? null : (
                <ReactTooltip
                  arrowColor="transparent"
                  id={randomHeading}
                  className={styles.dialog}
                  place={'bottom'}
                  effect={'solid'}
                />
              )}
            </span>
            <span
              data-tip={subheading}
              data-for={randomSubheading}
              className={`${styles.subheading} ${subheadingClass}`}
            >
              {subheading}
              {subheading?.length < 65 || type === CARD_TYPES.Poll || type === CARD_TYPES.OneSidedTrivia ? null : (
                <ReactTooltip
                  arrowColor="transparent"
                  id={randomSubheading}
                  className={styles.dialog}
                  place={'bottom'}
                  effect={'solid'}
                />
              )}
            </span>
          </div>
        </div>
      </CardScrollableContent>
      <CloudCorner className={styles.cloudCorner} />
    </div>
  )
}
CardHeader.propTypes = {
  className: PropTypes.string,
  headingClass: PropTypes.string,
  subheadingClass: PropTypes.string,
  type: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string
}
export default CardHeader
