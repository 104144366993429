export const sortDataByIds = (data, sortOrder) => {
  const idToDataMap = new Map()

  data.forEach(item => {
    idToDataMap.set(item._id, item)
  })

  const sortedData = sortOrder.map(id => idToDataMap.get(id)).filter(item => item !== undefined)

  return sortedData
}
