import Button from 'components/button'
import React from 'react'
import styles from './pledge.module.scss'
import planet from 'assets/images/planet.svg'
import people from 'assets/images/People.svg'
import places from 'assets/images/Places.svg'
import iconClose from 'assets/icons/iconCloseBlue.svg'

const PledgeModal = ({ onClickBack = null, onclickNext = null }) => {
  return (
    <div className={styles.upperPledgeWrapper}>
      <div className={styles.clickBack}>
        <div className={styles.back} onClick={onClickBack}>
          <img src={iconClose} alt="Close icon" />
        </div>
      </div>
      <div className={styles.pledgeWrapper}>
        <div className={styles.pledgeTitle}>The Wonder Shuffle Pledge</div>
        <div className={styles.pledgeInner}>
          All businesses on Wonder Shuffle must commit to the Wonder Shuffle Pledge:
          <div className={styles.imgAndText}>
            <img src={people} alt="" />
            <div className={styles.text}>PEOPLE</div>
          </div>
          We believe success is achieved through sharing and exchanging diverse perspectives and contributions among all
          People{' '}
          <div className={styles.imgAndText}>
            <img src={places} alt="" />
            <div className={styles.text}>PLACES</div>
          </div>
          We actively pursue equity and inclusion in every decision we make in the Places we live, work and serve
          <div className={styles.imgAndText}>
            <img src={planet} alt="" />
            <div className={styles.text}>PLANET</div>
          </div>
          We consciously consider the impact of our actions on our Planet - with all the People and Places in it – in
          everything we do.
        </div>
        <div className={styles.button}>
          <Button variation="secondary" label="AGREE & SUBMIT" onClick={onclickNext} />
        </div>
      </div>
    </div>
  )
}

export default PledgeModal
