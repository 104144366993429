import edit from 'assets/images/editIcon.svg'
import CampaignCreation from 'components/campaignCreation'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { openModal } from 'store/reducers/modal/modalSlice'
import styles from './singleCampain.module.scss'

const SingleCampain = ({ props }) => {
  const dispatch = useDispatch()
  const { name = '', duration = 0, type = '', createdAt = 0 } = props

  const startDate = moment(createdAt).format('DD/MM/YYYY')
  // start date + duration * one day in timestamp
  const endDateTimestamp = createdAt + duration * 1000 * 60 * 60 * 24
  const endDate = moment(endDateTimestamp).format('DD/MM/YYYY')

  const handleEdit = props => {
    dispatch(
      openModal({
        content: <CampaignCreation campaignEdit={props} edit={true} />,
        show: true,
        name: 'Edit campaign modal',
        size: 'max1300'
      })
    )
  }

  return (
    <div className={styles.singleCampainWrapper}>
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <div className={styles.name}>{name}</div>
          <div className={styles.type}>{type}</div>
          <div className={styles.duration}>
            {startDate} - {endDate}
          </div>
        </div>
      </div>
      <div className={styles.edit} onClick={() => handleEdit(props)}>
        <img src={edit} alt="edit" />
      </div>
    </div>
  )
}

export default SingleCampain
