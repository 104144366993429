import React from 'react'
import styles from './user.module.scss'
import PropTypes from 'prop-types'
import defaultLogo from '../../assets/images/defaultAvatar.jpg'

const User = ({ title, subtitle, logo, className, type, fontColor, onClick }) => {
  const onClickHandler = () => {
    onClick && onClick()
  }
  const onImageNonExist = err => {
    err.target.onError = null
    err.target.src = defaultLogo
  }
  return (
    <div className={`${styles.container} ${className} ${styles[type]}`} onClick={onClickHandler}>
      <div className={styles.logo}>
        <img src={logo ?? defaultLogo} alt="logo" onError={onImageNonExist} />
      </div>
      <div className={styles.content} style={{ color: fontColor + '!important' }}>
        <span title={title} className={styles.title}>
          {title}
        </span>
        <span title={subtitle} className={styles.subtitle}>
          {subtitle}
        </span>
      </div>
    </div>
  )
}

User.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  logo: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.string,
  fontColor: PropTypes.string
}
export default User
