import React from 'react'
import Card from 'components/card'
import CardBody from 'components/card/components/body'
import DndFields from 'components/dndFields'
import styles from './cardDoubleBackEditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_SIZE, CARD_TYPES } from 'staticConstants'
import CardHeader, { HeaderPosition, HeaderSize } from 'components/card/components/header'
import WonderLogo from 'assets/logo/iconShortLogoWhite.svg'
import CardScrollableContent from 'components/card/components/scrollableContent'
import MediaPicker from 'components/mediaPicker'
import CardWrapper from 'components/card/components/wrapper'
import { getMediaFromFileOrString } from 'utils/getMediaFromFileOrString'
import { setSelectedCard } from 'store/reducers/card/cardSlice'

const CardDoubleBackEditable = ({ className }) => {
  const dispatch = useDispatch()
  const card = useSelector(state => state.card.selectedCard)
  const onDeleteHandler = position => {
    const tempArray = []
    card.backFields.forEach(({ id, type, content }, index) => {
      if (index !== position) tempArray.push({ id: id, type: type, content: content })
    })
    dispatch(setSelectedCard({ ...card, backFields: tempArray }))
  }
  const onChangeHandler = array => {
    dispatch(setSelectedCard({ ...card, backFields: array }))
  }
  const onImageChange = image => {
    dispatch(setSelectedCard({ ...card, doubleCardImage: image }))
  }
  return (
    <CardWrapper className={`${styles[className]} ${className}`}>
      <Card className={className} type={CARD_TYPES.Double} size={CARD_SIZE.Large} double>
        <CardBody type={CARD_TYPES.Double} size={CARD_SIZE.Large}>
          <MediaPicker
            className="doubleCard"
            onImageChange={onImageChange}
            stateMedia={getMediaFromFileOrString(card.doubleCardImage)}
            showCrop
            type="doubleCard"
            widthForCropDivInsideWrapper="50%"
          />
          <div
            className={styles.container}
            style={{
              width: 'calc(50% - 0.625rem)',
              backgroundColor: card.backFields.length > 0 ? 'rgba(255,255,255,0.5)' : 'transparent'
            }}
          >
            <CardHeader
              type={CARD_TYPES.Double}
              size={HeaderSize.LargeWithLogo}
              position={HeaderPosition.Block}
              heading="Wonder Shuffle"
              subheading="Do The Shuffle!"
              logo={WonderLogo}
              className="m-5"
            />
            <CardScrollableContent type={card.cardType} className={styles.content}>
              <div className={styles.dndFields}>
                <DndFields fields={card.backFields} onDelete={onDeleteHandler} onChange={onChangeHandler} />
              </div>
            </CardScrollableContent>
          </div>
        </CardBody>
      </Card>
    </CardWrapper>
  )
}
export default CardDoubleBackEditable
