import styles from '../registerText.module.scss'

const WonderPlusText = () => {
  return (
    <div className={styles.wrapper}>
      <li>
        Are you a Business, Creator or Blog and want to share your stories, products and services and build a
        followership amongst our Wonder Shufflers?
      </li>
      <li>As a Wonder Shuffle Plus Subscriber you can:</li>
      <li style={{ paddingLeft: '1rem' }}>&#x2022; Open an account to create cards and message your followers;</li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; Build Standard cards as well as Slider cards (perfect for photo essays or comic strips);
      </li>
      <li style={{ paddingLeft: '1rem' }}>&#x2022; Place embedded links and videos inside your cards;</li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; Reach a wider audience by placing 3 Cards a month in our exciting Marketplace;
      </li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; Target readers with a weekly campaign with 1 Card through either the Main Deck OR Marketplace;
      </li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; Produce exciting content that, as it gains popularity, has the opportunity to be promoted towards the
        front of a Wonder Shuffle user’s Main Deck;
      </li>
      <li style={{ paddingLeft: '1rem' }}>&#x2022; Access backend aggregated analytics; and</li>
      <li style={{ paddingLeft: '1rem' }}>
        &#x2022; Champion People, Places and the Planet by committing to the Wonder Shuffle Pledge.
      </li>
      <li>
        The cost for a Wonder Shuffle Plus Package is $20/month, with an additional penny per impression after 10,000
        impressions.
      </li>
    </div>
  )
}

export default WonderPlusText
