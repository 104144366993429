export const days = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' }
]

export const dataFormats = [
  { label: 'MM/DD/YYYY', date: 'MM/DD/YYYY', datePicker: 'MM/dd/yyyy' },
  { label: 'DD/MM/YYYY', date: 'DD/MM/YYYY', datePicker: 'dd/MM/yyyy' }
]
export const DEFAULT_PAGE_SIZE = 20
export const AGE_RANGE_OPTIONS = [
  { value: '', label: 'View All' },
  // { value: '13-17', label: '13-17' },
  { value: '18-24', label: '18-24' },
  { value: '25-34', label: '25-34' },
  { value: '35-49', label: '35-49' },
  { value: '50-65', label: '50-65' },
  { value: '65+', label: '65+' }
]

export const SORT_OPTIONS = [
  { value: 'Name (A-Z)', label: 'Name (A-Z)' },
  { value: 'Name (Z-A)', label: 'Name (Z-A)' },
  { value: 'Tier (Lowest First)', label: 'Tier (LowestFirst)' },
  { value: 'Tier (Highest First)', label: 'Tier (Highest First)' },
  { value: 'Followers (Lowest First)', label: 'Followers (Lowest First)' },
  { value: 'Followers (Highest First)', label: 'Followers (Highest First)' },
  { value: 'Date Created (Newest First)', label: 'Date Created (Newest First)' },
  { value: 'Date Created (Oldest First)', label: 'Date Created (Oldest First)' }
]

export const USER_LEVEL_OPTIONS = [
  { value: '', label: 'View All' },
  { value: 1, label: 'Pink' },
  { value: 2, label: 'Silver' },
  { value: 3, label: 'Gold' },
  { value: 4, label: 'Platinum' },
  { value: 5, label: 'Diamond' }
]
export const TIER_NAME_BY_LEVEL = {
  1: 'Pink',
  2: 'Silver',
  3: 'Gold',
  4: 'Platinum',
  5: 'Diamond'
}

export const STATUS_OPTIONS = [
  { value: '', label: 'View All' },
  { value: 1, label: 'Active' },
  { value: 2, label: 'Inactive' },
  { value: 3, label: 'Disabled' },
  { value: 4, label: 'Deleted' }
]

export const USERS_TABLE_COLUMNS = [
  { label: 'Name', key: 'name' },
  { label: 'Username', key: 'username' },
  { label: 'Age Range', key: 'ageRange' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phoneNumber' },
  { label: 'Tier', key: ['tier', 'name'] },
  { label: 'Role', key: 'formatedSubType' },
  { label: 'Subscription ends', key: 'subscriptionEnds', format: 'date' },
  { label: 'Created At', key: 'createdAt', format: 'date' },
  { label: 'Last Login', key: 'loggedAt' },
  { label: 'Followers', key: 'followers' },
  { label: 'Followings', key: 'followings' },
  { label: 'Active', key: 'activeEl' },
  { label: '', key: 'edit' }
]
export const EMPTY_SEARCHES_TABLE_COLUMNS = [
  { label: 'Name', key: '_id' },
  { label: 'No. Searches', key: 'count' },
  { label: 'Rate of Change', key: 'roc' },
  { label: '', key: 'actionButtons' }
]

export const LOCATION_OPTIONS = [
  { value: 'any', label: 'Any Location' },
  { value: 'hongKong', label: 'Hong Kong' },
  { value: 'london', label: 'London' },
  { value: 'losAngeles', label: 'Los Angeles' },
  { value: 'paris', label: 'Paris' },
  { value: 'newYork', label: 'New York' },
  { value: 'singapore', label: 'Singapore' },
  { value: 'online', label: 'Online' },
  { value: 'other', label: 'Other' }
]

export const MAX_SIZE_FOR_VIDEO = 6
export const MAX_SIZE_FOR_IMAGE = 4
