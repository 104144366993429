import iconClose from 'assets/icons/iconCloseBlue.svg'
import Button from 'components/button'
import CardFlip from 'components/cardFlip'
import Heading from 'components/Heading'
import Input from 'components/input'
import InstacardHelp from 'components/instacardHelp'
import Selector from 'components/select'
import TagInput from 'components/tagInput'
import Toggle from 'components/toggle'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import dataService from 'services/data.service'
import { toastService } from 'services/toastService'
import { CARD_CREATE_CATEGORY_OPTIONS, USER_TYPE_VALUE } from 'staticConstants'
import { updateScrapedCard } from 'store/reducers/scrape/scrapeSlice'
import { scrapePage } from 'store/reducers/scrape/thunk'
import styles from './instacardCreation.module.scss'

const InstacardCreation = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector(state => state.auth.user)
  const [scrapingCard, setScrapingCard] = useState(false)
  const scrapedAtTime = useSelector(state => state.scrape.scrapedAt)
  const scrapedFlag = useSelector(state => state.scrape.scrapedFlag)
  const scrapedCard = useSelector(state => state.scrape.scrapedCard)
  const [scrapeUrl, setScrapeUrl] = useState('')
  const [explanationScrape, setExplanationScrape] = useState(false)
  const [showScrapedCard, setShowScrapedCard] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (scrapedAtTime + 10000 > moment().valueOf()) {
      if (scrapedFlag) {
        setShowScrapedCard(true)
      } else {
        toastService('error', 'Error creating card.')
      }
    }
  }, [scrapedAtTime && scrapedCard])

  useEffect(() => {
    if (user?.subType && ![USER_TYPE_VALUE.media, USER_TYPE_VALUE.admin, USER_TYPE_VALUE.editor].includes(user.subType)) {
      history.push('/home')
    }
  }, [user?._id])

  const goToMediaCampaign = () => {
    history.push('/campaign/new/media')
  }

  const onInputChange = (name, value) => {
    if (name) {
      dispatch(updateScrapedCard({ [name]: value }))
    }
  }

  const isFormValid = () => {
    return Boolean(scrapedCard?.category)
  }

  const onSubmitInstacardCreation = async () => {
    if (isFormValid()) {
      setLoading(true)
      const card = await dataService.run('data', 'card', 'new', scrapedCard)
      if (card?._id || card?.data?._id) {
        toastService('info', 'Article successfully scraped.')
        goToMediaCampaign()
      } else {
        toastService('error', 'Error scraping article.')
      }
      setLoading(false)
    } else {
      setError(true)
    }
  }

  const handleScrapeBack = () => {
    goToMediaCampaign()
  }

  const handleChangeScrape = event => {
    setScrapeUrl(event?.target?.value || '')
  }

  const onScrapeCardClick = async () => {
    setShowScrapedCard(false)
    setScrapingCard(true)
    await dispatch(scrapePage({ url: scrapeUrl }))
    setScrapingCard(false)
  }

  return (
    <div className={styles.scrapeAndSave}>
      <Heading
        title="Create An Instacard!"
        leftMiddleElement={
          <div className={styles.back} onClick={handleScrapeBack}>
            &#x27A4;
          </div>
        }
      />
      <div className={styles.inputAndButton}>
        <Input
          placeholderOrentation="placeholderLeftLarge"
          value={scrapeUrl}
          onChange={handleChangeScrape}
          placeholder={'Enter URL'}
          className={'cardInput'}
          size={'xl'}
        />

        <Selector
          selectClass={'selectGrayInput'}
          placeholder="Category"
          selectedOption={CARD_CREATE_CATEGORY_OPTIONS.find(
            option => option.value?.toLowerCase() === scrapedCard?.category?.toLowerCase()
          )}
          handleChangeSelect={event => onInputChange('category', event?.value?.toLowerCase())}
          options={CARD_CREATE_CATEGORY_OPTIONS}
          error={error && !scrapedCard?.category}
          errorMsg="Category required"
        />
        <TagInput
          placeholder="Add extra search terms, including locations and categories, here (optional)"
          tags={scrapedCard.tags}
          className="full-width-fixed"
          onTagChange={tags => onInputChange('tags', tags)}
          value={scrapedCard.tags ?? ''}
        />
        <Toggle
          className={styles.defaultToggle}
          checked={scrapedCard.mature}
          label="Mature content?"
          matureInfo
          onChange={event => onInputChange('mature', event.target.checked)}
        />
        <div className={styles.divButton}>
          <Button
            label="Create Now"
            size="fitContent"
            onClick={onScrapeCardClick}
            disabled={scrapeUrl === '' || scrapingCard}
            spinner={scrapingCard}
          />
        </div>
      </div>
      <p style={{ marginTop: '2rem' }}>
        If you cannot generate an Instacard! to your liking, please
        <span onClick={() => setExplanationScrape(true)}> click here</span>. We may have a solution for you.
      </p>
      {explanationScrape && (
        <div className={styles.explanation}>
          <div className={styles.innerDiv}>
            <div className={styles.closeExp}>
              <img src={iconClose} alt="" onClick={() => setExplanationScrape(false)} />
            </div>
            <InstacardHelp />
          </div>
        </div>
      )}
      {showScrapedCard && (
        <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '80%' }}>
            <CardFlip data={scrapedCard} />
          </div>

          <div style={{ margin: 'auto' }}>
            <Button label="Save Card" size="fitContent" onClick={onSubmitInstacardCreation} spinner={loading} />
          </div>
        </div>
      )}
    </div>
  )
}

export default InstacardCreation
