import Button from 'components/button'
import Input from 'components/input'
import Login from 'pages/auth/login'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toastService } from 'services/toastService'
import { confirmForgotPasswordChange } from 'store/reducers/auth/thunk'
import { openModal } from 'store/reducers/modal/modalSlice'
import NewRequest from '../newRequest'
import styles from './changeForgotPassword.module.scss'

const ChangeForgotPassword = ({ data }) => {
  data = { ...data, email: JSON.parse(atob(data.email)) }
  const dispatch = useDispatch()
  const history = useHistory()

  const [showConfPassword, setShowConfPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [passwords, setPasswords] = useState({ password: '', confirmPassword: '' })
  const stateAcc = useSelector(state => state.auth.user)

  const confirmForgotPasswordFlag = useSelector(state => state?.auth?.confirmForgotPasswordChange)
  const timestamp = useSelector(state => state?.auth?.confirmForgotPasswordChangeTimestamp)

  const submitChangePassword = () => {
    const passTrimed = passwords.password.trim()
    const confirmPassTrimed = passwords.confirmPassword.trim()
    if (passTrimed === '' || confirmPassTrimed === '') {
      toastService('error', 'All fields are required.')
    } else if (passTrimed.length < 6 || confirmPassTrimed < 6) {
      toastService('error', 'Password must be at least 6 characters long.')
    } else if (passTrimed !== confirmPassTrimed) {
      toastService('error', 'Password and confirm password does not match.')
    } else if (passTrimed !== '' && confirmPassTrimed !== '' && passTrimed === confirmPassTrimed) {
      dispatch(confirmForgotPasswordChange({ data, passwords }))
      if (confirmForgotPasswordFlag === true) {
        history.push('/register')
        dispatch(openModal({ content: <Login />, name: 'Login Modal', size: 'max600', show: true }))
      }
    }
  }

  const onEyeHandler = () => {
    setShowPassword(!showPassword)
  }

  const onEyeConfHandler = () => {
    setShowConfPassword(!showConfPassword)
  }

  useEffect(() => {
    if (confirmForgotPasswordFlag === 'false') {
      dispatch(
        openModal({
          content: (
            <NewRequest
              type="Forgot Password"
              data={data}
              expiredToken={timestamp > 600000}
              currentUser={data?.email !== stateAcc?.email}
            />
          ),
          show: true,
          size: 'maxCenter500'
        })
      )
    }
  }, [timestamp])

  return (
    <div className={styles.wrapper}>
      <h2>Forgot Password Change </h2>
      <Input
        variation="formInput"
        inputClass="lgInput"
        placeholder="*password"
        type={showPassword ? 'text' : 'password'}
        hasEye={true}
        onEyeClick={onEyeHandler}
        onChange={e => setPasswords({ ...passwords, password: e.target.value })}
        containerClass={styles.forgotInput}
      />
      <Input
        variation="formInput"
        inputClass="lgInput"
        placeholder="*confirm password"
        type={showConfPassword ? 'text' : 'password'}
        hasEye={true}
        onEyeClick={onEyeConfHandler}
        onChange={e => setPasswords({ ...passwords, confirmPassword: e.target.value })}
        containerClass={styles.forgotInput}
      />
      <div>
        <Button label="Change password" variation="secondary" onClick={submitChangePassword} />
      </div>
    </div>
  )
}
export default ChangeForgotPassword
