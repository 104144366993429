import IconClose from 'assets/icons/states/failed.png'
import usePrevious from 'hooks/usePrevious'
import { useEffect, useState } from 'react'
import styles from './questionSlider.module.scss'
// import TextArea from 'components/textarea'
import { QUESTIONS_ON_CHANGE_ACTION } from 'components/card/editable/doubleSidedTriviaBack'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_TYPES } from 'staticConstants'
import { validateOneSideTriviaQuestion } from 'utils/getCardFields'
import CardScrollableContent from '../scrollableContent'
import SliderDots from './dots'
import SliderEditableAnswer from './editableAnswer'
import { setRequiredFields, setSelectedCard } from 'store/reducers/card/cardSlice'
const QuestionsSliderEditable = ({
  className,
  slides = [],
  onChange,
  onSlideChange,
  showDots = true,
  showExaplanation = true
  // onFinish
}) => {
  const dispatch = useDispatch()
  const { selectedCard } = useSelector(store => store?.card)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const prevSlides = usePrevious({ slides })
  const previousTriggerSlideSwitch = usePrevious(selectedCard?.triggerSlideSwitch)

  const validateSelectedQuestionFields = index => {
    const error = validateOneSideTriviaQuestion(selectedCard?.questions?.[index])
    if (Object?.keys(error)?.length > 0) {
      dispatch(
        setRequiredFields({
          error: true,
          fields: {
            ...error
          }
        })
      )
    } else {
      dispatch(
        setRequiredFields({
          error: false,
          tempFields: []
        })
      )
    }
  }

  const onMoveDot = index => {
    setCurrentSlideIndex(index)
    validateSelectedQuestionFields(index)
  }

  useEffect(() => {
    if (prevSlides) {
      if (prevSlides.slides.length < slides.length) {
        setCurrentSlideIndex(slides.length - 1)
      } else if (prevSlides.slides.length > slides.length) {
        setCurrentSlideIndex(currentSlideIndex > 0 ? currentSlideIndex - 1 : 0)
      }
    }
  }, [slides, prevSlides])

  useEffect(() => {
    onSlideChange && onSlideChange(currentSlideIndex)
  }, [currentSlideIndex])

  useEffect(() => {
    if (selectedCard?.triggerSlideSwitch >= 0 && selectedCard?.triggerSlideSwitch !== previousTriggerSlideSwitch) {
      setCurrentSlideIndex(selectedCard?.triggerSlideSwitch)
      dispatch(
        setSelectedCard({
          ...selectedCard,
          activeSlide: selectedCard?.triggerSlideSwitch,
          triggerSlideSwitch: -1
        })
      )
    }
  }, [selectedCard?.triggerSlideSwitch])

  return (
    <div className={`${className} ${styles[className]} ${styles.container}`}>
      {/* Close icon  */}
      <div className={styles.closeContainer}>
        {slides.length > 1 &&
          slides.map((slide, index) => {
            return (
              <img
                key={'close' + index}
                className={`${styles.closeIcon} ${styles.slide} ${currentSlideIndex === index ? styles.active : ''}`}
                alt="X"
                src={IconClose}
                onClick={() => onChange(QUESTIONS_ON_CHANGE_ACTION.DELETE_QUESTION)}
              />
            )
          })}
      </div>
      {/* Slides */}
      <div className={styles.slidesContainer}>
        {slides.length > 0 &&
          slides.map((slide, index) => {
            return (
              <div
                key={'slide' + slide.id + index}
                className={`${styles.slide} ${currentSlideIndex === index ? styles.activeAnim : ''} ${
                  !showDots ? styles.noDots : ''
                }`}
                style={{ zIndex: currentSlideIndex === index ? '40' : '35' }}
              >
                {/* Explanation */}
                {showExaplanation && (
                  <CardScrollableContent className={styles.scrollableQuestionContainer}>
                    <span className={styles.question}>{slide.explanation}</span>
                  </CardScrollableContent>
                )}
                {/* Answers */}
                <CardScrollableContent
                  className={styles.scrollableOptionsContainer}
                  type={CARD_TYPES.DoubleSidedTrivia}
                >
                  <div className={styles.answersContainer}>
                    {/* Editable Answers  */}
                    {slide.answers.map(answer => (
                      <SliderEditableAnswer
                        key={'answer' + answer.id + index}
                        answer={answer}
                        index={index}
                        onChange={onChange}
                      />
                    ))}
                  </div>
                </CardScrollableContent>
              </div>
            )
          })}
      </div>
      {/* Dots  */}
      {showDots && slides.length > 0 && (
        <SliderDots slides={slides} onChange={onMoveDot} currentSlideIndex={currentSlideIndex} />
      )}
    </div>
  )
}

export default QuestionsSliderEditable
